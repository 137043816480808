import React, { useMemo } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "../../../components/ui/card";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { TooltipIcon } from "../../../screens/OverviewScreen/ProgressPrice";

interface BreakEvenAnalysisProps {
  actualPeriodsOnly?: boolean;
}

interface RowItemProps {
  label: string;
  value: string | number;
  tooltipContent?: string;
  isHighlighted?: boolean;
  isNegative?: boolean;
  highlightColor?: string;
  textColor?: string;
}

const RowItem: React.FC<RowItemProps> = ({ label, value, tooltipContent, isHighlighted = false, isNegative = false, highlightColor = "bg-gray-100", textColor = "text-black" }) => {
  // Format value if it's a number
  const formattedValue = typeof value === "number" ? (value === 0 ? "$0" : isNaN(value) ? "n.a." : (value < 0 ? "-" : "") + "$" + Math.abs(value).toLocaleString()) : value;

  return (
    <div className={`flex justify-between items-center px-2 h-8 ${isHighlighted ? `${highlightColor} rounded-md` : ""}`}>
      {/* Row Label with Tooltip */}
      <div className={`flex gap-2 items-center text-sm ${isHighlighted ? `${textColor} font-semibold` : "text-gray-500 font-medium"}`}>
        <span className="truncate">{label}</span>
        {tooltipContent && <TooltipIcon content={tooltipContent} />}
      </div>

      {/* Row Value */}
      <p className={`text-sm font-semibold ${isNegative ? "text-red-500" : isHighlighted ? textColor : "text-black"}`}>{formattedValue}</p>
    </div>
  );
};

const BreakEvenAnalysis: React.FC<BreakEvenAnalysisProps> = ({ actualPeriodsOnly = false }) => {
  const { t } = useTranslation();
  const cashflow = useSelector((state: any) => state.cashflow.tableData.cashFlow);
  const tranches = useSelector((state: any) => state.cashflow.tranches?.tranches || state.cashflow.tranches || []);

  // Filter cashflow to only include actual periods if actualPeriodsOnly is true
  const filteredCashflow = useMemo(() => {
    return actualPeriodsOnly ? (cashflow ? cashflow.filter((item: any) => item.isForecasted === false) : []) : cashflow;
  }, [cashflow, actualPeriodsOnly]);

  // Calculate values
  const calculations = useMemo(() => {
    if (!filteredCashflow || !Array.isArray(filteredCashflow) || filteredCashflow.length === 0) {
      return {
        grossRevenueRequired: NaN,
        averageEbitda: NaN,
        existingDebtService: 0,
        trancheServices: [], // Use an array of objects instead of separate variables
        breakEvenCash: 0,
        revenues: 0,
        allowableDecreaseRequired: NaN,
      };
    }

    // Calculate average EBITDA margin over all periods
    let totalEbitdaMargin = 0;
    let countPeriods = 0;

    filteredCashflow.forEach((yearData) => {
      const ebitda = yearData["Adjusted EBITDA"]?.value || 0;
      const revenue = yearData["Revenue"]?.value || 0;

      if (revenue > 0) {
        totalEbitdaMargin += ebitda / revenue;
        countPeriods++;
      }
    });

    const averageEbitdaMargin = countPeriods > 0 ? totalEbitdaMargin / countPeriods : 0;

    // Get the first period debt service (existing debt)
    const existingDebtService = filteredCashflow.length > 0 ? filteredCashflow[0]["Debt Ending Balance"]?.value || 0 : 0;

    // Helper function to calculate debt service for a tranche (same as in DebtServiceComponent)
    const calculateTrancheDebtService = (tranche: any): number => {
      if (!tranche || !tranche.principal || !tranche.rate || !tranche.amortization) {
        return 0;
      }

      const principal = tranche.principal;
      const rate = tranche.rate;
      const amortizationMonths = tranche.amortization;
      const isFullStandby = tranche.fullStandby === "Yes";
      const isBalloon = tranche.balloon === "Yes";

      // Convert annual rate to monthly
      const monthlyRate = rate / 100 / 12;

      // For revolver or full standby loans, return only interest (no principal)
      const isInterestOnly = isFullStandby || (principal === 100000 && amortizationMonths === 36);

      if (isInterestOnly) {
        return principal * monthlyRate * 12; // Annual interest-only payment
      }

      // Standard amortization formula for the first year
      const monthlyPayment = (principal * monthlyRate * Math.pow(1 + monthlyRate, amortizationMonths)) / (Math.pow(1 + monthlyRate, amortizationMonths) - 1);

      // Calculate first year's payment
      let remainingPrincipal = principal;
      let firstYearPayment = 0;

      // For balloon loans with amortization of 1 year or less, calculate full payment
      const amortizationYears = Math.ceil(amortizationMonths / 12);
      if (isBalloon && amortizationYears <= 1) {
        return principal + principal * (rate / 100); // Principal + one year's interest
      }

      // Calculate first 12 months of payments
      for (let month = 0; month < 12; month++) {
        if (remainingPrincipal <= 0) break;

        // Monthly interest
        const monthlyInterest = remainingPrincipal * monthlyRate;

        // Portion of monthly payment going to principal
        const monthlyPrincipal = Math.min(monthlyPayment - monthlyInterest, remainingPrincipal);

        firstYearPayment += monthlyPrincipal + monthlyInterest;
        remainingPrincipal -= monthlyPrincipal;
      }

      return firstYearPayment;
    };

    // Instead of hardcoded tranche types, collect each tranche with its category and payment
    const trancheServices: Array<{
      name: string;
      payment: number;
      tooltipContent: string;
    }> = [];

    // Extract debt service values from tranches
    if (tranches && Array.isArray(tranches)) {
      tranches.forEach((tranche) => {
        // Skip if the tranche has no name
        if (!tranche.name) return;

        // Calculate first year debt service
        const annualPayment = calculateTrancheDebtService(tranche);

        // Skip if the payment is zero
        if (annualPayment <= 0) return;

        // Simply use the tranche name as is and append "Debt Service" if not already part of the name
        const displayName = tranche.name.toLowerCase().includes("debt service") ? tranche.name : `${tranche.name} Debt Service`;

        // Add to our tranche services array
        trancheServices.push({
          name: displayName,
          payment: annualPayment,
          tooltipContent: `Annual debt service payment for ${tranche.name}`,
        });
      });
    }

    // Calculate breakeven cash - sum of all debt services
    const breakEvenCash = trancheServices.reduce((sum, tranche) => sum + tranche.payment, 0);

    // Get revenue from the last period (forecasted)
    const lastPeriodIdx = filteredCashflow.length - 1;
    const revenues = lastPeriodIdx >= 0 ? filteredCashflow[lastPeriodIdx]["Revenue"]?.value || 0 : 0;

    // Calculate Gross Revenue Required
    const grossRevenueRequired = averageEbitdaMargin > 0 ? breakEvenCash / averageEbitdaMargin : NaN;

    // Calculate Allowable Decrease/Required Increase
    const allowableDecreaseRequired = grossRevenueRequired > 0 ? revenues / grossRevenueRequired - 1 : NaN;

    return {
      grossRevenueRequired,
      averageEbitda: averageEbitdaMargin,
      existingDebtService,
      trancheServices,
      breakEvenCash,
      revenues,
      allowableDecreaseRequired,
    };
  }, [filteredCashflow, tranches]);

  // Format percentage values
  const formatPercentage = (value: number) => {
    if (isNaN(value)) return "n.a.";
    return (value * 100).toFixed(1) + "%";
  };

  return (
    <Card className="col-span-1 flex flex-col w-full p-4 space-y-4 shadow-md gap-2 max-w-[50%] mr-auto">
      {/* Header */}
      <div className="flex items-center justify-between mb-2">
        <CardTitle>{actualPeriodsOnly ? "Break Even Analysis" : "Break Even Analysis"}</CardTitle>
      </div>

      {/* Table Content */}
      <CardContent className="p-2 space-y-1">
        <RowItem label="Gross Revenue Required" value={isNaN(calculations.grossRevenueRequired) ? "n.a." : Number(calculations.grossRevenueRequired.toFixed(0))} tooltipContent="The gross revenue required to break even based on debt service requirements" />
        <RowItem label="Average EBITDA" value={isNaN(calculations.averageEbitda) ? "n.a." : formatPercentage(calculations.averageEbitda)} tooltipContent="Average EBITDA margin calculated across all periods" />
        {/* Display existing debt service if it exists */}
        {calculations.existingDebtService > 0 && <RowItem label="Existing Debt Service" value={calculations.existingDebtService} tooltipContent="Debt ending balance from the first period" isNegative={true} />}
        {/* Dynamically render each tranche that has a value */}
        {calculations.trancheServices.map((tranche, index) => (
          <RowItem key={index} label={tranche.name} value={Number(tranche.payment.toFixed(0))} tooltipContent={tranche.tooltipContent} isNegative={true} />
        ))}
        <RowItem label="Breakeven Cash" value={Number(calculations.breakEvenCash.toFixed(0))} tooltipContent="Total debt service payments required to break even" isHighlighted />
        <div className="my-2"></div> {/* Spacer */}
        <RowItem label="Revenues" value={Number(calculations.revenues.toFixed(0))} tooltipContent="Revenue from the last forecasted period" />
        <div className="my-2"></div> {/* Spacer */}
        <RowItem
          label="Allowable Decrease/Required Increase"
          value={isNaN(calculations.allowableDecreaseRequired) ? "n.a." : formatPercentage(calculations.allowableDecreaseRequired)}
          tooltipContent="The amount revenues can decrease (positive) or must increase (negative) while maintaining break-even"
          isHighlighted
          highlightColor={calculations.allowableDecreaseRequired < 0 ? "bg-red-100" : "bg-green-100"}
          textColor={calculations.allowableDecreaseRequired < 0 ? "text-red-800" : "text-green-800"}
        />
      </CardContent>
    </Card>
  );
};

export default BreakEvenAnalysis;
