import React, { FC, useRef, useState } from "react";
import { CellWrapper, useCellContext } from "@silevis/reactgrid";
import ForecastDialog from "./ForecastDialog";
import TooltipIcon from "./Tooltip";
export interface ChevronCellProps {
  text: string;
  indent: number; // how many levels to indent
  isExpanded: boolean;
  hasChildren: boolean;
  onExpandedToggled: () => void; // called when user toggles expand/collapse
  isTotal: boolean;
}

const ChevronCell: FC<ChevronCellProps> = ({ text, indent, isExpanded, hasChildren, onExpandedToggled, isTotal }) => {
  const ctx = useCellContext();
  // Remove isEditMode state as we never want to edit this cell
  const inputRef = useRef<HTMLInputElement>(null);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  // For Excel-like copy/paste, we provide these two callbacks:
  const handleStringValueRequested = () => text;
  const handleStringValueReceived = (newText) => {
    // No-op - we don't want to allow any edits
    return;
  };
  const [openDialogRowId, setOpenDialogRowId] = useState<string | null>(null);
  return (
    <CellWrapper
      onStringValueRequested={handleStringValueRequested}
      onStringValueReceived={handleStringValueReceived}
      className="font-semibold"
      style={{
        backgroundColor: `${hasChildren || isTotal ? "#f3f4f6" : "#fff"}`,
        // paddingLeft: `${indent * 2}rem`, // each indent is e.g. 2rem
        textAlign: "left",
        outline: "none",
        cursor: "default",
      }}
      // Completely prevent edit mode by handling all possible triggers
      onDoubleClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        return false;
      }}
      onKeyDown={(e) => {
        // Prevent any key from triggering edit mode
        e.stopPropagation();
        e.preventDefault();

        // Only allow special handling for specific keys that shouldn't trigger edit mode
        if (e.key === "ArrowLeft" || e.key === "ArrowRight" || e.key === "ArrowUp" || e.key === "ArrowDown" || e.key === "Tab" || e.key === "Escape") {
          // Let these navigation keys propagate to ReactGrid
          e.stopPropagation();
          return true;
        }
        return false;
      }}
      onClick={(e) => {
        // Prevent clicks from triggering edit mode
        e.stopPropagation();
      }}
      onFocus={(e) => {
        // Prevent focus events from triggering edit mode
        e.preventDefault();
      }}
    >
      <div
        className="truncate"
        style={{
          display: "inline-flex",
          alignItems: "center",
          width: "95%",
          justifyContent: "flex-start",
          gap: "3px",
          overflow: "hidden", // Ensure content doesn't overflow
          textOverflow: "ellipsis", // Add ellipsis for overflow
          whiteSpace: "nowrap", // Prevent text from wrapping
        }}
      >
        {hasChildren ? (
          <span
            className=""
            style={{
              display: "inline-block",
              transform: isExpanded ? "rotate(270deg)" : "rotate(0deg)",
              cursor: "pointer",
              marginRight: "4px",
            }}
            onPointerDown={(e) => {
              e.stopPropagation();
              onExpandedToggled();
            }}
          >
            ❯
          </span>
        ) : (
          // Just spacing if no children
          <span style={{ width: "1rem", display: "inline-block" }} />
        )}

        <span className="truncate mr-1">{text}</span>
        {/* {!hasChildren && (
          <span onClick={() => setIsDialogOpen(true)}>
            <TooltipIcon content="Edit Forecast" />{" "}
          </span>
        )} */}
        {/* 
          2) If no children, show an Info icon that triggers the forecast dialog.
             You can also do: if (!hasChildren && !isTotal), etc.
        */}
      </div>
      <ForecastDialog
        row={{ displayName: "Revenue", id: "1", terms: [], valuesByYear: {}, editableByYear: {} }}
        years={[]}
        isOpen={isDialogOpen}
        onClose={handleDialogClose}
        onApply={(value) => {
          // Save the value
        }}
      />
    </CellWrapper>
  );
};

export default ChevronCell;
