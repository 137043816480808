import React, { useEffect, useState } from "react";
import { Button } from "../../../components/ui/button";
import { DialogComponent } from "../../PipelineScreen/components/buttonaction";
import DrawerUploaderDemo from "../../../components/components/dialog-uploader";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNotifications } from "../../../router/NotificationsProvider";

const ButtonAction = () => {
  const { t } = useTranslation();
  const transaction = useSelector((state: any) => state.transactions.transaction);
  const [dis, setDis] = useState(transaction?.businessChecklistUploaded || false);
  const [isOpen, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  const { getObservedDocs } = useNotifications();

  // Update the disabled state when transaction changes
  useEffect(() => {
    setDis(transaction?.businessChecklistUploaded || false);
  }, [transaction]);

  const row = {
    original: {
      id: "businesschecklist",
      title: "Business checklist",
      type: "business-checklist",
    },
  };

  // Get the business checklist data from the notifications provider
  const dealsData = getObservedDocs(`deals_${transaction?.organisationId}`) || [];

  // Look for our transaction in the deals data
  const currentDeal = transaction?.id ? dealsData.find((deal: any) => deal.id === transaction.id) : null;

  // Monitor the checklist upload status
  useEffect(() => {
    if (currentDeal) {
      setDis(currentDeal.businessChecklistUploaded || false);
    }
  }, [currentDeal]);

  // Create dialog content object
  const dialogContent = {
    // @ts-ignore
    title: t("buttonAction.dialog.title"),
    // @ts-ignore

    description: t("buttonAction.dialog.description"),
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        // Use currentDeal first, fall back to transaction if not available
        disabled={currentDeal ? currentDeal.businessChecklistUploaded : dis}
        variant={"outline"}
      >
        {
          // @ts-ignore
          t("buttonActionCheck.uploadChecklist")
        }
      </Button>

      {/* <DialogComponent isOpen={isOpen} onClose={onClose} dialogContent={dialogContent} selectedDealType="document-upload" /> */}

      <DrawerUploaderDemo row={row} isDrawerOpen={isOpen} setDrawerOpen={setOpen} />
    </>
  );
};

export default ButtonAction;
