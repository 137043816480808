import React, { useEffect, useMemo, useRef } from "react";
import ReusableFinancialTable, { FinancialRow } from "./reactgrid";

import { Card, CardContent, CardHeader, CardTitle } from "../../../components/ui/card";
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from "../../../components/ui/table";
import { Button } from "../../../components/ui/button";
import { ScrollArea, ScrollBar } from "../../../components/ui/scroll";
import { useTranslation } from "react-i18next";
// import { Scroll } from "lucide-react";
const labelWidth = 350;
// Table Data
interface TableRowData {
  label: string;
  values: (string | number | any)[];
  isHighlighted?: boolean;
  rowType?: "default" | "mini-total" | "large-total" | string; // Add string type to allow flexibility
  isNegative?: boolean;
}

// Data for the financial table

// Data for the financial table (10 years)

const Row = ({ row }: { row: TableRowData }) => {
  const rowClasses = {
    default: "border-none",
    "mini-total": "font-semibold border-t border-b border-gray-300",
    "large-total": "bg-gray-100 font-bold border-none",
  };

  return (
    <TableRow className={`group  ${rowClasses[row.rowType || "default"]}`}>
      {/* Sticky Column Label */}
      <TableCell className={`sticky left-0 min-w-[310px] font-medium z-10 bg-white group-hover:bg-gray-100 ${row.rowType === "large-total" ? "bg-gray-100" : ""}`}>{row.label}</TableCell>
      {/* Row Values */}
      {row.values.map((value, index) => (
        <TableCell key={index} className={`text-right min-w-[150px] group-hover:bg-gray-100 ${row.isNegative && index === 0 ? "text-red-500" : ""}`}>
          {value !== "" && typeof value === "number" ? value.toLocaleString() : value}
        </TableCell>
      ))}
    </TableRow>
  );
};
interface FinancialTableProps {
  investmentCashFlowData: TableRowData[]; // Single row data
  dataModel: any; // The JSON model you showed for Balance Sheet or P&L
  title: string; // "Balance Sheet", "P&L", or "Cash Flow" etc.
  years: any[]; // e.g. ["2023", "2024"] or ["H1 2023", "H2 2023"]
  onSubmit?: (modifiedRows: FinancialRow[]) => void;
  transaction?: any;
  setExternal?: any;
  pricing?: any;
}

interface FinancialData {
  id: string;
  valuesByYear: Record<string, number>;
}

interface State {
  acquisitionCost: string;
  closingCost: string;
}

const FinancialTable: React.FC<FinancialTableProps> = ({ dataModel, title, years, onSubmit, transaction, setExternal, investmentCashFlowData, pricing }) => {
  const viewportRef1 = useRef<HTMLDivElement>(null);
  const viewportRef2 = useRef<HTMLDivElement>(null);
  const tableRef = useRef<{ handleSubmit: (isSubmit: boolean) => Promise<void> }>(null);
  // Memoize the transformed financial data
  const state = useMemo(
    () => ({
      acquisitionCost: String(pricing.purchasePrice || transaction.askPrice),
      closingCost: "20000", // Ensure correct naming to match `State` interface
    }),
    [transaction], // Only re-calculate when `transaction.askPrice` changes
  );
  const { t } = useTranslation(); // ✅ Use translation hook

  const syncScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (viewportRef1.current && viewportRef2.current) {
      // Determine which viewport triggered the event
      const isViewport1 = e.currentTarget === viewportRef1.current;

      if (isViewport1) {
        viewportRef2.current.scrollLeft = viewportRef1.current.scrollLeft;
      } else {
        viewportRef1.current.scrollLeft = viewportRef2.current.scrollLeft;
      }
    }
  };
  // console.log(transaction);
  const l = investmentCashFlowData.length === 0 ? 0 : investmentCashFlowData[0]!.values.length;
  const tableHeadData = Array.from({ length: l }, (_, index) => ({
    year: `Year ${index}`,
    date: `Dec ${2024 + index}`,
  }));
  const handleClick = () => {
    tableRef.current?.handleSubmit(true); // Submit the data
  };

  const handleCancel = () => {
    tableRef.current?.handleSubmit(false); // Cancel without dispatching
  };
  return (
    <Card className="w-full shadow-lg">
      {/* Card Header */}
      <CardHeader className="flex  justify-between">
        <CardTitle>
          {
            // @ts-ignore
            t("financialTable.investmentCashFlowDetails")
          }
        </CardTitle>
        <div className="ml-auto flex gap-3">
          <Button variant={"ghost"} onClick={handleCancel}>
            {
              // @ts-ignore
              t("financialTable.undo")
            }
          </Button>
          <Button onClick={handleClick}>
            {
              // @ts-ignore
              t("financialTable.save")
            }
          </Button>
        </div>
      </CardHeader>

      {/* Card Content */}
      <CardContent className="p-4 ">
        <ScrollArea type="always" viewportRef={viewportRef1} onScroll={syncScroll} className="w-full flex-1 p-4">
          <ScrollBar orientation="horizontal" className=" w-full  rounded-full " />
          <ReusableFinancialTable
            dataModel={dataModel}
            title="Project Cash Flow"
            years={years} // e.g. ["2028"]
            labelWidth={labelWidth}
            onSubmit={(modifiedRows) => {
              console.log("Final Data Submitted:", modifiedRows);
            }}
            state={state}
            ref={tableRef} // Pass the ref to child component
            growthRate={0.08}
            inflationRate={0.01}
          />
        </ScrollArea>
      </CardContent>
      <CardContent className="p-4 pt-0 ">
        <ScrollArea viewportRef={viewportRef2} onScroll={syncScroll} type="always" className="w-full flex-1 p-2">
          <Table className="table-auto min-w-[1200px] mb-2">
            {/* Table Header */}
            <TableHeader>
              <TableRow className="border-none hover:bg-transparent">
                {/* Sticky Column Label */}
                <TableHead className="sticky left-0 bg-white font-semibold min-w-[200px] text-black border-none">
                  {" "}
                  {
                    // @ts-ignore
                    t("financialTable.projectCashFlow")
                  }
                </TableHead>

                {/* Flex Column Header */}
                {tableHeadData.map((item, index) => (
                  <TableHead key={index} className="text-center min-w-[120px] text-black border-none hover:bg-transparent">
                    <div className="flex flex-col items-end">
                      <span className="font-semibold">{item.year}</span>
                      {item.date && <span className="text-sm text-black">{item.date}</span>}
                    </div>
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>

            {/* Table Body */}
            <TableBody>
              {investmentCashFlowData.map((row, index) => (
                <Row key={index} row={row} />
              ))}
            </TableBody>
          </Table>
          <ScrollBar orientation="horizontal" className=" w-full  rounded-full " />{" "}
        </ScrollArea>
      </CardContent>
    </Card>
  );
};

export default FinancialTable;
