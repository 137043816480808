import { AlertCircle } from "lucide-react";
import { Alert, AlertTitle, AlertDescription, AlertAction } from "../../../components/ui/alert";
import { useTranslation } from "react-i18next";

interface LoanTermAlertProps {
  title: string;
  description: string;
  buttonText: string;
  onApply: () => void;
  shouldShow: boolean;
  variant?: number;
}

// Reusable alert component for loan terms
export function LoanTermAlert({ title, description, buttonText, onApply, shouldShow, variant = 0 }: LoanTermAlertProps) {
  const { t } = useTranslation();

  if (!shouldShow) {
    return null;
  }

  return (
    <Alert
      variant={variant ? "destructive" : "default"}
      className="mb-4"
      actions={
        <>
          <AlertAction
            onClick={() => {
              onApply();
            }}
          >
            {buttonText}
          </AlertAction>
        </>
      }
    >
      {variant > 0 && <AlertCircle className="h-4 w-4" />}
      <div className="flex flex-col justify-center -mb-1">
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription className="inline">{description}</AlertDescription>
      </div>
    </Alert>
  );
}

interface LoanTermAlertsProps {
  uses: any[];
  totalUses: number;
  justificationRows: any[];
  tranches: any[];
  updateJustificationTerm: (index: number, term: number) => void;
  updateTrancheAmortization: (trancheName: string, amortization: number) => void;
  updateTrancheType: (trancheName: string, balloon: "Yes" | "No") => void;
}

export default function LoanTermAlerts({ uses, totalUses, justificationRows, tranches, updateJustificationTerm, updateTrancheAmortization, updateTrancheType }: LoanTermAlertsProps) {
  const { t } = useTranslation();

  // Check if real estate is >= 51% of total uses
  const realEstateIndex = uses.findIndex((u) => u.key === "realEstate");
  const realEstateAmount = realEstateIndex >= 0 ? uses[realEstateIndex].value : 0;
  const realEstatePercentage = totalUses > 0 ? (realEstateAmount / totalUses) * 100 : 0;
  const isRealEstateOver51Percent = realEstatePercentage >= 51;

  // Find SBA 7(a) tranche
  const sba7aTranche = tranches.find((t) => t.name === "SBA 7(a) Loan");
  const sbaTrancheTerm = sba7aTranche ? Math.ceil(sba7aTranche.amortization / 12) : 0;

  // Find any seller notes with balloon payment and 3+ year term
  const sellerNotes = tranches.filter((t) => t.name.toLowerCase().includes("seller note") && t.balloon === "Yes" && Math.ceil(t.amortization / 12) >= 3);

  // Determine if we should show the SBA term alerts - depends on whether term matches the right value
  const shouldShowSba10YearAlert = !isRealEstateOver51Percent && sbaTrancheTerm !== 10 && sba7aTranche !== undefined;
  const shouldShowSba25YearAlert = isRealEstateOver51Percent && sbaTrancheTerm !== 25 && sba7aTranche !== undefined;

  return (
    <>
      {/* Alert for SBA 10-year term when real estate < 51% */}
      <LoanTermAlert
        title="SBA 7(a) Loan Term Alert"
        description="SBA 7a: 10 year term (without real estate). The real estate portion is less than 51% of total use of proceeds."
        buttonText="Set SBA Term to 10 Years"
        shouldShow={shouldShowSba10YearAlert}
        onApply={() => {
          // Update justification row for SBA term
          // updateJustificationTerm(0, 10); // Update business acquisition term
          // updateJustificationTerm(2, 10); // Update M&E term
          // updateJustificationTerm(3, 10); // Update transaction expense term
          // updateJustificationTerm(4, 10); // Update SBA fees term
          // updateJustificationTerm(5, 10); // Update Working Capital term

          // Update SBA tranche amortization to 10 years (120 months)
          updateTrancheAmortization("SBA 7(a) Loan", 120);
        }}
      />

      {/* Alert for SBA 25-year term when real estate >= 51% */}
      <LoanTermAlert
        title="SBA 7(a) Loan Term Alert"
        description="SBA 7a: 25 year term as CRE purchase exceeds 51% of total use of proceeds. The term should be updated to reflect this."
        buttonText="Set SBA Term to 25 Years"
        shouldShow={shouldShowSba25YearAlert}
        onApply={() => {
          // Update justification row for real estate to 25 years
          // updateJustificationTerm(1, 25);

          // Blended term calculation would occur in the useEffect in the parent component

          // Update SBA tranche amortization to 25 years (300 months)
          updateTrancheAmortization("SBA 7(a) Loan", 300);
        }}
      />

      {/* Alert for seller notes with balloon payment */}
      {sellerNotes.map((note, index) => (
        <LoanTermAlert
          key={`seller-note-${index}`}
          title="Seller Note Terms"
          description="If the structure is a balloon payment of 3+ years after closing, amortize the note over a 7 year period. Balloon payment will be subject to bank subordination."
          buttonText="Convert to 7-Year Amortizing"
          shouldShow={true}
          onApply={() => {
            // Update seller note to be amortizing over 7 years (84 months)
            updateTrancheAmortization(note.name, 84);
            updateTrancheType(note.name, "No"); // Change to non-balloon
          }}
        />
      ))}
    </>
  );
}
