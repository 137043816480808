import { useState, useMemo, useEffect } from "react";
import { ReactGrid, NumberCell, NonEditableCell, Cell, StyledRange as defaultStyledRanges } from "@silevis/reactgrid";
import ChevronCell from "./ChevronCell";
import ValueCell from "./CustomNumberCell";
import TotalCell from "./TotalCell";
import { revertWaterfallToJson, outerStep } from "./outersteps";
import { useDispatch, useSelector } from "react-redux";
import { updateCashflowData } from "../../../redux/actions/cashflowAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { store } from "../../../redux/store";
import React, { forwardRef, useImperativeHandle } from "react";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import TooltipIcon from "./Tooltip";
import ForecastDialog from "./ForecastDialog";

export interface FinancialRow {
  id: string; // unique row identifier
  displayName: string; // what we display in the first column
  category?: string; // e.g. 'Revenue', 'Expenses', 'Assets', etc.
  parentName?: string;
  role?: "asset" | "liability" | "expense" | "revenue" | "total";
  terms: {
    name: string;
    value?: number | null;
    year: number;
    operation: "addition" | "subtraction" | "substraction" | "multiplication" | "division";
    isFixed?: boolean; // => indexes to inflation if true, else normal growth
    isForecasted?: boolean; // => if true, we scale it
  }[];
  valuesByYear: Record<string, number>; // e.g. {"2021": 10000, "2022": 20000}
  editableByYear: Record<string, boolean>; // e.g. {"2021": true, "2022": false}
  children?: FinancialRow[]; // assigned/filled at runtime
  _indent?: number; // used for UI indentation
}

interface ReusableFinancialTableProps {
  dataModel: any; // The JSON model you showed for Balance Sheet or P&L
  title: string; // "Balance Sheet", "P&L", or "Cash Flow" etc.
  years: any[]; // e.g. ["2023", "2024"] or ["H1 2023", "H2 2023"]
  onSubmit: (modifiedRows: FinancialRow[]) => any;
  labelWidth: number;
  state?: any;
  inflationRate: number;
  growthRate: number;
}

const numberFormat = new Intl.NumberFormat("de", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

function createChildRowsFromTerms(allRows: FinancialRow[]): FinancialRow[] {
  const result = [...allRows];
  const rowByDisplayName = new Map<string, FinancialRow>();
  // Prepopulate map
  for (const r of result) {
    rowByDisplayName.set(r.displayName, r);
  }

  for (const row of allRows) {
    if (row.terms && row.terms.length > 0) {
      for (const t of row.terms) {
        // If a row with displayName = t.name does not already exist, create it
        if (!rowByDisplayName.has(t.name)) {
          const newId = row.id + "--" + t.name;
          const newRow: FinancialRow = {
            id: newId,
            displayName: t.name,
            parentName: row.displayName,
            role: "expense", // you can decide a better default if needed
            terms: [],
            valuesByYear: {},
            editableByYear: {},
            children: [],
          };

          // Because the parent might have multiple year columns, set them all to 0 except for t.year
          const parentYears = Object.keys(row.valuesByYear);
          parentYears.forEach((yr) => {
            if (yr === String(t.year)) {
              newRow.valuesByYear[yr] = t.value ?? 0;
              newRow.editableByYear[yr] = t.isFixed ? false : true;
            } else {
              // other years default to 0, non‐editable
              newRow.valuesByYear[yr] = 0;
              newRow.editableByYear[yr] = false;
            }
          });

          result.push(newRow);
          rowByDisplayName.set(t.name, newRow);
        }
      }
    }
  }

  return result;
}

/**
 * Build parent->children links based on row.parentName
 */
function buildHierarchy(rows: FinancialRow[]): FinancialRow[] {
  // Clear out children arrays first
  rows.forEach((r) => {
    r.children = [];
  });

  // For each row that has parentName, link it to the parent's .children
  rows.forEach((child) => {
    if (child.parentName) {
      const parent = rows.find((r) => r.displayName === child.parentName);
      if (parent) {
        parent.children = parent.children || [];
        // if ()
        if (parent!.displayName !== "Debt Starting Balance") {
          parent.children.push(child);
        }
      }
    }
  });

  // Return only top-level rows (no parentName)
  return rows.filter((r) => !r.parentName);
}

/**
 * Flatten the tree for rendering in the grid.
 * We place the parent row, then its children (if expanded).
 */
function flattenRows(row: FinancialRow, expandedState: Record<string, boolean>, level: number): FinancialRow[] {
  const result: FinancialRow[] = [];
  // Mark indentation
  row._indent = level;
  // Add self
  result.push(row);

  // If expanded and children exist, flatten them in order
  if (expandedState[row.id] !== false && row.children && row.children.length > 0) {
    for (const child of row.children) {
      result.push(...flattenRows(child, expandedState, level + 1));
    }
  }

  return result;
}

function recalcAll(rows: FinancialRow[], maxPasses: number = 10): FinancialRow[] {
  // Map displayName -> row
  const rowByName: Record<string, FinancialRow> = {};
  rows.forEach((r) => {
    rowByName[r.displayName] = r;
  });

  let changed = true;
  let passCount = 0;

  while (changed && passCount < maxPasses) {
    changed = false;
    passCount++;

    for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
      const row = rows[rowIndex];

      if (row!.displayName === "Debt Starting Balance") {
        // Directly set Debt Starting Balance to previous year's Debt Ending Balance
        const yearKeys = Object.keys(row!.valuesByYear);

        for (const yearKey of yearKeys) {
          const prevYear = (parseInt(yearKey) - 1).toString(); // Get previous year

          if (rowByName["Debt Ending Balance"]) {
            row!.valuesByYear[yearKey] = rowByName["Debt Ending Balance"].valuesByYear[prevYear] ?? 0;
          } else {
            row!.valuesByYear[yearKey] = 0;
          }
        }

        changed = true;
        continue; // Skip further processing for this row!
      }
      // if (row!.displayName === "Payable Interest") {
      //   // Directly set Debt Starting Balance to previous year's Debt Ending Balance
      //   const yearKeys = Object.keys(row!.valuesByYear);

      //   for (const yearKey of yearKeys) {
      //     const prevYear = (parseInt(yearKey) - 1).toString(); // Get previous year

      //     if (rowByName["New Debt"]) {
      //       row!.valuesByYear[yearKey] = rowByName["New Debt"].valuesByYear[prevYear] ?? 0;
      //     } else {
      //       row!.valuesByYear[yearKey] = 0;
      //     }
      //   }

      //   changed = true;
      //   continue; // Skip further processing for this row!
      // }

      if (row!.terms && row!.terms.length > 0) {
        // Store old values for change detection
        const oldValues = { ...row!.valuesByYear };
        const yearKeys = Object.keys(row!.valuesByYear);

        for (const yearKey of yearKeys) {
          let resultValue: number | null = null;
          const prevYear = (parseInt(yearKey) - 1).toString(); // Get previous year

          for (const t of row!.terms) {
            let termVal = 0;
            const ref = rowByName[t.name];

            if (ref) {
              termVal = ref.valuesByYear[yearKey] ?? 0;
            } else if (typeof t.value === "number") {
              termVal = t.value;
            }

            if (resultValue === null) {
              // First operation => just set
              resultValue = termVal;
            } else {
              switch (t.operation) {
                case "addition":
                  resultValue += termVal;
                  break;
                case "subtraction":
                case "substraction": // Handling typo
                  resultValue -= termVal;
                  break;
                case "multiplication":
                  resultValue *= termVal;
                  break;
                case "division":
                  if (termVal !== 0) {
                    resultValue /= termVal;
                  }
                  break;
              }
            }
          }
          if (row!.displayName === "Payable Interest") {
            if (rowByName["New Debt"] && rowByName["New Debt"].valuesByYear[prevYear] !== undefined && rowByName["Payable Interest Percentage"] && rowByName["Payable Interest Percentage"].valuesByYear[yearKey] !== undefined) {
              // @ts-ignore
              resultValue -= rowByName["New Debt"].valuesByYear[prevYear] * rowByName["Payable Interest Percentage"].valuesByYear[yearKey];
            }
          }
          if (row!.displayName === "Taxable Income") {
            // @ts-ignore
            resultValue += rowByName["Payable Interest"].valuesByYear[yearKey];
          }

          // Convert specific values to negative
          if (["Tax", "Payable Interest"].includes(row!.displayName)) {
            resultValue = resultValue !== null ? -Math.abs(resultValue) : 0;
          }
          if (["Change in Current Assets", "Change in Long Term Assets"].includes(row!.displayName)) {
            resultValue = resultValue !== null ? -resultValue : 0;
          }

          // Store the calculated value
          if (resultValue !== null) {
            row!.valuesByYear[yearKey] = resultValue;
          }
        }

        // Check if any value changed
        for (const y of yearKeys) {
          if (oldValues[y] !== row!.valuesByYear[y]) {
            changed = true;
            break;
          }
        }
      }
    }
  }

  return rows;
}

const ReusableFinancialTable = forwardRef<
  { handleSubmit: (isSubmit: boolean) => Promise<void> }, // Ref type
  ReusableFinancialTableProps // Component props
>(({ labelWidth, dataModel, title, years = [{ year: "2023" }], onSubmit, state, growthRate, inflationRate }, ref) => {
  const years_dates = years.map((year) => year.year);
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  // const state = store.getState();
  const { growthRate: oldGrowth, inflationRate: oldInf } = useSelector((state: any) => state.cashflow.tableData);
  const transaction = useSelector((state: any) => state.transactions?.transaction);
  const organization = useSelector((state: any) => state.auth.organization);

  console.log(oldGrowth);

  // STATE

  const [rows, setRows] = useState<FinancialRow[]>(() => dataModel.model || []);
  const [expandedState, setExpandedState] = useState<Record<string, boolean>>({});
  const [openDialogRowId, setOpenDialogRowId] = useState<string | null>(null);

  // If dataModel changes, re-init rows
  React.useEffect(() => {
    setRows(dataModel.model || []);
  }, [dataModel]);
  // 1) Recompute "terms" whenever rows changes
  // 2) Rebuild parent→child hierarchy
  // 3) Expand any row that has children by default (once)
  const computedRows = useMemo(() => {
    // Make a copy
    let newRows = [...rows];
    // Step 1: Create child rows from `terms` that have no matching row
    newRows = createChildRowsFromTerms(newRows);

    // Step 2: Recalc aggregator rows top-down
    newRows = recalcAll(newRows);

    // Step 3: Build parent->child links
    const topLevel = buildHierarchy(newRows);

    // Expand all new parents by default (if not already in expandedState)
    const newExpanded = { ...expandedState };
    for (const r of newRows) {
      if (r.children && r.children.length > 0 && !(r.id in newExpanded)) {
        newExpanded[r.id] = true;
      }
    }
    if (JSON.stringify(newExpanded) !== JSON.stringify(expandedState)) {
      // asynchronously update expandedState
      setExpandedState(newExpanded);
    }

    return newRows;
  }, [rows, expandedState]);

  const isDialogOpenForRow = (rowId: string) => openDialogRowId === rowId;

  const handleApplyForecast = (rowId: string, forecastType: string, sliders: number[]) => {
    // Example logic from snippet above
    setRows((prev) => {
      const newRows = [...prev];
      // find row, adjust .valuesByYear
      return newRows;
    });
  };
  // Each time "rows" or "expandedState" changes, create a flattened array to render
  const flattenedRows = useMemo(() => {
    const topLevel = buildHierarchy(computedRows);
    const result: FinancialRow[] = [];
    for (const r of topLevel) {
      result.push(...flattenRows(r, expandedState, 0));
    }
    // Also add a "Total" in each row's `valuesByYear`
    return result.map((row) => {
      const sum = years_dates.reduce((acc, y) => acc + (row.valuesByYear[y] ?? 0), 0);
      return {
        ...row,
        valuesByYear: {
          ...row.valuesByYear,
          Total: sum,
        },
      };
    });
  }, [computedRows, expandedState, years]);
  // We also store an updated array of "rowsWithTotals" to pass to onSubmit
  const rowsWithTotals = useMemo(() => {
    return computedRows.map((r) => {
      const sum = years_dates.reduce((acc, y) => acc + (r.valuesByYear[y] ?? 0), 0);
      return {
        ...r,
        valuesByYear: { ...r.valuesByYear },
        editableByYear: { ...r.editableByYear },
      };
    });
  }, [computedRows, years]);
  // 4. Build the ReactGrid-compatible array of cells from rowsWithTotals
  // const year_label = years.map((year,i) => {year : year , label : rows[i].isForecasted ? "Forecast" : "Actual"});
  console.log("rows", rows);
  const allColumns = ["", ...years.map((year) => `${year.year}`)];
  const headerRowIndex = 0;
  const dataRowStartIndex = 1;
  const styles = {
    header: {},
    body: {},
  };
  const cells: Cell[] = [];

  // Build header cells
  allColumns.forEach((colName, colIndex) => {
    cells.push({
      rowIndex: headerRowIndex,
      colIndex,
      Template: NonEditableCell,
      props: {
        // Add a line break between colName and years
        value: colName === "" ? title : `${colName}-${years[colIndex - 1]?.isForecasted}`,
        style: {
          fontWeight: 500, // Bold header
          textAlign: colIndex === 0 ? "left" : "right",
        },
      },
    });
  });

  flattenedRows.forEach((row, i) => {
    const actualRowIndex = dataRowStartIndex + i;
    const indent = (row as any)._indent || 0;
    const isParent = row.children && row.children.length > 0;
    const isExpanded = expandedState[row.id];
    const isTotalRow = row.role === "total" || (row.terms && row.terms.length > 0);
    // === 1) First column => ChevronCell ===
    const rowStyle = isTotalRow ? { backgroundColor: "#f3f4f6", fontWeight: "500" } : {};
    const isAggregator = (row.terms && row.terms.length > 0) || row.role === "total";

    cells.push({
      rowIndex: actualRowIndex,
      colIndex: 0,
      // type: "chevron", // if you want, or just omit
      Template: isTotalRow ? TotalCell : ChevronCell,
      // isEditable: false,
      isFocusable: false,
      props: {
        text: row.displayName,
        indent,
        // Template: NonEditableCell,
        isExpanded: !!isExpanded,
        hasChildren: isParent,
        isTotal: isAggregator,
        style: { fontWeight: 500, ...rowStyle },
        onExpandedToggled: () => {
          setExpandedState((prev) => ({
            ...prev,
            [row.id]: !prev[row.id],
          }));
        },
      },
    });

    // === 2) The rest of the columns ===
    // console.log(row.displayName);
    allColumns.slice(1).forEach((colName, offset) => {
      const colIndex = offset + 1;
      const isEditable =
        (row.displayName !== "New Debt" &&
          (organization.type !== "lender" || row.parentName !== "Adjustments to EBITDA") &&
          (organization.type !== "lender" || row.displayName !== "Adjustments to EBITDA") &&
          row.displayName !== "Interest Paid" &&
          row.displayName !== "New Debt Interest Payment" &&
          row.editableByYear[colName]) ??
        false;
      // console.log(isEditable, row, row.parentName, colName);
      const rawValue = row.valuesByYear[colName] ?? 0;
      // For "Total" column, we can make it read-only:
      const isExpense = row.role === "expense";
      const displayedValue = rawValue;

      // 3) Choose correct formatter
      const formatter = numberFormat;
      const isTotalCol = colName === "Total";

      cells.push({
        rowIndex: actualRowIndex,
        colIndex,
        Template: isTotalCol ? NonEditableCell : ValueCell,
        // isFocusable: isEditable && !isTotalCol,
        isFocusable: isEditable,

        props: {
          style: {
            ...rowStyle,
            textAlign: "right",
            ...(isEditable ? { color: "rgb(0,0,255)", backgroundColor: "rgb(255,255,204)", border: "1px solid rgb(142,184,218)" } : {}),
          },
          format: formatter,
          value: displayedValue,
          // If editable, handle changes
          ...(isEditable &&
            !isTotalCol && {
              onValueChanged: (newValue: number) => {
                console.log("this is triggered");
                const actualVal = newValue;

                setRows((prev) => {
                  // Update the matching row in state
                  const copy = [...prev];
                  const idx = copy.findIndex((r) => r.id === row.id);
                  if (idx >= 0) {
                    const oldRow = copy[idx];
                    if (oldRow) {
                      copy[idx] = {
                        ...oldRow,
                        valuesByYear: {
                          ...(oldRow?.valuesByYear ?? {}),
                          [colName]: actualVal,
                        },
                      };
                    }
                  }
                  // Recompute totals (including parent's total)
                  // const recalculated = recomputeAllTerms(copy);
                  // return recalculated;
                  return copy;
                });
              },
            }),
        },
      });
    });
  });

  const styledRanges = {
    gap: {
      width: "0px",
      color: "transparent",
    },
    focusIndicator: {
      background: "transparent",
      border: {
        color: "#6b7280",
        // style: "dashed",
      },
    },

    // paneContainer: {
    //   top: { background: "transparent" },
    // },
    // cellContainer: {
    //   // padding: Padding;
    //   background: "transparent",
    // },
    line: { backgroundColor: "none" },
    gridWrapper: { fontSize: "0.875rem", lineHeight: "1.25rem" },
  };
  // etc.

  // 6. Provide a Submit handler

  const handleSubmit = async (actionType: "submit" | "cancel") => {
    const rev = revertWaterfallToJson(rowsWithTotals, outerStep);
    const { acquisitionCost, closingCost } = state;

    if (actionType === "submit") {
      await dispatch(updateCashflowData(rev, true, years_dates[0], rowsWithTotals, acquisitionCost, closingCost, transaction.id, false));
      console.log("Data submitted successfully");
    } else if (actionType === "cancel") {
      console.log("Changes reverted without saving");
      // Perform additional cancel logic if needed
    }

    // Common logic for both submit and cancel
    console.log("Processed Data:", rev);
  };
  const { t } = useTranslation();
  useImperativeHandle(ref, () => ({
    handleSubmit: async (isSubmit: boolean) => {
      const { acquisitionCost, closingCost } = state;

      if (isSubmit) {
        const rev = revertWaterfallToJson(rowsWithTotals, outerStep);
        await dispatch(updateCashflowData(rev, true, years_dates[0], rowsWithTotals, acquisitionCost, closingCost, transaction.id, true));
        console.log("Data submitted successfully");
        console.log("Processed Data:", rev);
        toast(
          // @ts-ignore
          t("toasts.data_submitted_successfully.title"),
          {
            // @ts-ignore
            description: t("toasts.data_submitted_successfully.description"),
          },
        );
      } else {
        await dispatch(updateCashflowData(dataModel, false, years_dates[0], rowsWithTotals, acquisitionCost, closingCost, transaction.id, false));
        console.log("Changes reverted without saving");
        toast(
          // @ts-ignore
          t("toasts.changes_reverted.title"),
          {
            // @ts-ignore
            description: t("toasts.changes_reverted.description"),
          },
        );
      }
    },
  }));

  const func = async () => {
    const { acquisitionCost, closingCost } = state;

    const rev = revertWaterfallToJson(rowsWithTotals, outerStep);
    await dispatch(updateCashflowData(rev, true, years_dates[0], rowsWithTotals, acquisitionCost, closingCost, transaction.id, false));
  };
  useEffect(() => {
    func();
  }, []);

  const rowDefinitions = flattenedRows.map((row, rowIndex) => {
    const hasChevron = (row.children && row.children.length > 0) || (row.terms && row.terms.length > 0);

    return {
      rowIndex: rowIndex, // ReactGrid wants an identifier
      height: 36, // or any default height
      reorderable: !hasChevron,
    };
  });

  // 2) Columns array
  // We'll create a column definition for each column (including the first "chevron" column).
  // Let's assume a default width of 120, except maybe for the first column which can be wider.

  const columnDefinitions = allColumns.map((colName, colIndex) => ({
    colIndex: colIndex,
    // example widths (make them whatever you want)
    width: colIndex === 0 ? labelWidth : 120,
  }));

  // Render
  // console.log(cells);
  return (
    <div>
      <ReactGrid rows={rowDefinitions} columns={columnDefinitions} cells={cells} styles={styledRanges} stickyLeftColumns={1} stickyTopRows={1} />

      {/* <button onClick={handleSubmit} style={{ marginTop: "10px" }}>
        Submit
      </button> */}
    </div>
  );
});

export default ReusableFinancialTable;
