import React, { useEffect, useState, useCallback } from "react";
import { z } from "zod";

import { getColumns } from "./columns";
import { DataTable } from "./DataTable";
import { UserNav } from "./user-nav";
import { taskSchema } from "../data/schema";
import Spinner from "../../../components/ui/Spinner";
import AlertDestructive from "./alert";
import DrawerUploaderDemo from "../../../components/components/dialog-uploader";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions, setTransaction } from "../../../redux/actions/transactionAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { clearDocuments, fetchDocuments, delDocument } from "../../../redux/actions/documentsAction";
import { useTranslation } from "react-i18next";
import { fetchCashflowData, fetchDebt, fetchPurchasePrice, updatecashgraph, updateMinideal } from "../../../redux/actions/cashflowAction";
import { useNotifications } from "../../../router/NotificationsProvider";
import { and, collection, or, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import { toast } from "sonner";
import { Alert, AlertTitle, AlertDescription, AlertAction } from "../../../components/ui/alert";
import { AlertCircle } from "lucide-react";
import { useFirebaseDocuments } from "../../../hooks/useFirebaseDocuments";

type Task = {
  id: string;
  title: string;
  status: string;
  label: string;
  // priority: string;
  timestamp?: number | null;
  type?: string | null;
};

// New component for long loading alerts
interface LongLoadingAlertProps {
  tasks: Task[];
  transaction: any;
  dispatch: ThunkDispatch<{}, {}, AnyAction>;
  onDelete: (taskId: string) => Promise<void>;
}

const LongLoadingAlert: React.FC<LongLoadingAlertProps> = ({ tasks, transaction, dispatch, onDelete }) => {
  const { t } = useTranslation();
  const [longLoadingTasks, setLongLoadingTasks] = useState<Task[]>([]);

  // Check for documents in "uploading" status for too long (5 minutes)
  useEffect(() => {
    const now = Date.now();
    // Filter tasks that have been uploading for more than 5 minutes
    const longLoading = tasks.filter((task) => {
      // Check if task is in uploading status and has a timestamp property
      return task.status === "uploading" && task.timestamp && now - task.timestamp > 5 * 60 * 1000; // 5 minutes in milliseconds
    });

    setLongLoadingTasks(longLoading);
  }, [tasks]);

  // If no tasks are loading for too long, don't show the alert
  if (longLoadingTasks.length === 0) {
    return null;
  }

  const handleDeleteDocument = async (task: Task) => {
    await onDelete(task.id);

    // Display toast notification
    // @ts-ignore
    toast(t("alerts.documentDeleted"), {
      // @ts-ignore
      description: t("alerts.documentDeletedDescription", { title: task.title }),
    });
  };

  return (
    <Alert
      variant="destructive"
      className="mb-4"
      actions={
        <>
          {longLoadingTasks.map((task) => (
            <AlertAction key={task.id} onClick={() => handleDeleteDocument(task)}>
              {/* @ts-ignore */}
              {t("alerts.deleteDocument", { title: task.title })}
            </AlertAction>
          ))}
        </>
      }
    >
      <AlertCircle className="h-4 w-4" />
      <div className="flex flex-col justify-center -mb-1">
        {/* @ts-ignore */}
        <AlertTitle>{t("alerts.longLoadingTitle")}</AlertTitle>
        {/* @ts-ignore */}
        <AlertDescription className="inline">{t("alerts.longLoadingDescription")}</AlertDescription>
      </div>
    </Alert>
  );
};

export default function TaskPage() {
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const transaction = useSelector((state: any) => state.transactions?.transaction);
  const documents = useSelector((state: any) => state.documents);
  const organization = useSelector((state: any) => state.auth.organization);

  const [tasks, setTasks] = useState<Task[]>([]); // Manage tasks state
  const [loading, setLoading] = useState(true); // Manage loading state
  const [error, setError] = useState<string | null>(null); // Manage error state

  useEffect(() => {
    const fn = async () => {
      await dispatch(clearDocuments());
    };
    fn();
  }, [transaction]);

  // Function to fetch tasks, memoized to avoid recreating it
  const fetchTasks = useCallback(async () => {
    try {
      const doc = await dispatch(fetchDocuments(transaction.id));
      const mapping = doc.map((d) => {
        return {
          ...d,
          title:
            d.type
              .split("-") // Split the type by "-"
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
              .join(" ") + // Join words with a space
            " " +
            d.year, // Append the year,
          label: "documentation",
          // Add a timestamp to track when document started loading
          timestamp: d.timestamp || (d.status === "uploading" ? Date.now() : null),
        };
      });
      const generalLedgerTask = {
        id: "general-ledger", // unique ID
        title: "General Ledger", // "title" property that you can check later
        status: "pending", // or "pending", "not-uploaded", etc.
        label: "documentation", // or any label you prefer
      };
      console.log("Tasks updated:", mapping);

      const newTasks = organization?.type === "lender" ? [...mapping] : [generalLedgerTask, ...mapping];
      setTasks(newTasks);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  }, [dispatch, transaction?.id, organization?.type]);

  // Initial data fetch
  useEffect(() => {
    if (!transaction) return;
    fetchTasks();
  }, [transaction, fetchTasks]);

  // Use the Firebase documents hook to monitor document status changes
  useFirebaseDocuments({
    transactionId: transaction?.id,
    organisationId: transaction?.organisationId,
    currentTasks: tasks,
    onTasksChanged: fetchTasks,
  });

  const handleAction = async (rowData: Task, actionType: string) => {
    console.log(`Action "${actionType}" triggered for`, rowData);
    if (!transaction) return;

    await fetchTasks();
  };

  const { t } = useTranslation();
  const columns = getColumns(t, handleAction);

  const fetchAndSetTransactionData = async (transactionData: any) => {
    await dispatch(fetchDebt(transactionData.id));
    await dispatch(fetchPurchasePrice(transactionData.id));
    await dispatch(fetchCashflowData(transactionData.id));
    await dispatch(updateMinideal(transactionData.revenue, transactionData.ebitda, transactionData.cashFlow, transactionData.earningsMultiple, transactionData.revenueMultiple, transactionData.uploadStatus));

    if (transactionData.uploadStatus !== "completed") {
      await dispatch(updatecashgraph());
    }
    await dispatch(setTransaction(transactionData));
  };

  React.useEffect(() => {
    if (!transaction && transaction?.uploadStatus === "completed") {
      fetchAndSetTransactionData(transaction);
    }
  }, [dispatch, transaction]);

  // Delete document handler for the alert
  const handleDeleteLongLoading = async (taskId: string) => {
    if (!transaction) return;

    // Find the task with the given ID
    const taskToDelete = tasks.find((task) => task.id === taskId);
    if (!taskToDelete) return;

    const type = `${taskToDelete.type}s`;
    await dispatch(delDocument(transaction.id, type, taskId));

    // Refresh the task list
    await fetchTasks();
  };

  if (loading) {
    return (
      <div className="flex flex-col mt-60 mx-auto h-full">
        <Spinner size={72} />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // 1) Find the General Ledger task.
  const generalLedgerTask = tasks.find((task) => task.title === "General Ledger");

  // 2) Is General Ledger validated?
  const isGeneralLedgerValidated = generalLedgerTask?.status === "validated";

  // 3) Are all *other* tasks validated or not-applicable?
  //    (exclude the General Ledger task by title or ID)
  const areAllOtherTasksValidated = tasks
    .filter((task) => task.title !== "General Ledger")
    .every((task) => {
      return task.status === "validated" || task.status === "not-applicable";
    });

  // 4) Combine conditions:
  const shouldShowAlert = isGeneralLedgerValidated || areAllOtherTasksValidated;

  return (
    <>
      <div className="hidden h-full flex-1 flex-col space-y-8 p-2 md:flex">
        <div className="flex flex-col items-center justify-between space-y-2 gap-2">
          {(!documents || !shouldShowAlert) && <AlertDestructive />}

          {/* Long loading alert */}
          <LongLoadingAlert tasks={tasks} transaction={transaction} dispatch={dispatch} onDelete={handleDeleteLongLoading} />

          <div className="flex flex-col justify-start mr-auto">
            <h2 className="text-2xl font-bold tracking-tight">
              {
                // @ts-ignore
                t("upload.title")
              }
            </h2>
            <p className="text-muted-foreground">
              {
                // @ts-ignore
                t("upload.description")
              }
            </p>
          </div>
        </div>
        <DataTable data={tasks} columns={columns} />
      </div>
    </>
  );
}
