import React, { useEffect, useMemo, useRef, useState } from "react";
import ReusableFinancialTable, { FinancialRow } from "./reactgrid";
import { backendClient } from "../../../api/backend";

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../../components/ui/card";
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell, TableCaption } from "../../../components/ui/table";
import { Button } from "../../../components/ui/button";
import { ScrollArea, ScrollBar } from "../../../components/ui/scroll";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter, DialogTrigger } from "../../../components/ui/dialog";
import { useTranslation } from "react-i18next";
import { CirclePlus, X, Loader2 } from "lucide-react";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
// import { Scroll } from "lucide-react";
const labelWidth = 350;
// Table Data
interface TableRowData {
  label: string;
  values: (string | number | any)[];
  isHighlighted?: boolean;
  rowType?: "default" | "mini-total" | "large-total" | string; // Add string type to allow flexibility
  isNegative?: boolean;
}

// Data for the financial table

// Data for the financial table (10 years)

const Row = ({ row }: { row: TableRowData }) => {
  const rowClasses = {
    default: "border-none",
    "mini-total": "font-semibold border-t border-b border-gray-300",
    "large-total": "bg-gray-100 font-bold border-none",
  };

  return (
    <TableRow className={`group  ${rowClasses[row.rowType || "default"]}`}>
      {/* Sticky Column Label */}
      <TableCell className={`sticky left-0 min-w-[310px] font-medium z-10 bg-white group-hover:bg-gray-100 ${row.rowType === "large-total" ? "bg-gray-100" : ""}`}>{row.label}</TableCell>
      {/* Row Values */}
      {row.values.map((value, index) => (
        <TableCell key={index} className={`text-right min-w-[150px] group-hover:bg-gray-100 ${row.isNegative && index === 0 ? "text-red-500" : ""}`}>
          {value !== "" && typeof value === "number" ? value.toLocaleString() : value}
        </TableCell>
      ))}
    </TableRow>
  );
};
interface FinancialTableProps {
  investmentCashFlowData: TableRowData[]; // Single row data
  dataModel: any; // The JSON model you showed for Balance Sheet or P&L
  title: string; // "Balance Sheet", "P&L", or "Cash Flow" etc.
  years: any[]; // e.g. ["2023", "2024"] or ["H1 2023", "H2 2023"]
  onSubmit?: (modifiedRows: FinancialRow[]) => void;
  transaction?: any;
  setExternal?: any;
  pricing?: any;
  onAdjustmentsSaved?: () => void;
}

interface FinancialData {
  id: string;
  valuesByYear: Record<string, number>;
}

interface State {
  acquisitionCost: string;
  closingCost: string;
}

// EBITDA Adjustment interface
interface EBITDAAdjustment {
  description: string;
  values: Record<string, string | number>;
}

const FinancialTable: React.FC<FinancialTableProps> = ({ dataModel, title, years, onSubmit, transaction, setExternal, investmentCashFlowData, pricing, onAdjustmentsSaved }) => {
  const viewportRef1 = useRef<HTMLDivElement>(null);
  const viewportRef2 = useRef<HTMLDivElement>(null);
  const tableRef = useRef<{ handleSubmit: (isSubmit: boolean) => Promise<void> }>(null);
  // Dialog state
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [adjustments, setAdjustments] = useState<EBITDAAdjustment[]>([{ description: "Ex) Seller Family Member Salary", values: {} }]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Effect to handle dialog open/close and fetch data when needed
  useEffect(() => {
    // When the dialog opens and we don't have adjustments yet, fetch the latest data
    // @ts-ignore
    if (isDialogOpen && transaction?.id && (!adjustments.length || (adjustments.length === 1 && !adjustments[0].description))) {
      fetchEBITDAAdjustments();
    }
  }, [isDialogOpen, transaction?.id, adjustments]);

  // Hard-coded years for the EBITDA adjustments table
  const adjustmentYears = years.map((year) => year.year);

  // Fetch existing EBITDA adjustments on component load
  useEffect(() => {
    if (transaction?.id) {
      fetchEBITDAAdjustments();
    }
  }, [transaction?.id]);

  // Function to fetch EBITDA adjustments from the backend
  const fetchEBITDAAdjustments = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const data = await backendClient.getCustomEBITDA(transaction.id);
      console.log("data", data);
      if (data && Array.isArray(data) && data.length > 0) {
        // Transform backend data to the format expected by the component
        const transformedAdjustments = data.map((item) => {
          const adjustmentValues: Record<string, string | number> = {};

          // Convert the values object to match our expected format
          // where the keys are years and values are amounts
          if (item.values) {
            Object.keys(item.values).forEach((year) => {
              // Find the corresponding adjustmentYear regardless of the source format
              adjustmentValues[year] = item.values[year];
            });
          }

          return {
            description: item.name || "",
            values: adjustmentValues,
          };
        });

        // If we have adjustments, set them as state
        if (transformedAdjustments.length > 0) {
          setAdjustments(transformedAdjustments);
        }
      }
    } catch (err) {
      console.error("Error fetching EBITDA adjustments:", err);
      setError("Failed to load EBITDA adjustments");
    } finally {
      setIsLoading(false);
    }
  };

  // Memoize the transformed financial data
  const state = useMemo(
    () => ({
      acquisitionCost: String(pricing.purchasePrice || transaction.askPrice),
      closingCost: "20000", // Ensure correct naming to match `State` interface
    }),
    [transaction], // Only re-calculate when `transaction.askPrice` changes
  );
  const { t } = useTranslation(); // ✅ Use translation hook

  const syncScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (viewportRef1.current && viewportRef2.current) {
      // Determine which viewport triggered the event
      const isViewport1 = e.currentTarget === viewportRef1.current;

      if (isViewport1) {
        viewportRef2.current.scrollLeft = viewportRef1.current.scrollLeft;
      } else {
        viewportRef1.current.scrollLeft = viewportRef2.current.scrollLeft;
      }
    }
  };
  // console.log(transaction);
  const l = investmentCashFlowData.length === 0 ? 0 : investmentCashFlowData[0]!.values.length;

  const handleClick = () => {
    tableRef.current?.handleSubmit(true); // Submit the data
  };

  const handleCancel = () => {
    tableRef.current?.handleSubmit(false); // Cancel without dispatching
  };

  // Function to handle submitting the EBITDA adjustments
  const handleSubmitAdjustments = async (years: string[]) => {
    try {
      setIsLoading(true);
      setError(null);

      // Filter out adjustments with empty descriptions
      console.log("years", years);
      const validAdjustments = adjustments.filter((adj) => adj.description.trim() !== "");

      // If no valid adjustments, create an empty array to clear all adjustments
      const formattedAdjustments =
        validAdjustments.length === 0
          ? []
          : validAdjustments.map((adjustment) => {
              // Create a values object using the Year N format
              const formattedValues: Record<string, number> = {};

              // Process each year and ensure values are numbers
              adjustmentYears.forEach((year, index) => {
                // Check if we have a corresponding year in the passed years array
                if (index < years.length && years[index]) {
                  // Ensure yearKey is always a string
                  const yearKey = String(years[index]);
                  const value = adjustment.values[year];

                  // Convert to number, handling empty strings, undefined values, and string numbers
                  formattedValues[yearKey] = value === "" || value === undefined ? 0 : typeof value === "string" ? parseFloat(value.replace(/,/g, "")) : Number(value);

                  // Handle NaN cases
                  // @ts-ignore
                  if (isNaN(formattedValues[yearKey])) {
                    formattedValues[yearKey] = 0;
                  }
                }
              });

              return {
                category: "Manual Adjustment",
                name: adjustment.description,
                role: "adjustment-to-lender-ebitda",
                terms: [],
                values: formattedValues,
              };
            });

      console.log("EBITDA Adjustments submitted:", formattedAdjustments);

      // Save the adjustments to the backend
      if (transaction?.id) {
        await backendClient.updateCustomEBITDA(transaction.id, formattedAdjustments);
      }

      // Pass the formatted adjustments to the parent component if needed
      if (setExternal && typeof setExternal === "function") {
        setExternal((prevState) => ({
          ...prevState,
          ebitdaAdjustments: formattedAdjustments,
        }));
      }

      setIsDialogOpen(false);

      // Call the callback to notify parent that adjustments were saved
      if (onAdjustmentsSaved && typeof onAdjustmentsSaved === "function") {
        onAdjustmentsSaved();
      }
    } catch (err) {
      console.error("Error saving EBITDA adjustments:", err);
      setError("Failed to save EBITDA adjustments");
    } finally {
      setIsLoading(false);
    }
  };

  // Function to add a new adjustment row
  const handleAddAdjustment = () => {
    setAdjustments([...adjustments, { description: "", values: {} }]);
  };

  // Function to update adjustment values
  const handleAdjustmentChange = (index: number, year: string, value: string) => {
    // Only allow numeric input (positive numbers, commas and periods)
    if (value !== "" && !/^[0-9,\\.]*$/.test(value)) {
      return; // Ignore non-numeric input
    }

    const newAdjustments = [...adjustments];
    if (newAdjustments[index]) {
      // Remove commas for storage
      const numericValue = value === "" ? "" : value.replace(/,/g, "");
      // @ts-ignore
      newAdjustments[index].values[year] = numericValue;
    }
    setAdjustments(newAdjustments);
  };

  // Format numbers for display
  const formatNumber = (value: string | number | undefined): string => {
    if (value === undefined || value === "") return "";
    // Parse the string to a number and format with commas
    const num = typeof value === "string" ? parseFloat(value) : value;
    return isNaN(num) ? "" : num.toLocaleString();
  };

  // Function to update adjustment description
  const handleDescriptionChange = (index: number, description: string) => {
    const newAdjustments = [...adjustments];
    if (newAdjustments[index]) {
      // @ts-ignore
      newAdjustments[index].description = description;
    }
    setAdjustments(newAdjustments);
  };

  // Function to remove an adjustment
  const handleRemoveAdjustment = (index: number) => {
    setAdjustments(adjustments.filter((_, i) => i !== index));
  };

  // Calculate total for each year
  const calculateTotals = () => {
    const totals: Record<string, string | number> = {};

    adjustmentYears.forEach((year) => {
      const yearTotal = adjustments.reduce((sum, adjustment) => {
        const value = adjustment.values[year];
        // Convert to number for calculation
        const numValue = value === "" || value === undefined ? 0 : typeof value === "string" ? parseFloat(value) : value;
        return sum + (isNaN(numValue) ? 0 : numValue);
      }, 0);

      totals[year] = yearTotal === 0 ? "-" : yearTotal;
    });

    return totals;
  };

  return (
    <Card className="w-full shadow-lg">
      {/* Card Header */}
      <CardHeader className="flex  justify-between">
        <CardTitle>
          {
            // @ts-ignore
            t("financialTable.investmentCashFlowDetails")
          }
        </CardTitle>
        <div className="ml-auto flex gap-3">
          <Button variant={"ghost"} onClick={handleCancel}>
            {
              // @ts-ignore
              t("financialTable.undo")
            }
          </Button>
          <Button onClick={handleClick}>
            {
              // @ts-ignore
              t("financialTable.save")
            }
          </Button>
        </div>
        {/* Add a hover effect on the card description and a trigger to the dialog */}
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogTrigger asChild>
            <CardDescription className="flex ml-auto items-center gap-2 cursor-pointer hover:text-primary transition-colors flex-shrink">
              <CirclePlus className="w-6 h-6" />
              <div className="text-sm font-semibold">Add EBITDA Adjustment</div>
            </CardDescription>
          </DialogTrigger>
          <DialogContent className="max-w-4xl">
            <DialogHeader>
              <DialogTitle>EBITDA Adjustments</DialogTitle>
              <DialogDescription>Add or edit adjustments to the EBITDA calculations. These adjustments will be saved to your transaction and used in financial reports. Once saved, the financial model will automatically refresh to reflect your changes.</DialogDescription>
            </DialogHeader>

            {error && <div className="bg-red-50 text-red-700 p-2 rounded-md mb-4">{error}</div>}

            {isLoading ? (
              <div className="flex justify-center items-center py-10">
                <Loader2 className="h-4 w-4 animate-spin text-primary" />
                {/* <p className="ml-2 text-gray-600">Loading adjustments...</p> */}
              </div>
            ) : (
              <div className="overflow-x-auto">
                <div className="max-h-[50vh] overflow-y-auto">
                  <Table className="w-full">
                    <TableCaption>*Only include adjustments that can be 100% verified</TableCaption>
                    <TableHeader className="sticky top-0 bg-white z-10">
                      <TableRow>
                        <TableHead className="min-w-[250px]">Description</TableHead>
                        {adjustmentYears.map((year) => (
                          <TableHead key={year} className="text-right min-w-[100px]">
                            {year}
                          </TableHead>
                        ))}
                        <TableHead className="w-[50px]"></TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {adjustments.map((adjustment, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Input value={adjustment.description} onChange={(e) => handleDescriptionChange(index, e.target.value)} placeholder="Enter adjustment description" />
                          </TableCell>
                          {adjustmentYears.map((year) => (
                            <TableCell key={year} className="text-right">
                              <div className="flex items-center justify-end">
                                <div className="text-gray-500 mr-1">$</div>
                                <Input value={formatNumber(adjustment.values[year])} onChange={(e) => handleAdjustmentChange(index, year, e.target.value)} className="w-24 text-right" placeholder="-" />
                              </div>
                            </TableCell>
                          ))}
                          <TableCell>
                            {index > 0 && (
                              <Button variant="ghost" size="icon" onClick={() => handleRemoveAdjustment(index)}>
                                <X className="h-4 w-4" />
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow className="border-t border-gray-300">
                        <TableCell className="font-bold">Total</TableCell>
                        {adjustmentYears.map((year) => {
                          const totals = calculateTotals();
                          return (
                            <TableCell key={year} className="text-right font-bold">
                              {/* @ts-ignore */}
                              {totals[year] !== "-" ? `$ ${typeof totals[year] === "number" ? totals[year].toLocaleString() : totals[year]}` : "$ -"}
                            </TableCell>
                          );
                        })}
                        <TableCell></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            )}

            <DialogFooter className="flex sm:justify-between items-center gap-2 mt-6 w-full">
              <Button variant="outline" onClick={handleAddAdjustment} className="flex justify-start items-center gap-1" disabled={isLoading}>
                <CirclePlus className="h-4 w-4" />
                Add Adjustment
              </Button>
              <div className="flex gap-2">
                <Button variant="outline" onClick={() => setIsDialogOpen(false)} disabled={isLoading}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    handleSubmitAdjustments(adjustmentYears);
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Saving...
                    </>
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </CardHeader>
      {/* Card Content */}
      <CardContent className="p-4 ">
        <ScrollArea type="always" viewportRef={viewportRef1} onScroll={syncScroll} className="w-full flex-1 p-4">
          <ScrollBar orientation="horizontal" className=" w-full  rounded-full " />
          <ReusableFinancialTable
            dataModel={dataModel}
            title="Project Cash Flow"
            years={years} // e.g. ["2028"]
            labelWidth={labelWidth}
            onSubmit={(modifiedRows) => {
              console.log("Final Data Submitted:", modifiedRows);
            }}
            state={state}
            ref={tableRef} // Pass the ref to child component
            growthRate={0.08}
            inflationRate={0.01}
          />
        </ScrollArea>
      </CardContent>
    </Card>
  );
};

export default FinancialTable;
