import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// Shadcn UI components (adjust imports to match your project)
import { Card, CardDescription, CardHeader, CardContent, CardTitle } from "../../../components/ui/card";
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import { Checkbox } from "../../../components/ui/checkbox";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { updateTransactionProperty } from "../../../redux/actions/transactionAction";
// Icons
import { Check, Info, X } from "lucide-react";

// --------------------------
// Helper to show a tooltip icon. If you don't need tooltips, remove.
// --------------------------
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../../components/ui/tooltip";
import { cn } from "../../../lib/utils";

export function TooltipIcon({ content, cs = "" }: { content: string; cs?: string }) {
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="cursor-pointer">
            <Info className={cn("h-4 w-4 text-gray-500 hover:text-black", cs)} />
          </span>
        </TooltipTrigger>
        <TooltipContent className="max-w-96 flex flex-wrap">
          <p>{content}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

// --------------------------
// A small KPI display item (LTM EBITDA, Debt Avail, etc.)
// --------------------------
interface StatItemProps {
  title: string;
  tooltipContent: string;
  value: string | number;
  suffix?: string; // e.g. "$", "%", etc.
}
const StatItem: React.FC<StatItemProps> = ({ title, tooltipContent, value, suffix = "" }) => {
  return (
    <div className="flex flex-col text-left">
      <div className="flex items-center space-x-2">
        <CardDescription className="text-sm font-medium">{title}</CardDescription>
        <TooltipIcon content={tooltipContent} />
      </div>
      <div className="text-base sm:text-lg 2lg:text-xl font-bold">
        {value}
        {suffix}
      </div>
    </div>
  );
};

// --------------------------
// RowItem layout for Sources & Uses
// --------------------------
interface RowItemProps {
  label: string;
  value: React.ReactNode | string;
  tooltipContent?: string;
  rowIndex?: number;
  isHighlighted?: boolean;
}
export const RowItem: React.FC<RowItemProps> = ({ label, value, tooltipContent, rowIndex = 0, isHighlighted = false }) => {
  // Optional row background
  const isEven = rowIndex % 2 === 1;
  const bg = isEven ? "bg-gray-50" : "";

  return (
    <div className={`flex justify-between items-center px-2 h-8 ${isHighlighted ? "bg-gray-100 rounded-md" : ""}`}>
      <div className={`flex gap-2 items-center text-sm ${isHighlighted ? "text-black font-semibold" : "text-gray-500 font-medium"}`}>
        <span className="truncate">{label}</span>
        {tooltipContent && <TooltipIcon content={tooltipContent} />}
      </div>
      <div className="text-sm font-semibold text-black">{value}</div>
    </div>
  );
};

// --------------------------
// Minimal interfaces for SourceDebts and Uses
// --------------------------
export interface SourceItem {
  label: string;
  value: number;
  tooltipContent?: string;
  isRevolver?: boolean; // Flag to identify Revolver items
}
export interface UseItem {
  label: string;
  value: number;
  tooltipContent?: string;
  readOnly?: boolean;
}

interface MySourcesUsesLayoutProps {
  transaction: {
    name: string;
    createdAt: number; // numeric timestamp, e.g. 1738851958
  };
  /** Example KPI values - you can customize or pass them differently. */
  lenderName?: string;
  loanType?: string;
  principalName?: string;
  phone?: string;
  email?: string;

  ltmEbitda?: number;
  debtAvailable?: number;

  /** The array of sources (Bank Debt, Equity Injection, etc.) */
  sources: SourceItem[];

  /** The array of uses (Business Acquisition, Working Capital, etc.) */
  uses: UseItem[];
}

// --------------------------
// The main component
// --------------------------
export const MySourcesUsesLayout: React.FC<MySourcesUsesLayoutProps> = ({ transaction, lenderName = "SBA Lender", loanType = "SBA 7(a)", principalName = "Principal", phone = "N/A", email = "N/A", ltmEbitda = 0, debtAvailable = 0, sources, uses }) => {
  const { t } = useTranslation();
  // Convert numeric timestamp to readable date
  // If your numeric timestamp is in *seconds*, multiply by 1000.
  const formattedDate = React.useMemo(() => {
    if (!transaction?.createdAt) return "";
    return new Date(transaction.createdAt * 1000).toLocaleDateString();
  }, [transaction?.createdAt]);

  // Sum of all "sources"
  const totalSources = sources
    .filter((s) => !s.isRevolver) // Exclude Revolver from total sources
    .reduce((sum, s) => sum + (s.value || 0), 0);

  // Sum of all "uses"
  const totalUses = uses.reduce((sum, u) => sum + (u.value || 0), 0);

  // For "Debt Outstanding," calculate as sum of all non-equity sources
  const debtOutstanding = sources
    .filter((s) => !s.isRevolver) // Exclude Revolver from debt outstanding
    .reduce((sum, s) => {
      // Only count non-equity sources as debt
      if (s.label.toLowerCase().includes("equity")) {
        return sum;
      }
      return sum + (s.value || 0);
    }, 0);

  // Find equity sources
  const equitySources = sources.filter((s) => s.label.toLowerCase().includes("equity"));
  const totalEquity = equitySources.reduce((sum, s) => sum + (s.value || 0), 0);

  // Calculate equity % = (Total Equity / totalSources) * 100
  const equityPct = totalSources > 0 ? (totalEquity / totalSources) * 100 : 0;

  // Helper function to format currency values
  const formatCurrency = (value: number | null | undefined): string => {
    if (value === null || value === undefined) return "$0";
    return `$${(Number(value.toFixed(0)) || 0).toLocaleString()}`;
  };

  // Helper function to format percentage values
  const formatPercentage = (value: number): string => {
    return `${value.toFixed(1)}%`;
  };

  return (
    <Card className="max-w-full col-span-2">
      {/* Header: Title & Description */}
      <CardHeader>
        <CardTitle>
          {
            // @ts-ignore
            "Overview"
          }
        </CardTitle>
        <CardDescription></CardDescription>
      </CardHeader>

      <CardContent className="space-y-3">
        {/* Row of KPI stats */}
        <Card className="shadow-none !border-none p-0">
          <CardContent className="flex flex-wrap items-start gap-4 p-0">
            <div className="flex items-center gap-4 w-full justify-between">
              <StatItem title="Lender" tooltipContent="The name of the lender" value={lenderName} />
              <StatItem title="Loan Type" tooltipContent="Type of loan (e.g. 7(a))" value={loanType} />
              <StatItem title="Business Name" tooltipContent="Name of the business" value={transaction.name} />
              <StatItem title="Principal" tooltipContent="Key principal's name" value={principalName} />
              <StatItem title="Phone" tooltipContent="Contact phone" value={phone} />
              {/* Add or Remove Tranches dialog */}
            </div>
            <div className="flex items-center gap-4 w-full justify-between">
              <StatItem title="Email" tooltipContent="Contact email" value={email} />
              <StatItem title="Date" tooltipContent="Created date (converted from timestamp)" value={formattedDate} />
              <StatItem title="LTM EBITDA" tooltipContent="Last Twelve Months EBITDA" value={formatCurrency(ltmEbitda)} />
              <StatItem title="Debt Available" tooltipContent="Total Debt Available" value={formatCurrency(debtAvailable)} />
              <StatItem title="Debt Outstanding" tooltipContent="Amount of current debt used" value={formatCurrency(debtOutstanding)} />
            </div>
          </CardContent>
        </Card>

        {/* Key Metrics */}
        {/* <Card className="p-0 shadow-md">
          <CardHeader className="pb-4">
            <CardTitle>Key Metrics</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-4 gap-4">
              <StatItem title="Total Debt" tooltipContent="Total debt from all sources" value={formatCurrency(debtOutstanding)} />
              <StatItem title="Total Equity" tooltipContent="Total equity contribution" value={formatCurrency(totalEquity)} />
              <StatItem title="Equity %" tooltipContent="Equity as a percentage of total sources" value={formatPercentage(equityPct)} />
              <StatItem title="Debt-to-Equity Ratio" tooltipContent="Ratio of debt to equity (lower is better)" value={totalEquity > 0 ? (debtOutstanding / totalEquity).toFixed(2) : "N/A"} />
            </div>
          </CardContent>
        </Card> */}

        {/* 2) Sources & Uses, side by side */}
        <Card className="p-0 shadow-md">
          <CardHeader className="pb-4">
            <CardTitle>Sources & Uses of Funds</CardTitle>
            <CardDescription>
              {/* {totalSources === totalUses ? (
                <span className="text-green-600"></span>
              ) : (
                <span className="text-amber-600">{totalSources > totalUses ? `Sources exceed Uses by ${formatCurrency(totalSources - totalUses)}` : `Uses exceed Sources by ${formatCurrency(totalUses - totalSources)}`}</span>
              )} */}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-2 gap-4 items-stretch">
              {/* LEFT: Sources */}
              <Card className="!border-none shadow-none flex flex-col h-full">
                <Label className="font-semibold mb-2 block">Sources</Label>
                <div className="space-y-2 flex-1">
                  {/* For each debt row in "sourceDebts" */}
                  {sources
                    .filter((sd) => !sd.isRevolver) // Exclude Revolver from main sources list
                    .map((sd, idx) => {
                      // If this is an equity source, show equity % in label
                      let labelWithPct = sd.label;
                      if (sd.label.toLowerCase().includes("equity") && totalSources > 0) {
                        const sourcePct = (sd.value / totalSources) * 100;
                        labelWithPct += ` (${formatPercentage(sourcePct)})`;
                      }

                      return <RowItem key={sd.label} rowIndex={idx} label={labelWithPct} isHighlighted={idx % 2 === 0} tooltipContent={sd.tooltipContent || "Source of funds"} value={formatCurrency(sd.value)} />;
                    })}

                  {/* Total Sources */}
                  <RowItem label="Total Sources" value={formatCurrency(totalSources)} isHighlighted={true} />

                  {/* Display Revolver separately if any exists */}
                  {sources.some((sd) => sd.isRevolver) && (
                    <div className="pt-4 border-t mt-4">
                      {sources
                        .filter((sd) => sd.isRevolver)
                        .map((sd, idx) => (
                          <RowItem key={`revolver-${idx}`} label={sd.label} tooltipContent={sd.tooltipContent || "Revolver credit line (not included in total sources)"} value={formatCurrency(sd.value)} />
                        ))}
                    </div>
                  )}
                </div>
              </Card>

              {/* RIGHT: Uses */}
              <Card className="!border-none shadow-none flex flex-col h-full">
                <Label className="font-semibold mb-2 block">Uses</Label>
                <div className="space-y-2 flex-1">
                  {uses.map((u, idx) => (
                    <RowItem key={u.label} rowIndex={idx} isHighlighted={idx % 2 === 0} label={u.label} tooltipContent={u.tooltipContent || "Use of funds"} value={formatCurrency(u.value)} />
                  ))}

                  {/* Total Uses */}
                  <RowItem label="Total Uses" value={formatCurrency(totalUses)} isHighlighted rowIndex={9999} />
                </div>
              </Card>
            </div>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};
