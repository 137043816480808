import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from "../../../components/ui/tooltip";

import { Edit, Info } from "lucide-react"; // or whichever icon library

function TooltipIcon({ content, icon = false }: { content: string; icon?: boolean }) {
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="cursor-pointer">{icon ? <Info className="h-4 w-4 text-gray-500 hover:text-black" /> : <Edit className="h-4 w-4 text-gray-500 hover:text-black" />}</span>
        </TooltipTrigger>
        <TooltipContent className="max-w-96 flex flex-wrap">
          <p className="color-white">{content}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default TooltipIcon;
