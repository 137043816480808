import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import { AppSidebar } from "../../components/components/app-sidebar";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage } from "../../components/ui/breadcrumb";
import { Separator } from "../../components/ui/separator";
import { SidebarInset, SidebarTrigger } from "../../components/ui/sidebar";
import Spinner from "../../components/ui/Spinner";
import { useTranslation } from "react-i18next";
import { backendClient } from "../../api/backend";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";

import Cards from "./components/cards";
import ProgressPrice from "./ProgressPrice";
import CardTab from "./components/tab_b_s";
import MapCard from "./components/map";
import CapitalStructure from "./components/capitalstructure";
import YearCashflow from "./components/yearcashflow";
import Cashflow, { CashCard } from "../CashflowScreen/components/Cashflow";

import IRR from "../CapexPlanScreen/_irrResult";
import Payback from "../CapexPlanScreen/_payback";
import { extractFinancialItem } from "../../utils/utils";

// ------------------------
// Helper Functions
// ------------------------
function calculateFinancialRatios(transaction, fcf, debt, purchasePrice, new_debt) {
  let covenants = false;
  let dscr = [];
  let ltv = [];
  let minDSCR, minLTV;

  if (fcf.length > 0 && transaction.uploadStatus === "completed") {
    covenants = true;

    dscr = fcf.map((cf, i) => {
      if (debt.schedule && i < debt.schedule.length) {
        return cf["Free Cash Flow"] / debt.schedule[i].debtService;
      } else {
        return "N/A";
      }
    });

    ltv = [new_debt / purchasePrice];

    // Filter out 'N/A' values and find the minimum DSCR and LTV
    const validDSCR = dscr.filter((value) => value !== "N/A");
    const validLTV = ltv.filter((value) => value !== "N/A");

    minDSCR = validDSCR.length > 0 ? Math.min(...validDSCR) : "N/A";
    minLTV = validLTV.length > 0 ? Math.min(...validLTV) : "N/A";
    if (minDSCR === "N/A") covenants = false;
  } else {
    dscr = "N/A";
    ltv = "N/A";
    minDSCR = "N/A";
    minLTV = "N/A";
    covenants = false;
  }

  return {
    covenants,
    dscr,
    ltv,
    minDSCR,
    minLTV,
  };
}

const calculateUnleveredIRR = (cashFlows) => {
  // Step 1: Sort the cash flows by year
  cashFlows.sort((a, b) => a.year - b.year);

  // Step 2: Create arrays for values and dates
  const values = cashFlows.map((cf) => Number(cf.freeCashFlow));
  // Step 3: Call the IRR function
  return IRR(values, 0.1) * 100;
};

const calculatePaybackPeriod = (cashFlows, investment) => {
  // Step 1: Sort the cash flows by year
  cashFlows.sort((a, b) => a.year - b.year);
  // Step 2: Extract free cash flows into an array
  const cashFlowValues = cashFlows.map((cf) => Number(cf.freeCashFlow));
  const pb = Payback(cashFlowValues, 0);
  return pb;
};

// Calculate the cash on cash return
// Cash on Cash Return = (Total Cash Flow / Total Investment)
const calculateCashOnCashReturn = (cashFlows) => {
  // Step 1: Sort the cash flows by year
  cashFlows.sort((a, b) => a.year - b.year);

  // Step 2: Extract free cash flows into an array
  const cashFlowValues = cashFlows.map((cf) => Number(cf.freeCashFlow));

  // Step 3: Calculate the total positive cash flow
  const totalCashFlow = cashFlowValues.reduce((acc, value) => (value > 0 ? acc + value : acc), 0);

  // Step 4: Calculate the sum of negative flows (the investment)
  const investmentCashFlow = cashFlowValues.reduce((acc, value) => (value < 0 ? acc + value : acc), 0);

  // Step 5: Return ratio
  return totalCashFlow / Math.abs(investmentCashFlow);
};

function mapCashFlow(data, investment, exitValue) {
  // Extract years
  const years = data.map((item) => item.year).sort((a, b) => a - b);
  const firstYear = years[0];
  const lastYear = years[years.length - 1];

  // Prepare list
  const mappedCashFlows = [];

  // 1) Investment year
  mappedCashFlows.push({
    year: firstYear - 1,
    freeCashFlow: investment,
    isForecasted: false,
  });

  // 2) The existing data
  data.forEach((item) => {
    mappedCashFlows.push({
      year: item.year,
      freeCashFlow: item["Free Cash Flow"] ?? item.freeCashFlow,
      isForecasted: item.isForecasted,
    });
  });

  // 3) The exit year
  mappedCashFlows.push({
    year: lastYear + 1,
    freeCashFlow: exitValue,
    isForecasted: false,
  });

  return mappedCashFlows;
}

function getLastYearValue(data) {
  if (!Array.isArray(data) || data.length === 0) {
    throw new Error("The data array is empty.");
  }
  const sortedData = data.slice().sort((a, b) => a.year - b.year);
  const lastYearObject = sortedData[sortedData.length - 1];
  return lastYearObject["Revenue"];
}

function extractLast(cashflow, itemname) {
  if (!cashflow || cashflow.length === 0) return null;
  const item = extractFinancialItem(cashflow, itemname, null, false);
  return item[item.length - 1];
}

// ------------------------
// Main QoE Component
// ------------------------
export default function QoE() {
  const { t } = useTranslation();

  // Redux data
  const transaction = useSelector((state) => state.transactions.transaction);
  const transactions = useSelector((state) => state.transactions.transactions);
  const tr = transactions.find((e) => e.id === transaction.id);
  const cashflow = useSelector((state) => state.cashflow.tableData.cashFlow);
  const pricing = useSelector((state) => state.cashflow.purchasePrice) || {};
  const debt = useSelector((state) => state.cashflow.debt);
  const minideal = useSelector((state) => state.cashflow.miniDeal);
  const investmentCashFlowData = useSelector((state) => state.cashflow.investmentCashFlowData);

  const [multiple, setmultiple] = useState(pricing.multiple || transaction.revenueMultiple);
  const [purchasePrice, setPurchasePrice] = useState(pricing?.purchasePrice || minideal.entryValue);

  // Some checks
  const hasMinidealEntryValue = minideal.entryValue && minideal.projections;
  const status = transaction.uploadStatus === "completed" && cashflow;

  // Extract FCF
  let fcf = [];
  if (cashflow) {
    fcf = extractFinancialItem(cashflow, "Free Cash Flow", null, true);
  } else if (hasMinidealEntryValue) {
    fcf = minideal.projections.map((e) => ({
      year: e.year,
      freeCashFlow: Number(e.cashFlow),
    }));
  }

  // For “Revenue” extraction
  let rev_pro = [];
  if (status) {
    rev_pro = extractFinancialItem(cashflow, "Revenue", null, true);
  } else if (hasMinidealEntryValue) {
    rev_pro = minideal.projections
      .filter((e) => e.revenue)
      .map((e) => ({
        year: e.year,
        Revenue: Number(e.revenue),
      }));
  }

  // Debt & new debt
  let new_debt = 0;
  if (debt && debt?.maximumDebt) {
    new_debt = debt.customDebt !== 0 ? debt.customDebt : debt.maximumDebt;
  } else if (minideal && minideal.debt) {
    new_debt = minideal.debt;
  }

  // Gather some numbers
  const revenue = status ? extractLast(cashflow, "Revenue")?.["Revenue"] : transaction.revenue;
  const ebitda = status ? extractLast(cashflow, "Adjusted EBITDA")?.["Adjusted EBITDA"] : transaction.ebitda;
  const old_debt = status ? extractLast(cashflow, "Debt Starting Balance")?.["Debt Starting Balance"] : 0;

  const earnings = status ? extractLast(cashflow, "Taxable Income")?.["Taxable Income"] + extractLast(cashflow, "Tax")?.["Tax"] : transaction.cashFlow;

  // Basic Price logic
  const earningsPrice = transaction.earningsMultiple * (earnings || 0);
  const revenuePrice = transaction.revenueMultiple * (revenue || 0);
  const minPrice = Math.floor(Math.max(Math.min(earningsPrice, revenuePrice), 0));
  const maxPrice = Math.floor(Math.max(earningsPrice, revenuePrice));
  const lowerBound = minPrice;
  const upperBound = maxPrice;
  const mu = (upperBound + lowerBound) / 2;
  const sigma = (upperBound - lowerBound) / 4;
  const limitLow = Number(Math.max(mu - 3 * sigma, 0).toFixed(0));
  const limitHigh = Math.max(Number(transaction.askPrice || 0) * 1.5, Number((mu + 3 * sigma).toFixed(0)));

  useEffect(() => {
    if (!purchasePrice) {
      setPurchasePrice(pricing?.purchasePrice || minideal.entryValue || maxPrice);
    }
  }, [transaction, minideal]);

  // Build final mapped CF for IRR, payback, etc.
  const cashf = status ? mapCashFlow(fcf, -purchasePrice, rev_pro.length > 0 ? getLastYearValue(rev_pro) * multiple : 0) : mapCashFlow(fcf, -purchasePrice, 0);

  const irr = Math.round(calculateUnleveredIRR(cashf));
  const pb = calculatePaybackPeriod(cashf, 0).toFixed(2);
  const cashOnCashReturn = calculateCashOnCashReturn(cashf);

  // Potential basic multiples
  const totalPotentialProfit = ebitda ? purchasePrice / ebitda : 0;
  const equityMultiple = revenue ? purchasePrice / revenue : 0;

  // Covenant checks
  let { covenants, dscr, ltv, minDSCR, minLTV } = calculateFinancialRatios(transaction, fcf, debt, purchasePrice, new_debt);

  // Example: grabbing “similar places”
  const [place, setPlace] = useState([]);
  const getSimilarplace = async () => {
    const places = await backendClient.getsimilarbusiness(transaction.id);
    setPlace(places);
  };
  useEffect(() => {
    if (!transaction) return;
    getSimilarplace();
  }, [transaction]);

  // If data not ready, you could show a spinner (comment out if not needed):
  // if (!transaction || !cashflow) return <Spinner />;

  // -----------------------------------------------------------
  // We now create TWO SUB-TABS:
  //  - "qoe" for the QoE Review
  //  - "databook" for the DataBook
  // The DataBook will import and show the <Cashflow /> component.
  // -----------------------------------------------------------
  return (
    <SidebarInset>
      {/* HEADER */}
      <header className="flex h-16 shrink-0 items-center gap-2">
        <div className="flex items-center gap-2 px-4">
          <SidebarTrigger className="-ml-1" />
          <Separator orientation="vertical" className="mr-2 h-4" />
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage>{t("overview.overview")}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </header>

      {/* MAIN CONTENT */}
      <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
        <Tabs defaultValue="qoe" className="w-full">
          {/* ----- TABS LIST: QoE & Databook ----- */}
          <TabsList className="grid w-full grid-cols-2 mb-4">
            <TabsTrigger value="qoe">QoE & Adjustments</TabsTrigger>
            <TabsTrigger value="databook">Databook / Source Data</TabsTrigger>
          </TabsList>

          {/* ----- TAB CONTENT #1: QoE REVIEW ----- */}
          <TabsContent value="qoe" className="flex flex-col gap-4">
            {/* Existing QoE top-level info: KPI cards, IRR, payback, etc. */}
            <Cards transaction={tr ? tr : transaction} earnings={earnings} />

            {/* Example row of QoE metrics */}
            <div className="grid auto-rows-min gap-4 grid-cols-2">
              <ProgressPrice
                lowerLimit={limitLow}
                upperLimit={limitHigh}
                newDebt={new_debt}
                irr={irr}
                cashonCashReturn={cashOnCashReturn}
                totalPotentialProfit={totalPotentialProfit}
                equityMultiple={equityMultiple}
                multiple={multiple}
                setPurchasePrice={setPurchasePrice}
                purchasePrice={purchasePrice}
                transaction={transaction}
                pricing={pricing}
                minideal={minideal}
                pb={pb}
                old_debt={0} // or pass old_debt if needed
              />

              {/* If you want to display additional QoE panels, do so here */}
              {/* <CardTab irr={irr} pb={pb} purchasePrice={purchasePrice} old_debt={old_debt} /> */}

              {/* Show YearCashflow + CapitalStructure if FCF is present */}
              {fcf.length > 0 && (
                <>
                  <YearCashflow old_debt={old_debt} new_debt={new_debt} purchasePrice={purchasePrice} payback={pb} closingCost={20000} initialInvestment={0} />
                  {dscr && new_debt && purchasePrice && <CapitalStructure transaction={transaction} purchasePrice={purchasePrice} newDebt={new_debt} covenants={covenants} dscr={dscr} ltv={ltv} minDSCR={minDSCR} minLTV={minLTV} />}
                </>
              )}

              {/* If we have investment CF data, show the chart card */}
              {investmentCashFlowData && investmentCashFlowData.length > 0 && <CashCard investmentCashFlowData={investmentCashFlowData} />}

              <MapCard transaction={transaction} place={place} />
            </div>

            {/* Potential space for a "Balance Sheet Health" or "Owner Expenses" table */}
            <div className="min-h-[100vh] flex-1 rounded-xl bg-muted/50 md:min-h-min p-4">
              {/* 
                e.g. a placeholder box or collapsible panel for 
                EBITDA Adjustments / P&L Normalizations
                or Seasonality charts 
              */}
              <p className="text-sm text-muted-foreground">[Placeholder for line-by-line adjustments, balance sheet items, etc.]</p>
            </div>
          </TabsContent>

          {/* ----- TAB CONTENT #2: DATABOOK ----- */}
          <TabsContent value="databook" className="flex flex-col gap-4">
            {/* 
              The entire <Cashflow /> component,
              which includes your financial table, DCF, pivot options, etc.
            */}
            <Cashflow />

            {/* 
              Optionally, if you want to show other DataBook references here,
              you can add them below or inside the Cashflow component. 
            */}
          </TabsContent>
        </Tabs>
      </div>
    </SidebarInset>
  );
}
