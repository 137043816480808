import * as React from "react";
import { Card, CardHeader, CardContent, CardTitle } from "../../components/ui/card";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { RowItem } from "../Debtscreen/DebtBuilder"; // or wherever you have the RowItem component
import { calculateSbaGtyFee } from "../Debtscreen/calculatefees";

/** Each Use item. If readOnly is true, we display a read-only row. */
export interface UseItemParam {
  key: string;
  label: string;
  value?: number;
  readOnly?: boolean;
}

export interface SBAUsesProps {
  /** If you want SBA Gty Fee to auto-calc, pass in the relevant loan amounts */
  sbaLoanAmount?: number;
  revolverAmount?: number;

  /** Optional: pass an array of initial Uses, e.g. to override "Business Acquisition" to be readOnly or a certain value */
  initialUses?: UseItemParam[];

  /** Optional callback if you want the parent to track totalUses or the updated array */
  onUsesChange?: (updatedUses: UseItemParam[], total: number) => void;
}

/**
 * A standalone component that displays only the “Uses of Funds” portion,
 * with optional SBA Guarantee Fee auto-calculation.
 */
export const SBAUses: React.FC<SBAUsesProps> = ({ sbaLoanAmount = 0, revolverAmount = 0, initialUses, onUsesChange }) => {
  // 1) Define your default uses
  const defaultUses: UseItemParam[] = [
    { key: "businessAcquisition", label: "Business Acquisition", value: 0 },
    { key: "realEstate", label: "Real Estate", value: 0 },
    { key: "mePurchase", label: "M&E Purchase", value: 0 },
    { key: "transactionExpense", label: "Transaction Expense", value: 0 },
    { key: "sbaGtyFee", label: "SBA Gty Fee", value: 0, readOnly: true }, // read-only
    { key: "workingCapital", label: "Working Capital", value: 0 },
  ];

  // 2) Merge user-provided initial uses (if any)
  //    If a user item has the same `key`, it overrides defaults
  //    If user passes a brand-new key not in defaults, we append it
  // 2) Merge user-provided `initialUses`
  const mergedUses = React.useMemo(() => {
    const finalList: UseItemParam[] = [...defaultUses];
    if (initialUses?.length) {
      initialUses.forEach((u) => {
        const idx = finalList.findIndex((du) => du.key === u.key);
        if (idx >= 0) {
          finalList[idx] = { ...finalList[idx], ...u, value: u.value ?? 0 };
        } else {
          finalList.push({ ...u, value: u.value ?? 0 });
        }
      });
    }
    return finalList;
  }, [initialUses]);

  // 3) Create local state for each Use’s numeric & displayed text
  interface UseState {
    key: string;
    label: string;
    value: number;
    inputValue: string; // store the raw user input
    readOnly?: boolean;
  }

  const [uses, setUses] = React.useState<UseState[]>(
    mergedUses.map((u) => ({
      key: u.key,
      label: u.label,
      value: u.value ?? 0,
      inputValue: u.value?.toLocaleString?.() || "",
      readOnly: u.readOnly,
    })),
  );
  // 3a) If parent's `mergedUses` changes, re-synchronize readOnly items
  React.useEffect(() => {
    setUses((prev) =>
      prev.map((existing) => {
        const incoming = mergedUses.find((m) => m.key === existing.key);
        if (!incoming) {
          // not found => keep
          return existing;
        }
        // if readOnly in either old or new => overwrite
        if (existing.readOnly || incoming.readOnly) {
          return {
            ...existing,
            label: incoming.label, // might have changed label
            value: incoming.value ?? 0,
            inputValue: (incoming.value ?? 0).toLocaleString(),
            readOnly: incoming.readOnly,
          };
        } else {
          // it's user-editable => keep the old value/input
          return existing;
        }
      }),
    );
  }, [mergedUses]);
  // 4) Auto-calc the SBA Gty Fee anytime the user changes or if sbaLoanAmount / revolver changes
  React.useEffect(() => {
    // 4a) compute fee
    const fee = calculateSbaGtyFee(sbaLoanAmount, revolverAmount);

    // 4b) set it in the uses array
    setUses((prev) =>
      prev.map((u) => {
        if (u.key === "sbaGtyFee") {
          return {
            ...u,
            value: fee,
            inputValue: fee ? fee.toLocaleString() : "",
          };
        }
        return u;
      }),
    );
  }, [sbaLoanAmount, revolverAmount]);

  // 5) Each time uses changes, call onUsesChange if provided
  React.useEffect(() => {
    const total = uses.reduce((sum, u) => sum + (u.value || 0), 0);
    onUsesChange?.(
      uses.map((u) => ({
        key: u.key,
        label: u.label,
        value: u.value,
        readOnly: u.readOnly,
      })),
      total,
    );
  }, [uses, onUsesChange]);

  // 6) Handlers for user text changes
  const handleUsesInputChange = (idx: number, raw: string) => {
    setUses((prev) => {
      const copy = [...prev];
      // If readOnly, ignore
      if ((copy[idx] as UseState).readOnly) return copy;
      (copy[idx] as UseState).inputValue = raw;
      return copy;
    });
  };

  const handleUsesInputBlur = (idx: number) => {
    setUses((prev) => {
      const copy = [...prev];
      if (!copy[idx] || (copy[idx] as UseState).readOnly) return copy;

      const raw = (copy[idx] as UseState).inputValue;
      const cleaned = raw.replace(/[^\d.]/g, "");
      if (!cleaned || isNaN(parseFloat(cleaned))) {
        (copy[idx] as UseState).value = 0;
        (copy[idx] as UseState).inputValue = "";
      } else {
        const val = parseFloat(cleaned);
        (copy[idx] as UseState).value = val;
        (copy[idx] as UseState).inputValue = val.toLocaleString();
      }
      return copy;
    });
  };

  // 7) Summation
  const totalUses = uses.reduce((sum, u) => sum + (u.value || 0), 0);

  // 8) Render
  return (
    <Card className="p-4 shadow-md">
      <CardHeader className="pb-2">
        <CardTitle>Uses of Funds</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-2">
          {/* Display each row */}
          {uses.map((u, idx) => {
            if (u.readOnly) {
              // Show read-only row
              return <RowItem key={u.key} label={u.label} rowIndex={idx} isHighlighted={idx % 2 === 0} tooltipContent="Calculated automatically" value={`$${(u.value || 0).toLocaleString()}`} />;
            } else {
              // Show editable row
              return (
                <RowItem
                  key={u.key}
                  label={u.label}
                  rowIndex={idx}
                  isHighlighted={idx % 2 === 0}
                  tooltipContent="Enter your expected cost"
                  value={<Input className="w-[100px] text-right" type="text" inputMode="decimal" pattern="[0-9]*" placeholder="0" value={u.inputValue} onChange={(e) => handleUsesInputChange(idx, e.target.value)} onBlur={() => handleUsesInputBlur(idx)} />}
                />
              );
            }
          })}

          {/* Total row */}
          <RowItem label="Total Uses" rowIndex={999} isHighlighted value={`$${totalUses.toLocaleString()}`} />
        </div>
      </CardContent>
    </Card>
  );
};
