import React, { useMemo } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "../../../components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../components/ui/table";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

interface DebtTrancheParam {
  name: string;
  principal: number;
  rate: number;
  amortization: number; // in months
  fullStandby: string;
  balloon: string;
}

interface DebtServiceComponentProps {
  years?: number; // Number of years to display (default to 6 as in the image)
  actualPeriodsOnly?: boolean;
}

const DebtServiceComponent: React.FC<DebtServiceComponentProps> = ({ years = 6, actualPeriodsOnly = false }) => {
  const { t } = useTranslation();
  const cashflow = useSelector((state: any) => state.cashflow.tableData.cashFlow);
  // Use optional chaining to handle possible undefined paths
  const tranches = useSelector((state: any) => state.cashflow.tranches?.tranches || state.cashflow.tranches || []);

  // Filter cashflow to only include actual periods if actualPeriodsOnly is true
  const filteredCashflow = useMemo(() => {
    return actualPeriodsOnly ? (cashflow ? cashflow.filter((item: any) => item.isForecasted === false) : []) : cashflow;
  }, [cashflow, actualPeriodsOnly]);

  // Create an array of year columns
  const yearColumns = useMemo(() => {
    return Array.from({ length: years }, (_, i) => i);
  }, [years]);

  // Get existing debt service from cashflow data
  const getExistingDebtService = (year: number): number => {
    if (!filteredCashflow || !Array.isArray(filteredCashflow) || year >= filteredCashflow.length) return 0;

    const yearData = filteredCashflow[year];
    if (!yearData) return 0;

    // Look for both Debt Repayment and Interest Expense
    const debtRepayment = yearData["Debt Repayment"]?.value || 0;
    const interestExpense = yearData["Interest Expense"]?.value || 0;

    // Return absolute values summed
    return Math.abs(debtRepayment) + Math.abs(interestExpense);
  };

  // Calculate tranche debt service for each tranche
  const calculateTrancheDebtService = (tranche: DebtTrancheParam): number[] => {
    if (!tranche || !tranche.principal || !tranche.rate || !tranche.amortization) {
      return yearColumns.map(() => 0);
    }

    const isFullStandby = tranche.fullStandby === "Yes";
    const isBalloon = tranche.balloon === "Yes";
    const annualRate = tranche.rate / 100;
    const monthlyRate = annualRate / 12;
    const totalMonths = tranche.amortization;
    const totalYears = Math.ceil(totalMonths / 12);

    // Calculate yearly payment schedule
    const yearlyPayments: number[] = [];
    let currentBalance = tranche.principal;

    // Debug information array to track payments over time
    const debugInfo: Array<{ year: number; interest: number; principal: number; totalPayment: number; remainingBalance: number }> = [];

    // Pre-calculate the fixed monthly payment amount for standard amortizing loans
    // This is calculated once and used throughout the term for consistent payments
    let fixedMonthlyPayment = 0;

    if (!isFullStandby && !isBalloon && tranche.name !== "Revolver" && annualRate > 0.0001) {
      // Standard PMT formula: PMT = P * r * (1+r)^n / ((1+r)^n - 1) for MONTHLY payments
      fixedMonthlyPayment = (monthlyRate * tranche.principal * Math.pow(1 + monthlyRate, totalMonths)) / (Math.pow(1 + monthlyRate, totalMonths) - 1);
    }

    console.log(`🔍 Starting calculations for ${tranche.name}:`);
    console.log(`- Principal: $${tranche.principal.toLocaleString()}`);
    console.log(`- Rate: ${tranche.rate}% (monthly: ${(monthlyRate * 100).toFixed(4)}%)`);
    console.log(`- Amortization: ${totalMonths} months (${totalYears} years)`);
    console.log(`- Type: ${isBalloon ? "Balloon" : isFullStandby ? "Full Standby" : tranche.name === "Revolver" ? "Revolver" : "Standard Amortizing"}`);
    console.log(`- Fixed Monthly Payment: $${fixedMonthlyPayment.toLocaleString()}`);
    console.log(`- Fixed Annual Payment (×12): $${(fixedMonthlyPayment * 12).toLocaleString()}`);

    // For each year, we'll calculate monthly payments and sum them
    for (let year = 0; year < years; year++) {
      let yearlyInterest = 0;
      let yearlyPrincipal = 0;
      let yearlyTotal = 0;

      // Check if we're past the amortization period
      if (year >= totalYears) {
        yearlyPayments.push(0);

        // Add to debug info
        debugInfo.push({
          year: year + 1,
          interest: 0,
          principal: 0,
          totalPayment: 0,
          remainingBalance: currentBalance,
        });

        continue;
      }

      // No more payments if balance is already 0
      if (currentBalance <= 0) {
        yearlyPayments.push(0);

        // Add to debug info
        debugInfo.push({
          year: year + 1,
          interest: 0,
          principal: 0,
          totalPayment: 0,
          remainingBalance: 0,
        });

        continue;
      }

      // Calculate each month's payment separately and sum for the year
      for (let month = 0; month < 12; month++) {
        // Skip calculation if we're out of months or balance is paid off
        const currentMonth = year * 12 + month;
        if (currentMonth >= totalMonths || currentBalance <= 0) {
          break;
        }

        // Special handling for Revolver - only calculate interest
        if (tranche.name === "Revolver") {
          const monthlyInterest = currentBalance * monthlyRate;
          yearlyInterest += monthlyInterest;
          yearlyTotal += monthlyInterest;
          // Revolver balance stays constant
          continue;
        }

        // For balloon payments, only calculate interest until final month
        if (isBalloon) {
          const monthlyInterest = currentBalance * monthlyRate;
          yearlyInterest += monthlyInterest;

          // Check if this is the final month of amortization (balloon payment)
          const isFinalMonth = currentMonth === totalMonths - 1;
          const monthlyPrincipal = isFinalMonth ? currentBalance : 0;

          yearlyPrincipal += monthlyPrincipal;
          yearlyTotal += monthlyInterest + monthlyPrincipal;
          currentBalance -= monthlyPrincipal;
          continue;
        }

        // For full standby periods, only pay interest
        if (isFullStandby) {
          const monthlyInterest = currentBalance * monthlyRate;
          yearlyInterest += monthlyInterest;
          yearlyTotal += monthlyInterest;
          // No principal reduction for full standby
          continue;
        }

        // Zero interest loans - simple division over total months
        if (annualRate === 0 || monthlyRate < 0.000001) {
          const monthlyPrincipal = tranche.principal / totalMonths;
          yearlyPrincipal += monthlyPrincipal;
          yearlyTotal += monthlyPrincipal;
          currentBalance -= monthlyPrincipal;
          continue;
        }

        // Standard amortizing loan with stable MONTHLY payments
        // Calculate interest portion based on current balance
        const monthlyInterest = currentBalance * monthlyRate;

        // Fixed payment minus interest gives principal
        let monthlyPrincipal = fixedMonthlyPayment - monthlyInterest;

        // Ensure we don't overpay the remaining balance
        monthlyPrincipal = Math.min(monthlyPrincipal, currentBalance);

        // Add to yearly totals
        yearlyInterest += monthlyInterest;
        yearlyPrincipal += monthlyPrincipal;
        yearlyTotal += monthlyInterest + monthlyPrincipal;

        // Reduce the balance for next month
        currentBalance -= monthlyPrincipal;
      }

      // Add the yearly totals to our payment schedule
      yearlyPayments.push(yearlyTotal);

      // Add to debug info
      debugInfo.push({
        year: year + 1,
        interest: yearlyInterest,
        principal: yearlyPrincipal,
        totalPayment: yearlyTotal,
        remainingBalance: currentBalance,
      });
    }

    // Fill remaining years with zeros if needed
    while (yearlyPayments.length < years) {
      yearlyPayments.push(0);

      // Add to debug info for completeness
      debugInfo.push({
        year: debugInfo.length + 1,
        interest: 0,
        principal: 0,
        totalPayment: 0,
        remainingBalance: currentBalance,
      });
    }

    // Display debug info in a table format
    console.log(`📊 Debt Service Schedule for ${tranche.name}:`);
    console.table(debugInfo);
    console.log("Total Debt Service:", yearlyPayments.map((payment) => `$${payment.toLocaleString()}`).join(", "));
    console.log("---------------------------------------------------");

    return yearlyPayments;
  };

  // Calculate total debt service by summing all tranches for each year
  const calculateTotalDebtService = useMemo((): number[] => {
    const totalByYear = yearColumns.map(() => 0);

    if (!tranches || !Array.isArray(tranches)) {
      return totalByYear;
    }

    tranches.forEach((tranche: DebtTrancheParam) => {
      if (!tranche) return;

      const trancheService = calculateTrancheDebtService(tranche);

      trancheService.forEach((payment, yearIndex) => {
        if (yearIndex < totalByYear.length) {
          // Using a type guard to avoid the TypeScript error
          if (totalByYear && totalByYear[yearIndex] !== undefined) {
            totalByYear[yearIndex] += payment;
          }
        }
      });
    });

    return totalByYear;
  }, [tranches, yearColumns]);

  // Function to get the EBITDA for a given year
  const getEbitda = (year: number): number => {
    if (!filteredCashflow || !Array.isArray(filteredCashflow) || year >= filteredCashflow.length) return 0;
    const yearData = filteredCashflow[year];
    if (!yearData) return 0;
    return yearData["EBITDA"]?.value;
  };
  const getAdjEbitda = (year: number): number => {
    if (!filteredCashflow || !Array.isArray(filteredCashflow) || year >= filteredCashflow.length) return 0;
    const yearData = filteredCashflow[year];
    if (!yearData) return 0;
    return yearData["Adjusted EBITDA"]?.value;
  };

  // Calculate DSCR using EBITDA
  const calculateDSCR = useMemo(() => {
    if (!filteredCashflow || !Array.isArray(filteredCashflow) || filteredCashflow.length === 0) {
      return yearColumns.map(() => "n.a.");
    }

    return yearColumns.map((yearIndex) => {
      if (yearIndex >= filteredCashflow.length) return "n.a.";

      // Use the safe accessor function we created
      const ebitda = getAdjEbitda(yearIndex);

      // Handle the TypeScript error by ensuring the index exists
      const debtService = calculateTotalDebtService && yearIndex < calculateTotalDebtService.length ? calculateTotalDebtService[yearIndex] || 0 : 0;

      if (debtService === 0) return "n.a.";
      return ebitda / debtService;
    });
  }, [filteredCashflow, calculateTotalDebtService, yearColumns]);

  // Format currency
  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    }).format(amount);
  };

  // Format percentage or return DIV/0! string
  const formatDSCR = (value: number | string) => {
    if (typeof value === "string") return value;
    if (isNaN(value) || !isFinite(value)) return "n.a.";
    return value.toFixed(2);
  };

  // Function to get the Revenue for a given year
  const getRevenue = (year: number): number => {
    if (!filteredCashflow || !Array.isArray(filteredCashflow) || year >= filteredCashflow.length) return 0;
    const yearData = filteredCashflow[year];
    if (!yearData) return 0;
    return yearData["Revenue"]?.value || 0;
  };

  return (
    <Card className="col-span-2 w-full shadow-lg mt-4">
      <CardHeader>
        <CardTitle>{actualPeriodsOnly ? "Debt Service & Coverage Analysis" : "Debt Service & Coverage Analysis"}</CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[200px]"></TableHead>
              {yearColumns.map((year) => (
                <TableHead key={year} className="text-right">
                  Year {year + 1}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {/* EBITDA Row */}
            <TableRow>
              <TableCell className="font-medium">EBITDA</TableCell>
              {yearColumns.map((year) => (
                <TableCell key={year} className="text-right">
                  {formatCurrency(getEbitda(year))}
                </TableCell>
              ))}
            </TableRow>

            {/* EBITDA Margin % */}
            <TableRow>
              <TableCell className="font-medium">EBITDA Margin %</TableCell>
              {yearColumns.map((year) => (
                <TableCell key={year} className="text-right">
                  {getRevenue(year) > 0 ? ((getEbitda(year) / getRevenue(year)) * 100).toFixed(1) + "%" : "n.a."}
                </TableCell>
              ))}
            </TableRow>

            {/* Adjustments */}
            <TableRow>
              <TableCell className="font-medium">Adjustments</TableCell>
              {yearColumns.map((year) => (
                <TableCell key={year} className="text-right">
                  {formatCurrency(0)}
                </TableCell>
              ))}
            </TableRow>

            {/* Adjusted EBITDA */}
            <TableRow>
              <TableCell className="font-medium">Adjusted EBITDA</TableCell>
              {yearColumns.map((year) => (
                <TableCell key={year} className="text-right">
                  {formatCurrency(getAdjEbitda(year))}
                </TableCell>
              ))}
            </TableRow>

            {/* Adjusted EBITDA Margin % */}
            <TableRow>
              <TableCell className="font-medium">Adjusted EBITDA Margin %</TableCell>
              {yearColumns.map((year) => (
                <TableCell key={year} className="text-right">
                  {getRevenue(year) > 0 ? ((getAdjEbitda(year) / getRevenue(year)) * 100).toFixed(1) + "%" : "n.a."}
                </TableCell>
              ))}
            </TableRow>

            {/* Existing Debt Service */}
            <TableRow>
              <TableCell className="font-medium">Existing Debt Service</TableCell>
              {yearColumns.map((year) => (
                <TableCell key={year} className="text-right">
                  {formatCurrency(getExistingDebtService(year))}
                </TableCell>
              ))}
            </TableRow>

            {/* Individual Tranches */}
            {Array.isArray(tranches) &&
              tranches.map((tranche: DebtTrancheParam, index: number) => {
                if (!tranche) return null;

                const trancheService = calculateTrancheDebtService(tranche);
                // Use the actual tranche name from the data
                const trancheName = `${tranche.name} Debt Service`;

                return (
                  <TableRow key={index}>
                    <TableCell className="font-medium">{trancheName}</TableCell>
                    {yearColumns.map((year) => (
                      <TableCell key={year} className="text-right">
                        {formatCurrency(trancheService[year] || 0)}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}

            {/* Total Debt Service */}
            <TableRow className="font-bold">
              <TableCell className="font-medium">Total Debt Service</TableCell>
              {calculateTotalDebtService.map((total, year) => (
                <TableCell key={year} className="text-right">
                  {formatCurrency(total)}
                </TableCell>
              ))}
            </TableRow>

            {/* DSCR */}
            <TableRow>
              <TableCell className="font-medium">DSCR</TableCell>
              {calculateDSCR.map((dscr, year) => (
                <TableCell key={year} className="text-right">
                  {formatDSCR(dscr)}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default DebtServiceComponent;
