import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

// Shadcn UI components (adjust imports to match your project)
import { Card, CardDescription, CardHeader, CardContent, CardTitle } from "../../components/ui/card";
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { Checkbox } from "../../components/ui/checkbox";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Slider } from "../../components/ui/slider";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../components/ui/tooltip";
import { Check, Info, X, AlertCircle } from "lucide-react";
import { cn } from "../../lib/utils";
// Shadcn UI Table
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/table";
// Shadcn UI Select
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { calculateSbaGtyFee, calculateLoanFees } from "./calculatefees";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { updatetranches } from "../../redux/actions/cashflowAction";
import { toast } from "sonner";
import LoanTermAlerts from "./components/LoanTermAlerts";
import { Alert, AlertTitle, AlertDescription, AlertAction } from "../../components/ui/alert";

// -------------------------------------------------------------------
// 1) Types for props
// -------------------------------------------------------------------

/** Tranche param that can be passed in. If a field is omitted, we default to 0 or "No". */
export interface DebtTrancheParam {
  name: string;
  principal?: number;
  rate?: number;
  amortization?: number;
  fullStandby?: "Yes" | "No";
  balloon?: "Yes" | "No";
}

/** Each Use item. If readOnly is true (like "SBA Gty Fee"), we don't show an editable input. */
export interface UseItemParam {
  key: string;
  label: string;
  value?: number; // if omitted => 0
  readOnly?: boolean; // if true => display only
  inputValue?: string; // Added to match UseOfFunds
}

/** The main props for the SBAFinancingPage. */
export interface SBAFinancingPageProps {
  initialLtmEbitda?: number;
  initialDebtAvailable?: number;

  /** The user may pass an array of tranches that should appear by default, each with optional fields. */
  initialTranches?: DebtTrancheParam[];

  /** The user can pass an array of Uses. If none passed, we default to your standard list. */
  initialUses?: UseItemParam[];

  /** The user can pass an initial Equity, if they want. */
  initialNewEquity?: number;

  /** If the user wants to have some optionalTranches already "checked" or added. */
  optionalTranchesChecked?: Record<string, boolean>;

  // New props from Debt component
  sources?: any;
  uses?: any;
  tranches?: any[];
  trancheTerms?: any[];
  sbaData?: any;
  updateSourcesAndUses?: (sources: any, uses: any) => void;
  addTranche?: (tranche: any) => void;
  removeTranche?: (index: number) => void;
  updateTrancheTerm?: (index: number, terms: any) => void;
  setSbaData?: (data: any) => void;
  defaultTrancheTerms?: {
    amortization: number;
    dscr: number;
    interestRate: number;
    cashSweep: number;
    transaction: any;
  };
  updateSchedule?: (data: { schedule: any[]; totalDebt: number; sources: any; uses: any; tranches: any[]; sbaData: any }) => void;
  fcfArray?: number[];
  transaction?: any;
}

// -------------------------------------------------------------------
// Reusable RowItem (layout style for label + value), from your snippet
// -------------------------------------------------------------------

export function TooltipIcon({ content, cs = "" }: { content: string; cs?: string }) {
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="cursor-pointer">
            <Info className={cn("h-4 w-4 text-gray-500 hover:text-black", cs)} />
          </span>
        </TooltipTrigger>
        <TooltipContent className="max-w-96 flex flex-wrap">
          <p className="color-white">{content}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

interface StatItemProps {
  title: string;
  tooltipContent: string;
  value: string | number;
  suffix?: string; // Optional suffix like %, $, x
}

const StatItem: React.FC<StatItemProps> = ({ title, tooltipContent, value, suffix = "" }) => {
  return (
    <div className="flex flex-col text-left">
      <div className="flex items-center space-x-2">
        <CardDescription className="text-sm font-medium">{title}</CardDescription>
        <TooltipIcon content={tooltipContent} />
      </div>
      <div className="text-base sm:text-lg 2lg:text-xl font-bold">
        {value}
        {suffix}
      </div>
    </div>
  );
};

interface RowItemProps {
  label: string;
  value: React.ReactNode | string;
  tooltipContent?: string;
  rowIndex?: number;
  isHighlighted?: boolean;
  isNegative?: boolean;
  highlightColor?: string;
  textColor?: string;
  onSolve?: () => void;
  showSolve?: boolean;
  isFocused?: boolean;
}

export const RowItem: React.FC<RowItemProps> = ({ label, value, tooltipContent, rowIndex = 0, isHighlighted = false, isNegative = false, highlightColor = "bg-gray-100", textColor = "text-black", onSolve, showSolve = false, isFocused = false }) => {
  const isEven = rowIndex % 2 === 1;
  const bg = isEven ? "bg-gray-50" : "";

  return (
    <div className={`flex justify-between items-center px-2 h-8  ${isHighlighted ? `${highlightColor} rounded-md` : ""}`}>
      <div className={`flex gap-2 items-center text-sm ${isHighlighted ? `${textColor} font-semibold` : "text-gray-500 font-medium"}`}>
        <span className="truncate">{label}</span>
        {tooltipContent && <TooltipIcon content={tooltipContent} />}
        {showSolve && onSolve && isFocused && (
          <Button variant="ghost" size="sm" className="h-6 px-2 py-0 text-xs hover:bg-gray-100" onClick={onSolve}>
            Solve
          </Button>
        )}
      </div>
      <div className={`text-sm font-semibold ${isNegative ? "text-red-500" : isHighlighted ? textColor : "text-black"}`}>{value}</div>
    </div>
  );
};

// -------------------------------------------------------------------
// DebtTranche logic, with sliders for Rate/Amort, from your snippet
// -------------------------------------------------------------------
interface DebtTranche {
  name: string;
  principal: number; // now controlled from "Sources"
  rate: number;
  amortization: number;
  fullStandby: "Yes" | "No";
  balloon: "Yes" | "No";
}

// We will display each tranche in a "Loan Terms" card later.
const DebtTrancheRow: React.FC<{
  tranche: DebtTranche;
  onChange: (updated: DebtTranche) => void;
}> = ({ tranche, onChange }) => {
  // Handle Rate / Amort
  const handleRate = (vals: number[]) => {
    onChange({ ...tranche, rate: vals[0] ?? 0 });
  };
  const handleAmort = (vals: number[]) => {
    onChange({ ...tranche, amortization: vals[0] ?? 0 });
  };
  const handleFullStandby = (val: string) => {
    onChange({ ...tranche, fullStandby: val as "Yes" | "No" });
  };
  const handleBalloon = (val: string) => {
    onChange({ ...tranche, balloon: val as "Yes" | "No" });
  };

  // Yearly Payment = ( monthlyPayment * 12 ),  monthlyPayment from PMT formula
  // PMT = r * PV / (1 - (1+r)^-n)
  // where r = monthlyRate, n = amortization in months, PV = principal
  // if invalid => 0
  function computeYearlyPayment(tr: DebtTranche) {
    if (tr.principal <= 0 || tr.rate < 0) return 0;
    const annualRate = tr.rate / 100;
    const monthlyRate = annualRate / 12;
    const totalMonths = tr.amortization;

    // For Revolver, only calculate interest on principal
    if (tr.name === "Revolver") {
      return tr.principal * annualRate;
    }

    if (tr.balloon === "Yes") {
      // interest only
      return tr.principal * annualRate;
    } else if (tr.fullStandby === "Yes") {
      // interest only for full standby
      return tr.principal * annualRate;
    } else {
      // standard PMT using monthly payments
      if (annualRate === 0 || monthlyRate < 0.000001) {
        // no interest or invalid amort => simple division
        return tr.principal / (tr.amortization / 12);
      }

      // Calculate fixed monthly payment using standard PMT formula
      // PMT = P * r * (1+r)^n / ((1+r)^n - 1)
      const fixedMonthlyPayment = (monthlyRate * tr.principal * Math.pow(1 + monthlyRate, totalMonths)) / (Math.pow(1 + monthlyRate, totalMonths) - 1);

      // Return annualized payment
      return fixedMonthlyPayment * 12;
    }
  }
  const yearlyPayment = computeYearlyPayment(tranche);

  const isRevolver = tranche.name === "Revolver";

  return (
    <Card className="p-2 shadow-sm">
      <CardHeader className="pb-4">
        <CardTitle className="text-sm">{tranche.name} Terms</CardTitle>
      </CardHeader>
      <CardContent className="space-y-2">
        {/* Principal shown read-only */}
        <div className="flex items-center gap-2 justify-between">
          <Label className="w-24">Principal</Label>
          <CardDescription className="text-sm pr-3">{tranche.principal.toLocaleString()}</CardDescription>
        </div>

        {/* Rate Slider */}
        <div className="">
          <Label className="text-sm">Rate (%)</Label>
          <Slider className="mt-1" min={0} max={20} step={0.25} value={[tranche.rate]} onValueChange={handleRate} />
          <p className="text-xs text-gray-500 mt-1">{tranche.rate.toFixed(2)}%</p>
        </div>

        {/* Amort Slider */}
        <div className="">
          <Label className="text-sm">Amort (months)</Label>
          <Slider className="mt-1" min={12} max={360} step={12} value={[tranche.amortization]} onValueChange={handleAmort} />
          <p className="text-xs text-gray-500 mt-1">{tranche.amortization} months</p>
        </div>

        {/* Full Standby Dropdown */}
        <div className="flex items-center gap-2 justify-between">
          <Label className="w-24">Full Standby</Label>
          <Select value={tranche.fullStandby} onValueChange={handleFullStandby}>
            <SelectTrigger className="w-[100px]">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Yes">Yes</SelectItem>
              <SelectItem value="No">No</SelectItem>
            </SelectContent>
          </Select>
        </div>

        {/* Balloon Dropdown - Hide for Revolver */}
        {!isRevolver && (
          <div className="flex items-center gap-2 justify-between">
            <Label className="w-24">Balloon</Label>
            <Select value={tranche.balloon} onValueChange={handleBalloon}>
              <SelectTrigger className="w-[100px]">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Yes">Yes</SelectItem>
                <SelectItem value="No">No</SelectItem>
              </SelectContent>
            </Select>
          </div>
        )}

        {/* Yearly Payment */}
        <div className="flex items-center gap-2 justify-between">
          <Label className="w-36">Yearly Payment</Label>
          <CardDescription className="text-sm pr-3">{yearlyPayment > 0 ? `$${Number(yearlyPayment.toFixed(0)).toLocaleString()}` : "$0"}</CardDescription>
        </div>
      </CardContent>
    </Card>
  );
};

// -------------------------------------------------------------------
// The SBA Loan Term Justification card (example based on YearCashflow).
// You can rename or adapt the row items to match your screenshot's fields.
interface SbaLoanTermJustificationProps {
  rows: JustificationRow[];
  totalAmount: number;
  onChangeTerm: (index: number, newTerm: number) => void;
}
interface JustificationRow {
  label: string;
  amount: number;
  term: number;
  // derived
  pctOfTotal: number;
  wtdAvg: number;
}

export const SbaLoanTermJustification: React.FC<SbaLoanTermJustificationProps> = ({ rows, totalAmount, onChangeTerm }) => {
  const handleTermChange = (index: number, raw: string) => {
    const cleaned = raw.replace(/[^\d.]/g, "");
    if (!cleaned || isNaN(parseFloat(cleaned))) {
      onChangeTerm(index, 0);
    } else {
      onChangeTerm(index, parseFloat(cleaned));
    }
  };

  // sum for final row
  const sumPct = rows.reduce((acc, r) => acc + r.pctOfTotal, 0);
  const sumWtdAvg = rows.reduce((acc, r) => acc + r.wtdAvg, 0);

  return (
    <Card className="">
      <CardHeader className="pb-2">
        <CardTitle>SBA Loan Term Justification</CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Use</TableHead>
              <TableHead>Amount</TableHead>
              <TableHead>% of Total</TableHead>
              <TableHead>Term</TableHead>
              <TableHead>Wtd. Avg</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {rows.map((row, idx) => (
              <TableRow className="hover:bg-gray-100" key={idx}>
                <TableCell>{row.label}</TableCell>
                <TableCell>${row.amount.toLocaleString()}</TableCell>
                <TableCell>{row.pctOfTotal.toFixed(1)}%</TableCell>
                <TableCell>
                  {/* editable term */}
                  <Input className="w-[70px]" type="text" inputMode="decimal" pattern="[0-9]*" value={row.term || ""} onChange={(e) => handleTermChange(idx, e.target.value)} />
                </TableCell>
                <TableCell>{row.wtdAvg.toFixed(1)}</TableCell>
              </TableRow>
            ))}
            {/* Total row */}
            <TableRow className="font-semibold bg-gray-100">
              <TableCell>Total</TableCell>
              <TableCell>${totalAmount.toLocaleString()}</TableCell>
              <TableCell>{sumPct.toFixed(1)}%</TableCell>
              <TableCell />
              <TableCell>{sumWtdAvg.toFixed(1)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

// -------------------------------------------------------------------
// Example: the big "SBA Financing" page that puts it all together:
//  1) Debt KPIs & Add Tranches
//  2) Sources & Uses side by side
//  3) SBA Loan Term Justification
//  4) Additional Lender Loan Terms (2 columns per row)
// -------------------------------------------------------------------

const defaultDebtTranches: DebtTranche[] = [
  {
    name: "SBA 7(a) Loan",
    principal: 0,
    rate: 10,
    amortization: 120,
    fullStandby: "No",
    balloon: "No",
  },
];

const optionalTranches: Omit<DebtTranche, "fullStandby" | "balloon">[] = [
  { name: "Junior Debt", principal: 0, rate: 9, amortization: 60 },
  { name: "Seller Note A", principal: 0, rate: 5, amortization: 180 },
  { name: "Seller Note B", principal: 0, rate: 5, amortization: 180 },
  { name: "Revolver", principal: 0, rate: 8, amortization: 36 },
  { name: "Senior Debt", principal: 0, rate: 7, amortization: 120 },
  { name: "Other", principal: 0, rate: 10, amortization: 60 },
];

// Utility to convert a partial param to a full DebtTranche
function convertToFullTranche(t: DebtTrancheParam): DebtTranche {
  return {
    name: t.name,
    principal: t.principal ?? 0,
    rate: t.rate ?? 0,
    amortization: t.amortization ?? 0,
    fullStandby: t.fullStandby ?? "No",
    balloon: t.balloon ?? "No",
  };
}

// We'll also store each principal in "sources" with a text input
interface SourceDebtState {
  name: string;
  principal: number; // numeric
  principalInput: string; // raw string for the input field
}

interface UseOfFunds {
  key: string;
  label: string;
  value: number;
  inputValue: string;
  readOnly?: boolean;
}

// Add types for schedule entries
interface TrancheScheduleEntry {
  name: string;
  balance: number;
  interestPayment: number;
  principalPayment: number;
  debtService: number;
  originalPrincipal?: number; // Add this field to track original principal
}

interface ScheduleEntry {
  year: number;
  noi: number;
  debtService: number;
  interestPayment: number;
  principalPayment: number;
  cashSweepPayment: number;
  dividends: number;
  debtBalance: number;
  trancheDetails: TrancheScheduleEntry[];
}

// PMT formula using monthly payments and converting to yearly
const calculatePMT = (annualRate: number, years: number, principal: number): number => {
  if (annualRate === 0) return principal / years;
  const monthlyRate = annualRate / 12;
  const totalMonths = years * 12;

  // Standard PMT formula: PMT = P * r * (1+r)^n / ((1+r)^n - 1)
  const monthlyPayment = (monthlyRate * principal * Math.pow(1 + monthlyRate, totalMonths)) / (Math.pow(1 + monthlyRate, totalMonths) - 1);

  return monthlyPayment * 12;
};

// IPMT formula using monthly payments and converting to yearly
const calculateIPMT = (annualRate: number, currentYear: number, totalYears: number, principal: number): number => {
  if (annualRate === 0) return 0;
  const monthlyRate = annualRate / 12;
  const totalMonths = totalYears * 12;

  // Calculate for each month in the current year
  let yearlyInterest = 0;
  let remainingBalance = principal;

  // Get monthly payment
  const monthlyPayment = (monthlyRate * principal) / (1 - Math.pow(1 + monthlyRate, -totalMonths));

  // Calculate interest for each month in the current year
  const startMonth = (currentYear - 1) * 12;
  for (let month = 1; month <= 12; month++) {
    const monthNumber = startMonth + month;
    if (monthNumber > totalMonths) break;

    // Calculate interest portion for this month
    const interestThisMonth = remainingBalance * monthlyRate;
    yearlyInterest += interestThisMonth;

    // Update remaining balance
    const principalThisMonth = monthlyPayment - interestThisMonth;
    remainingBalance -= principalThisMonth;
  }

  return yearlyInterest;
};

// Calculate both principal and interest payments for a tranche
const calculateTranchePayments = (
  tranche: DebtTranche,
  currentYear: number,
  currentBalance: number,
): {
  interestPayment: number;
  principalPayment: number;
  totalPayment: number;
  endingBalance: number;
} => {
  if (currentBalance <= 0) {
    return {
      interestPayment: 0,
      principalPayment: 0,
      totalPayment: 0,
      endingBalance: 0,
    };
  }

  const annualRate = tranche.rate / 100;
  const monthlyRate = annualRate / 12;
  const totalYears = Math.ceil(tranche.amortization / 12);
  const totalMonths = tranche.amortization;

  // Special handling for Revolver - only calculate interest
  if (tranche.name === "Revolver") {
    if (currentYear <= totalYears) {
      const interestPayment = currentBalance * annualRate;

      // Add detailed logging for Revolver
      console.log(`[Year ${currentYear}] ${tranche.name} (Revolver): `, {
        type: "revolver",
        rate: `${(annualRate * 100).toFixed(2)}%`,
        interestPayment: interestPayment.toLocaleString("en-US", { style: "currency", currency: "USD" }),
        principalPayment: "$0",
        totalPayment: interestPayment.toLocaleString("en-US", { style: "currency", currency: "USD" }),
        startBalance: currentBalance.toLocaleString("en-US", { style: "currency", currency: "USD" }),
        endBalance: currentBalance.toLocaleString("en-US", { style: "currency", currency: "USD" }),
      });

      return {
        interestPayment,
        principalPayment: 0,
        totalPayment: interestPayment,
        endingBalance: currentBalance, // Revolver balance stays constant
      };
    } else {
      return {
        interestPayment: 0,
        principalPayment: 0,
        totalPayment: 0,
        endingBalance: currentBalance,
      };
    }
  }

  // For balloon payments, only calculate interest until final year
  if (tranche.balloon === "Yes") {
    const interestPayment = currentBalance * annualRate;
    const principalPayment = currentYear === totalYears ? currentBalance : 0;

    // Add detailed logging for balloon payments
    console.log(`[Year ${currentYear}] ${tranche.name} (Balloon): `, {
      type: "balloon",
      rate: `${(annualRate * 100).toFixed(2)}%`,
      interestPayment: interestPayment.toLocaleString("en-US", { style: "currency", currency: "USD" }),
      principalPayment: principalPayment.toLocaleString("en-US", { style: "currency", currency: "USD" }),
      totalPayment: (interestPayment + principalPayment).toLocaleString("en-US", { style: "currency", currency: "USD" }),
      startBalance: currentBalance.toLocaleString("en-US", { style: "currency", currency: "USD" }),
      endBalance: (currentBalance - principalPayment).toLocaleString("en-US", { style: "currency", currency: "USD" }),
      isFinalYear: currentYear === totalYears,
    });

    return {
      interestPayment,
      principalPayment,
      totalPayment: interestPayment + principalPayment,
      endingBalance: currentBalance - principalPayment,
    };
  }

  // For full standby periods, only pay interest
  if (tranche.fullStandby === "Yes") {
    const interestPayment = currentBalance * annualRate;

    // Add detailed logging for standby payments
    console.log(`[Year ${currentYear}] ${tranche.name} (Standby): `, {
      type: "standby",
      rate: `${(annualRate * 100).toFixed(2)}%`,
      interestPayment: interestPayment.toLocaleString("en-US", { style: "currency", currency: "USD" }),
      principalPayment: "$0",
      totalPayment: interestPayment.toLocaleString("en-US", { style: "currency", currency: "USD" }),
      startBalance: currentBalance.toLocaleString("en-US", { style: "currency", currency: "USD" }),
      endBalance: currentBalance.toLocaleString("en-US", { style: "currency", currency: "USD" }),
    });

    return {
      interestPayment,
      principalPayment: 0,
      totalPayment: interestPayment,
      endingBalance: currentBalance,
    };
  }

  // Calculate yearly totals using month-by-month amortization
  let yearlyInterest = 0;
  let yearlyPrincipal = 0;
  let yearlyTotal = 0;
  let endingBalance = currentBalance;

  // Pre-calculate fixed monthly payment for standard amortizing loans
  // This is calculated once based on original terms and used for consistent monthly payments
  let fixedMonthlyPayment = 0;

  if (annualRate > 0.0001) {
    // Standard PMT formula: PMT = P * r * (1+r)^n / ((1+r)^n - 1) for MONTHLY payments
    fixedMonthlyPayment = (monthlyRate * tranche.principal * Math.pow(1 + monthlyRate, totalMonths)) / (Math.pow(1 + monthlyRate, totalMonths) - 1);
  } else {
    // For zero interest loans, simple division
    fixedMonthlyPayment = tranche.principal / totalMonths;
  }

  // Skip calculation if we're past the amortization period
  if (currentYear > totalYears) {
    // Add detailed logging for expired loan
    console.log(`[Year ${currentYear}] ${tranche.name} (Expired): `, {
      type: "expired",
      message: "Past amortization period",
      startBalance: currentBalance.toLocaleString("en-US", { style: "currency", currency: "USD" }),
    });

    return {
      interestPayment: 0,
      principalPayment: 0,
      totalPayment: 0,
      endingBalance: currentBalance,
    };
  }

  // Zero interest loans - special case
  if (annualRate === 0 || monthlyRate < 0.000001) {
    // Calculate remaining periods
    const remainingMonths = totalMonths - (currentYear - 1) * 12;
    const monthsThisYear = Math.min(12, remainingMonths);
    const monthlyPrincipal = tranche.principal / totalMonths;

    yearlyPrincipal = monthlyPrincipal * monthsThisYear;
    yearlyTotal = yearlyPrincipal;
    endingBalance = currentBalance - yearlyPrincipal;

    // Add detailed logging for zero interest loans
    console.log(`[Year ${currentYear}] ${tranche.name} (Zero Interest): `, {
      type: "zero_interest",
      monthsThisYear,
      monthlyPrincipal: monthlyPrincipal.toLocaleString("en-US", { style: "currency", currency: "USD" }),
      yearlyPrincipal: yearlyPrincipal.toLocaleString("en-US", { style: "currency", currency: "USD" }),
      totalPayment: yearlyTotal.toLocaleString("en-US", { style: "currency", currency: "USD" }),
      startBalance: currentBalance.toLocaleString("en-US", { style: "currency", currency: "USD" }),
      endBalance: endingBalance.toLocaleString("en-US", { style: "currency", currency: "USD" }),
    });

    return {
      interestPayment: 0,
      principalPayment: yearlyPrincipal,
      totalPayment: yearlyTotal,
      endingBalance: endingBalance,
    };
  }

  // Standard amortizing loan with interest
  // Calculate each month's payment separately for this year
  let localBalance = currentBalance;

  for (let month = 0; month < 12; month++) {
    // Skip calculation if balance is paid off
    if (localBalance <= 0) {
      break;
    }

    // Calculate current month within the overall loan
    const currentMonth = (currentYear - 1) * 12 + month;

    // Skip if we're past total months
    if (currentMonth >= totalMonths) {
      break;
    }

    // Calculate interest portion based on current balance
    const monthlyInterest = localBalance * monthlyRate;

    // Fixed payment minus interest gives principal
    let monthlyPrincipal = fixedMonthlyPayment - monthlyInterest;

    // Ensure we don't overpay the remaining balance
    monthlyPrincipal = Math.min(monthlyPrincipal, localBalance);

    // Add to yearly totals
    yearlyInterest += monthlyInterest;
    yearlyPrincipal += monthlyPrincipal;
    yearlyTotal += monthlyInterest + monthlyPrincipal;

    // Reduce the balance for next month
    localBalance -= monthlyPrincipal;
  }

  endingBalance = localBalance;

  // Add detailed logging for standard amortizing loans
  console.log(`[Year ${currentYear}] ${tranche.name} (Amortizing): `, {
    type: "amortizing",
    rate: `${(annualRate * 100).toFixed(2)}%`,
    monthlyRate: `${(monthlyRate * 100).toFixed(4)}%`,
    fixedMonthlyPayment: fixedMonthlyPayment.toLocaleString("en-US", { style: "currency", currency: "USD" }),
    yearlyInterest: yearlyInterest.toLocaleString("en-US", { style: "currency", currency: "USD" }),
    yearlyPrincipal: yearlyPrincipal.toLocaleString("en-US", { style: "currency", currency: "USD" }),
    yearlyPayment: yearlyTotal.toLocaleString("en-US", { style: "currency", currency: "USD" }),
    startBalance: currentBalance.toLocaleString("en-US", { style: "currency", currency: "USD" }),
    endBalance: endingBalance.toLocaleString("en-US", { style: "currency", currency: "USD" }),
  });

  return {
    interestPayment: yearlyInterest,
    principalPayment: yearlyPrincipal,
    totalPayment: yearlyTotal,
    endingBalance: endingBalance,
  };
};

export const SBAFinancingPage: React.FC<SBAFinancingPageProps> = ({
  initialLtmEbitda = 0,
  initialDebtAvailable = 0,

  // If user passes a set of initialTranches, we convert them to full tranches
  initialTranches,
  // If user passes uses, we'll incorporate them, default to a standard set
  initialUses,
  // If user passes an initial equity
  initialNewEquity = 0,
  // If user passes a record of already-checked optional tranches
  optionalTranchesChecked,
  sources,
  uses: passedUses,
  tranches: passedTranches,
  trancheTerms,
  sbaData: passedSbaData,
  updateSourcesAndUses,
  addTranche,
  removeTranche,
  updateTrancheTerm,
  setSbaData,
  defaultTrancheTerms,
  updateSchedule,
  fcfArray,
  transaction,
}) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();

  const debtAvailable = initialDebtAvailable;
  // Example KPI states
  console.log(sources);
  const [ltmEbitda, setLtmEbitda] = useState<number>(initialLtmEbitda);
  const [schedule, setSchedule] = useState<ScheduleEntry[]>([]);

  // Initialize tranches from props if available
  const initialBaseTranches: DebtTrancheParam[] =
    passedTranches && passedTranches.length > 0
      ? passedTranches.map((t) => ({
          name: t.name,
          principal: t.principal,
          rate: t.rate || defaultTrancheTerms?.interestRate || 0,
          amortization: t.amortization || defaultTrancheTerms?.amortization || 0,
          fullStandby: t.fullStandby || "No",
          balloon: t.balloon || "No",
        }))
      : initialTranches && initialTranches.length > 0
        ? initialTranches
        : defaultDebtTranches;

  // Initialize source debts from props if available
  console.log(sources);
  const initialSourceDebts = sources
    ? sources.map((e) => ({
        name: e.name,
        principal: e.principal || 0,
        principalInput: (e.principal || 0).toLocaleString(),
      }))
    : initialBaseTranches.map((t) => ({
        name: t.name,
        principal: t.principal || 0,
        principalInput: t.principal ? t.principal.toLocaleString() : "",
      }));

  const [sourceDebts, setSourceDebts] = useState<SourceDebtState[]>(initialSourceDebts);

  // Default uses if none provided
  const defaultUseItems: UseOfFunds[] = [
    { key: "businessAcquisition", label: "Business Acquisition", value: 0, inputValue: "0", readOnly: false },
    { key: "realEstate", label: "Real Estate", value: 0, inputValue: "0", readOnly: false },
    { key: "equipmentAndMachinery", label: "M&E Purchase", value: 0, inputValue: "0", readOnly: false },
    { key: "legalcosts", label: "Legal Costs", value: 0, inputValue: "0", readOnly: false },
    { key: "transactionExpense", label: "Transaction Expense", value: 0, inputValue: "0", readOnly: false },
    { key: "sbaGtyFee", label: "SBA Gty Fee", value: 0, inputValue: "0", readOnly: true },
    { key: "workingCapital", label: "Working Capital", value: 0, inputValue: "0", readOnly: false },
  ];

  // Initialize uses from props if available
  const initialUseItems = passedUses
    ? Array.isArray(passedUses)
      ? passedUses.map((u) => ({
          key: u.key,
          label: u.label || getUseLabelFromKey(u.key),
          value: u.value || 0,
          inputValue: (u.value || 0).toLocaleString(),
          readOnly: u.readOnly || u.key === "sbaGtyFee",
        }))
      : Object.entries(passedUses).map(([key, value]) => ({
          key,
          label: getUseLabelFromKey(key),
          value: (value as number) || 0,
          inputValue: ((value as number) || 0).toLocaleString(),
          readOnly: key === "sbaGtyFee",
        }))
    : initialUses
      ? initialUses.map((u) => ({
          key: u.key,
          label: u.label || getUseLabelFromKey(u.key),
          value: u.value || 0,
          inputValue: (u.value || 0).toLocaleString(),
          readOnly: u.readOnly || u.key === "sbaGtyFee",
        }))
      : defaultUseItems;

  const [uses, setUses] = useState<UseOfFunds[]>(initialUseItems);

  // Helper function to get label from key
  function getUseLabelFromKey(key: string): string {
    const labelMap: Record<string, string> = {
      businessAcquisition: "Business Acquisition",
      realEstate: "Real Estate",
      equipmentAndMachinery: "M&E Purchase",
      legalcosts: "Legal Costs",
      transactionExpense: "Transaction Expense",
      sbaGtyFee: "SBA Gty Fee",
      workingCapital: "Working Capital",
    };
    return labelMap[key] || key;
  }

  // "New Equity" with local input
  const [newEquity, setNewEquity] = useState<number>(initialNewEquity);
  const [newEquityInput, setNewEquityInput] = useState<string>(initialNewEquity ? initialNewEquity.toLocaleString() : "");

  // We also have a slider for equity % (relative to totalUses).
  // Range: 0..100
  const [equitySlider, setEquitySlider] = useState<number[]>([0]);

  // For the "Add More Tranches" dialog
  const [showDialog, setShowDialog] = useState(false);
  const [otherTrancheName, setOtherTrancheName] = useState("");

  // Initialize tranches state with passed values
  const [tranches, setTranches] = useState<DebtTranche[]>(
    initialBaseTranches.map((t) => ({
      ...convertToFullTranche(t),
      rate: t.rate || defaultTrancheTerms?.interestRate || 0,
      amortization: t.amortization || defaultTrancheTerms?.amortization || 0,
    })),
  );

  // Helper to sum principal from sourceDebts, excluding Revolver
  const totalDebt = sourceDebts.reduce((sum, d) => {
    // Exclude Revolver from total debt calculation
    if (d.name === "Revolver") return sum;
    return sum + d.principal;
  }, 0);

  // Track Revolver amount separately
  const revolverAmount = sourceDebts.find((d) => d.name === "Revolver")?.principal || 0;

  const debtOutstanding = totalDebt;

  // Initialize justification rows with terms from sbaData if available
  const [justificationRows, setJustificationRows] = useState<JustificationRow[]>([
    {
      label: "Business Acq",
      amount: passedSbaData?.businessAcq?.amount || 0,
      term: passedSbaData?.businessAcq?.term || 10,
      pctOfTotal: passedSbaData?.businessAcq?.pctOfTotal || 0,
      wtdAvg: 0,
    },
    {
      label: "Real Estate",
      amount: passedSbaData?.realEstate?.amount || 0,
      term: passedSbaData?.realEstate?.term || 25,
      pctOfTotal: passedSbaData?.realEstate?.pctOfTotal || 0,
      wtdAvg: 0,
    },
    {
      label: "Legal Costs",
      amount: passedSbaData?.legalcosts?.amount || 0,
      term: passedSbaData?.legalcosts?.term || 10,
      pctOfTotal: passedSbaData?.legalcosts?.pctOfTotal || 0,
      wtdAvg: 0,
    },
    {
      label: "M&E Purchase",
      amount: passedSbaData?.equipment?.amount || 0,
      term: passedSbaData?.equipment?.term || 10,
      pctOfTotal: passedSbaData?.equipment?.pctOfTotal || 0,
      wtdAvg: 0,
    },
    {
      label: "Transaction Expense",
      amount: passedSbaData?.transactionExpense?.amount || 0,
      term: passedSbaData?.transactionExpense?.term || 10,
      pctOfTotal: passedSbaData?.transactionExpense?.pctOfTotal || 0,
      wtdAvg: 0,
    },
    {
      label: "SBA Fees",
      amount: 0,
      term: 10,
      pctOfTotal: 0,
      wtdAvg: 0,
    },
    {
      label: "Working Capital",
      amount: 0,
      term: 10,
      pctOfTotal: 0,
      wtdAvg: 0,
    },
  ]);

  // Initialize checked tranches based on passed tranches
  const [checkedTranches, setCheckedTranches] = useState<Record<string, boolean>>(() => {
    const initial: Record<string, boolean> = {};
    for (const ot of optionalTranches) {
      const name = ot.name;
      // Check if this tranche exists in passed tranches
      const existsInPassed = passedTranches?.some((t) => t.name === name);
      if (existsInPassed) {
        initial[name] = true;
        continue;
      }
      // If not in passed tranches, check optionalTranchesChecked
      const userVal = optionalTranchesChecked?.[name];
      initial[name] = userVal || false;
    }
    return initial;
  });

  // Track renamed "Other" tranches with their original identifier
  const [renamedOtherTranches, setRenamedOtherTranches] = useState<Record<string, string>>(() => {
    // Initialize by identifying any non-standard tranches that might be renamed "Other" tranches
    const initialRenamedOthers: Record<string, string> = {};

    // Check if there are any tranches with names not in our standard lists
    if (passedTranches && passedTranches.length > 0) {
      // Get standard tranche names (excluding "Other")
      const standardTranchesNames = ["SBA 7(a) Loan", ...optionalTranches.filter((t) => t.name !== "Other").map((t) => t.name)];

      // Find any tranche that doesn't match our standard names
      // These might be renamed "Other" tranches
      passedTranches.forEach((tranche) => {
        if (!standardTranchesNames.includes(tranche.name) && tranche.name !== "Other") {
          // Assume this is a renamed "Other" tranche
          initialRenamedOthers[tranche.name] = "Other";
        }
      });
    }

    return initialRenamedOthers;
  });

  // Summation of uses
  const totalUses = uses.reduce((sum, u) => sum + u.value, 0);
  // Summation of sources = totalDebt + newEquity (excluding Revolver)
  const totalSources = totalDebt + newEquity;

  // If totalSources === totalUses => green check, else red cross
  const isBalanced = totalSources === totalUses;

  // ----------------------------------------------------------------
  // Handlers for the "Sources" text input
  //   We store user text in `sourceDebts[idx].principalInput`
  //   On blur, parse & update numeric `principal`, then re-format
  // ----------------------------------------------------------------

  const handleSourceDebtChange = (idx: number, raw: string) => {
    // just store the raw string
    setSourceDebts((prev) => {
      const copy = [...prev];
      copy[idx] = { ...(copy[idx] as SourceDebtState), principalInput: raw };
      return copy;
    });
  };

  const handleSourceDebtBlur = (idx: number) => {
    setSourceDebts((prev) => {
      const copy = [...prev];
      // Make sure we have the item at this index
      // @ts-ignore
      if (!copy[idx]) return copy;
      // @ts-ignore
      const raw = copy[idx].principalInput;
      const cleaned = raw.replace(/[^\d.]/g, "");

      if (!cleaned || isNaN(parseFloat(cleaned))) {
        // not a valid number -> set to 0
        // @ts-ignore
        copy[idx].principal = 0;
        // @ts-ignore
        copy[idx].principalInput = "";
      } else {
        const val = parseFloat(cleaned);
        // @ts-ignore
        copy[idx].principal = val;
        // @ts-ignore
        copy[idx].principalInput = val.toLocaleString();
      }
      return copy;
    });
    // Then, we also must keep the "tranches" in sync
    syncTranchesWithSourceDebts();
  };
  // ----------------------------------------------------------------
  // Keep "tranches" in sync with "sourceDebts" so the Loan Terms sees the same principal
  // We'll re-map each Tranche by name
  // ----------------------------------------------------------------
  const syncTranchesWithSourceDebts = () => {
    setTranches((prev) => {
      // For each existing Tranche, try to find the matching principal from sourceDebts
      return prev.map((tr) => {
        const found = sourceDebts.find((sd) => sd.name === tr.name);
        if (found) {
          return { ...tr, principal: found.principal };
        }
        return tr;
      });
    });
  };

  // ----------------------------------------------------------------
  // Add new optional tranches from the dialog
  // ----------------------------------------------------------------
  const handleAddTranches = () => {
    const newlySelected = optionalTranches
      .filter((ot) => checkedTranches[ot.name])
      .map((ot) => {
        // if "Other" is chosen + user typed a name
        const name = ot.name === "Other" && otherTrancheName.trim() ? otherTrancheName.trim() : ot.name;

        // convert to a full Tranche
        const fullT = convertToFullTranche(ot);
        return { ...fullT, name };
      });

    // Merge them into the "tranches" state
    setTranches((prev) => [...prev, ...newlySelected]);

    // Also add them to "sourceDebts" so the user can assign principal in Sources
    setSourceDebts((prev) => [
      ...prev,
      ...newlySelected.map((nt) => ({
        name: nt.name,
        principal: 0,
        principalInput: "",
      })),
    ]);

    // cleanup & close
    setOtherTrancheName("");
    setShowDialog(false);
  };

  // ----------------------------------------------------------------
  // Lender Loan Terms changes for Rate/Amort/Standby/Balloon
  //  -> must update `tranches` in state
  //  -> keep same principal
  //  -> no changes to "sourceDebts" if only rate/amort changes
  // ----------------------------------------------------------------

  // Now we handle "Add or Remove" from the optionalTranches based on check toggles
  // If user checks => add if not present
  // If user unchecks => remove if present
  // We handle "Other" name changes => rename if already in state
  const handleToggleTranche = (ot: Omit<DebtTranche, "fullStandby" | "balloon">, checked: boolean) => {
    const isRevolver = ot.name === "Revolver";
    const isOther = ot.name === "Other";

    // For all tranches, check if they exist in tranches or sourceDebts
    const existsInTranches = tranches.some((tr) => tr.name === ot.name);
    const existsInSourceDebts = sourceDebts.some((sd) => sd.name === ot.name);

    // Also check for renamed "Other" tranches
    let renamedOtherExists = false;
    let renamedOtherName = "";
    if (isOther) {
      // Find any renamed "Other" tranches in the current collections
      for (const [renamed, original] of Object.entries(renamedOtherTranches)) {
        if (original === "Other") {
          const hasTrancheWithName = tranches.some((t) => t.name === renamed);
          const hasSourceWithName = sourceDebts.some((sd) => sd.name === renamed);
          if (hasTrancheWithName || hasSourceWithName) {
            renamedOtherExists = true;
            renamedOtherName = renamed;
            break;
          }
        }
      }
    }

    console.log(`Toggle ${ot.name}: checked=${checked}, existsInTranches=${existsInTranches}, existsInSourceDebts=${existsInSourceDebts}, renamedOtherExists=${renamedOtherExists}`);

    if (checked) {
      // add if not present in tranches
      if (!existsInTranches && !renamedOtherExists) {
        let name = ot.name;
        if (isOther && otherTrancheName.trim()) {
          name = otherTrancheName.trim();

          // Update renamed other tranches tracking
          if (name !== "Other") {
            setRenamedOtherTranches((prev) => ({
              ...prev,
              [name]: "Other",
            }));
          }
        }
        const newTranche = convertToFullTranche({
          name,
          principal: ot.principal,
          rate: ot.rate,
          amortization: ot.amortization,
        });

        console.log(`Adding tranche: ${name}`);
        setTranches((prev) => [...prev, newTranche]);

        // Also add to sourceDebts if not present
        if (!existsInSourceDebts) {
          console.log(`Adding to sourceDebts: ${name}`);
          setSourceDebts((prev) => [
            ...prev,
            {
              name,
              principal: 0,
              principalInput: "",
            },
          ]);
        }
      } else if (isOther && otherTrancheName.trim()) {
        // if "Other" changed name => rename existing in both arrays
        const existingName = renamedOtherExists ? renamedOtherName : "Other";
        const newName = otherTrancheName.trim();

        // If the renamed tranche exists, update its name
        setTranches((prev) => prev.map((x) => (x.name === existingName ? { ...x, name: newName } : x)));
        setSourceDebts((prev) => prev.map((x) => (x.name === existingName ? { ...x, name: newName } : x)));

        // Update renamed other tranches tracking
        if (newName !== "Other") {
          setRenamedOtherTranches((prev) => {
            const updated = { ...prev };
            // Remove the old renamed entry if it exists
            if (renamedOtherExists) {
              delete updated[renamedOtherName];
            }
            // Add the new renamed entry
            updated[newName] = "Other";
            return updated;
          });
        }
      }
    } else {
      // Remove logic - check both direct and renamed matches
      if (isOther) {
        // For "Other" - remove both any direct "Other" and any renamed "Other" tranches
        if (existsInTranches || existsInSourceDebts) {
          // Remove direct "Other" matches
          console.log(`Removing direct "Other" tranche`);
          setTranches((prev) => prev.filter((x) => x.name !== "Other"));
          setSourceDebts((prev) => prev.filter((x) => x.name !== "Other"));
        }

        if (renamedOtherExists) {
          // Remove renamed "Other" tranches
          console.log(`Removing renamed "Other" tranche: ${renamedOtherName}`);
          setTranches((prev) => prev.filter((x) => x.name !== renamedOtherName));
          setSourceDebts((prev) => prev.filter((x) => x.name !== renamedOtherName));

          // Clean up from renamedOtherTranches
          setRenamedOtherTranches((prev) => {
            const updated = { ...prev };
            delete updated[renamedOtherName];
            return updated;
          });
        }
      } else {
        // Standard removal for non-Other tranches
        if (existsInTranches) {
          console.log(`Removing from tranches: ${ot.name}`);
          setTranches((prev) => prev.filter((x) => x.name !== ot.name));
        }
        if (existsInSourceDebts) {
          console.log(`Removing from sourceDebts: ${ot.name}`);
          setSourceDebts((prev) => prev.filter((x) => x.name !== ot.name));
        }
      }
    }

    // Force UI update for revolverAmount
    if (isRevolver) {
      // Make sure all checked state is updated correctly
      setCheckedTranches((prev) => ({
        ...prev,
        [ot.name]: checked,
      }));
    }
  };

  // We'll call handleToggleTranche on every checkbox change
  // For "Other," we also handle changes to "otherTrancheName"
  const handleOtherNameChange = (newName: string) => {
    setOtherTrancheName(newName);
    // if "Other" is toggled on, rename the existing "Other" in real time
    if (checkedTranches["Other"]) {
      // rename in the arrays
      setTranches((prev) => prev.map((tr) => (tr.name === "Other" || tr.name === otherTrancheName ? { ...tr, name: newName } : tr)));
      setSourceDebts((prev) => prev.map((sd) => (sd.name === "Other" || sd.name === otherTrancheName ? { ...sd, name: newName } : sd)));

      // Track the renamed Other tranche if it has a custom name
      if (newName.trim() && newName !== "Other") {
        setRenamedOtherTranches((prev) => ({
          ...prev,
          [newName]: "Other", // Map the new name to "Other" as its original identifier
        }));
      }
    }
  };

  const handleTrancheChange = (index: number, updated: DebtTranche) => {
    setTranches((prev) => {
      const copy = [...prev];
      copy[index] = updated;
      return copy;
    });
  };

  // ----------------------------------------------------------------
  // Handle user changes to "New Equity" input
  // ----------------------------------------------------------------
  const handleNewEquityChange = (raw: string) => {
    setNewEquityInput(raw);
  };
  const handleNewEquityBlur = () => {
    const cleaned = newEquityInput.replace(/[^\d.]/g, "");
    if (!cleaned || isNaN(parseFloat(cleaned))) {
      setNewEquity(0);
      setNewEquityInput("");
      setEquitySlider([0]);
    } else {
      const val = parseFloat(cleaned);
      setNewEquity(val);
      setNewEquityInput(val.toLocaleString());

      // Recompute the slider = % of totalUses
      const newPct = totalUses > 0 ? (val / totalUses) * 100 : 0;
      setEquitySlider([Math.min(100, Math.max(0, newPct))]);
    }
  };
  // ----------------------------------------------------------------
  // Handle user changes to the Equity slider
  //   newEquity = totalUses * (sliderVal / 100)
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // "Uses" changes
  // ----------------------------------------------------------------
  const handleUsesChange = (idx: number, raw: string) => {
    setUses((prev) => {
      const copy = [...prev];
      if ((copy[idx] as UseOfFunds).readOnly) return copy; // do nothing if readOnly
      (copy[idx] as UseOfFunds).inputValue = raw;
      return copy;
    });
  };
  const handleUsesBlur = (idx: number) => {
    setUses((prev) => {
      const copy = [...prev];
      if (!copy[idx]) return copy;
      // @ts-ignore
      const raw = copy[idx].inputValue;
      // @ts-ignore
      const cleaned = raw.replace(/[^\d.]/g, "");
      if (!cleaned || isNaN(parseFloat(cleaned))) {
        // @ts-ignore
        copy[idx].value = 0;
        // @ts-ignore
        copy[idx].inputValue = "";
      } else {
        const val = parseFloat(cleaned);

        // @ts-ignore
        copy[idx].value = val;
        // @ts-ignore
        copy[idx].inputValue = val.toLocaleString();
      }
      return copy;
    });
  };

  // ----------------------------------------------------------------
  // SBA Loan Term Justification data
  // ----------------------------------------------------------------

  // Recompute sba justification each render
  const totalJustificationAmt = justificationRows.reduce((acc, r) => acc + r.amount, 0);
  const equityPct = totalSources > 0 ? ((newEquity / totalSources) * 100).toFixed(1) : "n.a.";
  // Sync sba justification amounts to "uses" automatically
  useEffect(() => {
    setJustificationRows((prev) => {
      return prev.map((row) => {
        // Map justification row labels to use keys
        let useKey = "";
        if (row.label === "Business Acq") useKey = "businessAcquisition";
        if (row.label === "M&E") useKey = "equipmentAndMachinery";
        if (row.label === "Legal Costs") useKey = "legalcosts";
        if (row.label === "Real Estate") useKey = "realEstate";
        if (row.label === "Transaction Expense") useKey = "transactionExpense";
        if (row.label === "SBA Fees") useKey = "sbaGtyFee";
        if (row.label === "Working Capital") useKey = "workingCapital";

        // Find matching use amount
        const amount = uses.find((u) => u.key === useKey)?.value || 0;

        // Calculate percentage of total
        const pctOfTotal = totalUses > 0 ? (amount / totalUses) * 100 : 0;

        // Calculate weighted average
        const wtdAvg = row.term * (pctOfTotal / 100);

        return {
          ...row,
          amount,
          pctOfTotal,
          wtdAvg,
        };
      });
    });
  }, [uses, totalUses]); // Re-run when uses or totalUses changes

  // Calculate schedule for all tranches
  const calculateTrancheSchedule = useCallback(
    (tranches: DebtTranche[]) => {
      console.log("🔄 Calculating Tranche Schedule for all tranches:", tranches.map((t) => t.name).join(", "));

      const maxAmortYears = Math.max(...tranches.map((t) => Math.ceil(t.amortization / 12)));
      const schedule: ScheduleEntry[] = [];

      // Initialize tranche balances with original values
      let trancheBalances = tranches.map((t) => ({
        name: t.name,
        originalPrincipal: t.principal, // Store original principal for payment calculations
        balance: t.principal, // Current balance that will be reduced over time
        rate: t.rate / 100,
        amortization: t.amortization,
        balloon: t.balloon,
        fullStandby: t.fullStandby,
      }));

      console.log(
        "🏁 Initial Tranche Balances:",
        trancheBalances.map((t) => ({
          name: t.name,
          originalPrincipal: t.originalPrincipal.toLocaleString("en-US", { style: "currency", currency: "USD" }),
          balance: t.balance.toLocaleString("en-US", { style: "currency", currency: "USD" }),
          rate: `${(t.rate * 100).toFixed(2)}%`,
          amortizationMonths: t.amortization,
          amortizationYears: Math.ceil(t.amortization / 12),
          balloon: t.balloon,
          fullStandby: t.fullStandby,
        })),
      );

      // For each year
      for (let year = 1; year <= maxAmortYears; year++) {
        console.log(`\n📅 === YEAR ${year} ===`);

        // Use FCF for the current year if available, otherwise use the last available FCF value
        const yearlyNOI: number = fcfArray?.[year - 1] ?? ((fcfArray && fcfArray.length > 0 ? fcfArray[fcfArray.length - 1] : 0) || ltmEbitda || 0);
        console.log(`NOI: ${yearlyNOI.toLocaleString("en-US", { style: "currency", currency: "USD" })}`);

        let trancheDetails: TrancheScheduleEntry[] = [];
        let totalInterest = 0;
        let totalPrincipal = 0;
        let totalDebtService = 0;

        // Calculate payments for each tranche
        trancheBalances = trancheBalances.map((tranche) => {
          const payments = calculateTranchePayments(
            {
              name: tranche.name,
              principal: tranche.originalPrincipal, // Use original principal for payment calculation
              rate: tranche.rate * 100,
              amortization: tranche.amortization,
              balloon: tranche.balloon,
              fullStandby: tranche.fullStandby,
            },
            year,
            tranche.balance, // Pass current balance
          );

          totalInterest += payments.interestPayment;
          totalPrincipal += payments.principalPayment;
          totalDebtService += payments.totalPayment;

          trancheDetails.push({
            name: tranche.name,
            balance: payments.endingBalance,
            interestPayment: payments.interestPayment,
            principalPayment: payments.principalPayment,
            debtService: payments.totalPayment,
            originalPrincipal: tranche.originalPrincipal,
          });

          return {
            ...tranche,
            balance: payments.endingBalance,
          };
        });

        // Calculate total debt balance excluding Revolver
        const currentDebtBalance = trancheBalances.reduce((sum, t) => {
          if (t.name === "Revolver") return sum;
          return sum + t.balance;
        }, 0);

        const cashAvailable = yearlyNOI - totalDebtService;

        console.log(`\n📊 Year ${year} Summary:`);
        console.log(`Total Interest: ${totalInterest.toLocaleString("en-US", { style: "currency", currency: "USD" })}`);
        console.log(`Total Principal: ${totalPrincipal.toLocaleString("en-US", { style: "currency", currency: "USD" })}`);
        console.log(`Total Debt Service: ${totalDebtService.toLocaleString("en-US", { style: "currency", currency: "USD" })}`);
        console.log(`Cash Available: ${cashAvailable.toLocaleString("en-US", { style: "currency", currency: "USD" })}`);
        console.log(`Outstanding Debt Balance: ${currentDebtBalance.toLocaleString("en-US", { style: "currency", currency: "USD" })}`);

        console.log("\n🧾 End of Year Tranche Status:");
        trancheBalances.forEach((t) => {
          console.log(`${t.name}: ${t.balance.toLocaleString("en-US", { style: "currency", currency: "USD" })}`);
        });

        schedule.push({
          year,
          noi: yearlyNOI,
          debtService: totalDebtService,
          interestPayment: totalInterest,
          principalPayment: totalPrincipal,
          cashSweepPayment: 0, // We can add cash sweep logic if needed
          dividends: cashAvailable,
          debtBalance: currentDebtBalance,
          trancheDetails,
        });

        // If all non-revolver debt is paid off, we can stop
        if (currentDebtBalance <= 0) {
          console.log(`\n🎉 All non-revolver debt is fully paid off in Year ${year}!`);
          break;
        }
      }

      console.log("\n🏁 Final Schedule Generated:", {
        years: schedule.length,
        finalOutstandingDebt: schedule.length > 0 ? (schedule[schedule.length - 1]?.debtBalance ?? 0).toLocaleString("en-US", { style: "currency", currency: "USD" }) : "$0",
      });

      return schedule;
    },
    [fcfArray, ltmEbitda],
  );

  // Update schedule when tranches change
  useEffect(() => {
    // Calculate schedule
    const newSchedule = calculateTrancheSchedule(tranches);
    setSchedule(newSchedule);

    // Calculate total debt
    const totalDebt = tranches.reduce((sum, t) => sum + t.principal, 0);
    const debtOutstanding = totalDebt;

    // If parent component provided an updateSchedule callback, use it
    if (updateSchedule) {
      // Create payload data
      const payload = {
        schedule: newSchedule,
        totalDebt,
        sources: sourceDebts, // Pass array format directly
        uses, // Pass array format directly
        tranches,
        sbaData: {
          businessAcq: {
            amount: justificationRows[0]?.amount || 0,
            pctOfTotal: justificationRows[0]?.pctOfTotal || 0,
            term: justificationRows[0]?.term || 10,
          },
          realEstate: {
            amount: justificationRows[1]?.amount || 0,
            pctOfTotal: justificationRows[1]?.pctOfTotal || 0,
            term: justificationRows[1]?.term || 25,
          },
          legalcosts: {
            amount: justificationRows[2]?.amount || 0,
            pctOfTotal: justificationRows[2]?.pctOfTotal || 0,
            term: justificationRows[2]?.term || 10,
          },
          equipment: {
            amount: justificationRows[3]?.amount || 0,
            pctOfTotal: justificationRows[3]?.pctOfTotal || 0,
            term: justificationRows[3]?.term || 10,
          },
          transactionExpense: {
            amount: justificationRows[4]?.amount || 0,
            pctOfTotal: justificationRows[4]?.pctOfTotal || 0,
            term: justificationRows[4]?.term || 10,
          },
        },
      };

      // Compare stringified versions of previous and current data
      const dataString = JSON.stringify(payload);
      const previousDataString = lastUpdateRef.current;

      // Only call updateSchedule if data has changed
      if (dataString !== previousDataString) {
        updateSchedule(payload);
        lastUpdateRef.current = dataString;
      }
    }
  }, [tranches, calculateTrancheSchedule]);

  // Create a ref to store the last update payload
  const lastUpdateRef = React.useRef<string>("");

  // Keep existing code for SBA fee calculation
  useEffect(() => {
    // Find the principal for the "SBA 7(a) Loan"
    const sba7Loan = tranches.find((t) => t.name === "SBA 7(a) Loan");
    // Find the principal for the "Revolver"
    const revolveLoan = tranches.find((t) => t.name === "Revolver");

    const sbaVal = sba7Loan?.principal ?? 0;
    const revolveVal = revolveLoan?.principal ?? 0;

    // Call the function
    const fee = calculateSbaGtyFee(sbaVal, revolveVal);
    console.log("sbaVal", sbaVal);
    console.log("revolveVal", revolveVal);
    // const fee = calculateLoanFees(sbaVal, revolveVal);

    // Put that into the "sbaGtyFee" row of uses
    setUses((prev) => {
      return prev.map((u) => {
        if (u.key === "sbaGtyFee") {
          return {
            ...u,
            value: fee,
            inputValue: fee ? fee.toLocaleString() : "0",
          };
        }
        return u;
      });
    });
  }, [tranches]);

  const handleSaveScenario = async () => {
    const scenarioData = {
      ltmEbitda,
      debtAvailable,
      debtOutstanding,
      uses,
      sourceDebts, // This includes the Revolver
      newEquity,
      tranches,
      justificationRows,
      schedule, // Include the schedule in saved data
      revolverAmount, // Add Revolver amount separately
    };
    // e.g. send to an API or do whatever
    if (isBalanced) {
      await dispatch(updatetranches(transaction.id, { debtLines: { ...scenarioData } }));
      console.log("Saved scenario:", scenarioData);
      toast.success("Scenario saved! Check overview for details.");
    } else {
      toast.error("Sources and Uses are not balanced. Please adjust your sources and uses.");
    }
    // alert("Scenario saved! Check console for details.");
  };

  // Sync changes back to parent component
  useEffect(() => {
    if (updateSourcesAndUses) {
      // Convert uses array to object format expected by parent
      const usesObj = uses.reduce(
        (acc, use) => {
          acc[use.key] = use.value;
          return acc;
        },
        {} as Record<string, number>,
      );

      // Convert sourceDebts to object format
      const sourcesObj = sourceDebts.reduce(
        (acc, source) => {
          // Include all sources, including Revolver
          acc[source.name] = source.principal;
          return acc;
        },
        {} as Record<string, number>,
      );

      // Only update if values have changed
      const prevUsesStr = JSON.stringify(usesObj);
      const prevSourcesStr = JSON.stringify(sourcesObj);

      if (prevUsesStr !== lastUsesRef.current || prevSourcesStr !== lastSourcesRef.current) {
        // Pass both the object format and array format
        updateSourcesAndUses(sourcesObj, uses);
        lastUsesRef.current = prevUsesStr;
        lastSourcesRef.current = prevSourcesStr;
      }
    }
  }, [uses, sourceDebts, updateSourcesAndUses]);

  // Sync SBA data changes back to parent
  useEffect(() => {
    if (setSbaData && justificationRows.length >= 4) {
      const sbaDataObj = {
        businessAcq: {
          amount: justificationRows[0]?.amount || 0,
          pctOfTotal: justificationRows[0]?.pctOfTotal || 0,
          term: justificationRows[0]?.term || 10,
        },
        realEstate: {
          amount: justificationRows[1]?.amount || 0,
          pctOfTotal: justificationRows[1]?.pctOfTotal || 0,
          term: justificationRows[1]?.term || 25,
        },
        legalcosts: {
          amount: justificationRows[2]?.amount || 0,
          pctOfTotal: justificationRows[2]?.pctOfTotal || 0,
          term: justificationRows[2]?.term || 10,
        },

        equipment: {
          amount: justificationRows[3]?.amount || 0,
          pctOfTotal: justificationRows[3]?.pctOfTotal || 0,
          term: justificationRows[3]?.term || 10,
        },
        transactionExpense: {
          amount: justificationRows[4]?.amount || 0,
          pctOfTotal: justificationRows[4]?.pctOfTotal || 0,
          term: justificationRows[4]?.term || 10,
        },
      };

      // Only update if values have changed
      const sbaDataStr = JSON.stringify(sbaDataObj);
      if (sbaDataStr !== lastSbaDataRef.current) {
        setSbaData(sbaDataObj);
        lastSbaDataRef.current = sbaDataStr;
      }
    }
  }, [justificationRows, setSbaData]);

  // Sync tranche changes back to parent
  useEffect(() => {
    if (updateTrancheTerm && tranches) {
      const trancheTerms = tranches.map((tranche) => ({
        amortization: tranche.amortization,
        rate: tranche.rate,
        fullStandby: tranche.fullStandby,
        balloon: tranche.balloon,
      }));

      // Only update if values have changed
      const trancheTermsStr = JSON.stringify(trancheTerms);
      if (trancheTermsStr !== lastTrancheTermsRef.current) {
        tranches.forEach((tranche, index) => {
          updateTrancheTerm(index, trancheTerms[index]);
        });
        lastTrancheTermsRef.current = trancheTermsStr;
      }
    }
  }, [tranches, updateTrancheTerm]);

  // Add refs to store previous values
  const lastUsesRef = React.useRef<string>("");
  const lastSourcesRef = React.useRef<string>("");
  const lastSbaDataRef = React.useRef<string>("");
  const lastTrancheTermsRef = React.useRef<string>("");

  // Memoize handlers
  const handleJustificationTermChange = React.useCallback((index: number, newTerm: number) => {
    setJustificationRows((prev) => {
      const copy = [...prev];
      // @ts-ignore
      if (!copy[index]) return prev;

      // @ts-ignore
      const pctOfTotal = copy[index].pctOfTotal;
      // @ts-ignore
      copy[index] = {
        // @ts-ignore
        ...copy[index],
        // @ts-ignore
        term: newTerm,
        wtdAvg: newTerm * (pctOfTotal / 100),
      };
      return copy;
    });
  }, []);

  // Sync justification rows with uses
  useEffect(() => {
    if (!uses.length) return;

    setJustificationRows((prev) => {
      const newRows = prev.map((row) => {
        // Map justification row labels to use keys
        let useKey = "";
        if (row.label === "Business Acq") useKey = "businessAcquisition";
        if (row.label === "M&E Purchase") useKey = "equipmentAndMachinery";
        if (row.label === "Legal Costs") useKey = "legalcosts";
        if (row.label === "Real Estate") useKey = "realEstate";
        if (row.label === "Transaction Expense") useKey = "transactionExpense";
        if (row.label === "SBA Fees") useKey = "sbaGtyFee";
        if (row.label === "Working Capital") useKey = "workingCapital";

        // Find matching use amount
        const amount = uses.find((u) => u.key === useKey)?.value || 0;
        const pctOfTotal = totalUses > 0 ? (amount / totalUses) * 100 : 0;

        // Keep existing term, update other values
        return {
          ...row,
          amount,
          pctOfTotal,
          wtdAvg: row.term * (pctOfTotal / 100),
        };
      });

      // Only update if values have actually changed
      if (JSON.stringify(newRows) !== JSON.stringify(prev)) {
        return newRows;
      }
      return prev;
    });
  }, [uses, totalUses]);

  // Inside SBAFinancingPage component, add state for tracking focused inputs
  const [focusedInput, setFocusedInput] = useState<string | null>(null);
  const [lastFocusedInput, setLastFocusedInput] = useState<string | null>(null);

  const handleInputFocus = (inputId: string) => {
    setFocusedInput(inputId);
    setLastFocusedInput(inputId);
  };

  const handleInputBlur = (idx: number, handler: () => void) => {
    handler();
    setFocusedInput(null);
    // Don't clear lastFocusedInput here
  };

  // Add helper functions for handling tranche updates
  const handleTrancheAmortizationChange = (trancheName: string, amortization: number) => {
    setTranches((prev) => {
      return prev.map((t) => {
        if (t.name === trancheName) {
          return { ...t, amortization };
        }
        return t;
      });
    });
  };

  const handleTrancheTypeChange = (trancheName: string, balloon: "Yes" | "No") => {
    setTranches((prev) => {
      return prev.map((t) => {
        if (t.name === trancheName) {
          return { ...t, balloon };
        }
        return t;
      });
    });
  };

  // Make sure revolverAmount updates properly when sourceDebts changes
  useEffect(() => {
    // This effect ensures that any time sourceDebts changes, all components depending
    // on revolverAmount will re-render with the updated value
    // No action needed here as revolverAmount is calculated directly from sourceDebts
  }, [sourceDebts]);

  // Make sure the UI updates correctly when the Revolver is toggled
  useEffect(() => {
    // This effect ensures that the UI updates properly when Revolver-related state changes
    // No explicit action needed as the component will naturally re-render
    // The useEffect dependency ensures this runs whenever sourceDebts changes
  }, [sourceDebts]);

  // Ensure the "Other" tranche name is properly set when showing the dialog
  useEffect(() => {
    if (showDialog) {
      // When opening the dialog, check if we have a renamed "Other" tranche
      // and update the otherTrancheName field to match it
      let hasRenamedOther = false;

      for (const [renamed, original] of Object.entries(renamedOtherTranches)) {
        if (original === "Other") {
          // Verify this renamed tranche actually exists in our collections
          const hasTrancheWithName = tranches.some((t) => t.name === renamed);
          const hasSourceWithName = sourceDebts.some((sd) => sd.name === renamed);

          if (hasTrancheWithName || hasSourceWithName) {
            // Found a renamed Other tranche, update the input field
            setOtherTrancheName(renamed);
            hasRenamedOther = true;
            break;
          }
        }
      }

      // If no renamed Other was found but "Other" is checked,
      // make sure the field is empty for a fresh name
      if (!hasRenamedOther && checkedTranches["Other"]) {
        const hasOtherTranche = tranches.some((t) => t.name === "Other");
        const hasOtherSource = sourceDebts.some((sd) => sd.name === "Other");

        if ((hasOtherTranche || hasOtherSource) && otherTrancheName === "") {
          // We have a regular "Other" tranche with no custom name
          setOtherTrancheName("");
        }
      }
    }
  }, [showDialog, tranches, sourceDebts, renamedOtherTranches, checkedTranches]);

  // EquityTicketAlert component to warn when equity is below 10%
  interface EquityTicketAlertProps {
    equityPct: string | number;
    totalSources: number;
    newEquity: number;
    setNewEquity: (val: number) => void;
    setNewEquityInput: (val: string) => void;
  }

  const EquityTicketAlert: React.FC<EquityTicketAlertProps> = ({ equityPct, totalSources, newEquity, setNewEquity, setNewEquityInput }) => {
    const { t } = useTranslation();
    // Parse equity percentage to number, handling both string and number input
    const numericPct = typeof equityPct === "string" ? parseFloat(equityPct) : equityPct;
    const isEquityBelow10Pct = !isNaN(numericPct) && numericPct < 10;

    if (!isEquityBelow10Pct) {
      return null;
    }

    const handleAdjustEquity = () => {
      // The correct formula to calculate the equity needed for it to be 10% of the final total:
      // If E = equity, D = current debt, and we want E to be 10% of the total (D + E)
      // Then: E = 0.1 * (D + E)
      // Solving for E: E = D / 9

      const currentDebt = totalSources - newEquity; // Current debt (excluding equity)
      const targetEquity = Math.ceil(currentDebt / 9); // This will make equity exactly 10% of the new total

      // Update equity state
      setNewEquity(targetEquity);
      setNewEquityInput(targetEquity.toLocaleString());
    };

    return (
      <Alert
        variant="destructive"
        className="mb-4"
        actions={
          <>
            <AlertAction onClick={handleAdjustEquity}>Adjust to 10% Equity</AlertAction>
          </>
        }
      >
        <AlertCircle className="h-4 w-4" />
        <div className="flex flex-col justify-center -mb-1">
          <AlertTitle>Low Equity Ticket</AlertTitle>
          <AlertDescription className="inline">Equity contribution is only {numericPct.toFixed(1)}% of total sources. SBA guidelines typically require at least 10% equity contribution for 7(a) loans.</AlertDescription>
        </div>
      </Alert>
    );
  };
  console.log(uses);
  console.log(justificationRows);
  return (
    <>
      {/* 1) Debt KPIs + "Add More Tranches" Dialog */}
      <Card className="max-w-full">
        <CardHeader>
          <CardTitle>
            {
              // @ts-ignore
              t("debt_calculator.title")
            }
          </CardTitle>
          <CardDescription>
            <div className="flex">
              <div className="w-[70%]">
                {
                  // @ts-ignore
                  t("debt_calculator.description")
                }
              </div>
              <div className="ml-auto flex gap-3">
                <Button onClick={handleSaveScenario}>
                  {
                    // @ts-ignore
                    t("debt_calculator.save_scenario")
                  }
                </Button>
              </div>
            </div>
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-3 border-none shadow-none ">
          <Card className="shadow-none !border-none  p-0">
            <CardContent className="flex flex-wrap items-start gap-4 p-0 border-none shadow-none ">
              <div className="flex items-center gap-4 w-full justify-between">
                <StatItem title="LTM EBITDA" tooltipContent="Last Twelve Months EBITDA" value={Number(ltmEbitda.toFixed(0))?.toLocaleString()} suffix="$" />
                <StatItem title="Debt Available" tooltipContent="Total Debt Available" value={debtAvailable?.toLocaleString()} suffix="$" />
                <StatItem title="Debt Outstanding" tooltipContent="Total Debt Outstanding (excl. Revolver)" value={debtOutstanding?.toLocaleString()} suffix="$" />
                <StatItem title="Yearly Payment" tooltipContent="Total yearly debt service across all tranches" value={Number((schedule[0]?.debtService || 0).toFixed(0)).toLocaleString()} suffix="$" />
                {/* The Check Cell is replaced by a green check or red cross */}
                <div className="flex flex-col text-left">
                  <div className="flex items-center space-x-2">
                    <CardDescription className="text-sm font-medium">Balanced?</CardDescription>
                  </div>
                  <div className="text-base sm:text-lg 2lg:text-xl font-bold">{isBalanced ? <Check className="text-green-600" /> : <X className="text-red-600" />}</div>
                </div>
                {/* Add More Tranches Dialog */} {/* Add/Remove Tranches Dialog */}
                {/* Add/Remove Tranches Button */}
                <Dialog open={showDialog} onOpenChange={setShowDialog}>
                  {" "}
                  <DialogTrigger asChild>
                    <Button variant="outline">Add or Remove Tranches</Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Select Additional Debt Tranches</DialogTitle>
                      <DialogDescription>Tick = Add, Untick = Remove</DialogDescription>
                    </DialogHeader>
                    <div className="grid grid-cols-2 gap-2 py-2">
                      {optionalTranches.map((ot) => {
                        // Check if this tranche exists in either the tranches or sourceDebts arrays
                        const existsInTranches = tranches.some((t) => t.name === ot.name);
                        const existsInSourceDebts = sourceDebts.some((sd) => sd.name === ot.name);

                        // Enhanced logic for "Other" tranches
                        let checked = existsInTranches || existsInSourceDebts;
                        let renamedOther = "";

                        // For "Other", we need to also check for renamed versions
                        if (ot.name === "Other") {
                          // Check if any renamed "Other" exists in the collections
                          for (const [renamed, original] of Object.entries(renamedOtherTranches)) {
                            if (original === "Other") {
                              const hasTrancheWithName = tranches.some((t) => t.name === renamed);
                              const hasSourceWithName = sourceDebts.some((sd) => sd.name === renamed);
                              if (hasTrancheWithName || hasSourceWithName) {
                                checked = true;
                                renamedOther = renamed;
                                // Update the other tranche name for UI
                                if (otherTrancheName !== renamed) {
                                  setOtherTrancheName(renamed);
                                }
                                break;
                              }
                            }
                          }
                        }

                        return (
                          <div key={ot.name} className="flex items-center gap-2">
                            <Checkbox
                              checked={checked}
                              onCheckedChange={(val) => {
                                // Update the checked state in UI
                                setCheckedTranches((prev) => ({
                                  ...prev,
                                  [ot.name]: Boolean(val),
                                }));
                                // Actually add/remove the tranche
                                handleToggleTranche(ot, Boolean(val));

                                // Force sync the tranches data after toggle
                                if (ot.name === "Revolver") {
                                  setTimeout(() => {
                                    syncTranchesWithSourceDebts();
                                  }, 0);
                                }

                                // Reset otherTrancheName if unchecking "Other"
                                if (ot.name === "Other" && !val) {
                                  setOtherTrancheName("");
                                }
                              }}
                              id={ot.name}
                            />
                            <Label htmlFor={ot.name}>{ot.name}</Label>
                            {/* If "Other" is toggled, let user rename */}
                            {ot.name === "Other" && checked && (
                              <Input
                                type="text"
                                placeholder="Specify other"
                                value={otherTrancheName}
                                onChange={(e) => {
                                  handleOtherNameChange(e.target.value);
                                }}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <DialogFooter>
                      <Button variant="outline" onClick={() => setShowDialog(false)}>
                        Close
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </div>
            </CardContent>
          </Card>

          {/* Equity Ticket Alert */}
          {totalSources > 0 && <EquityTicketAlert equityPct={totalSources > 0 ? (newEquity / totalSources) * 100 : 0} totalSources={totalSources} newEquity={newEquity} setNewEquity={setNewEquity} setNewEquityInput={setNewEquityInput} />}

          {/* 2) Sources & Uses, side by side + same heights */}
          <Card className="p-0 shadow-md">
            <CardHeader className="pb-4">
              <CardTitle>Sources & Uses</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-2 gap-4 items-stretch">
                {/* LEFT: Sources */}
                <Card className="!border-none shadow-none flex flex-col h-full">
                  <Label className="font-semibold mb-2 block">Sources</Label>
                  <div className="space-y-2 flex-1">
                    {/* For each debt row in "sourceDebts" except Revolver */}
                    {sourceDebts
                      .filter((sd) => sd.name !== "Revolver")
                      .map((sd, idx) => {
                        // Find the actual index in the original sourceDebts array by name
                        const sourceIndex = sourceDebts.findIndex((s) => s.name === sd.name);
                        return (
                          <RowItem
                            key={sd.name}
                            rowIndex={idx}
                            label={sd.name}
                            isHighlighted={idx % 2 === 0}
                            tooltipContent="Debt principal"
                            showSolve={true}
                            isFocused={focusedInput === `source-${sd.name}` || (!focusedInput && lastFocusedInput === `source-${sd.name}`)}
                            onSolve={() => {
                              const otherSourcesSum = sourceDebts.filter((s) => s.name !== "Revolver" && s.name !== sd.name).reduce((sum, s) => sum + s.principal, 0) + newEquity;
                              const neededSourceValue = totalUses - otherSourcesSum;

                              const newSourceDebts = [...sourceDebts];
                              const sourceIndex = newSourceDebts.findIndex((s) => s.name === sd.name);
                              newSourceDebts[sourceIndex] = {
                                ...sd,
                                principal: neededSourceValue,
                                principalInput: neededSourceValue.toLocaleString(),
                              };
                              setSourceDebts(newSourceDebts);
                              syncTranchesWithSourceDebts();
                            }}
                            value={
                              <Input
                                className="w-[100px] text-right mr-[-8px]"
                                type="text"
                                inputMode="decimal"
                                pattern="[0-9]*"
                                placeholder="0"
                                value={sd.principalInput}
                                onChange={(e) => handleSourceDebtChange(sourceIndex, e.target.value)}
                                onBlur={() => handleInputBlur(idx, () => handleSourceDebtBlur(sourceIndex))}
                                onFocus={() => handleInputFocus(`source-${sd.name}`)}
                              />
                            }
                          />
                        );
                      })}

                    {/* Summation: Total Debt row (excluding Revolver) */}
                    <RowItem label="Total Debt" tooltipContent="Sum of all debt principals (excluding Revolver)" value={`$${totalDebt.toLocaleString()}`} rowIndex={sourceDebts.length} />

                    {/* New Equity (no slider) => show percent in parentheses */}
                    <RowItem
                      label={`New Equity (${equityPct}%)`}
                      tooltipContent="Equity injection as a % of total sources"
                      rowIndex={sourceDebts.length + 1}
                      showSolve={true}
                      isFocused={focusedInput === "equity" || (!focusedInput && lastFocusedInput === "equity")}
                      onSolve={() => {
                        const totalDebtSum = sourceDebts.filter((sd) => sd.name !== "Revolver").reduce((sum, s) => sum + s.principal, 0);
                        const neededEquityValue = totalUses - totalDebtSum;
                        setNewEquity(neededEquityValue);
                        setNewEquityInput(neededEquityValue.toLocaleString());
                        setEquitySlider([Math.min(100, Math.max(0, (neededEquityValue / totalUses) * 100))]);
                      }}
                      value={
                        <div className="flex items-center gap-2">
                          <Input
                            className="w-[100px] mr-[-8px] text-right"
                            type="text"
                            inputMode="decimal"
                            pattern="[0-9]*"
                            placeholder="0"
                            value={newEquityInput}
                            onChange={(e) => handleNewEquityChange(e.target.value)}
                            onBlur={() => handleInputBlur(-1, handleNewEquityBlur)}
                            onFocus={() => handleInputFocus("equity")}
                          />
                        </div>
                      }
                    />

                    {/* Total Sources */}
                    <RowItem label="Total Sources" tooltipContent="Total Debt (excl. Revolver) + Equity" value={`$${totalSources.toLocaleString()}`} isHighlighted rowIndex={sourceDebts.length + 2} />

                    {/* Display Revolver separately */}
                    {/* We now check both the revolverAmount and if Revolver exists in sourceDebts, for more reliable detection */}
                    {(revolverAmount > 0 || sourceDebts.some((sd) => sd.name === "Revolver")) && (
                      <div className="pt-4 border-t mt-4">
                        <RowItem
                          label="Revolver (Undrawn)"
                          tooltipContent="Revolver credit line (not included in total sources)"
                          value={
                            <div className="flex items-center gap-2">
                              <Input
                                className="w-[100px] mr-[-8px] text-right"
                                type="text"
                                inputMode="decimal"
                                pattern="[0-9]*"
                                placeholder="0"
                                value={sourceDebts.find((sd) => sd.name === "Revolver")?.principalInput || ""}
                                onChange={(e) => {
                                  const revolverIndex = sourceDebts.findIndex((sd) => sd.name === "Revolver");
                                  if (revolverIndex >= 0) {
                                    handleSourceDebtChange(revolverIndex, e.target.value);
                                  }
                                }}
                                onBlur={() => {
                                  const revolverIndex = sourceDebts.findIndex((sd) => sd.name === "Revolver");
                                  if (revolverIndex >= 0) {
                                    handleSourceDebtBlur(revolverIndex);
                                  }
                                }}
                                onFocus={() => handleInputFocus("source-Revolver")}
                              />
                            </div>
                          }
                        />
                      </div>
                    )}
                  </div>
                </Card>

                {/* RIGHT: Uses */}
                <Card className="!border-none shadow-none flex flex-col h-full">
                  <Label className="font-semibold mb-2 block">Uses</Label>
                  <div className="space-y-2 flex-1">
                    {uses.map((u, idx) => {
                      if (u.readOnly) {
                        return <RowItem key={u.key} isHighlighted={idx % 2 === 0} rowIndex={idx} label={u.label} tooltipContent="This item is calculated automatically" value={`$${u.value.toLocaleString()}`} />;
                      } else {
                        return (
                          <RowItem
                            key={u.key}
                            isHighlighted={idx % 2 === 0}
                            rowIndex={idx}
                            label={u.label}
                            tooltipContent="Enter your expected cost"
                            showSolve={true}
                            isFocused={focusedInput === `use-${u.key}` || (!focusedInput && lastFocusedInput === `use-${u.key}`)}
                            onSolve={() => {
                              const otherUsesSum = uses.reduce((sum, use) => (use.key !== u.key ? sum + use.value : sum), 0);
                              const neededUseValue = totalSources - otherUsesSum;

                              const newUses = [...uses];
                              newUses[idx] = {
                                ...u,
                                value: neededUseValue,
                                inputValue: neededUseValue.toLocaleString(),
                              };
                              setUses(newUses);
                            }}
                            value={
                              <Input
                                className="w-[100px] text-right mr-[-8px]"
                                type="text"
                                inputMode="decimal"
                                pattern="[0-9]*"
                                placeholder="0"
                                value={u.inputValue}
                                onChange={(e) => handleUsesChange(idx, e.target.value)}
                                onBlur={() => handleInputBlur(idx, () => handleUsesBlur(idx))}
                                onFocus={() => handleInputFocus(`use-${u.key}`)}
                              />
                            }
                          />
                        );
                      }
                    })}

                    {/* Total Uses */}
                    <RowItem label="Total Uses" value={`$${totalUses.toLocaleString()}`} isHighlighted rowIndex={999} />
                  </div>
                </Card>
              </div>
            </CardContent>
          </Card>
        </CardContent>
      </Card>

      {/* 3) SBA Loan Term Justification */}
      <SbaLoanTermJustification rows={justificationRows} totalAmount={totalJustificationAmt} onChangeTerm={handleJustificationTermChange} />

      {/* Loan Term Alerts - Add this section above Lender Loan Terms */}
      <LoanTermAlerts uses={uses} totalUses={totalUses} justificationRows={justificationRows} tranches={tranches} updateJustificationTerm={handleJustificationTermChange} updateTrancheAmortization={handleTrancheAmortizationChange} updateTrancheType={handleTrancheTypeChange} />

      {/* 4) Additional Lender Loan Terms */}
      <Card className="">
        <CardHeader className="pb-2">
          <CardTitle>Lender Loan Terms</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {tranches.map((tranche, idx) => (
              <DebtTrancheRow key={tranche.name + idx} tranche={tranche} onChange={(updated) => handleTrancheChange(idx, updated)} />
            ))}
          </div>
        </CardContent>
      </Card>
    </>
  );
};
