import React, { useEffect, useState } from "react";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../../components/ui/card";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { store } from "../../../redux/store";
import { fetchCashflowData } from "../../../redux/actions/cashflowAction";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../components/ui/tabs";
import DCF from "./dcf";

import FinancialTable from "./financialtable";
import PopulationChart from "./overview";
import ReusableFinancialTable from "./reactgrid";
import { FinancialRow } from "./reactgrid";
import { outerStep, buildWaterfallRowsFromJson, revertWaterfallToJson } from "./outersteps";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { MacroCard } from "./SetMacro";
import DebtServiceComponent from "./DebtServiceComponent";
import BreakEvenAnalysis from "./BreakEvenAnalysis";
import { Loader2 } from "lucide-react";

interface Task {
  year: number;
  [key: string]: any; // if other properties exist
}

interface TableRowData {
  label: string;
  values: (string | number | any)[];
  isHighlighted?: boolean;
  rowType?: "default" | "mini-total" | "large-total" | string; // Add string type to allow flexibility
  isNegative?: boolean;
}

const processInvestmentCashFlow = (
  data: TableRowData[] | undefined,
  isCumulative: boolean = true,
): {
  year: string;
  cashOutflow: number;
  cashInflow: number;
  cashTotal: number;
}[] => {
  if (!data) return [];

  const cashFlowRow = data.find((row) => row.label === "Investment Cash Flow");
  if (!cashFlowRow) {
    throw new Error("Investment Cash Flow row not found!");
  }

  let cumulativeNegative = 0;
  let cumulativePositive = 0;
  let cumulativeTotal = 0;

  return cashFlowRow.values.map((value, index) => {
    const numericValue = typeof value === "string" ? Number(value.replace(/,/g, "")) || 0 : value || 0;

    if (isCumulative) {
      // Existing logic for cumulative cash flows
      if (numericValue < 0) {
        cumulativeNegative += numericValue;
      } else {
        cumulativePositive += numericValue;
      }
      cumulativeTotal += numericValue;

      return {
        year: `Year ${index}`,
        cashOutflow: cumulativeNegative,
        cashInflow: cumulativePositive,
        cashTotal: cumulativeTotal,
      };
    } else {
      // NEW logic: non-cumulative (raw yearly in/out)
      const cashOutflow = numericValue < 0 ? numericValue : 0;
      const cashInflow = numericValue > 0 ? numericValue : 0;
      const cashTotal = numericValue;

      return {
        year: `Year ${index}`,
        cashOutflow,
        cashInflow,
        cashTotal,
      };
    }
  });
};

const chartConfig = {
  cashOutflow: {
    label: "Cash Outflow",
    color: "#ef4444", // Blue
  },
  cashInflow: {
    label: "Cash Inflow",
    color: "#93c5fd", // Light Blue
  },
  cashTotal: {
    label: "Total Cash",
    color: "#1e40af", // Dark Blue
    isTotal: true,
  },
};
export const CashCard = ({ investmentCashFlowData, isCumulative = true, title = null }) => {
  const { t } = useTranslation();
  return (
    <Card className="col-span-2 w-full shadow-lg mt-2">
      <CardHeader>
        <CardTitle>
          {title
            ? title
            : // @ts-ignore
              t("overview.yearly_cash_flow")}
        </CardTitle>
      </CardHeader>
      <CardContent className={`p-6 grid grid-cols-${"1"} gap-4 col-span-2`}>
        <PopulationChart data={processInvestmentCashFlow(investmentCashFlowData, isCumulative)} chartConfig={chartConfig} title="" />
        {/* {combinedCharts.map((chart) => (
      <PopulationChart key={chart.id} title={`${chart.title}`} data={chart.data} chartConfig={chart.chartConfig} />
    ))} */}
      </CardContent>
    </Card>
  );
};

interface CashFlowRow {
  label: string;
  values: (string | number)[];
  isNegative?: boolean;
  rowType?: string; // e.g., "large-total"
}

interface ExtendedCashFlows {
  newInitialCashFlowData: CashFlowRow[];
  newInvestmentCashFlowData: CashFlowRow[];
}

export function extendCashFlowsWithTerminal(inputData: any, initialCashFlowData: CashFlowRow[], investmentCashFlowData: CashFlowRow[]): ExtendedCashFlows {
  if (!inputData || !Array.isArray(inputData) || inputData.length === 0 || !inputData[0].year) {
    return {
      newInitialCashFlowData: [],
      newInvestmentCashFlowData: [],
    };
  }

  if (!Array.isArray(investmentCashFlowData) || investmentCashFlowData.length === 0) {
    return {
      newInitialCashFlowData: [],
      newInvestmentCashFlowData: [],
    };
  }

  // Filter inputData to only include actual periods
  const actualInputData = inputData.filter((item: any) => item.isForecasted === false);

  if (actualInputData.length === 0) {
    console.warn("No actual (non-forecasted) data available");
    return {
      newInitialCashFlowData: [],
      newInvestmentCashFlowData: [],
    };
  }

  // Locate required items with safeguards
  const acquisitionRow = investmentCashFlowData.find((e) => e.label === "Acquisition Costs");
  if (!acquisitionRow || !Array.isArray(acquisitionRow.values) || acquisitionRow.values.length === 0) {
    console.warn("Acquisition Costs row missing or malformed");
    console.log(investmentCashFlowData);
    console.log(initialCashFlowData);
    return {
      newInitialCashFlowData: [],
      newInvestmentCashFlowData: [],
    };
  }
  // @ts-ignore
  const acquisitionCost = Math.abs(parseFloat(investmentCashFlowData.find((e) => e.label === "Acquisition Costs").values[0].replace(/,/g, "")));

  // Get actual years only
  const actualYears = actualInputData.map((e) => e.year);
  console.log("Actual years:", actualYears);

  // Get the last year of actual data
  const lastActualYear = Math.max(...actualYears);

  // Get EBITDA, debt, and FCFE for the last actual year
  const lastYearData = actualInputData.find((e) => e.year === lastActualYear);

  if (!lastYearData || !lastYearData["Adjusted EBITDA"]) {
    console.warn("Missing critical data for last actual year");
    return {
      newInitialCashFlowData: [],
      newInvestmentCashFlowData: [],
    };
  }

  const ebitdaLastActual = lastYearData["Adjusted EBITDA"].value;
  const debtLastActual = lastYearData["Debt Ending Balance"]?.value || 0;
  const fcfeLastActual = lastYearData["Free Cash Flow to Equity"]?.value || 0;

  // Compute the Entry EBITDA Multiple
  let divider;
  if (ebitdaLastActual === 0) {
    console.warn("Last actual year EBITDA is 0");
    divider = fcfeLastActual || 1; // Fallback to fcfe or 1 to avoid division by zero
  } else {
    divider = ebitdaLastActual;
  }
  const entryEbitdaMultiple = acquisitionCost / divider;
  console.log("Entry EBITDA Multiple:", entryEbitdaMultiple);
  console.log("Last actual debt:", debtLastActual);

  // Compute the Terminal Value based on last actual year's EBITDA
  const terminalValue = ebitdaLastActual * entryEbitdaMultiple;
  console.log("Terminal Value:", terminalValue);

  // Create copies of the original arrays
  const extendedInvestment = JSON.parse(JSON.stringify(investmentCashFlowData)) as CashFlowRow[];
  const extendedInitial = JSON.parse(JSON.stringify(initialCashFlowData)) as CashFlowRow[];

  // Extend each row's values by 1 more column for terminal value
  extendedInvestment.forEach((row) => {
    if (row.label === "Loan Balance") row.values = [...row.values, -debtLastActual];
    else {
      row.values = [...row.values, ""];
    }
  });
  extendedInitial.forEach((row) => {
    row.values = [...row.values, ""];
  });

  // Handle FCFE row for terminal period
  const fcfeRow = extendedInvestment.find((r) => r.label === "Annual Project Cash Flow");
  if (fcfeRow) {
    fcfeRow.values[fcfeRow.values.length - 1] = 0;
  }

  // Insert a dedicated row for Terminal Value
  const closingCostIndex = extendedInvestment.findIndex((r) => r.label === "Closing Costs");

  const newTerminalValueRow: CashFlowRow = {
    label: "Terminal Value (Based on Last Actual Year)",
    // @ts-ignore
    values: extendedInvestment[0].values.map(() => ""),
  };
  // Place the terminalValue in the final column
  newTerminalValueRow.values[newTerminalValueRow.values.length - 1] = terminalValue;

  // Insert the Terminal Value row
  if (closingCostIndex !== -1) {
    extendedInvestment.splice(closingCostIndex + 1, 0, newTerminalValueRow);
  } else {
    console.warn("Closing Costs row not found, adding Terminal Value at the end.");
    extendedInvestment.push(newTerminalValueRow);
  }

  // Update the Investment Cash Flow row with terminal value
  const investmentCfRow = extendedInvestment.find((r) => r.label === "Investment Cash Flow");
  if (investmentCfRow) {
    const lastIndex = investmentCfRow.values.length - 1;
    const existingFinalValue = Number(investmentCfRow.values[lastIndex]) || 0;
    investmentCfRow.values[lastIndex] = existingFinalValue + terminalValue - debtLastActual;
  }

  // Update initialCashFlowData if relevant
  const initialCfRow = extendedInitial.find((r) => r.label === "Investment Cash Flow");
  if (initialCfRow) {
    const lastIndex = initialCfRow.values.length - 1;
    const existingFinalValue = Number(initialCfRow.values[lastIndex]) || 0;
    initialCfRow.values[lastIndex] = existingFinalValue + terminalValue;
  }

  return {
    newInitialCashFlowData: extendedInitial,
    newInvestmentCashFlowData: extendedInvestment,
  };
}

export default function Cashflow() {
  const { t } = useTranslation();

  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const state = store.getState();
  const transaction = useSelector((state: any) => state.transactions.transaction);
  const cashflow = useSelector((state: any) => state.cashflow.tableData.cashFlow);
  const debt = useSelector((state: any) => state.cashflow.debt);
  const savedsu = useSelector((state: any) => state.cashflow.tranches);
  console.log(savedsu);
  console.log(debt);
  console.log(cashflow);
  const initialCashFlowData = useSelector((state: any) => state.cashflow.initialCashFlowData);
  const investmentCashFlowData = useSelector((state: any) => state.cashflow.investmentCashFlowData);

  const [loading, setLoading] = useState(true); // Manage loading state
  const [error, setError] = useState<string | null>(null); // Manage error state
  const [tasks, setTasks] = useState<any[]>([]); // Manage tasks state
  const [growth, setGrowth] = useState(null); // Manage tasks state
  const [inflation, setInflation] = useState(null); // Manage tasks state
  const year = "2024";

  // Filter cashflow to only include actual (non-forecasted) periods
  const actualCashflow = cashflow ? cashflow.filter((item: any) => item.isForecasted === false) : [];

  // Build final rows in the desired waterfall sequence but only with actual periods
  const years =
    actualCashflow && actualCashflow.length > 0
      ? actualCashflow.map((y: any) => {
          return { year: String(y.year ?? ""), isForecasted: "Actual" };
        })
      : [];

  const finalRows = React.useMemo(() => {
    console.log("final rows re-render");
    if (!actualCashflow) return [];
    if (actualCashflow.length === 0) return [];
    console.log("final rows re-render_2");
    // we call our new build function once, passing the entire array plus outerSteps, but only with actual data
    return buildWaterfallRowsFromJson(actualCashflow, outerStep);
  }, [actualCashflow, debt]);

  const fetchCashflow = async () => {
    try {
      setLoading(true); // Set loading to true when fetching starts
      // @ts-ignore
      const { cashFlow, growthRate, inflationRate } = await dispatch(fetchCashflowData(transaction.id, debt));
      console.log(cashFlow);
      // Filter cashFlow to include only non-forecasted data
      const actualCashFlow = cashFlow ? cashFlow.filter((item: any) => item.isForecasted === false) : [];
      // Set tasks state to only include actual data
      setTasks(actualCashFlow);
      setGrowth(growthRate);
      setInflation(inflationRate);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  // Function to handle EBITDA adjustments saved event
  const handleAdjustmentsSaved = () => {
    console.log("EBITDA adjustments saved, refreshing data...");
    fetchCashflow();
  };

  useEffect(() => {
    fetchCashflow();
  }, [dispatch, transaction, debt]);

  const dataModel = { model: finalRows };

  const handleDataChange = async (data) => {
    // console.log("Final Data Submitted:", data);
  };

  const pricing = useSelector((state: any) => state.cashflow.purchasePrice) || {};
  console.log(pricing);

  // Filter initialCashFlowData and investmentCashFlowData to match the actual periods
  const filteredInitialCashFlowData =
    initialCashFlowData && initialCashFlowData.length > 0 && years.length > 0
      ? initialCashFlowData.map((row) => ({
          ...row,
          values: row.values.slice(0, years.length),
        }))
      : initialCashFlowData;

  const filteredInvestmentCashFlowData =
    investmentCashFlowData && investmentCashFlowData.length > 0 && years.length > 0
      ? investmentCashFlowData.map((row) => ({
          ...row,
          values: row.values.slice(0, years.length),
        }))
      : investmentCashFlowData;

  // Use only actual cashflow data for extending with terminal value
  const { newInitialCashFlowData, newInvestmentCashFlowData } = extendCashFlowsWithTerminal(actualCashflow, filteredInitialCashFlowData, filteredInvestmentCashFlowData);

  console.log(filteredInitialCashFlowData, filteredInvestmentCashFlowData);

  return (
    <>
      <div className="hidden h-full flex-1 flex-col space-y-8 p-2 md:flex ">
        <div className="flex flex-col items-center justify-between space-y-2 gap-2">
          {/* <AlertDestructive /> */}
          <div className="flex flex-col justify-start mr-auto">
            <h2 className="text-2xl font-bold tracking-tight">
              {
                // @ts-ignore
                t("cashflowPro.title")
              }
            </h2>
            <p className="text-muted-foreground">
              {
                // @ts-ignore
                t("cashflowPro.subtitle")
              }{" "}
              {/* <span className="font-semibold">(Actual Periods Only)</span> */}
            </p>
          </div>

          {error && (
            <div className="w-full p-4 bg-red-50 rounded-md mb-2">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          )}

          {loading ? (
            <Card className="w-full flex items-center justify-center p-4 rounded-md mb-2">
              <Loader2 className="h-4 w-4 animate-spin text-primary mr-2" />
              {/* <p className="text-sm text-blue-700">Refreshing financial data...</p> */}
            </Card>
          ) : (
            <FinancialTable
              investmentCashFlowData={newInvestmentCashFlowData}
              dataModel={dataModel}
              title={
                // @ts-ignore
                t("cashflow.financialTableTitle") + " (Actual Periods)"
              }
              years={years}
              onSubmit={handleDataChange}
              transaction={transaction}
              pricing={pricing}
              onAdjustmentsSaved={handleAdjustmentsSaved}
            />
          )}

          {/* Add the Debt Service Component with actual periods only */}
          <DebtServiceComponent years={years.length > 0 ? years.length : 6} actualPeriodsOnly={true} />

          {/* Add the Break Even Analysis Component with actual periods only */}
          <BreakEvenAnalysis actualPeriodsOnly={true} />
        </div>
      </div>
      {/* <DrawerUploaderDemo row={row} setRow={setRow} /> */}
    </>
  );
}
