import React, { useEffect, useRef, useState } from "react";

import Spinner from "../../../components/ui/Spinner";
import AlertDestructive from "./alert";
import DrawerUploaderDemo from "../../../components/components/dialog-uploader";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DisplayMultiplePdfs from "../../../components/pdf-viewer/DisplayMultiplePdfs";
import { PdfFocusProvider } from "../../../components/pdf-viewer/pdfContext";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "../../../components/ui/resizable";
import { Box, Grid } from "@mui/material";
import PageSelector from "./PageSelector";
import { store } from "../../../redux/store";
import { Button } from "../../../components/ui/button";
import { addDocuments, clearDocuments } from "../../../redux/actions/documentsAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { backendClient } from "../../../api/backend";
import { useTranslation } from "react-i18next";
import { Alert, AlertTitle, AlertDescription, AlertAction } from "../../../components/ui/alert";
import { AlertCircle } from "lucide-react";

// Define the schema for an array of tasks
export default function Select() {
  const [totalPages, settotalPages] = useState(0);
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();

  const { transactionId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const row = location.state?.row; //
  const file = location.state?.file; //
  const upload = location.state?.upload; //
  const state = store.getState();
  const transaction = useSelector((state: any) => state.transactions.transaction);
  const documents = useSelector((state: any) => state.documents.documents);
  const docs = useSelector((state: any) => state.documents);
  const [rowSelection, setRowSelection] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 200, height: 120 });
  const [clicked, setClicked] = useState(false);
  const [showTaxAlert, setShowTaxAlert] = useState(true);
  console.log(transactionId);
  console.log(state);
  const handleResize = () => {
    // sizes is an array of panel widths in percentage (e.g., [50, 50])
    console.log("event fired");
    if (containerRef.current) {
      const { clientWidth, clientHeight } = containerRef.current;
      console.log(clientWidth, clientHeight);
      const newWidth = clientWidth < 550 ? 200 : 200; // Use second panel's size
      const newHeight = 120;
      setDimensions({
        width: newWidth,
        height: newHeight,
      });
    }
  };
  console.log(row);
  const handleNextStep = async () => {
    setLoading(true);
    const data = Object.keys(rowSelection).map((e, i) => {
      return Number(e) + 1;
    });
    console.log(documents[0]);
    // document: row.title,
    // console.log(row);
    if (row.type !== "business-checklist") {
      const obj = {
        years: row.year,
        type: row.type,
        pages: data,
      };
      console.log(file[0]);
      // const file = documents[0].documents[0];
      await dispatch(addDocuments(transaction.id, file[0], obj));
      navigate("/upload");
    } else {
      const bus_obj = {
        pages: data,
      };
      await backendClient.addbuschecklist(transaction.id, file[0], bus_obj);
      navigate("/checklist");
    }
    setLoading(false);
  };

  // Handle tax return page selection based on document type
  const handleTaxReturnSelection = () => {
    if (row.type === "income-statement") {
      // For income statements, select first and last page
      if (totalPages > 1) {
        setRowSelection({
          0: true,
          [totalPages - 1]: true,
        });
      } else if (totalPages === 1) {
        setRowSelection({ 0: true });
      }
    } else if (row.type === "balance-sheet") {
      // For balance sheets, select second to last page
      if (totalPages > 1) {
        setRowSelection({
          [totalPages - 2]: true,
        });
      } else if (totalPages === 1) {
        setRowSelection({ 0: true });
      }
    }
    setShowTaxAlert(false);
  };

  const USTaxReturnAlert = () => {
    const { t } = useTranslation();
    console.log(row);
    // Only show for US Tax returns
    const isTaxReturn = true;

    if (!isTaxReturn || !showTaxAlert || totalPages === 0) {
      return null;
    }

    return (
      <Alert
        variant="default"
        className="mb-4"
        actions={
          <>
            {/* @ts-ignore */}
            <AlertAction onClick={handleTaxReturnSelection}>{t("selector.select_tax_pages", "Select Relevant Pages")}</AlertAction>
            {/* @ts-ignore */}
            <AlertAction onClick={() => setShowTaxAlert(false)}>{t("selector.dismiss", "Dismiss")}</AlertAction>
          </>
        }
      >
        <AlertCircle className="h-4 w-4" />
        <div className="flex flex-col justify-center -mb-1">
          {/* @ts-ignore */}
          <AlertTitle>{t("selector.us_tax_return", "Is this a US Tax Return?")}</AlertTitle>
          <AlertDescription className="inline">
            {/* @ts-ignore */}
            {row.type === "income-statement" ? t("selector.tax_income_desc", "For US Tax Returns income statements, we typically need the first and last pages.") : t("selector.tax_balance_desc", "For US Tax Returns balance sheets, we typically need the second-to-last page.")}
          </AlertDescription>
        </div>
      </Alert>
    );
  };

  const NoRowsAlert = () => {
    const { t } = useTranslation();

    // Only show when there are no pages extracted
    if (totalPages > 0) {
      return null;
    }

    return (
      <Alert
        variant="destructive"
        className="mb-4"
        actions={
          <>
            {/* @ts-ignore */}
            <AlertAction onClick={() => navigate("/upload")}>{t("selector.try_again", "Try Again")}</AlertAction>
          </>
        }
      >
        <AlertCircle className="h-4 w-4" />
        <div className="flex flex-col justify-center -mb-1">
          {/* @ts-ignore */}
          <AlertTitle>{t("selector.extraction_error", "Extraction Error")}</AlertTitle>
          <AlertDescription className="inline">
            {/* @ts-ignore */}
            {t("selector.no_pages_error", "Error extracting pages from document. Please try again.")}
          </AlertDescription>
        </div>
      </Alert>
    );
  };

  const { t } = useTranslation();
  // console.log(file);
  useEffect(() => {
    if (!documents[0].documents[0].url) {
      navigate("/upload");
    }
  }, []); // Dependency array ensures effect runs when activePdf changes
  console.log(documents[0].documents[0]);
  if (!documents[0].documents[0].url) {
    return (
      <div className="flex flex-col mt-60 mx-auto h-full">
        <Spinner size={72} />
      </div>
    );
  }
  if (!row?.title) {
    navigate("/upload");
  }
  return (
    <>
      <div className="hidden h-full flex-1 flex-col space-y-8 p-2 md:flex">
        <div className="flex items-center justify-between space-y-2 gap-2">
          <div className="flex flex-col justify-start mr-auto">
            <h2 className="text-2xl font-bold tracking-tight">
              {
                // @ts-ignore
                t("selector.title", { title: row?.title })
              }
            </h2>
            <p className="text-muted-foreground">
              {
                // @ts-ignore
                t("selector.description", { title: row?.title })
              }
            </p>
          </div>
          <div className="ml-auto">
            <Button
              onClick={() => {
                handleNextStep();
                setClicked(true);
              }}
              disabled={clicked || Object.keys(rowSelection).length === 0}
            >
              {
                // @ts-ignore
                t("selector.next_step")
              }
            </Button>
          </div>
        </div>

        {/* US Tax Return Alert */}
        <USTaxReturnAlert />

        {/* No Rows Alert */}
        <NoRowsAlert />

        <div className="grid auto-rows-min gap-y-4 gap-x-4 md:grid-cols-1">
          {loading ? (
            <div className="flex flex-col mt-60 mx-auto h-full">
              <Spinner size={72} />
            </div>
          ) : (
            <PdfFocusProvider>
              <ResizablePanelGroup direction="horizontal">
                <ResizablePanel>
                  <PageSelector numpages={totalPages} row={row} upload={upload} rowSelection={rowSelection} setRowSelection={setRowSelection} />
                </ResizablePanel>
                <ResizableHandle withHandle onResize={handleResize} onDragging={() => handleResize()} />
                <ResizablePanel className="p-1">
                  <Grid item xs={12} md={7} style={{ height: "70vh" }}>
                    <Box
                      ref={containerRef}
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "95%",
                      }}
                    >
                      <DisplayMultiplePdfs is_conversation={true} pdfWidth={dimensions.width} pdfHeight={dimensions.height} handleRemoveDocument={() => {}} isUploadingDocuments={false} settotalPages={settotalPages} />
                    </Box>
                  </Grid>
                </ResizablePanel>
              </ResizablePanelGroup>
            </PdfFocusProvider>
          )}
        </div>
      </div>
    </>
  );
}
