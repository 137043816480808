import { ADD_TO_NEWSLETTER, AUTH_SUCCESS, GET_USER, LOGOUT_SUCCESS, SET_ACCESS_TOKEN, SET_IS_LOADING, FIREBASE_AUTH_ERROR, FIREBASE_AUTH_SUCCESS, TOKEN_REFRESH_SUCCESS, SET_LANGUAGE, SET_LOGO, SET_ORGANIZATION } from "../actions/types";
import { createSlice } from "@reduxjs/toolkit";
import i18n from "../../i18n"; // Import i18n for language switching
const savedLang = localStorage.getItem("language") || "en";

const initialState = {
  isLoading: true,
  isAuthentificated: null,
  accountData: null,
  accessToken: null,
  user: null,
  error: null, // optionally track error messages
  tokenExpirationTime: null,
  language: savedLang, // ✅ Load persisted language
  logo: null,
  organization: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      };

    case ADD_TO_NEWSLETTER:
      return state;

    case AUTH_SUCCESS:
      return {
        ...state,
        isAuthentificated: action.payload.isAuthentificated,
        user: action.payload.user,
        error: null, // clear error on success
      };
    case FIREBASE_AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthentificated: null,
        user: null,
        accessToken: null,
        tokenExpirationTime: null, // Reset expiration time on logout
      };

    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        tokenExpirationTime: action.payload.tokenExpirationTime, // Set expiration time with the token
      };

    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case SET_LANGUAGE:
      i18n.changeLanguage(action.payload); // ✅ Update i18n
      localStorage.setItem("language", action.payload); // ✅ Persist language
      return {
        ...state,
        language: action.payload, // ✅ Update state
      };

    case SET_LOGO:
      return {
        ...state,
        logo: action.payload,
      };

    case SET_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
