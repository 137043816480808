/**
 * Calculates the SBA Guarantee Fee for a loan scenario.
 *
 * @param sbaLoanValue  The SBA loan principal.
 * @param revolverValue The Revolver loan principal.
 * @param sbaGtyPct     The SBA guarantee percentage (default 0.75).
 * @param revolveGtyPct The Revolver guarantee percentage (default 0.50).
 * @param lowerFee      The fee rate for amounts up to `lowerBound` (default 3.5%).
 * @param upperFee      The fee rate for amounts above `lowerBound` (default 3.75%).
 * @param minValue      The minimum threshold below which the fee is 0 (default 750,000).
 * @param lowerBound    Amount above which `upperFee` applies (default 1,000,000).
 * @returns The calculated SBA guarantee fee (a number).
 */
export function calculateSbaGtyFee(sbaLoanValue, revolverValue, sbaGtyPct = 0.75, revolveGtyPct = 0.5, lowerFee = 0.035, upperFee = 0.0375, minValue = 750001, lowerBound = 1000000) {
  // 1) Compute the total guaranteed portion
  const totalGuaranteed = sbaLoanValue * sbaGtyPct + revolverValue * revolveGtyPct;

  // 2) If total is below the min threshold, return 0
  if (totalGuaranteed < minValue) {
    return 0;
  }

  // 3) Calculate fee on up to `lowerBound` at lowerFee,
  //    and any excess above `lowerBound` at upperFee
  let fee = 0;
  const portionBelow = Math.min(totalGuaranteed, lowerBound);
  fee += portionBelow * lowerFee;

  if (totalGuaranteed > lowerBound) {
    const portionAbove = totalGuaranteed - lowerBound;
    fee += portionAbove * upperFee;
  }

  return fee;
}

/**
 * Calculates loan fees based on the SBA loan amount range and revolver amount.
 *
 * @param sbaLoanValue The SBA loan principal.
 * @param revolverValue The Revolver loan principal.
 * @returns An object containing calculated fees and details.
 */
export function calculateLoanFees(sbaLoanValue, revolverValue) {
  let guaranteePct = 0;
  let additionalFee = 0;
  let result = {
    sbaLoanValue,
    revolverValue,
    guaranteePct: 0,
    additionalFee: 0,
    revolverFee: 0,
    totalSbaFee: 0,
    totalFees: 0,
  };

  // Determine guarantee percentage and additional fee based on SBA loan amount
  if (sbaLoanValue <= 150000) {
    guaranteePct = 0.85;
    additionalFee = 0;
  } else if (sbaLoanValue <= 500000) {
    guaranteePct = 0.75;
    additionalFee = 0;
  } else if (sbaLoanValue > 500000 && sbaLoanValue <= 700000) {
    guaranteePct = 0.75;
    additionalFee = 0.0055;
  } else if (sbaLoanValue > 700000 && sbaLoanValue <= 1000000) {
    guaranteePct = 0.75;
    additionalFee = 0.0105;
  } else if (sbaLoanValue > 1000000 && sbaLoanValue <= 5000000) {
    guaranteePct = 0.75;
    additionalFee = 0.015;
  } else {
    // Default values for loans outside the specified ranges
    guaranteePct = 0.75;
    additionalFee = 0.015;
  }

  // Calculate SBA loan fee
  const guaranteedAmount = sbaLoanValue * guaranteePct;
  const sbaFee = guaranteedAmount * additionalFee;

  // Calculate revolver fee (if > 500,000)
  let revolverFee = 0;
  if (revolverValue > 500000) {
    revolverFee = revolverValue * 0.0055;
  }

  // Populate result object
  result.guaranteePct = guaranteePct;
  result.additionalFee = additionalFee;
  result.revolverFee = revolverFee;
  result.totalSbaFee = sbaFee;
  result.totalFees = sbaFee + revolverFee;

  return result.totalFees;
}
