// src/store/actions/cashflowActions.js
import { backendClient } from "../../api/backend";
import { FETCH_CASHFLOW_DATA, SET_CASHFLOW_DATA, UPDATE_CASHFLOW_DATA, CLEAR_CASHFLOW_DATA, UPDATE_DEBT, UPDATE_PURCHASE_PRICE, UPDATE_MINIDEAL, FETCH_DEBT, FETCH_PURCHASE_PRICE, UPDATE_TRANCHES, FETCH_TRANCHES } from "./types";
import { calculatePaperValuation, initCashFlow, transformFinancialData } from "./util";
export const fetchCashflowData =
  (transactionId, debt = {}) =>
  async (dispatch) => {
    try {
      let response = await backendClient.fetchCashflow(transactionId); // Fetch initial cashflow
      console.log("Initial Cashflow Response:", response);

      // If debt exists, update only relevant cashFlow properties
      if (debt?.interestRate) {
        console.log(debt);
        const updatedCashFlow = await backendClient.updatedebtCF(transactionId, { debtSchedule: debt });
        console.log("Updated Cashflow Data:", updatedCashFlow);

        // Ensure cashFlow exists in response
        console.log(response);
        if (response.cashFlow && Array.isArray(response.cashFlow)) {
          response = {
            ...response, // Keep all other properties in response
            cashFlow: response.cashFlow.map((item, index) => ({
              ...item, // Keep all other properties in each cashFlow item
              ...{
                "New Debt": updatedCashFlow[index]["New Debt"],
                "New Debt Interest Payment": updatedCashFlow[index]["New Debt Interest Payment"],
                "Interest Paid": updatedCashFlow[index]["Interest Paid"],
                "Debt Starting Balance": updatedCashFlow[index]["Debt Starting Balance"],
                "Debt Repayment": updatedCashFlow[index]["Debt Repayment"],
                "Debt Ending Balance": updatedCashFlow[index]["Debt Ending Balance"],
              },
            })),
          };
        }
      }

      console.log(response);
      dispatch({
        type: FETCH_CASHFLOW_DATA,
        payload: { tableData: response },
      });

      return response;
    } catch (error) {
      console.error("Error fetching cashflow data:", error);
      return error;
    }
  };

export const fetchDebt = (transactionId) => {
  return async (dispatch) => {
    const debtData = await backendClient.fetchDebt(transactionId);
    dispatch({
      type: FETCH_DEBT,
      payload: debtData.debtSchedule,
    });
    return debtData.debtSchedule;
  };
};
export const fetchTranches = (transactionId) => {
  return async (dispatch) => {
    const tranchesData = await backendClient.fetchTranches(transactionId);
    console.log(tranchesData);
    dispatch({
      type: FETCH_TRANCHES,
      payload: tranchesData?.debtLines?.debtLines ?? {},
    });
    return tranchesData?.debtLines?.debtLines;
  };
};
export const fetchPurchasePrice = (transactionId) => {
  return async (dispatch) => {
    const price = await backendClient.fetchPurchasePrice(transactionId);

    dispatch({
      type: FETCH_PURCHASE_PRICE,
      payload: price,
    });
    return price;
  };
};

export const setCashflowData = (cashflowData) => {
  return (dispatch) => {
    dispatch({
      type: SET_CASHFLOW_DATA,
      payload: cashflowData,
    });
  };
};

export const updateCashflowData = (updatedFields, transform = false, year = "", rowWithTotals = [], acquisitionCost, closingCost, transactionId, update = true) => {
  return async (dispatch, getState) => {
    const state = getState();
    const currentCashflow = state.cashflow.tableData.cashFlow;

    let updatedState = {
      tableData: {
        ...state.cashflow.tableData,
        cashFlow: currentCashflow.map((cf, i) => {
          return { ...cf, ...updatedFields[i] };
        }),
      },
    };
    if (update) {
      console.log("running update cashflow");
      await backendClient.updateCashflow(transactionId, updatedState.tableData);
    }
    console.log(updatedFields);
    console.log(updatedState.tableData.cashFlow);
    // If transform is true, compute investment cash flow data
    if (transform) {
      const transformedData = transformFinancialData(rowWithTotals, state.cashflow, year, acquisitionCost, closingCost);
      updatedState = {
        ...updatedState,
        investmentCashFlowData: transformedData.investmentCashFlowData,
        initialCashFlowData: transformedData.initialCashFlowData,
      };
      console.log(updatedState);
    }

    dispatch({
      type: UPDATE_CASHFLOW_DATA,
      payload: updatedState,
    });
  };
};
export const updateDealParam = (transactionId, payload) => {
  return async (dispatch, getState) => {
    const state = getState();

    // Separate growthRate from the rest of the payload
    const { growthRate, ...restPayload } = payload;

    let updatedState = {
      tableData: {
        ...state.cashflow.tableData,
        ...restPayload, // Only updating with the rest of the parameters
      },
    };

    console.log("Running update cashflow");

    // First API call with only growthRate
    if (growthRate !== undefined) {
      await backendClient.updateGrowth(transactionId, { growthRate });
    }

    // Second API call with the rest of the payload
    if (Object.keys(restPayload).length > 0) {
      await backendClient.updatePenalties(transactionId, restPayload);
    }

    dispatch({
      type: UPDATE_CASHFLOW_DATA,
      payload: updatedState,
    });
  };
};

export const updatecashgraph = () => {
  return (dispatch, getState) => {
    const state = getState();
    const miniDeal = state.cashflow.miniDeal;
    const transformedData = initCashFlow(miniDeal.projections, miniDeal.debt, miniDeal.entryValue);
    dispatch({
      type: UPDATE_CASHFLOW_DATA,
      payload: { investmentCashFlowData: transformedData.investmentCashFlowData, initialCashFlowData: transformedData.initialCashFlowData },
    });
  };
};

export const updateDebt = (transactionId, debtData) => {
  return async (dispatch) => {
    await backendClient.updateDebt(transactionId, debtData);
    await backendClient.updatedebtCF(transactionId, debtData);

    dispatch({
      type: UPDATE_DEBT,
      payload: debtData,
    });
  };
};
export const updatetranches = (transactionId, tranches) => {
  return async (dispatch) => {
    await backendClient.updateTranches(transactionId, tranches);
    // await backendClient.updatedebtCF(transactionId, debtData);

    dispatch({
      type: UPDATE_TRANCHES,
      payload: tranches.debtLines,
    });
  };
};
export const clearMinideal = () => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_MINIDEAL,
      payload: {},
    });
  };
};
export const clearCashflow = () => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_CASHFLOW_DATA,
      payload: { tableData: {} },
    });
  };
};
export const updatePurchasePrice = (transactionId, price) => {
  return async (dispatch) => {
    const resp = await backendClient.updatePurchasePrice(transactionId, price);
    dispatch({
      type: UPDATE_PURCHASE_PRICE,
      payload: price,
    });
    return resp;
  };
};

export const updateMinideal = (revenue, ebitda, earnings, entryMultiple_1, entry_multiple_2, status) => {
  return (dispatch) => {
    console.log(status);
    if (status === "completed") {
      dispatch({
        type: UPDATE_MINIDEAL,
        payload: {},
      });
    } else {
      const response = calculatePaperValuation(revenue, ebitda, earnings, entryMultiple_1, entry_multiple_2);
      console.log(response);
      dispatch({
        type: UPDATE_MINIDEAL,
        payload: response,
      });
      return response;
    }
  };
};

export const clearCashflowData = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CASHFLOW_DATA,
    });
  };
};
