import { TrendingUp } from "lucide-react";
import { Button } from "../../components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import * as Slider from "@radix-ui/react-slider";
import { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "../../components/ui/form";
import { Info } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../components/ui/tooltip";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { store } from "../../redux/store";
import { toast } from "sonner";
import { fetchCashflowData, updateDebt } from "../../redux/actions/cashflowAction";
import { useTranslation } from "react-i18next";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { AlertTriangle } from "lucide-react";
import AlertDestructive from "./components/alert";

interface RowItemProps {
  label: string;
  value: string;
  tooltipContent?: string;
  isHighlighted?: boolean; // For rows like Total Cash Received, Invested, etc.
  isNegative?: boolean; // For rows like Loan Balance or Debt
  highlightColor?: string; // Optional custom highlight color
  textColor?: string; // Optional custom highlight color
}

const RowItem: React.FC<RowItemProps> = ({
  label,
  value,
  tooltipContent,
  isHighlighted = false,
  isNegative = false,
  highlightColor = "bg-gray-100", // Default highlight color
  textColor = "text-black", // Default highlight color
}) => {
  return (
    <div className={`flex justify-between items-center px-2 h-8 ${isHighlighted ? `${highlightColor} rounded-md` : ""}`}>
      {/* Row Label with Tooltip */}
      <div className={`flex gap-2 items-center text-sm  ${isHighlighted ? `${textColor} font-semibold` : "text-gray-500 font-medium"}`}>
        <span className="truncate">{label}</span>
        {tooltipContent && <TooltipIcon content={tooltipContent} />}
      </div>

      {/* Row Value */}
      <p className={`text-sm font-semibold ${isNegative ? "text-red-500" : isHighlighted ? textColor : "text-black"}`}>{value}</p>
    </div>
  );
};

export function TooltipIcon({ content }: { content: string }) {
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="cursor-pointer">
            <Info className="h-4 w-4 text-gray-500 hover:text-black" />
          </span>
        </TooltipTrigger>
        <TooltipContent className="max-w-96 flex flex-wrap">
          <p className="color-white">{content}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default function DebtPricing({
  noi,
  setNoi,
  purchaseCost,
  setPurchaseCost,
  amortization,
  setAmortization,
  dscr,
  setDscr,
  cashSweep,
  setCashSweep,
  interestRate,
  setInterestRate,
  maximumDebt,
  setMaximumDebt,
  calculateMaximumDebt,
  schedule,
  customDebt,
  setCustomDebt,
  transaction,
  minideal,
  debt,
  LTM_CFADS,
  cashflow,
  fcf,
  isCustomDebtFromBuilder = false,
}) {
  const [isFormLocked, setIsFormLocked] = useState(isCustomDebtFromBuilder);
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();

  const FormSchema = z.object({
    noi: z.number().min(1, { message: "NOI must be greater than 0." }),
    purchaseCost: z.number().min(1, { message: "Purchase Cost must be greater than 0." }),
    amortization: z.number().min(1, { message: "Amortization must be greater than 0." }),
    dscr: z.number().min(1, { message: "DSCR must be greater than 1." }),
    cashSweep: z.number().refine(
      (value) => value >= 0 && value <= 1, // Validate as decimal
      (value) => (value < 0 ? { message: "Cash Sweep must be at least 0%." } : { message: "Cash Sweep must not exceed 100%." }),
    ),
    customDebt: z.number().min(0, { message: "Debt must be greater than 0." }),
  });

  const form = useForm({
    resolver: zodResolver(FormSchema),
    mode: "onChange", // Validate dynamically on change

    defaultValues: { noi, purchaseCost, amortization, dscr, cashSweep, customDebt },
  });
  useEffect(() => {
    resetState();
    const debt = calculateMaximumDebt(fcf, amortization, dscr, interestRate);
    setMaximumDebt(debt);
  }, [transaction, minideal]);
  useEffect(() => {
    const debt = calculateMaximumDebt(fcf, amortization, dscr, interestRate);
    setMaximumDebt(debt);
  }, [noi, amortization, dscr, interestRate]);

  // Add effect to watch customDebt changes
  useEffect(() => {
    if (isCustomDebtFromBuilder) {
      if (customDebt === 0) {
        setIsFormLocked(false);
      } else {
        setIsFormLocked(true);
      }
    }
  }, [customDebt, isCustomDebtFromBuilder]);

  const onSubmit = (data) => {
    // const debt = calculateMaximumDebt(data.noi, data.amortization, data.dscr, interestRate);
    // setMaximumDebt(debt);
    // console.log("Calculate");
  };

  const handleEraseDebt = async () => {
    // const debtdata = {};
    const debtdata = {
      noi,
      amortization,
      dscr,
      cashSweep,
      interestRate,
      maximumDebt,
      schedule: [
        {
          cashSweepPayment: "0.00",
          debtBalance: "0.00",
          dividends: "0.00",
          interestPayment: "0.00",
          noi: "0.00",
          principalPayment: "0.00",
          year: 1,
        },
      ],
      customDebt,
    };
    await dispatch(updateDebt(transaction.id, debtdata));
    resetState();
    toast(
      // @ts-ignore
      t("debts.toasts.deal_updated"),
      {
        description:
          // @ts-ignore
          t("debts.toasts.original_debt_restored"),
      },
    );
  };
  const handleSaveDebt = async () => {
    const debtdata = {
      noi,
      amortization,
      dscr,
      cashSweep,
      interestRate,
      maximumDebt,
      schedule,
      customDebt,
    };
    console.log("debtdata", debtdata);
    await dispatch(updateDebt(transaction.id, debtdata));
    try {
      await dispatch(fetchCashflowData(transaction.id));
    } catch {
      console.log("Error fetching cashflow data");
    }
    toast(
      // @ts-ignore
      t("debt.toasts.deal_updated"),
      {
        // @ts-ignore
        description: t("debt.toasts.new_debt_reflected"),
      },
    );
  };
  const { t } = useTranslation();
  const totalCashSweep = schedule.reduce((acc, item) => {
    const cashSweepPayment = parseFloat(item.cashSweepPayment);
    // console.log("Cash Sweep Payment:", cashSweepPayment);
    return acc + cashSweepPayment;
  }, 0);
  const totalPrincipalPayment = schedule.reduce((acc, item) => {
    const principalPayment = parseFloat(item.principalPayment);
    // console.log("Principal Payment:", principalPayment);
    return acc + principalPayment;
  }, 0);

  const resetState = () => {
    // Set the states with fallback values from minideal or defaults
    if (!minideal.entryValue) return;
    setPurchaseCost(2072000);
    setAmortization(debt?.amortization || minideal?.amortization || 20);
    setDscr(debt?.dscr || minideal?.dscr || 2.0);
    setCashSweep(debt?.cashSweep || minideal?.cashSweep || 0);
    setMaximumDebt(0);
    setCustomDebt(debt?.customDebt || minideal?.customDebt || 0);
    setInterestRate(debt?.interestRate || minideal?.interestRate || 16);
    setNoi(debt?.noi || LTM_CFADS || Number(minideal.projections[0].cashFlow));
    // Reset form fields with the updated state values
    form.reset({
      noi: debt?.noi || LTM_CFADS || Number(minideal.projections[0].cashFlow), // Retain current value of NOI if available
      purchaseCost: 2072000,
      amortization: debt?.amortization || minideal?.amortization || 20,
      dscr: debt?.dscr || minideal?.dscr || 2.0,
      cashSweep: debt?.cashSweep || minideal?.cashSweep || 0,
      customDebt: debt?.customDebt || minideal?.customDebt || 0,
      // interestRate: minideal?.interestRate || 8,
    });
  };
  return (
    <Card className="max-w-full p-6">
      {isCustomDebtFromBuilder && isFormLocked && (
        <AlertDestructive
          title="Form is Locked"
          description="Set Custom Debt to 0 to unlock the form and enable manual editing of all fields."
          setCustomDebt={() => {
            setCustomDebt(0);
            setIsFormLocked(false);
          }}
          buttonText="Unlock Form"
          variant={1}
        />
      )}
      <CardHeader>
        <CardTitle>
          {
            // @ts-ignore
            t("debt_calculator.title")
          }
        </CardTitle>
        <CardDescription>
          <div className="flex">
            <div className="w-[70%]">
              {
                // @ts-ignore
                t("debt_calculator.description")
              }
            </div>
            <div className="ml-auto flex gap-3">
              {/* <Button
                variant={"ghost"}
                onClick={() => {
                  handleEraseDebt();
                }}
              >
                {
                  // @ts-ignore
                  t("debt_calculator.remove_scenario")
                }
              </Button> */}

              <Button onClick={handleSaveDebt}>
                {
                  // @ts-ignore
                  t("debt_calculator.save_scenario")
                }
              </Button>
            </div>
          </div>
        </CardDescription>
      </CardHeader>

      <CardContent className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Card className="p-4 bg-muted text-muted-foreground flex flex-col space-y-4 justify-center gap-2 px-2">
          <CardContent className="flex flex-col items-center justify-center">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-1 gap-y-4">
                  {/* NOI Field */}
                  {!cashflow && (
                    <FormField
                      control={form.control}
                      name="noi"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="flex gap-2 ">
                            {
                              // @ts-ignore
                              t("debt_calculator.noi_label")
                            }
                            <TooltipIcon
                              content={
                                // @ts-ignore
                                t("debt_calculator.noi_tooltip")
                              }
                            />
                          </FormLabel>
                          <FormControl>
                            <Input
                              type="text"
                              inputMode="numeric"
                              disabled={isFormLocked}
                              value={field.value !== undefined ? `$${field.value.toLocaleString()}` : ""}
                              onChange={(e) => {
                                const rawValue = e.target.value.replace("$", "").replace(/,/g, "");
                                if (/^\d*$/.test(rawValue)) {
                                  const numericValue = Number(rawValue);
                                  field.onChange(numericValue); // Update form value
                                  setNoi(numericValue); // Sync local state
                                }
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}

                  {/* DSCR */}
                  <FormField
                    control={form.control}
                    name="dscr"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="flex gap-2">
                          {
                            // @ts-ignore
                            t("debt_calculator.dscr_label")
                          }
                          <TooltipIcon
                            content={
                              // @ts-ignore
                              t("debt_calculator.dscr_tooltip")
                            }
                          />
                        </FormLabel>
                        <FormControl>
                          <Input type="number" step="0.1" disabled={isFormLocked} value={dscr.toFixed(1)} onChange={(e) => setDscr(Number(e.target.value))} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {/* Amortization */}
                  <FormField
                    control={form.control}
                    name="amortization"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="flex gap-2">
                          {
                            // @ts-ignore
                            t("debt_calculator.amortization_label")
                          }
                          <TooltipIcon
                            content={
                              // @ts-ignore
                              t("debt_calculator.amortization_tooltip")
                            }
                          />
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="text"
                            inputMode="numeric"
                            disabled={isFormLocked}
                            value={field.value !== undefined ? `${field.value.toLocaleString()}` : ""}
                            onChange={(e) => {
                              const rawValue = e.target.value.replace("$", "").replace(/,/g, "");
                              if (/^\d*$/.test(rawValue)) {
                                const numericValue = Number(rawValue);
                                field.onChange(numericValue); // Update form value
                                setAmortization(numericValue); // Sync local state
                              }
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {/* Cash Sweep */}
                  <FormField
                    control={form.control}
                    name="cashSweep"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel className="flex gap-2">
                          {
                            // @ts-ignore
                            t("debt_calculator.cash_sweep_label")
                          }
                          <TooltipIcon
                            content={
                              // @ts-ignore
                              t("debt_calculator.cash_sweep_tooltip")
                            }
                          />
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="text"
                            inputMode="numeric"
                            disabled={isFormLocked}
                            value={field.value !== undefined ? field.value * 100 : ""}
                            onChange={(e) => {
                              const rawValue = e.target.value.replace("%", "");
                              if (/^\d*\.?\d*$/.test(rawValue)) {
                                const numericValue = Number(rawValue);
                                const decimalValue = numericValue / 100;
                                field.onChange(decimalValue);
                                setCashSweep(decimalValue);
                              }
                            }}
                          />
                        </FormControl>
                        <FormMessage>{fieldState.error?.message}</FormMessage>
                      </FormItem>
                    )}
                  />

                  {/* Custom Debt */}
                  <FormField
                    control={form.control}
                    name="customDebt"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel className="flex gap-2">
                          {
                            // @ts-ignore
                            t("debt_calculator.custom_debt_label")
                          }
                          <TooltipIcon
                            content={
                              // @ts-ignore
                              t("debt_calculator.custom_debt_tooltip")
                            }
                          />
                        </FormLabel>

                        <FormControl>
                          <Input
                            type="text"
                            inputMode="numeric"
                            value={field.value !== undefined ? `$${customDebt.toLocaleString()}` : ""}
                            onChange={(e) => {
                              const rawValue = e.target.value.replace("$", "").replace(/,/g, "");
                              if (/^\d*$/.test(rawValue)) {
                                const numericValue = Number(rawValue);
                                field.onChange(numericValue); // Update form value
                                setCustomDebt(numericValue); // Sync local state
                              }
                            }}
                          />
                        </FormControl>
                        <FormMessage>{fieldState.error?.message}</FormMessage>
                      </FormItem>
                    )}
                  />
                </div>

                {/* Interest Rate Slider */}
                <Card className="p-4 bg-muted text-muted-foreground flex flex-col space-y-4 justify-center gap-2">
                  <CardContent className="flex flex-col items-center justify-center gap-3">
                    <div className="flex justify-between min-w-24 border-muted bg-gray-100 border-[1px] p-1 text-md font-bold">
                      <span className="mr-4">
                        {
                          // @ts-ignore
                          t("debt_calculator.interest_rate_label")
                        }
                      </span>
                      <span>{interestRate}%</span>
                    </div>
                    <Slider.Root defaultValue={[interestRate]} min={0} max={32} step={0.1} disabled={isFormLocked} onValueChange={(value) => setInterestRate(value[0] || 0)} className={`relative flex items-center h-6 w-full ${isFormLocked ? "opacity-50" : ""}`}>
                      <Slider.Track className="relative bg-gray-200 h-2 w-full rounded-full">
                        <Slider.Range className="absolute bg-black h-2 rounded-full" />
                      </Slider.Track>
                      <Slider.Thumb className="SliderThumb" asChild>
                        <div className="absolute flex items-center justify-center w-4 h-4 bg-white border-2 border-black rounded-full -translate-x-[50%] -translate-y-[50%]" />
                      </Slider.Thumb>
                    </Slider.Root>
                  </CardContent>
                </Card>

                {/* Maximum Debt */}
                <div className="text-2xl font-bold">
                  {" "}
                  {customDebt === 0
                    ? `${
                        // @ts-ignore
                        t("debt_calculator.maximum_debt")
                      }: ${maximumDebt.toLocaleString()}`
                    : `${
                        // @ts-ignore
                        t("debt_calculator.custom_debt")
                      }: ${customDebt.toLocaleString()}`}
                </div>

                <Button className="hidden" type="submit">
                  {
                    // @ts-ignore
                    t("debt_calculator.calculate")
                  }
                </Button>
              </form>
            </Form>
          </CardContent>
        </Card>
        <Card className="p-4 text-muted-foreground flex flex-col ustify-center gap-2">
          <CardHeader className="p-0">
            <CardTitle>
              {
                // @ts-ignore
                t("schedule.summaryTitle")
              }
            </CardTitle>
            <CardDescription>
              {
                // @ts-ignore
                t("schedule.totalCashflows", { years: schedule.length })
              }
            </CardDescription>
          </CardHeader>
          <CardContent className="p-2 flex flex-col mt-2 flex-grow justify-between">
            {/* Total NOI */}
            {(() => {
              const totalNOI = schedule.reduce((acc, item) => acc + parseFloat(item.noi), 0);
              return (
                <RowItem
                  label={
                    // @ts-ignore
                    t("debt_summary.total_noi_label")
                  }
                  value={`$${totalNOI.toLocaleString()}`}
                  tooltipContent={
                    // @ts-ignore
                    t("debt_summary.total_noi_tooltip")
                  }
                />
              );
            })()}

            {/* Total Interest Paid */}
            {(() => {
              const totalInterestPaid = schedule.reduce((acc, item) => acc + parseFloat(item.interestPayment), 0);
              return (
                <RowItem
                  label={
                    // @ts-ignore
                    t("debt_summary.total_interest_paid_label")
                  }
                  value={`$${totalInterestPaid.toLocaleString()}`}
                  tooltipContent={
                    // @ts-ignore
                    t("debt_summary.total_interest_paid_tooltip")
                  }
                />
              );
            })()}

            {/* Total Principal Payment */}
            <RowItem
              label={
                // @ts-ignore
                t("debt_summary.total_principal_payment_label")
              }
              value={`$${totalPrincipalPayment.toLocaleString()}`}
              tooltipContent={
                // @ts-ignore
                t("debt_summary.total_principal_payment_tooltip")
              }
            />

            {/* Total Cash Sweep Payment */}
            <RowItem
              label={
                // @ts-ignore
                t("debt_summary.total_cash_sweep_label")
              }
              value={`$${totalCashSweep.toLocaleString()}`}
              tooltipContent={
                // @ts-ignore
                t("debt_summary.total_cash_sweep_tooltip")
              }
            />

            {/* Total Dividends */}
            {(() => {
              const totalDividends = schedule.reduce((acc, item) => acc + parseFloat(item.dividends), 0);
              return (
                <RowItem
                  label={
                    // @ts-ignore
                    t("debt_summary.total_dividends_label")
                  }
                  value={`$${totalDividends.toLocaleString()}`}
                  tooltipContent={
                    // @ts-ignore
                    t("debt_summary.total_dividends_tooltip")
                  }
                />
              );
            })()}

            {/* Maximum or Custom Debt */}
            {(() => {
              const maximumDebt = parseFloat(schedule[0]?.debtBalance || "0");
              return (
                <RowItem
                  label={
                    // @ts-ignore
                    t(customDebt === 0 ? "debt_summary.maximum_debt_label" : "debt_summary.custom_debt_label")
                  }
                  value={`$${Number(totalCashSweep + totalPrincipalPayment).toLocaleString()}`}
                  tooltipContent={
                    // @ts-ignore
                    t(customDebt === 0 ? "debt_summary.maximum_debt_tooltip" : "debt_summary.custom_debt_tooltip")
                  }
                  isHighlighted
                />
              );
            })()}
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
}
