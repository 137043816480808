import { createContext, Dispatch, SetStateAction } from "react";

export type InteractionMode = "conversational" | "informational";

export type ConversationType = "voice-to-voice" | "text-voice" | null;

interface AppStateContextValue {
  interactionMode: InteractionMode;
  setInteractionMode: Dispatch<SetStateAction<InteractionMode>>;
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  webrtcEnabled: boolean;
  websocketEnabled: boolean;
}

const noop = () => {};

export const AppStateContext = createContext<AppStateContextValue>({
  interactionMode: "informational",
  setInteractionMode: noop,
  searchQuery: "",
  setSearchQuery: noop,
  webrtcEnabled: false,
  websocketEnabled: false,
});
