// src/store/reducers/cashflowReducer.js
import { FETCH_CASHFLOW_DATA, SET_CASHFLOW_DATA, UPDATE_CASHFLOW_DATA, CLEAR_CASHFLOW_DATA, UPDATE_DEBT, UPDATE_PURCHASE_PRICE, UPDATE_MINIDEAL, FETCH_PURCHASE_PRICE, FETCH_DEBT, UPDATE_TRANCHES, FETCH_TRANCHES } from "../actions/types";

const initialState = {
  tableData: [],
  investmentCashFlowData: [],
  initialCashFlowData: [],
  processedCashFlow: [],
  debt: {},
  purchasePrice: {},
  miniDeal: {},
  tranches: {},
};

const cashflowReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CASHFLOW_DATA:
      return {
        ...state, // Ensure all variables are initialized
        ...action.payload,
      };
    case FETCH_DEBT:
      return {
        ...state,
        debt: {
          ...action.payload, // Merge new debt values
        },
      };
    case FETCH_TRANCHES:
      return {
        ...state,
        tranches: {
          ...action.payload, // Merge new debt values
        },
      };
    case FETCH_PURCHASE_PRICE:
      return {
        ...state,
        purchasePrice: action.payload,
      };
    case SET_CASHFLOW_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case UPDATE_CASHFLOW_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case UPDATE_DEBT:
      return {
        ...state,
        debt: {
          ...action.payload, // Merge new debt values
        },
      };
    case UPDATE_TRANCHES:
      return {
        ...state,
        tranches: {
          ...action.payload, // Merge new debt values
        },
      };

    case UPDATE_PURCHASE_PRICE:
      return {
        ...state,
        purchasePrice: action.payload, // Update purchase price directly
      };
    case UPDATE_MINIDEAL:
      console.log(action.payload);
      return {
        ...state,
        miniDeal: action.payload, // Update purchase price directly
      };

    case CLEAR_CASHFLOW_DATA:
      return initialState;

    default:
      return state;
  }
};

export default cashflowReducer;
