import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "../../../components/ui/card";
import PopulationChart from "./PopulationChart"; // or wherever your chart is imported
import { useTranslation } from "react-i18next";

/**
 * Reusable DSCR chart, very similar in structure to CashCard.
 */
export const DscrCard = ({ dscrValues }: { dscrValues: { year: number; value: number }[] }) => {
  const { t } = useTranslation();

  // Filter out invalid values
  const validDscrValues = dscrValues.filter((item) => item.value !== null && item.value !== undefined && !isNaN(item.value));

  // Calculate min, max, and average DSCR
  const numericValues = validDscrValues.map((item) => item.value);
  const minDscr = numericValues.length > 0 ? Math.min(...numericValues) : 0;
  const maxDscr = numericValues.length > 0 ? Math.max(...numericValues) : 0;
  const avgDscr = numericValues.length > 0 ? numericValues.reduce((sum, val) => sum + val, 0) / numericValues.length : 0;

  // Convert array of DSCR objects into an array of objects your chart can understand
  // Each object includes the DSCR for that year plus fixed lines for minDSCR and defaultDSCR
  const dscrData = validDscrValues.map((item) => ({
    year: String(item.year),
    dscr: item.value,
    minDSCR: 1.25, // Common minimum DSCR covenant
    defaultDSCR: 1.0, // Default threshold
  }));

  // Define the chartConfig for each "series" your chart will display
  const dscrChartConfig = {
    dscr: {
      label: "DSCR",
      color: "#4f46e5", // Indigo color
    },
    minDSCR: {
      label: "Min DSCR (1.25×)",
      color: "#f59e0b", // Amber color
    },
    defaultDSCR: {
      label: "Default DSCR (1.0×)",
      color: "#ef4444", // Red color
    },
  };

  return (
    <Card className="col-span-2 w-full shadow-lg mt-2">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>
          {
            // @ts-ignore
            t("metrics.dscr", "Debt Service Coverage Ratio")
          }
        </CardTitle>
        <div className="flex space-x-4 text-sm">
          <div>
            <span className="font-semibold">Min:</span> <span className={minDscr < 1.0 ? "text-red-500" : minDscr < 1.25 ? "text-amber-500" : "text-green-500"}>{minDscr.toFixed(2)}×</span>
          </div>
          <div>
            <span className="font-semibold">Avg:</span> <span className={avgDscr < 1.0 ? "text-red-500" : avgDscr < 1.25 ? "text-amber-500" : "text-green-500"}>{avgDscr.toFixed(2)}×</span>
          </div>
          <div>
            <span className="font-semibold">Max:</span> <span className="text-green-500">{maxDscr.toFixed(2)}×</span>
          </div>
        </div>
      </CardHeader>
      <CardContent className="p-6">{dscrData.length > 0 ? <PopulationChart data={dscrData} chartConfig={dscrChartConfig} title="" /> : <div className="flex justify-center items-center h-64 text-gray-500">No DSCR data available</div>}</CardContent>
    </Card>
  );
};
