"use client";

import { ArrowBigUpDash, BookOpen, ChartColumnStacked, Command, FileText, FileUp, Filter, Hospital, House, Layout, LifeBuoy, ListChecks, MapPinHouse, MessageCircleQuestion, PiggyBank, ScanSearch, Search, Send, Tag, TextSearch, TrendingUpDown } from "lucide-react";
import * as React from "react";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useEffect, useState } from "react";

import { Sidebar, SidebarContent, SidebarFooter, SidebarHeader, SidebarMenu, SidebarMenuButton, SidebarMenuItem } from "../ui/sidebar";
import { NavMain } from "./nav-main";
import { NavProjects } from "./nav-projects";
import { NavUser } from "./nav-user";
// import logoImage2 from "../../assets/logo/logo.svg";
import { collection, query, where } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast as othertoast } from "sonner";
import { db } from "../../firebase";
import { toast } from "../../hooks/use-toast";
import { firebaseSignOutAction, fetchLogo, fetchOrganization } from "../../redux/actions/authAction";
import { fetchTransactions, setTransaction } from "../../redux/actions/transactionAction";
import { store } from "../../redux/store";
import { useNotifications } from "../../router/NotificationsProvider";
import { useFirebaseDeals } from "../../hooks/useFirebaseDeals";

import { ScrollArea } from "../ui/scroll";
import { TeamSwitcher } from "./team-switcher";
import { backendClient } from "../../api/backend";
import Spinner from "../ui/Spinner";

export function AppSidebar({ logo, ...props }: React.ComponentProps<typeof Sidebar> & { logo?: string }) {
  const location = useLocation();
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const navigate = useNavigate();
  const transaction = useSelector((state: any) => state.transactions.transaction);
  const transactions = useSelector((state: any) => state.transactions.transactions);
  const { addQueryListener, observedData, removeListener } = useNotifications();
  const reduxLogo = useSelector((state: any) => state.auth.logo);
  const organization = useSelector((state: any) => state.auth.organization);

  const transactionId = transaction?.id;

  // Use the new hook instead of local state + complex useEffect logic
  const { uploadStatus, uploadMarket } = useFirebaseDeals({
    transactionId: transactionId,
    organisationId: transaction?.organisationId,
    currentTransaction: transaction,
    transactions: transactions,
  });

  const state = store.getState();
  const email = state?.auth?.user?.email;
  const name = !email ? "" : email.split(".")[0][0].toUpperCase() + email.split(".")[0].slice(1);
  const { t } = useTranslation();

  // Monitor state changes for debugging
  React.useEffect(() => {
    console.log("🔔 Local state uploadStatus changed:", uploadStatus);
    console.log("🔔 UI sections should now be:", uploadStatus !== "pending" ? "active" : "inactive");
  }, [uploadStatus]);

  React.useEffect(() => {
    console.log("🔔 Local state uploadMarket changed:", uploadMarket);
  }, [uploadMarket]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (transaction?.organisationId) {
        console.log("%c Organization & Logo Management ", "background: #3498db; color: white; padding: 2px 6px; border-radius: 3px;");
        console.log("Fetching logo and organization using Redux actions");
        await dispatch(fetchLogo());
        const orgData = await dispatch(fetchOrganization());
        console.log("Organization type:", orgData?.type || "undefined");

        // Visibility logic for lender organizations
        if (orgData?.type === "lender") {
          console.log("%c Lender-specific View Activated ", "background: #e74c3c; color: white; padding: 2px 6px; border-radius: 3px;");
          console.log("Hidden menu items for lender organizations:");
          console.log(" - Expansion Plans (capexplan)");
          // console.log(" - Financial Audit (audit)");
          console.log(" - QoE (qoe)");
          console.log(" - Cashflow Projection (cashflow)");
          console.log(" - Pricing/Valuation (valuation)");
        } else {
          console.log("%c Standard View Activated ", "background: #2ecc71; color: white; padding: 2px 6px; border-radius: 3px;");
          console.log("All menu items shown (non-lender organization)");
        }
      }
      setIsLoading(false);
    };

    fetchData();
  }, [transaction?.organisationId, dispatch]);

  const userData = {
    user: {
      name: name,
      email: email,
      avatar: "",
    },
  };
  const data = {
    teams: [
      {
        name: "Select Deal",
        logo: Hospital,
        plan: "Current Deal",
      },
    ],
    navMain: [
      {
        //@ts-ignore
        title: t("sidebar.seller_docs"),
        url: "#",
        icon: BookOpen,
        isActive: true && transaction,
        items: [
          // {
          //   //@ts-ignore
          //   title: t("sidebar.investment_memo"),
          //   url: "/invmemo",
          //   icon: FileText,
          //   isActive: true,
          // },
          {
            //@ts-ignore
            title: t("sidebar.upload_documents"),
            url: "/upload",
            icon: FileUp,
            isActive: true,
          },
          {
            //@ts-ignore
            title: t("sidebar.business_checklist"),
            url: "/checklist",
            icon: ListChecks,
            isActive: true,
          },
        ],
      },

      {
        //@ts-ignore

        title: t("sidebar.scenarios"),
        url: "#",
        icon: MessageCircleQuestion,
        isActive: uploadMarket && transaction,
        items: [
          {
            //@ts-ignore
            title: t("sidebar.debt_schedule"),
            url: "/debt",
            icon: PiggyBank,
            isActive: true,
          },

          ...(organization && organization?.type !== "lender"
            ? [
                {
                  //@ts-ignore
                  title: t("sidebar.expansion_plans"),
                  url: "/capexplan",
                  icon: ArrowBigUpDash,
                  isActive: true,
                },
              ]
            : []),
          {
            //@ts-ignore
            title: `${t("sidebar.market_analysis")}`,
            url: "/market",
            icon: MapPinHouse,
            isActive: transaction?.regionalBusinessAnalysisCompleted,
          },
        ],
      },
      {
        //@ts-ignore
        title: uploadStatus === "pending" ? t("sidebar.audit_pending") : t("sidebar.audit"),
        url: "#",
        icon: Search,
        isActive: uploadStatus !== "pending" && transaction,
        items: [
          ...(organization
            ? organization.type !== "lender"
              ? [
                  {
                    title: "QoE",
                    url: "/qoe",
                    icon: TextSearch,
                    isActive: uploadStatus !== "pending" && transaction,
                  },
                  {
                    //@ts-ignore
                    title: t("sidebar.cashflow_projection"),
                    url: "/cashflow",
                    icon: TrendingUpDown,
                    isActive: uploadStatus !== "pending" && transaction,
                  },
                  {
                    //@ts-ignore
                    title: t("sidebar.pricing"),
                    url: "/valuation",
                    icon: Tag,
                    isActive: uploadStatus !== "pending" && transaction,
                  },
                ]
              : []
            : []),
          {
            //@ts-ignore
            title: t("sidebar.financial_audit"),
            url: "/audit",
            icon: ScanSearch,
            isActive: uploadStatus !== "pending" && transaction,
          },
          {
            title: `${
              //@ts-ignore
              t("sidebar.cashflow_projection")
            }`,
            url: "/debtpl",
            icon: TrendingUpDown,
            isActive: uploadStatus !== "pending" && transaction,
          },
        ],
      },
    ],
    navSecondary: [
      {
        //@ts-ignore
        title: t("sidebar.support"),
        url: "#",
        icon: LifeBuoy,
      },
      {
        //@ts-ignore
        title: t("sidebar.feedback"),
        url: "#",
        icon: Send,
      },
    ],
    projects: [
      {
        //@ts-ignore
        name: t("sidebar.pipeline"),
        url: "/pipeline",
        icon: Filter,
        isActive: true,
      },
      {
        //@ts-ignore
        // name: t("sidebar.pipeline"),
        name: "Benchmark",
        url: "/invmemo",
        icon: ChartColumnStacked,
        isActive: transaction,
      },
      {
        //@ts-ignore
        name: t("sidebar.overview"),
        url: "/overview",
        icon: House,
        isActive: transaction,
      },
    ],
  };
  const handleSignOut = async () => {
    console.log("Running sign out");
    try {
      await dispatch(firebaseSignOutAction());
      toast({ title: "Signed out successfully" });
      navigate("/sign-in");
    } catch (err) {
      toast({
        variant: "destructive",
        title: "Sign out failed",
        description: "Unable to sign out, please try again.",
      });
      console.error("Error signing out:", err);
    }
  };

  // Add a separate useEffect for logo cleanup
  useEffect(() => {
    // Cleanup function to revoke object URLs when component unmounts
    // or when a new logo is loaded
    return () => {
      if (reduxLogo && reduxLogo.startsWith("blob:")) {
        console.log("Revoking logo URL:", reduxLogo);
        URL.revokeObjectURL(reduxLogo);
      }
    };
  }, [reduxLogo]);

  return (
    <Sidebar variant="inset" {...props}>
      {isLoading && !organization && !reduxLogo ? (
        <div className="flex items-center justify-center h-full">
          <Spinner size={16} />
        </div>
      ) : (
        <ScrollArea className="flex flex-col h-full">
          <SidebarHeader>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton size="lg" asChild>
                  <a href="/buyers">
                    {!transaction?.organisationId ? (
                      reduxLogo ? (
                        <div className="flex items-center">
                          <img src={reduxLogo} className="w-24 mr-32" alt="Organization logo" />
                        </div>
                      ) : logo ? (
                        <div className="flex items-center">
                          <img src={logo} className="w-24 mr-32" alt="Default logo" />
                        </div>
                      ) : (
                        <div className="flex items-center">
                          <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
                            <Command className="size-4" />
                          </div>
                          <div className="grid flex-1 text-left text-sm leading-tight">
                            {organization?.name && <span className="truncate font-semibold">{organization.name}</span>}
                            {organization?.type && (
                              <span className="truncate text-xs">
                                {organization.type.charAt(0).toUpperCase() + organization.type.slice(1)}
                                {organization.type === "lender" ? " View" : ""}
                              </span>
                            )}
                          </div>
                        </div>
                      )
                    ) : (
                      <div className="flex items-center">
                        <img src={reduxLogo || logo} className="w-24 mr-32" alt="Organization logo" />
                      </div>
                    )}
                  </a>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>

            <SidebarMenu>
              <TeamSwitcher />
            </SidebarMenu>
          </SidebarHeader>
          <SidebarContent>
            <NavProjects
              projects={data.projects}
              name={
                //@ts-ignore
                t("sidebar.summary_opportunities")
              }
              transaction={transaction}
            />
            <NavMain items={data.navMain} transaction={transaction} />
            {/* <NavSecondary items={data.navSecondary} className="mt-auto" /> */}
          </SidebarContent>
        </ScrollArea>
      )}
      <SidebarFooter>
        <div className="flex w-full h-full">
          <NavUser signout={handleSignOut} user={userData.user} />
        </div>
      </SidebarFooter>
    </Sidebar>
  );
}
