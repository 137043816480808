import React, { useState, useEffect } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "../../../components/ui/card";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "../../../components/ui/form";
import { Button } from "../../../components/ui/button";
import { Slider } from "../../../components/ui/slider";
import { Checkbox } from "../../../components/ui/checkbox";
import { Input } from "../../../components/ui/input";
import Tooltip from "./Tooltip"; // <-- your Tooltip component
import { toast } from "sonner";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { updateDealParam, fetchCashflowData } from "../../../redux/actions/cashflowAction";
/**
 * 1) Define a Zod schema (optional but recommended)
 *    This ensures your input ranges match the requirement
 */
const FormSchema = z.object({
  growthRate: z.number().min(-20).max(100).default(0),
  ebitdaMargin: z.number().min(-20).max(20).default(0),
  recurringRevenue: z.number().min(0).max(100).default(0),
  concentration: z.number().min(0).max(100).default(0),
  ownerInvolvement: z.boolean().default(false),
  insuranceIncluded: z.boolean().default(false),
  rent: z.boolean().default(false),
  rentValue: z.number().default(0), // Only relevant if rent is checked
});

type FormSchemaType = z.infer<typeof FormSchema>;

export function MacroCard() {
  const cashflow = useSelector((state: any) => state.cashflow.tableData);
  const transaction = useSelector((state: any) => state.transactions?.transaction);
  const debt = useSelector((state: any) => state.cashflow.debt);
  const [loading, setLoading] = useState(true); // Manage loading state
  const [error, setError] = useState<string | null>(null); // Manage error state
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();

  console.log(cashflow);

  const [growthRate, setGrowthRate] = useState(cashflow.growthRate * 100);
  const [ebitdaMargin, setEbitdaMargin] = useState(cashflow.ebitdaMargin ?? 0);
  const [recurringRevenue, setRecurringRevenue] = useState(cashflow.recurringRevenue ?? 100);
  const [concentration, setConcentration] = useState(cashflow.concentration ?? 20);
  const [ownerInvolvement, setOwnerInvolvement] = useState(cashflow.ownerInvolvement ?? false);
  const [insuranceIncluded, setInsuranceIncluded] = useState(cashflow.insuranceIncluded ?? false);
  const [rent, setRent] = useState(cashflow.rent ?? false);
  const [rentValue, setRentValue] = useState(cashflow.rentValue ?? 0);

  // 2) Hook form setup
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      growthRate,
      ebitdaMargin,
      recurringRevenue,
      concentration,
      ownerInvolvement,
      insuranceIncluded,
      rent,
      rentValue,
    },
  });
  const fetchCashflow = async () => {
    try {
      // @ts-ignore
      const { cashFlow, growthRate, inflationRate } = await dispatch(fetchCashflowData(transaction.id, debt));
      console.log(cashFlow);
      // console.log(growthRate);
      // const data = await getTasks();
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCashflow();
  }, [dispatch, transaction, debt]);
  // 3) On submit handle scenario
  const onSubmit = async (data: FormSchemaType) => {
    try {
      await dispatch(updateDealParam(data));
      toast.success("Scenario saved successfully!");
    } catch (err) {
      toast.error("Failed to save scenario. Please try again.");
    }
  };

  // 4) We watch `rent` to conditionally show the rent input
  const watchRent = form.watch("rent");

  // Example of computing an "estimated valuation range" for demonstration
  // In real usage, you might compute this based on the form inputs.

  // Calculate possible prices based on earnings and revenue
  const earningsPrice = transaction.earningsMultiple * transaction.cashFlow;
  const revenuePrice = transaction.revenueMultiple * transaction.revenue;

  // Calculate min and max values
  const minPrice = Math.floor(Math.max(Math.min(earningsPrice, revenuePrice), 0)); // Round down
  const maxPrice = Math.floor(Math.max(earningsPrice, revenuePrice)); // Round down

  const lowerBound = minPrice / 1000 / 1000;
  const upperBound = maxPrice / 1000 / 1000;

  return (
    <Card className="w-full">
      <CardHeader className="flex justify-between items-baseline">
        <CardTitle className="mb-0">Scenario Macro Parameter</CardTitle>

        <div className="flex justify-between items-center mt-0 w-full">
          <CardDescription className="mt-0">Customize your scenario parameters to forecast the business value. We've provided starting values based on the provided data</CardDescription>
          <div className="text-right">
            {/* 2) Top-right estimated range valuation */}
            {/* <span className="text-sm font-semibold">
              Estimated Valuation Range:{" "}
              <span className="">
                ${lowerBound.toLocaleString()}m - ${upperBound.toLocaleString()}m
              </span>
            </span> */}
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <div className="w-full flex justify-end">
              <Button type="submit" className="">
                Save Scenario
              </Button>
            </div>
            {/* 1) Annual growth rate slider */}
            <FormField
              control={form.control}
              name="growthRate"
              render={({ field }) => (
                <FormItem>
                  <div className="flex items-center mb-2 gap-2 ">
                    <FormLabel>Annual Growth Rate (%)</FormLabel>
                    <Tooltip icon={true} content="Adjust the annual growth rate. This represents the expected percentage increase in revenue each year." />
                  </div>
                  <FormControl>
                    <Slider min={-20} max={100} step={1} value={[field.value]} onValueChange={(val) => field.onChange(val[0])} className="max-w-md" />
                  </FormControl>
                  <FormMessage />
                  <p className="mt-1 text-sm text-muted-foreground">Current: {field.value.toFixed(0)}%</p>
                </FormItem>
              )}
            />

            {/* 2) EBITDA Margin Improvement */}
            <FormField
              control={form.control}
              name="ebitdaMargin"
              render={({ field }) => (
                <FormItem>
                  <div className="flex items-center mb-2 gap-2">
                    <FormLabel>EBITDA Margin Improvement (%)</FormLabel>
                    <Tooltip icon={true} content="Adjust the EBITDA margin improvement. This represents the expected percentage change in EBITDA margin." />
                  </div>
                  <FormControl>
                    <Slider min={-20} max={20} step={1} value={[field.value]} onValueChange={(val) => field.onChange(val[0])} className="max-w-md" />
                  </FormControl>
                  <FormMessage />
                  <p className="mt-1 text-sm text-muted-foreground">Current: {field.value.toFixed(0)}%</p>
                </FormItem>
              )}
            />

            {/* 3) Recurring Revenue */}
            <FormField
              control={form.control}
              name="recurringRevenue"
              render={({ field }) => (
                <FormItem>
                  <div className="flex items-center mb-2 gap-2">
                    <FormLabel>Recurring Revenue (%)</FormLabel>
                    <Tooltip icon={true} content="Specify the percentage of revenue that is recurring each year. This indicates the stability of revenue streams." />
                  </div>
                  <FormControl>
                    <Slider min={0} max={100} step={1} value={[field.value]} onValueChange={(val) => field.onChange(val[0])} className="max-w-md" />
                  </FormControl>
                  <FormMessage />
                  <p className="mt-1 text-sm text-muted-foreground">Current: {field.value.toFixed(0)}%</p>
                </FormItem>
              )}
            />

            {/* 4) Concentration of top 5 customers */}
            <FormField
              control={form.control}
              name="concentration"
              render={({ field }) => (
                <FormItem>
                  <div className="flex items-center mb-2 gap-2">
                    <FormLabel>Top 5 Customer Concentration (%)</FormLabel>
                    <Tooltip icon={true} content="Specify the percentage of revenue generated by the top 5 customers. This indicates the dependency on key customers." />
                  </div>
                  <FormControl>
                    <Slider min={0} max={100} step={1} value={[field.value]} onValueChange={(val) => field.onChange(val[0])} className="max-w-md" />
                  </FormControl>
                  <FormMessage />
                  <p className="mt-1 text-sm text-muted-foreground">Current: {field.value.toFixed(0)}%</p>
                </FormItem>
              )}
            />

            {/* 5) Checkboxes: Owner Involvement, Insurance Included, Rent */}
            <div className="grid grid-cols-3 gap-4">
              {/* Owner Involvement */}
              <FormField
                control={form.control}
                name="ownerInvolvement"
                render={({ field }) => (
                  <FormItem className="flex items-center space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                    <FormLabel>Owner Involvement</FormLabel>
                    <Tooltip icon={true} content="Indicate if the owner is actively involved in the business operations." />
                  </FormItem>
                )}
              />

              {/* Insurance Included */}
              <FormField
                control={form.control}
                name="insuranceIncluded"
                render={({ field }) => (
                  <FormItem className="flex items-center space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                    <FormLabel>Insurance Included</FormLabel>
                    <Tooltip icon={true} content="Indicate if insurance is included in the business expenses." />
                  </FormItem>
                )}
              />

              {/* Rent */}
              <FormField
                control={form.control}
                name="rent"
                render={({ field }) => (
                  <FormItem className="flex items-center space-x-2 space-y-0 mt-0">
                    <FormControl>
                      <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                    <FormLabel>Add Rent</FormLabel>
                    <Tooltip icon={true} content="Indicate if rent should be added separately as a new business expenses." />
                  </FormItem>
                )}
              />
            </div>

            {/* If rent is ticked, show an absolute input */}
            {watchRent && (
              <FormField
                control={form.control}
                name="rentValue"
                render={({ field }) => (
                  <FormItem className="mt-2 max-w-[15%] ml-auto">
                    <FormLabel>Annual Rent Value</FormLabel>
                    <FormControl>
                      <Input type="number" placeholder="Enter rent amount" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
          </form>
        </Form>
      </CardContent>

      <CardFooter>{/* You could place additional info or disclaimers here if needed */}</CardFooter>
    </Card>
  );
}
