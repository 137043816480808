import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter, DialogClose } from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import { useForm, Controller } from "react-hook-form";
import { FileUploader } from "../../../components/file_upload/file-uploader";
import { backendClient } from "../../../api/backend";
import Spinner from "../../../components/ui/Spinner";

interface FileInputDialogProps {
  open: boolean;
  onClose: () => void;
  onFileProcessed: (dealData: any) => void;
}

export function FileInputDialog({ open, onClose, onFileProcessed }: FileInputDialogProps) {
  const { control, handleSubmit, reset } = useForm();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      setIsFileUploaded(false);
      reset();
    }
  }, [open, reset]);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const file = data.file[0];
      const dealData = await backendClient.importTransactionFromFile(file);
      onFileProcessed(dealData);
      onClose();
    } catch (error) {
      console.error("Error processing file:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Upload File</DialogTitle>
          <DialogDescription>Upload a file to extract deal details.</DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="file"
            control={control}
            render={({ field }) => (
              <FileUploader
                maxFileCount={1}
                maxSize={32 * 1024 * 1024}
                onValueChange={(uploadedFiles) => {
                  field.onChange(uploadedFiles);
                  setIsFileUploaded(uploadedFiles.length > 0);
                }}
                isLoading={isLoading}
              />
            )}
          />
          <DialogFooter style={{ marginTop: "16px" }}>
            <DialogClose asChild>
              <Button variant="ghost" disabled={isLoading}>
                Cancel
              </Button>
            </DialogClose>
            <Button type="submit" disabled={!isFileUploaded || isLoading}>
              {isLoading ? (
                <>
                  Importing <Spinner size={16} />
                </>
              ) : (
                "Import"
              )}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
