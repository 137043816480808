// OverviewMetricsTable.tsx
import * as React from "react";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "../../../components/ui/table"; // <-- Adjust path to where your Shadcn UI table is exported from
import { Card, CardDescription, CardHeader, CardContent, CardTitle } from "../../../components/ui/card";

// Minimal type for each row
interface RowData {
  label: string;
  values: (string | number)[];
}

interface OverviewMetricsTableProps {
  title?: string;
  columns: number[]; // the list of years
  rows: RowData[]; // each row's label + array of values
  showPercents?: boolean; // toggle whether numeric fractions are shown as %
}

/**
 * Renders a table with row labels on the left,
 * and one column for each year in `columns`.
 * `rows` must have the same length of `values` as `columns.length`.
 */
export function OverviewMetricsTable({ title = "Overview Table", columns, rows, showPercents = true }: OverviewMetricsTableProps) {
  return (
    <Card className="max-w-full col-span-2">
      <CardHeader>{title && <CardTitle>{title}</CardTitle>}</CardHeader>
      <CardContent>
        <Table>
          <TableCaption>Metrics overview by year</TableCaption>
          <TableHeader>
            <TableRow>
              <TableHead className="w-1/3">Line Item</TableHead>
              {columns.map((year) => (
                <TableHead key={year} className="text-right">
                  {year}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>

          <TableBody>
            {rows.map((row) => (
              <TableRow className="hover:bg-gray-100" key={row.label}>
                <TableCell className="font-medium">{row.label}</TableCell>
                {row.values.map((val, idx) => {
                  if (typeof val === "string") {
                    // e.g. "-", "#DIV/0!"
                    return (
                      <TableCell key={idx} className="text-right">
                        {val}
                      </TableCell>
                    );
                  } else {
                    // numeric
                    const numberVal = Number(val);
                    // If it's between 0 and 1, and we're showing percents,
                    // treat it as a fraction that should be shown as a percentage.
                    if (showPercents && numberVal >= 0 && numberVal < 1) {
                      return (
                        <TableCell key={idx} className="text-right">
                          {(numberVal * 100).toFixed(1)}%
                        </TableCell>
                      );
                    } else {
                      // normal numeric formatting
                      return (
                        <TableCell key={idx} className="text-right">
                          {numberVal.toLocaleString()}
                        </TableCell>
                      );
                    }
                  }
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
