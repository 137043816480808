import { AppSidebar } from "../../components/components/app-sidebar";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "../../components/ui/breadcrumb";
import { Separator } from "../../components/ui/separator";
import { SidebarInset, SidebarProvider, SidebarTrigger } from "../../components/ui/sidebar";
import Cards from "./components/cards";
import ProgressPrice from "./ProgressPrice";
import CardTab from "./components/tab_b_s";
import MapCard from "./components/map";
import CapitalStructure from "./components/capitalstructure";
import YearCashflow from "./components/yearcashflow";
import Cashflow, { CashCard } from "../CashflowScreen/components/Cashflow";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { extractFinancialItem } from "../../utils/utils";
import { useState, useRef, useEffect, useMemo } from "react";
import IRR from "../CapexPlanScreen/_irrResult";
import Payback from "../CapexPlanScreen/_payback";
import React from "react";
import Spinner from "../../components/ui/Spinner";
import { useTranslation } from "react-i18next";
import { backendClient } from "../../api/backend";
import { MySourcesUsesLayout } from "./components/DebtSU";
import { DscrCard } from "./components/DscrCard";
import { OverviewMetricsTable } from "./components/OverviewTable";

function calculateFinancialRatios(transaction, fcf, debt, purchasePrice, new_debt, savedsu) {
  // Initialize with safe default values
  let covenants = false;
  let dscr = [];
  let ltv = [];
  let minDSCR = "N/A";
  let minLTV = "N/A";

  // Safety check for required inputs
  if (!transaction || !fcf || fcf.length === 0) {
    return { covenants, dscr, ltv, minDSCR, minLTV };
  }

  // Check if we should use savedsu tranches
  const useSavedsuTranches = savedsu && savedsu.tranches && savedsu.tranches.length > 0;

  if (fcf.length > 0 && transaction.uploadStatus === "completed") {
    covenants = true;

    try {
      // First try to use savedsu.schedule if available
      if (useSavedsuTranches && savedsu.schedule && savedsu.schedule.length > 0) {
        dscr = savedsu.schedule.map((scheduleEntry) => {
          return scheduleEntry.noi / scheduleEntry.debtService;
        });
      } else if (debt && debt.schedule && debt.schedule.length > 0) {
        // Fall back to debt.schedule if savedsu.schedule is not available
        dscr = fcf.map((cf, i) => {
          if (debt.schedule && i < debt.schedule.length) {
            return cf["Free Cash Flow"] / debt.schedule[i].debtService;
          } else {
            return "N/A";
          }
        });
      } else {
        // If neither schedule is available, calculate DSCR directly from FCF
        dscr = fcf.map((cf) => {
          // Assuming debt service is approximately 10% of the debt balance per year
          // This is a fallback approximation when no schedule is available
          const estimatedDebtService = new_debt * 0.1;
          return estimatedDebtService > 0 ? cf["Free Cash Flow"] / estimatedDebtService : 0;
        });
      }

      // Calculate LTV using the appropriate debt value
      ltv = purchasePrice > 0 ? [new_debt / purchasePrice] : [0];

      // Filter out 'N/A' values and find the minimum DSCR and LTV
      const validDSCR = dscr.filter((value) => value !== "N/A" && typeof value === "number");
      const validLTV = ltv.filter((value) => value !== "N/A" && typeof value === "number");

      minDSCR = validDSCR.length > 0 ? Math.min(...validDSCR) : "N/A";
      minLTV = validLTV.length > 0 ? Math.min(...validLTV) : "N/A";
      if (minDSCR === "N/A") covenants = false;
    } catch (error) {
      console.error("Error calculating financial ratios:", error);
      covenants = false;
      dscr = [];
      ltv = [];
      minDSCR = "N/A";
      minLTV = "N/A";
    }
  } else {
    dscr = [];
    ltv = [];
    minDSCR = "N/A";
    minLTV = "N/A";
    covenants = false;
  }

  return {
    covenants,
    dscr,
    ltv,
    minDSCR,
    minLTV,
  };
}
const calculateUnleveredIRR = (cashFlows) => {
  // Step 1: Sort the cash flows by year
  cashFlows.sort((a, b) => a.year - b.year);

  // Step 2: Create arrays for values and dates
  const values = cashFlows.map((cf) => Number(cf.freeCashFlow));
  const dates = cashFlows.map((cf) => (cf.year - cashFlows[0].year) * 365);
  // Step 3: Call the IRR function
  console.log(values);
  return IRR(values, 0.1) * 100;
};
const calculatePaybackPeriod = (cashFlows, investment) => {
  // Step 1: Sort the cash flows by year
  cashFlows.sort((a, b) => a.year - b.year);

  // Step 2: Extract free cash flows into an array
  const cashFlowValues = cashFlows.map((cf) => Number(cf.freeCashFlow));
  const pb = Payback(cashFlowValues, 0);
  return pb;
};

// Calculate the cash on cash return similar to payback above :
// Cash on Cash Return = (Total Cash Flow / Total Investment)
// Total Cash Flow = Sum of all cash flows
// Total Investment = Initial Investment
const calculateCashOnCashReturn = (cashFlows) => {
  // Step 1: Sort the cash flows by year
  cashFlows.sort((a, b) => a.year - b.year);

  // Step 2: Extract free cash flows into an array
  const cashFlowValues = cashFlows.map((cf) => Number(cf.freeCashFlow));

  // Step 3: Calculate the total cash flow if they are positive
  const totalCashFlow = cashFlowValues.reduce((acc, value) => (value > 0 ? acc + value : acc), 0);

  // Step 4: Calculate the investment cashflow (sum of negative cash flows)

  const investmentCashFlow = cashFlowValues.reduce((acc, value) => (value < 0 ? acc + value : acc), 0);

  // Step 5: Calculate the cash on cash return

  return totalCashFlow / Math.abs(investmentCashFlow);
};

function mapCashFlow(data, investment, exitValue) {
  // Handle empty data case
  if (!data || data.length === 0) {
    // Return a minimal cash flow with just the investment and exit in consecutive years
    return [
      {
        year: 0,
        freeCashFlow: investment,
        isForecasted: false,
      },
      {
        year: 1,
        freeCashFlow: exitValue,
        isForecasted: false,
      },
    ];
  }

  // Extract years and sort to determine the range
  const years = data.map((item) => item.year).sort((a, b) => a - b);
  const firstYear = years[0];
  const lastYear = years[years.length - 1];

  // Prepare the transformed list
  const mappedCashFlows = [];

  // Add the investment year (1 year before first year)
  mappedCashFlows.push({
    year: firstYear - 1,
    freeCashFlow: investment,
    isForecasted: false, // Investment typically not forecasted
  });

  // Map existing data
  data.forEach((item) => {
    mappedCashFlows.push({
      year: item.year,
      freeCashFlow: item["Free Cash Flow"] ?? item.freeCashFlow,
      isForecasted: item.isForecasted,
    });
  });

  // Add the exit year (1 year after last year)
  mappedCashFlows.push({
    year: lastYear + 1,
    freeCashFlow: exitValue,
    isForecasted: false, // Exit typically not forecasted
  });

  return mappedCashFlows;
}

function getLastYearValue(data) {
  if (!data || data.length === 0) {
    return 0; // Return a safe default value instead of throwing an error
  }

  // Sort the data by year in ascending order
  const sortedData = data.sort((a, b) => a.year - b.year);

  // Get the last year object (largest year)
  const lastYearObject = sortedData[sortedData.length - 1];

  return lastYearObject["Revenue"] || 0; // Add fallback to 0 if Revenue is undefined
}
function getLastYearDebt(data) {
  if (!data || data.length === 0) {
    return 0; // Return a safe default value instead of throwing an error
  }

  // Sort the data by year in ascending order
  const sortedData = data.sort((a, b) => a.year - b.year);

  // Get the last year object (largest year)
  const lastYearObject = sortedData[sortedData.length - 1];

  return lastYearObject["Debt Ending Balance"] || 0; // Add fallback to 0 if Debt Ending Balance is undefined
}
const extractLast = (cashflow, itemname) => {
  if (!cashflow || cashflow.length === 0) return null;
  try {
    const item = extractFinancialItem(cashflow, itemname, null, false);
    if (!item || item.length === 0) return null;
    const last = item[item.length - 1];
    return last;
  } catch (error) {
    console.error(`Error extracting ${itemname}:`, error);
    return null;
  }
};
export default function Overview() {
  const { t } = useTranslation();
  const transaction = useSelector((state) => state.transactions.transaction);
  const transactions = useSelector((state) => state.transactions.transactions);
  const savedsu = useSelector((state) => state.cashflow.tranches);
  const organization = useSelector((state) => state.auth.organization);

  console.log(savedsu);
  const tr = transactions.filter((e) => {
    return e.id === transaction.id;
  })[0];
  const cashflow = useSelector((state) => state.cashflow.tableData.cashFlow);
  console.log(cashflow);
  const pricing = useSelector((state) => state.cashflow.purchasePrice) || {};
  const d = useSelector((state) => state.cashflow) || {};
  // console.log(d);
  const minideal = useSelector((state) => state.cashflow.miniDeal);
  const [multiple, setmultiple] = useState(pricing.multiple || transaction.revenueMultiple); // Default purchase price
  const debt = useSelector((state) => state.cashflow.debt);

  // Determine if we should use savedsu tranches for debt calculations
  const useSavedsuTranches = savedsu && savedsu.tranches && savedsu.tranches.length > 0;

  // Calculate total debt from savedsu tranches if available, excluding Revolver
  const savedsuTotalDebt = useSavedsuTranches
    ? savedsu.tranches
        .filter((tranche) => tranche.name !== "Revolver") // Exclude Revolver from total debt
        .reduce((sum, tranche) => sum + (tranche.principal || 0), 0)
    : 0;

  // Track Revolver amount separately if available
  const revolverAmount = useSavedsuTranches ? savedsu.tranches.find((tranche) => tranche.name === "Revolver")?.principal || savedsu.revolverAmount || 0 : 0;

  // if (!transaction || !cashflow || !minideal || debt) return
  const hasMinidealEntryValue = minideal.entryValue && minideal.projections;
  const status = transaction.uploadStatus === "completed" && cashflow;

  // console.log(status);

  // Extract LTM_CFADS (Last Twelve Months Cash Flow Available for Debt Service)
  const LTM_CFADS = cashflow ? extractLast(cashflow, "Free Cash Flow")?.["Free Cash Flow"] : null;

  // Calculate maximum debt based on debt object or default to 0
  const maximumDebt = useSavedsuTranches ? savedsuTotalDebt : debt?.maximumDebt || 0;

  // Initialize noi from various sources, similar to Debt.js
  const noi = savedsu?.ltmEbitda || debt?.noi || LTM_CFADS || (minideal.projections && minideal.projections.length > 0 && Number(minideal?.projections[0]?.cashFlow)) || transaction.cashFlow || 0;

  let fcf;
  if (cashflow || hasMinidealEntryValue) {
    // console.log(cashflow, minideal.entryValue);

    fcf = status
      ? extractFinancialItem(cashflow, "Free Cash Flow", null, true)
      : hasMinidealEntryValue // ✅ Ensure `minideal.entryValue` exists before accessing `.projections`
        ? minideal.projections.map((e) => ({
            year: e.year,
            freeCashFlow: Number(e.cashFlow),
          }))
        : [];
  } else {
    fcf = [];
  }
  // console.log(fcf);
  // const hasMinidealEntryValue = minideal?.entryValue !== undefined && minideal?.entryValue !== null;
  const hasProjections = Array.isArray(minideal?.projections) && minideal.projections.length > 0;

  const rev_pro = status
    ? extractFinancialItem(cashflow, "Revenue", null, true)
    : hasProjections // ✅ Ensure projections exist before filtering/mapping
      ? minideal.projections
          .filter((e) => e.revenue) // ✅ Ensure revenue exists before accessing it
          .map((e) => ({
            year: e.year,
            Revenue: Number(e.revenue),
          }))
      : []; // ✅ Default to an empty array if projections are missing

  // Prioritize debt from savedsu if tranches are available
  let new_debt = useSavedsuTranches ? savedsuTotalDebt : (debt && debt?.maximumDebt ? (debt.customDebt !== 0 ? debt.customDebt : debt.maximumDebt) : 0) + (status ? extractLast(cashflow, "Debt Starting Balance")["Debt Starting Balance"] : minideal.debt);

  // console.log(minideal);
  // console.log(minideal);
  const revenue = status ? extractLast(cashflow, "Revenue")["Revenue"] : transaction.revenue;

  const ebitda = status ? extractLast(cashflow, "Adjusted EBITDA")["Adjusted EBITDA"] : transaction.ebitda;

  // Use savedsu debt for old_debt if available
  const old_debt = useSavedsuTranches ? savedsu.debtOutstanding || 0 : status ? extractLast(cashflow, "Debt Starting Balance")["Debt Starting Balance"] : 0;

  const earnings = status ? extractLast(cashflow, "Taxable Income")["Taxable Income"] + extractLast(cashflow, "Tax")["Tax"] : transaction.cashFlow;

  const earningsPrice = transaction.earningsMultiple * earnings || transaction.cashFlow;
  const revenuePrice = transaction.revenueMultiple * revenue || transaction.revenue;

  // Calculate min and max values
  const minPrice = Math.floor(Math.max(Math.min(earningsPrice, revenuePrice), 0)); // Round down and cap at 0
  const maxPrice = Math.floor(Math.max(earningsPrice, revenuePrice));
  const lowerBound = minPrice;
  const upperBound = maxPrice;

  // Calculate mean (mu) and standard deviation (sigma)
  const mu = (upperBound + lowerBound) / 2;
  // console.log(minideal);
  // console.log(maxPrice);
  const [purchasePrice, setPurchasePrice] = useState(pricing?.purchasePrice || minideal.entryValue || maxPrice);
  useEffect(() => {
    setPurchasePrice(pricing?.purchasePrice || minideal.entryValue || maxPrice);
  }, [transaction, minideal]);
  // Default purchase price

  const sigma = (upperBound - lowerBound) / 4; // Approximation for 1 standard deviation

  // Calculate 3 standard deviations of the mean and cap at zero
  const lowerLimit = Number(Math.max(mu - 3 * sigma, 0).toFixed(0));
  const upperLimit = Math.max(Number(transaction.askPrice || 0) * 1.5, Number((mu + 3 * sigma).toFixed(0)));
  const investmentCashFlowData = useSelector((state) => state.cashflow.investmentCashFlowData);
  // console.log(investmentCashFlowData);
  // const debtending = extractFinancialItem(cashflow, "Debt Ending Balance", null, true);

  // Safely create cashf with fallbacks
  const cashf = status ? mapCashFlow(fcf, -purchasePrice, getLastYearValue(rev_pro) * multiple) : mapCashFlow(fcf, -purchasePrice, 0);

  // Add safe calculations with fallbacks
  let irr = 0;
  let pb = "N/A";
  let cashOnCashReturn = 0;

  try {
    if (cashf && cashf.length > 0) {
      irr = Math.round(calculateUnleveredIRR(cashf)) || 0;
      pb = calculatePaybackPeriod(cashf, 0).toFixed(2) || "N/A";
      cashOnCashReturn = calculateCashOnCashReturn(cashf) || 0;
    }
  } catch (error) {
    console.error("Error calculating financial metrics:", error);
    // Keep default values
  }

  // Add safe calculations for ratios with fallbacks to prevent division by zero
  const totalPotentialProfit = ebitda && ebitda !== 0 ? purchasePrice / ebitda : 0;
  const equityMultiple = revenue && revenue !== 0 ? purchasePrice / revenue : 0;
  // console.log(transaction);
  const state = React.useMemo(
    () => ({
      acquisitionCost: String(transaction.askPrice),
      closingCost: "20000", // Ensure correct naming to match `State` interface
    }),
    [transaction.askPrice], // Only re-calculate when `transaction.askPrice` changes
  );

  let { covenants, dscr, ltv, minDSCR, minLTV } = calculateFinancialRatios(transaction, fcf, debt, purchasePrice, new_debt, savedsu);
  // if (!transaction || !cashflow || minideal) return <Spinner />;
  const [place, setPlace] = useState([]);
  const getSimilarplace = async () => {
    const places = await backendClient.getsimilarbusiness(transaction);
    // console.log(places);
    setPlace(places);
  };
  useEffect(() => {
    if (!transaction) return;
    getSimilarplace();
  }, [transaction]);
  // console.log("old_debt", old_debt, "irr", irr, "purchasePrice", purchasePrice, "pb", pb, "new_debt", new_debt, "covenants", covenants, "dscr");

  // Convert savedsu.sourceDebts to the format expected by MySourcesUsesLayout
  const sourcesData = useMemo(() => {
    let sources = [];

    if (savedsu?.sourceDebts && savedsu.sourceDebts.length > 0) {
      // Get sources from savedsu.sourceDebts, excluding Revolver
      sources = savedsu.sourceDebts
        .filter((source) => source.name !== "Revolver") // Exclude Revolver from main sources
        .map((source) => ({
          label: source.name,
          value: source.principal || 0,
          tooltipContent: `Source of funds: ${source.name}`,
        }));
    } else if (debt?.sources) {
      // If no savedsu, try to use debt.sources
      sources = Object.entries(debt.sources)
        .filter(([name]) => name !== "Revolver") // Exclude Revolver from main sources
        .map(([name, principal]) => ({
          label: name,
          value: principal || 0,
          tooltipContent: `Source of funds: ${name}`,
        }));
    } else {
      // Default sources if nothing is available
      sources = [{ label: "Bank Debt", value: 0, tooltipContent: "Primary bank financing" }];
    }

    // Add equity as a separate source item
    const equityValue = savedsu?.newEquity !== undefined && savedsu?.newEquity !== null ? savedsu.newEquity : purchasePrice && new_debt ? Math.max(0, purchasePrice - new_debt) : 0;

    // If we don't have a specific equity value but we have purchase price and debt,
    // we can calculate equity as purchase price - debt
    const calculatedEquity = equityValue;

    // Check if there's already an equity item in the sources
    const hasEquityItem = sources.some((source) => source.label.toLowerCase().includes("equity"));

    // Only add equity if it doesn't already exist
    if (!hasEquityItem) {
      sources.push({
        label: "Equity Injection",
        value: calculatedEquity,
        tooltipContent: "Owner's equity contribution",
      });
    }

    // Add Revolver as a separate item if it exists (will be displayed separately in the UI)
    if (revolverAmount > 0) {
      sources.push({
        label: "Revolver (Undrawn)",
        value: revolverAmount,
        tooltipContent: "Revolver credit line (not included in total sources)",
        isRevolver: true, // Flag to identify it as a Revolver
      });
    }

    return sources;
  }, [savedsu, debt, purchasePrice, new_debt, revolverAmount]);

  // Convert savedsu.uses to the format expected by MySourcesUsesLayout
  const usesData = useMemo(() => {
    if (savedsu?.uses && savedsu.uses.length > 0) {
      // If savedsu.uses is an array (from DebtBuilder)
      return savedsu.uses.map((use) => ({
        label: use.label || getUseLabelFromKey(use.key),
        value: use.value || 0,
        tooltipContent: `Use of funds: ${use.label || getUseLabelFromKey(use.key)}`,
        readOnly: use.readOnly,
      }));
    } else if (debt?.uses) {
      // If debt.uses is an object
      return Object.entries(debt.uses).map(([key, value]) => ({
        label: getUseLabelFromKey(key),
        value: value || 0,
        tooltipContent: `Use of funds: ${getUseLabelFromKey(key)}`,
        readOnly: key === "sbaGtyFee",
      }));
    } else {
      // Default uses if nothing is available
      return [
        { label: "Business Acquisition", value: 0, tooltipContent: "Cost to acquire the business" },
        { label: "Working Capital", value: 0, tooltipContent: "Funds for day-to-day operations" },
      ];
    }
  }, [savedsu, debt]);

  // Helper function to get label from key (copied from Debt.js)
  const getUseLabelFromKey = (key) => {
    const labelMap = {
      businessAcquisition: "Business Acquisition",
      realEstate: "Real Estate",
      equipmentAndMachinery: "M&E Purchase",
      transactionExpense: "Transaction Expense",
      sbaGtyFee: "SBA Gty Fee",
      workingCapital: "Working Capital",
    };
    return labelMap[key] || key;
  };

  // Table Props :
  // 2) For each line item, create an array of { year, value } objects
  //    e.g. get Revenue, Gross Profit, etc.
  let revenueArr = [];
  let grossProfitArr = [];
  let noiArr = [];
  let capexArr = [];
  let debtServiceArr = [];
  let sellerDebtArr = [];
  let otherDebtArr = [];
  let ebitdaArr = [];
  let debtStartingBalanceArr = [];
  let debtEndingBalanceArr = [];
  let interestExpenseArr = [];
  let principalPaymentArr = [];
  let freeCashFlowArr = [];
  let uniqueYears = [];
  let rows = [];

  // Only extract financial metrics if cashflow data is available
  if (cashflow && Array.isArray(cashflow) && cashflow.length > 0) {
    try {
      // Extract actual periods only (not forecasted)
      const actualCashflow = cashflow.filter((item) => item.isForecasted === false);

      // Only proceed if we have actual cashflow data
      if (actualCashflow.length > 0) {
        // Use only actual periods (false for isForecasted parameter in the last argument)
        revenueArr = extractFinancialItem(cashflow, "Revenue", null, false) || [];
        // Extract costs from cashflow instead of directly using Gross Profit
        const costsArr = extractFinancialItem(cashflow, "Costs", null, false) || [];

        // Calculate gross profit as revenue - costs instead of directly extracting it
        grossProfitArr = revenueArr.map((revItem) => {
          const year = revItem.year;
          const revenue = revItem.Revenue || 0;

          // Find matching cost for this year
          const costItem = costsArr.find((cost) => cost.year === year);
          const cost = costItem ? Math.abs(costItem.Costs || 0) : 0; // Ensure costs are positive for subtraction

          return {
            year: year,
            "Gross Profit": revenue - cost,
            isForecasted: revItem.isForecasted,
          };
        });

        // Keep the rest of the arrays as they were
        noiArr = [];
        capexArr = [];
        debtServiceArr = [];
        sellerDebtArr = [];
        otherDebtArr = [];
        ebitdaArr = extractFinancialItem(cashflow, "Adjusted EBITDA", null, false) || [];
        debtStartingBalanceArr = [];
        debtEndingBalanceArr = extractFinancialItem(cashflow, "Debt Ending Balance", null, false) || [];
        interestExpenseArr = [];
        principalPaymentArr = [];
        freeCashFlowArr = [];

        // 3) Gather all unique years that appear in the above arrays
        uniqueYears = Array.from(
          new Set([
            ...revenueArr.map((r) => r.year),
            ...grossProfitArr.map((r) => r.year),
            ...costsArr.map((r) => r.year),
            ...noiArr.map((r) => r.year),
            ...capexArr.map((r) => r.year),
            ...debtServiceArr.map((r) => r.year),
            ...sellerDebtArr.map((r) => r.year),
            ...otherDebtArr.map((r) => r.year),
            ...ebitdaArr.map((r) => r.year),
            ...debtStartingBalanceArr.map((r) => r.year),
            ...debtEndingBalanceArr.map((r) => r.year),
            ...interestExpenseArr.map((r) => r.year),
            ...principalPaymentArr.map((r) => r.year),
            ...freeCashFlowArr.map((r) => r.year),
          ]),
        ).sort((a, b) => a - b);

        // Only proceed with building rows if we have years
        if (uniqueYears.length > 0) {
          // Helper function to safely map year to value
          function mapYearToValue(arr) {
            const output = {};
            arr.forEach((item) => {
              // Handle different data structures that might come from extractFinancialItem
              if (item.value !== undefined) {
                // If the item has a direct 'value' property
                output[item.year] = item.value;
              } else {
                // Find the property that contains the actual value (not year or isForecasted)
                const valueKey = Object.keys(item).find((key) => key !== "year" && key !== "isForecasted" && key.indexOf("Terms") === -1);

                if (valueKey) {
                  output[item.year] = item[valueKey];
                } else {
                  output[item.year] = 0; // Default to 0 if no value is found
                }
              }
            });
            return output;
          }

          // 4) Convert each "extractFinancialItem" array into a dictionary by year for easy lookups
          const revenueByYear = mapYearToValue(revenueArr);
          const costsByYear = mapYearToValue(costsArr);
          const grossProfitByYear = {};

          // Calculate gross profit for each year based on revenue - costs
          Object.keys(revenueByYear).forEach((year) => {
            const revenue = revenueByYear[year] || 0;
            const costs = costsByYear[year] || 0;
            // Ensure costs are positive for subtraction
            grossProfitByYear[year] = revenue - Math.abs(costs);
          });

          const noiByYear = mapYearToValue(noiArr);
          const capexByYear = mapYearToValue(capexArr);
          const debtServiceByYear = mapYearToValue(debtServiceArr);
          const sellerDebtByYear = mapYearToValue(sellerDebtArr);
          const otherDebtByYear = mapYearToValue(otherDebtArr);
          const ebitdaByYear = mapYearToValue(ebitdaArr);
          const debtStartingBalanceByYear = mapYearToValue(debtStartingBalanceArr);
          const debtEndingBalanceByYear = mapYearToValue(debtEndingBalanceArr);
          const interestExpenseByYear = mapYearToValue(interestExpenseArr);
          const principalPaymentByYear = mapYearToValue(principalPaymentArr);
          const freeCashFlowByYear = mapYearToValue(freeCashFlowArr);

          // Calculate DSCR values for each year using savedsu.schedule if available
          const dscrByYear = {};

          if (useSavedsuTranches && savedsu.schedule && savedsu.schedule.length > 0) {
            // Use savedsu.schedule for DSCR values
            savedsu.schedule.forEach((scheduleEntry, index) => {
              // Map the schedule year to the corresponding year in uniqueYears
              // For savedsu schedule, the year is 1-indexed (year 1, 2, 3...)
              // We need to map this to the actual years in uniqueYears
              if (index < uniqueYears.length) {
                // Calculate DSCR directly from the schedule
                dscrByYear[uniqueYears[index]] = ebitdaByYear[uniqueYears[index]] / scheduleEntry.debtService;
              }
            });
          } else if (debt && debt.schedule && debt.schedule.length > 0) {
            // Fall back to debt.schedule
            debt.schedule.forEach((scheduleEntry, index) => {
              if (index < uniqueYears.length) {
                const fcf = ebitdaByYear[uniqueYears[index]] || 0;
                dscrByYear[uniqueYears[index]] = fcf / scheduleEntry.debtService;
              }
            });
          } else {
            // If no schedule is available, calculate DSCR from FCF and debt service
            uniqueYears.forEach((year) => {
              const fcf = ebitdaByYear[year] || 0;
              // Define debtServiceByYear here since it's used before finalDebtServiceByYear is defined
              const ds = debtServiceByYear[year] || 0;
              dscrByYear[year] = ds ? fcf / ds : 0;
            });
          }

          // Helper function to extract debt metrics from savedsu.schedule
          const extractSavedsuMetrics = (schedule) => {
            if (!schedule || !Array.isArray(schedule) || schedule.length === 0) {
              return { debtService: {}, interestPayment: {}, principalPayment: {}, debtBalance: {} };
            }

            const debtService = {};
            const interestPayment = {};
            const principalPayment = {};
            const debtBalance = {};

            schedule.forEach((entry, index) => {
              // Map to the corresponding year in uniqueYears if possible
              const year = uniqueYears[index] || index + 1;

              debtService[year] = entry.debtService;
              interestPayment[year] = entry.interestPayment;
              principalPayment[year] = entry.principalPayment;
              debtBalance[year] = entry.debtBalance;
            });

            return { debtService, interestPayment, principalPayment, debtBalance };
          };

          // Extract debt metrics from savedsu.schedule if available
          const savedsuMetrics = useSavedsuTranches && savedsu.schedule ? extractSavedsuMetrics(savedsu.schedule) : { debtService: {}, interestPayment: {}, principalPayment: {}, debtBalance: {} };

          // Use savedsu metrics or fall back to cashflow data
          const finalDebtServiceByYear = useSavedsuTranches ? savedsuMetrics.debtService : debtServiceByYear;

          const finalInterestExpenseByYear = useSavedsuTranches ? savedsuMetrics.interestPayment : interestExpenseByYear;

          const finalPrincipalPaymentByYear = useSavedsuTranches ? savedsuMetrics.principalPayment : principalPaymentByYear;

          const finalDebtEndingBalanceByYear = useSavedsuTranches ? savedsuMetrics.debtBalance : debtEndingBalanceByYear;

          // 5) Build rows for the table
          //    Each row has a `label` and an array of `values` corresponding to each unique year.
          //    We handle n.a. logic for percentages.
          rows = [
            {
              label: "Revenue",
              values: uniqueYears.map((y) => revenueByYear[y]?.toLocaleString("en-US", { style: "currency", currency: "USD" }) ?? "-"),
            },
            // {
            //   label: "Costs",
            //   values: uniqueYears.map((y) => costsByYear[y]?.toLocaleString("en-US", { style: "currency", currency: "USD" }) ?? "-"),
            // },
            {
              label: "Gross Profit",
              values: uniqueYears.map((y) => grossProfitByYear[y]?.toLocaleString("en-US", { style: "currency", currency: "USD" }) ?? "-"),
            },
            {
              // Gross Margin = Gross Profit / Revenue
              label: "Gross Margin",
              values: uniqueYears.map((y) => {
                const rev = revenueByYear[y] ?? 0;
                const gp = grossProfitByYear[y] ?? 0;
                if (!rev) return "n.a.";
                return gp / rev;
              }),
            },
            {
              label: "EBITDA",
              values: uniqueYears.map((y) => ebitdaByYear[y]?.toLocaleString("en-US", { style: "currency", currency: "USD" }) ?? "-"),
            },
            {
              label: "EBITDA Margin",
              values: uniqueYears.map((y) => {
                const rev = revenueByYear[y] ?? 0;
                const ebitda = ebitdaByYear[y] ?? 0;
                if (!rev) return "n.a.";
                return ebitda / rev;
              }),
            },
            {
              // EBITDA - Capex: If you have an "EBITDA" line, do that.
              // Or if your "Adjusted NOI" is effectively your EBITDA, adapt accordingly
              label: "EBITDA - Capex",
              values: uniqueYears.map((y) => {
                const ebitda = ebitdaByYear[y] ?? 0;
                const capex = capexByYear[y] ?? 0;
                return (ebitda - capex).toLocaleString("en-US", { style: "currency", currency: "USD" });
              }),
            },
            {
              // Adjusted NOI - Capex
              label: "Adjusted NOI - Capex",
              values: uniqueYears.map((y) => {
                const noi = noiByYear[y] ?? 0;
                const capex = capexByYear[y] ?? 0;
                return noi - capex;
              }),
            },
            {
              // Adjusted NOI - Capex % (of Revenue)
              label: "Adjusted NOI - Capex %",
              values: uniqueYears.map((y) => {
                const rev = revenueByYear[y] ?? 0;
                const noi = noiByYear[y] ?? 0;
                const capex = capexByYear[y] ?? 0;
                if (!rev) return "n.a.";
                return (noi - capex) / rev;
              }),
            },
            // {
            //   label: "Free Cash Flow",
            //   values: uniqueYears.map((y) => freeCashFlowByYear[y] ?? "-"),
            // },
            // {
            //   label: "Free Cash Flow After Debt Service",
            //   values: uniqueYears.map((y) => {
            //     const fcf = freeCashFlowByYear[y] ?? 0;
            //     const ds = finalDebtServiceByYear[y] ?? 0;
            //     return fcf - ds;
            //   }),
            // },
            // {
            //   label: "Debt Starting Balance",
            //   values: uniqueYears.map((y, index) => {
            //     if (index === 0) {
            //       // For the first year, use new_debt as the starting balance
            //       return useSavedsuTranches ? savedsuTotalDebt : debtStartingBalanceByYear[y] ?? "-";
            //     } else {
            //       // For subsequent years, use the ending balance of the previous year
            //       const prevYear = uniqueYears[index - 1];
            //       return finalDebtEndingBalanceByYear[prevYear] ?? "-";
            //     }
            //   }),
            // },
            {
              label: "Debt Ending Balance",
              values: uniqueYears.map((y) => finalDebtEndingBalanceByYear[y]?.toLocaleString("en-US", { style: "currency", currency: "USD" }) ?? "-"),
            },
            {
              label: "Interest Expense",
              values: uniqueYears.map((y) => finalInterestExpenseByYear[y]?.toLocaleString("en-US", { style: "currency", currency: "USD" }) ?? "-"),
            },
            {
              label: "Principal Payment",
              values: uniqueYears.map((y) => finalPrincipalPaymentByYear[y]?.toLocaleString("en-US", { style: "currency", currency: "USD" }) ?? "-"),
            },
            {
              label: "Debt Service",
              values: uniqueYears.map((y) => finalDebtServiceByYear[y]?.toLocaleString("en-US", { style: "currency", currency: "USD" }) ?? "-"),
            },
            {
              label: "DSCR",
              values: uniqueYears.map((y) => {
                const dscrValue = dscrByYear[y];
                if (dscrValue === undefined || dscrValue === 0) return "n.a.";
                return `${dscrValue.toFixed(2)}x`;
              }),
            },
          ];
        }
      } else {
        // If no actual cashflow data, set uniqueYears to empty array
        uniqueYears = [];
      }
    } catch (error) {
      console.error("Error processing financial metrics:", error);
      // Keep default empty arrays/objects
    }
  }
  // console.log("covenants", covenants);
  return (
    <SidebarInset>
      <header className="flex h-16 shrink-0 items-center gap-2">
        <div className="flex items-center gap-2 px-4">
          <SidebarTrigger className="-ml-1" />
          <Separator orientation="vertical" className="mr-2 h-4" />
          <Breadcrumb>
            <BreadcrumbList>
              {/* <BreadcrumbItem className="hidden md:block">
                <BreadcrumbLink href="#">Building Your Application</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator className="hidden md:block" /> */}
              <BreadcrumbItem>
                <BreadcrumbPage>
                  {
                    // @ts-ignore
                    t("overview.overview")
                  }
                </BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </header>

      <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
        <Cards transaction={tr ? tr : transaction} earnings={earnings} />
        <div className="grid auto-rows-min gap-4 grid-cols-2">
          {organization.type === "lender" && (
            <MySourcesUsesLayout
              sources={sourcesData}
              uses={usesData}
              transaction={transaction}
              lenderName={savedsu?.lenderName || "SBA Lender"}
              loanType={savedsu?.loanType || "SBA 7(a)"}
              principalName={transaction.principalName || "Principal"}
              phone={transaction.phone || "N/A"}
              email={transaction.email || "N/A"}
              ltmEbitda={LTM_CFADS || noi || 0}
              debtAvailable={maximumDebt || 0}
            />
          )}

          {organization.type !== "lender" && (
            <ProgressPrice
              lowerLimit={lowerLimit}
              upperLimit={upperLimit}
              newDebt={new_debt}
              irr={irr}
              cashonCashReturn={cashOnCashReturn}
              totalPotentialProfit={totalPotentialProfit}
              equityMultiple={equityMultiple}
              multiple={multiple}
              setPurchasePrice={setPurchasePrice}
              purchasePrice={purchasePrice}
              transaction={transaction}
              pricing={pricing}
              minideal={minideal}
              pb={pb}
              old_debt={old_debt}
            />
          )}
          {/* <CardTab irr={irr} pb={pb} purchasePrice={purchasePrice} old_debt={old_debt} /> */}
          {fcf.length > 0 && (
            <>
              {organization.type !== "lender" && <YearCashflow old_debt={old_debt} new_debt={new_debt} purchasePrice={purchasePrice} payback={pb} closingCost={Number(state.closingCost)} initialInvestment={0} />}
              {organization.type !== "lender" && <CapitalStructure transaction={transaction} purchasePrice={purchasePrice} newDebt={new_debt} covenants={covenants} dscr={dscr} ltv={ltv} minDSCR={minDSCR} minLTV={minLTV} />}
            </>
          )}
          {organization.type === "lender" && uniqueYears && uniqueYears.length > 0 && rows && rows.length > 0 && (
            <DscrCard
              dscrValues={uniqueYears
                .map((year) => {
                  // Find the DSCR row in the rows array
                  const dscrRow = rows.find((row) => row.label === "DSCR");
                  if (!dscrRow) return { year, value: 0 };

                  // Get the index of the year in uniqueYears
                  const yearIndex = uniqueYears.indexOf(year);
                  if (yearIndex === -1) return { year, value: 0 };

                  // Get the DSCR value from the row
                  const dscrValue = dscrRow.values[yearIndex];

                  // Extract numeric value from the formatted string (e.g., "1.25x" → 1.25)
                  const numericValue = typeof dscrValue === "string" && dscrValue !== "n.a." ? parseFloat(dscrValue.replace("x", "")) : 0;

                  return { year, value: isNaN(numericValue) ? 0 : numericValue };
                })
                .filter((item) => item.value > 0)}
            />
          )}
          {investmentCashFlowData && organization.type !== "lender" && investmentCashFlowData.length > 0 && <CashCard investmentCashFlowData={investmentCashFlowData} isCumulative={false} title={"Free Cash Flow After Debt Service"} />}
          {/* Only render OverviewMetricsTable when cashflow data and uniqueYears are available */}
          {cashflow && organization.type === "lender" && uniqueYears && uniqueYears.length > 0 && rows && rows.length > 0 && <OverviewMetricsTable title="Financial Metrics" columns={uniqueYears} rows={rows} showPercents={true} />}
          <MapCard transaction={transaction} place={place} />
        </div>
        <div className="min-h-[100vh] flex-1 rounded-xl bg-muted/50 md:min-h-min" />
      </div>
    </SidebarInset>
  );
}
