import { useEffect, useState, useCallback } from "react";
import { collection, query, where, and } from "firebase/firestore";
import { useNotifications } from "../router/NotificationsProvider";
import { db } from "../firebase";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

interface UseFirebaseDocumentsOptions {
  transactionId: string | null | undefined;
  organisationId: string | null | undefined;
  currentTasks: any[];
  onTasksChanged: () => void;
}

/**
 * Hook to monitor document statuses in Firebase and handle notifications
 *
 * This hook sets up listeners for:
 * - Income statements
 * - Balance sheets
 * - Other document types can be added as needed
 */
export function useFirebaseDocuments({ transactionId, organisationId, currentTasks, onTasksChanged }: UseFirebaseDocumentsOptions) {
  const { addQueryListener, removeListener } = useNotifications();
  const { t } = useTranslation();

  // Setup the Firebase listeners for documents
  useEffect(() => {
    if (!transactionId || !organisationId) return;

    // We'll need to remove these listeners later
    const listenersToRemove: string[] = [];

    // Set up income statements listener
    const incomeStatementsKey = `incomeStatements_${organisationId}`;
    const incomeStatementsRef = collection(db, `/deals/${transactionId}/income-statements`);
    const incomeStatementsQuery = query(incomeStatementsRef, where("status", "==", "uploaded"));

    addQueryListener({
      key: incomeStatementsKey,
      query: incomeStatementsQuery,
      onSnapshotCallback: (docs) => {
        // Create a map of tasks by ID for quick lookup
        const taskMap = new Map(currentTasks.map((task) => [task.id, task.status]));

        // Check if any document status changed
        const allMatch = docs.every((doc) => taskMap.get(doc.id) === doc.status);

        // Only update if something changed
        if (!allMatch) {
          console.log("⚠️ Income statements status changed, refreshing tasks...");
          onTasksChanged();
        } else {
          console.log("✅ No income statements status change detected.");
        }
      },
      toastConfig: (docs) => {
        // Show toasts for changed documents
        const taskMap = new Map(currentTasks.map((task) => [task.id, task.status]));
        const changedDocs = docs.filter((doc) => taskMap.get(doc.id) !== doc.status);

        if (changedDocs.length > 0) {
          return {
            // @ts-ignore
            title: t("toasts.income_statement_ready.title"),
            // @ts-ignore
            description: t("toasts.income_statement_ready.description"),
          };
        }

        return null;
      },
    });

    listenersToRemove.push(incomeStatementsKey);

    // Set up balance sheets listener
    const balanceSheetsKey = `balanceSheet_${organisationId}`;
    const balanceSheetsRef = collection(db, `/deals/${transactionId}/balance-sheets`);
    const balanceSheetsQuery = query(balanceSheetsRef, where("status", "==", "uploaded"));

    addQueryListener({
      key: balanceSheetsKey,
      query: balanceSheetsQuery,
      onSnapshotCallback: (docs) => {
        // Create a map of tasks by ID for quick lookup
        const taskMap = new Map(currentTasks.map((task) => [task.id, task.status]));

        // Check if any document status changed
        const allMatch = docs.every((doc) => taskMap.get(doc.id) === doc.status);

        // Only update if something changed
        if (!allMatch) {
          console.log("⚠️ Balance sheets status changed, refreshing tasks...");
          onTasksChanged();
        } else {
          console.log("✅ No balance sheets status change detected.");
        }
      },
      toastConfig: (docs) => {
        // Show toasts for changed documents
        const taskMap = new Map(currentTasks.map((task) => [task.id, task.status]));
        const changedDocs = docs.filter((doc) => taskMap.get(doc.id) !== doc.status);

        if (changedDocs.length > 0) {
          return {
            // @ts-ignore
            title: t("toasts.balance_sheet_ready.title"),
            // @ts-ignore
            description: t("toasts.balance_sheet_ready.description"),
          };
        }

        return null;
      },
    });

    listenersToRemove.push(balanceSheetsKey);

    // Cleanup function to remove all listeners
    return () => {
      console.log("Removing document status listeners");
      listenersToRemove.forEach((key) => removeListener(key));
    };
  }, [transactionId, organisationId, addQueryListener, removeListener, currentTasks, onTasksChanged, t]);

  // This hook doesn't return state directly, it just sets up listeners and calls onTasksChanged
  // when something changes. The component handles refreshing the task list.
  return {
    // Could add functions to manually refresh or add more document types here
  };
}
