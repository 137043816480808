import React, { useEffect, useRef, useState } from "react";

import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "../../components/ui/breadcrumb";
import { Separator } from "../../components/ui/separator";
import { Button } from "../../components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../../components/ui/dialog";
import { InfoIcon } from "lucide-react";

import { SidebarInset, SidebarProvider, SidebarTrigger } from "../../components/ui/sidebar";

import FortuneSheet from "./components/Fortune";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { fetchFinancialStatements, updateFinancialStatement } from "../../redux/actions/financialstatementAction";
import Spinner from "../../components/ui/Spinner";
import { useTranslation } from "react-i18next";
import { useDocumentStatus } from "../../hooks/useDocuments";

export default function Validation() {
  const [dimensions, setDimensions] = useState({ width: 200, height: 120 });
  const containerRef = useRef(null);
  const [totalPages, settotalPages] = useState(0);
  const [showInstructions, setShowInstructions] = useState(false);
  const location = useLocation();
  const { docId } = useParams();
  const transaction = useSelector((state: any) => state.transactions?.transaction);
  const navigate = useNavigate();
  const row = location.state?.row; //
  const preview = location.state?.preview;
  const fs_loading = useSelector((state: any) => state.financialStatement?.loading);
  const fs = useSelector((state: any) => state.financialStatement?.activeFinancialStatement);
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const parentScreen = location.state?.previous_screen;
  const parentScreenName = parentScreen
    ? parentScreen
        .split("/")
        .filter(Boolean)
        .map((segment) => segment.charAt(0).toUpperCase() + segment.slice(1))
    : "Othman Figure this out";
  // console.log(location.state);
  // console.log(parentScreen);
  const validateCallbackRef = useRef<any>(null); // A ref to hold the FortuneSheet's validate function

  const handleNextStep = async () => {
    console.log("Next PLease");
    // Call the validation function in FortuneSheet
    let info;
    if (validateCallbackRef.current) {
      info = validateCallbackRef.current(); // Trigger the FortuneSheet's validate function
    }
    if (info) {
      console.log(info);
      let type;
      if (row.type === "income-statement") {
        type = "income-statements";
      } else {
        type = "balance-sheets";
      }
      // console.log("about to run ")
      const model = { model: info.newItems.map(({ id, ...rest }) => rest) };
      const ans = await dispatch(updateFinancialStatement(transaction.id, type, row.id, model));
      navigate("/upload");
      // console.log("ans " , ans)
    }
  };
  const handleResize = () => {
    // sizes is an array of panel widths in percentage (e.g., [50, 50])
    console.log("event fired");
    if (containerRef.current) {
      const { clientWidth, clientHeight } = containerRef.current;
      console.log(clientWidth, clientHeight);
      const newWidth = clientWidth < 550 ? 200 : 200; // Use second panel's size
      const newHeight = 120;
      setDimensions({
        width: newWidth,
        height: newHeight,
      });
    }
  };
  // console.log(transaction)
  // console.log(row)
  useEffect(() => {
    if (!transaction || !row?.type) return;
    const fn = async () => {
      let type;
      if (row.type === "income-statement") {
        type = "income-statements";
      } else {
        type = "balance-sheets";
      }
      console.log("about to run ");
      const data = await dispatch(fetchFinancialStatements(transaction.id, type, row.id));
      // console.log(data)
      return data;
    };
    fn();
  }, [transaction]);
  console.log(fs_loading);
  console.log(fs);
  const { t } = useTranslation();
  if (!row?.type) {
    navigate("/upload");
  }
  return (
    <SidebarInset>
      <header className="flex h-16 shrink-0 items-center gap-2">
        <div className="flex items-center gap-2 px-4">
          <SidebarTrigger className="-ml-1" />
          <Separator orientation="vertical" className="mr-2 h-4" />
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem className="hidden md:block">
                <Link to={parentScreen}>
                  <BreadcrumbLink>{parentScreenName}</BreadcrumbLink>
                </Link>
              </BreadcrumbItem>
              <BreadcrumbSeparator className="hidden md:block" />
              <BreadcrumbItem>
                <BreadcrumbPage>Validation</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </header>
      <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
        <div className="grid auto-rows-min gap-4 md:grid-cols-1 h-full">
          <div className="hidden h-full flex-1 flex-col space-y-8 p-2 md:flex">
            <div className="flex items-center justify-between space-y-2 gap-2">
              <div className="flex flex-col justify-start mr-auto">
                <h2 className="text-2xl font-bold tracking-tight">
                  {
                    // @ts-ignore
                    t("validation.title")
                  }
                </h2>
                <p className="text-muted-foreground">
                  {
                    // @ts-ignore
                    t("validation.description")
                  }
                </p>
              </div>
              <div className="ml-auto flex gap-2">
                <Dialog open={showInstructions} onOpenChange={setShowInstructions}>
                  <DialogTrigger asChild>
                    <Button variant="ghost" className="flex items-center gap-1">
                      <InfoIcon className="h-4 w-4" />
                      Instructions
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-md">
                    <DialogHeader>
                      <DialogTitle>Validation Instructions</DialogTitle>
                      <DialogDescription>How to use the Excel-like validation interface</DialogDescription>
                    </DialogHeader>
                    <div className="space-y-4 py-4">
                      <div>
                        <h3 className="font-medium mb-1">Excel-like Interface</h3>
                        <p className="text-sm text-muted-foreground">This is an Excel-like interface that allows you to verify and validate financial data.</p>
                      </div>

                      <div>
                        <h3 className="font-medium mb-1">Totals & Formulas</h3>
                        <p className="text-sm text-muted-foreground">Totals are highlighted in grey (bold) and display Excel-like formulas. You can use these formulas to verify calculations. Only items labeled as "total" type should contain formulas.</p>
                      </div>

                      <div>
                        <h3 className="font-medium mb-1">Types of Variables</h3>
                        <p className="text-sm text-muted-foreground">The type of variables is important - there should only be one "final" type variable in the income statement. Make sure that only the totals are labeled as "total" type (with Excel formulas).</p>
                      </div>

                      <div>
                        <h3 className="font-medium mb-1">Categories</h3>
                        <p className="text-sm text-muted-foreground">Entries should be properly categorized. Make sure the categories match the entries (e.g., depreciation for depreciation entries, utilities for utilities entries, etc.).</p>
                      </div>
                    </div>
                    <DialogFooter>
                      <Button variant="outline" onClick={() => setShowInstructions(false)}>
                        Close
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
                <Button
                  onClick={() => {
                    handleNextStep();
                  }}
                >
                  {
                    // @ts-ignore
                    t("validation.validate_button")
                  }
                </Button>
              </div>
            </div>
            {/* <PdfFocusProvider>
              <ResizablePanelGroup direction="horizontal">
                <ResizablePanel> */}
            {fs_loading || !fs ? (
              <div className="flex flex-col mt-60 mx-auto h-full">
                <Spinner size={72} />
              </div>
            ) : (
              <FortuneSheet
                fs={fs}
                type={row?.type}
                lang="en"
                preview={preview}
                onValidate={(validateFunction) => {
                  validateCallbackRef.current = validateFunction; // Store the validate function in the ref
                }}
              />
            )}
          </div>
        </div>
      </div>
    </SidebarInset>
  );
}
