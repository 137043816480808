// promptActions.js

import { backendClient } from "../../api/backend";
import { SET_PROMPT, CLEAR_PROMPT, SET_OPEN_VOICE_AI } from "./types";

// Action to set the prompt
export const setPrompt = (transaction_id) => {
  return async (dispatch) => {
    const promptText = await backendClient.getoffermemo(transaction_id);
    dispatch({
      type: SET_PROMPT,
      payload: promptText?.model ?? "",
    });
    return promptText?.model ?? "";
  };
};

// Action to clear the prompt
export const clearPrompt = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_PROMPT,
    });
  };
};

export const setOpenVoiceAI = (isOpen) => ({
  type: SET_OPEN_VOICE_AI,
  payload: isOpen,
});
