// promptReducer.js

import { SET_PROMPT, CLEAR_PROMPT, SET_OPEN_VOICE_AI } from "../actions/types";

const initialState = {
  prompt: "",
  openVoiceAI: false,
};

const promptReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROMPT:
      return {
        ...state,
        prompt: action.payload,
      };
    case CLEAR_PROMPT:
      return {
        ...state,
        prompt: "",
      };
    case SET_OPEN_VOICE_AI:
      return {
        ...state,
        openVoiceAI: action.payload,
      };
    default:
      return state;
  }
};

export default promptReducer;
