import React, { useEffect } from "react";
// import { Routes, Route, useNavigate } from "react-router-dom";
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation, useNavigate } from "react-router-dom";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../redux/actions/authAction";
import Sellers from "../screens/LandingPageSMB/Sellers";
import SignInScreen from "../screens/SignInScreen/SignInScreen";

import Buyers from "../screens/LandingPageSMB/Buyers";
import Advisors from "../screens/LandingPageSMB/Advisors";
import SignUpScreen from "../screens/SignInScreen/SignUpScreen";
import Overview from "../screens/OverviewScreen/Overview";
import Audit from "../screens/AuditScreen/Audit";
import Upload from "../screens/UploadDocScreen/Upload";
import Valuation from "../screens/ValuationScreen/Valuation";
import Cashflow from "../screens/CashflowScreen/Cashflow";
import DebtPL from "../screens/DebtPLScreen/DebtPL";
import Checklist from "../screens/ChecklistScreen/Checklist";
import Debt from "../screens/Debtscreen/Debt";
import Market from "../screens/MarketScreen/Market";
import CapexPlan from "../screens/CapexPlanScreen/CapexPlan";
import Summary from "../screens/SummaryScreen/Overview";
import Pipeline from "../screens/PipelineScreen/Pipeline";
import Cim from "../screens/CimScreen/Cim";
import Select from "../screens/SelectScreen/SelectPage";
import Validation from "../screens/ValidationScreen/Validation";
import QoE from "../screens/QoEScreen/QoE";
import { AppSidebar } from "../components/components/app-sidebar";
import { SidebarProvider } from "../components/ui/sidebar";
import logoImage2 from "../assets/landingpage/logo.svg";
// import AuthenticationPage from "../screens/SigninScreen2/page";
// This component must be used inside the component tree that is a child of <Router>
import { store } from "../redux/store";
import { ScrollArea } from "../components/ui/scroll";
import { setTransaction } from "../redux/actions/transactionAction";
import { fetchCashflowData, fetchDebt, fetchPurchasePrice, fetchTranches, updatecashgraph, updateMinideal } from "../redux/actions/cashflowAction";
import Spinner from "../components/ui/Spinner";
import { NotificationsProvider } from "./NotificationsProvider";
import AskAIBox from "../components/ai-assistant/AskAIBox";

const NavBar = ({ children, logo }) => {
  const dispatch = useDispatch();

  const transaction = useSelector((state) => state.transactions.transaction);
  const minideal = useSelector((state) => state.cashflow.miniDeal);
  const cashflow = useSelector((state) => state.cashflow.tableData.cashFlow);
  const organization = useSelector((state) => state.auth.organization);

  const [loading, setLoading] = React.useState(!!transaction);
  const [minidealLoading, setMinidealLoading] = React.useState(false);
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);
  const [hasError, setHasError] = React.useState(false);

  // Function to check internet connection status
  React.useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const isMinidealEmpty = Object.keys(minideal).length === 0;
  const isCashflowEmpty = !cashflow || Object.keys(cashflow).length === 0;

  // Conditions for showing loading state
  const minidealIsLoading = transaction && isMinidealEmpty && transaction?.uploadStatus !== "completed";
  const cashflowIsLoading = transaction?.uploadStatus === "completed" && isCashflowEmpty;
  const showLoading = loading || minidealLoading || minidealIsLoading || cashflowIsLoading;

  const fetchAndSetTransactionData = React.useCallback(
    async (transactionData) => {
      if (!transactionData) {
        return;
      }

      // Set loading states based on what we're fetching
      if (isMinidealEmpty && transactionData?.uploadStatus !== "completed") {
        setMinidealLoading(true);
      }

      if (isCashflowEmpty && transactionData?.uploadStatus === "completed") {
        setLoading(true);
      }

      // If both data are already loaded, don't proceed
      if (!isMinidealEmpty && !isCashflowEmpty) {
        setLoading(false);
        setMinidealLoading(false);
        return;
      }

      try {
        if (isMinidealEmpty && transactionData?.uploadStatus !== "completed") {
          // Loading minideal data
          await dispatch(fetchDebt(transactionData.id));
          await dispatch(fetchTranches(transactionData.id));
          await dispatch(fetchPurchasePrice(transactionData.id));
          await dispatch(updateMinideal(transactionData.revenue, transactionData.ebitda, transactionData.cashFlow, transactionData.earningsMultiple, transactionData.revenueMultiple, transactionData.uploadStatus));
          setMinidealLoading(false);
        }

        if (transaction?.uploadStatus === "completed" && isCashflowEmpty) {
          // Loading cashflow data
          await dispatch(fetchDebt(transactionData.id));
          console.log("loading debt");
          await dispatch(fetchTranches(transactionData.id));
          console.log("loading tranches");
          await dispatch(fetchCashflowData(transactionData.id));
          if (transactionData?.uploadStatus === "completed" && organization && organization?.type !== "lender") {
            await dispatch(updatecashgraph());
          }
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching transaction data:", error);
        setLoading(false);
        setMinidealLoading(false);
        setHasError(true);
      }
    },
    [dispatch, minideal, cashflow, transaction, isMinidealEmpty, isCashflowEmpty],
  );

  React.useEffect(() => {
    if (transaction) {
      fetchAndSetTransactionData(transaction);
    } else {
      setLoading(false);
      setMinidealLoading(false);
    }
  }, [transaction, fetchAndSetTransactionData]);

  try {
    return (
      <ScrollArea className="flex flex-col h-full w-full">
        <SidebarProvider>
          <AppSidebar logo={logo} />
          {hasError ? (
            <div className="flex flex-col mx-auto my-auto items-center justify-center h-full text-center text-red-500">
              <p className="text-lg font-semibold">Oops! An error has occurred on our side</p>
              <p className="text-sm">Please refresh your browser. If this continues, please contact your administrator.</p>
            </div>
          ) : showLoading ? (
            <div className="flex flex-col mt-60 mx-auto h-full">
              <Spinner size={72} />
              {/* {minidealLoading && <p className="text-center mt-4">Loading minideal data...</p>} */}
            </div>
          ) : isOnline ? (
            <>
              {children}
              <div className="fixed bottom-4 right-4">
                <AskAIBox />
              </div>
            </>
          ) : (
            <div className="flex flex-col mx-auto my-auto  items-center justify-center h-full text-center text-red-500">
              <p className="text-lg font-semibold">No Internet Connection</p>
              <p className="text-sm">Please check your connection and try again.</p>
            </div>
          )}
        </SidebarProvider>
      </ScrollArea>
    );
  } catch (error) {
    console.error("Error rendering NavBar:", error);
    return (
      <div className="flex flex-col items-center justify-center h-full text-center text-red-500">
        <p className="text-lg font-semibold">Oops! An error has occurred on our side</p>
        <p className="text-sm">Please refresh your browser. If this continues, please contact your administrator.</p>
      </div>
    );
  }
};

const NavigationBarWrapper = ({ children }) => {
  const location = useLocation();
  const state = store.getState();
  const isAuthenticated = state.auth.isAuthentificated;

  // const isAuthenticated = useIsAuthenticated();

  const navigate = useNavigate();

  const pathsWhereNavigationBarShouldBeShown = [
    "/dashboard",
    "/deals",
    "/deals/hypothesis",
    "/data-import",
    "/data-import/transactions",
    "/data-import/transactions/:transactionId", // Dynamic paths should be handled differently if necessary
    "/data-import/transactions/explore",
    "/hypothesis",
    "/keyword-pairs",
    "/chat",
    "/chat/:conversationId", // Dynamic paths should be handled differently if necessary
    "/settings",
    "/demo",
    "/keyword",
    "/duediligencetemplate",
    "/questionnairetemplate",
    "/questionnairetemplate/:questionnaireId",
    "/duediligence",
    "/questionnaire",
    "/questionnaire/:workstreamId",
    "/table",
    "/table/:transactionId",
    "/overview",
    "/valuation",
    "/audit",
    "/upload",
    "/upload/:transactionId",
    "/checklist",
    "/cashflow",
    "/debt",
    "/market",
    "/capexplan",
    "/summary",
    "/pipeline",
    "/invmemo",
    "/qoe",
    "/validation",
    "/validation/:docId",
    "/debtpl",
    // Add any other paths as needed
  ];

  const shouldShowNavigationBar = (isAuthenticated || 1) && pathsWhereNavigationBarShouldBeShown.some((path) => location.pathname === path || location.pathname.startsWith(path + "/"));
  return shouldShowNavigationBar ? <NavBar logo={logoImage2}>{children}</NavBar> : children;
};

const HandleAuthCode = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { hash } = location;
    if (hash.includes("code=")) {
      // Extract the code from the hash
      const code = new URLSearchParams(hash.substring(1)).get("code");
      if (code) {
        // Here, you could dispatch this code to your global state, or perform an API call
        // For example, exchanging the code for an access token

        // Then navigate to the intended route
        navigate("/data-import", { replace: true });
      }
    }
  }, [location, navigate]);

  // This component does not render anything, or it could render a loading indicator
  return null;
};

const AppRouter = () => {
  const state = store.getState();
  const isAuthenticated = state.auth.isAuthentificated;
  // console.log("isAuthenticated", isAuthenticated);
  const dispatch = useDispatch();
  const accounts = [];

  const isLoading = useSelector((state) => state.auth.isLoading); // Adjust according to your Redux setup
  // console.log("isLoading", isLoading);
  // console.log("accounts", accounts);

  // if user authenticated, get user info
  useEffect(() => {
    if (isAuthenticated && accounts?.length > 0) {
      // console.log(accounts);
      // return;
      dispatch(getUser(accounts[0]?.idTokenClaims?.oid));
    }
  }, [isAuthenticated]);

  return (
    <Router>
      {/* {showNavigationBar && <NavigationBar selected={"data-import"} />} */}
      {/* <TokenAcquirer /> */}

      {isAuthenticated ? (
        <>
          <NavigationBarWrapper>
            <Routes>
              <Route path="/" element={<Navigate to="/buyers" replace />} />
              {/* <Route path="/sellers" element={<Sellers />} /> */}
              <Route path="/buyers" element={<Buyers />} />
              {/* <Route path="/advisors" element={<Advisors />} /> */}
              <Route path="/overview" element={<Overview />} />
              <Route path="/audit" element={<Audit />} />
              <Route path="/upload" element={<Upload />} />
              <Route path="/upload/:transactionId" element={<Select />} />
              <Route path="/validation/:docId" element={<Validation />} />
              <Route path="/valuation" element={<Valuation />} />
              <Route path="/cashflow" element={<Cashflow />} />
              <Route path="/debtpl" element={<DebtPL />} />
              <Route path="/checklist" element={<Checklist />} />
              <Route path="/debt" element={<Debt />} />
              <Route path="/market" element={<Market />} />
              <Route path="/capexplan" element={<CapexPlan />} />
              <Route path="/summary" element={<Summary />} />
              <Route path="/pipeline" element={<Pipeline />} />
              <Route path="/sign-in" element={<SignInScreen />} />
              <Route path="/sign-up" element={<SignUpScreen />} />
              <Route path="/invmemo" element={<Cim />} />
              <Route path="/qoe" element={<QoE />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </NavigationBarWrapper>
        </>
      ) : (
        <>
          <NavigationBarWrapper>
            <Routes>
              <Route path="/" element={<Navigate to="/buyers" replace />} />
              {/* <Route path="/sellers" element={<Sellers />} /> */}
              <Route path="/buyers" element={<Buyers />} />
              {/* <Route path="/advisors" element={<Advisors />} /> */}
              <Route path="/sign-in" element={<SignInScreen />} />
              <Route path="/sign-up" element={<SignUpScreen />} />
              <Route path="*" element={<Navigate to="/" replace />} />
              {/* <Route path="/overview" element={<Overview />} />
              <Route path="/audit" element={<Audit />} />
              <Route path="/upload" element={<Upload />} />
              <Route path="/valuation" element={<Valuation />} />
              <Route path="/cashflow" element={<Cashflow />} />
              <Route path="/checklist" element={<Checklist />} />
              <Route path="/debt" element={<Debt />} />
              <Route path="/market" element={<Market />} />
              <Route path="/capexplan" element={<CapexPlan />} />
              <Route path="/summary" element={<Summary />} />
              <Route path="/pipeline" element={<Pipeline />} /> */}
            </Routes>
          </NavigationBarWrapper>
        </>
      )}
    </Router>
    // </NotificationsProvider>
  );
};

export default AppRouter;
