import { useEffect, useState, useCallback } from "react";
import { collection, query, where } from "firebase/firestore";
import { useNotifications } from "../router/NotificationsProvider";
import { db } from "../firebase";
import { useDispatch } from "react-redux";
import { setTransaction } from "../redux/actions/transactionAction";
import { toast as othertoast } from "sonner";
import { useTranslation } from "react-i18next";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

interface DealDocument {
  id: string;
  name: string;
  uploadStatus: string;
  regionalBusinessAnalysisCompleted: boolean;
  businessChecklistUploaded: boolean;
  financialAuditUploaded: boolean;
  competitionSummary: any;
  offerMemorandumUploaded: boolean;
  organisationId: string;
  [key: string]: any;
}

interface UseFirebaseDealsOptions {
  transactionId: string | null | undefined;
  organisationId: string | null | undefined;
  currentTransaction: any;
  transactions: any[];
}

/**
 * Hook to monitor deals in Firebase and handle notifications
 *
 * This hook handles:
 * 1. Setting up Firebase listeners for deals
 * 2. Updating Redux when deal data changes
 * 3. Showing notifications for important changes
 * 4. Automatic cleanup of listeners
 */
export function useFirebaseDeals({ transactionId, organisationId, currentTransaction, transactions }: UseFirebaseDealsOptions) {
  const { addQueryListener, removeListener } = useNotifications();
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const { t } = useTranslation();
  const [dealsData, setDealsData] = useState<DealDocument[]>([]);

  // Track local state for UI rendering purposes
  const [uploadStatus, setUploadStatus] = useState<string | undefined>(currentTransaction?.uploadStatus);
  const [uploadMarket, setUploadMarket] = useState<boolean | undefined>(currentTransaction?.regionalBusinessAnalysisCompleted);

  // Reset local state when transaction changes
  useEffect(() => {
    if (currentTransaction) {
      setUploadStatus(currentTransaction.uploadStatus);
      setUploadMarket(currentTransaction.regionalBusinessAnalysisCompleted);
    } else {
      setUploadStatus(undefined);
      setUploadMarket(undefined);
    }
  }, [currentTransaction]);

  // Setup the Firebase listener
  useEffect(() => {
    if (!transactionId || !organisationId) return;

    const key = `deals_${organisationId}`;

    console.log(`📡 Setting up Firebase listener for deals in organization: ${organisationId}`);
    const dealsRef = collection(db, "deals");
    const q = query(dealsRef, where("organisationId", "==", organisationId));

    // Add the query listener
    addQueryListener({
      key,
      query: q,
      onSnapshotCallback: (docs) => {
        console.log("Deals updated:", docs);
        setDealsData(docs as DealDocument[]);

        // Find the transaction we're currently working with
        const updatedDeal = docs.find((d) => d.id === transactionId);

        if (updatedDeal) {
          console.log(`🔄 Found updated deal: ${updatedDeal.id}, status: ${updatedDeal.uploadStatus}`);

          // Update local state for UI rendering
          setUploadStatus(updatedDeal.uploadStatus);
          setUploadMarket(updatedDeal.regionalBusinessAnalysisCompleted);

          // Update Redux if the transaction exists
          if (currentTransaction && currentTransaction.id === updatedDeal.id) {
            const updatedTransaction = {
              ...currentTransaction,
              uploadStatus: updatedDeal.uploadStatus,
              regionalBusinessAnalysisCompleted: updatedDeal.regionalBusinessAnalysisCompleted,
              businessChecklistUploaded: updatedDeal.businessChecklistUploaded,
              financialAuditUploaded: updatedDeal.financialAuditUploaded,
              competitionSummary: updatedDeal.competitionSummary,
              offerMemorandumUploaded: updatedDeal.offerMemorandumUploaded,
            };

            if (JSON.stringify(currentTransaction) !== JSON.stringify(updatedTransaction)) {
              console.log("Transaction changed, updating Redux store:", updatedTransaction);
              dispatch(setTransaction(updatedTransaction));
            }
          }
        }
      },
      toastConfig: (docs, snapshot) => {
        // Track which notifications have been shown
        const changes = snapshot.docChanges();
        const notifiedChanges = new Map();
        const toasts = [];

        changes.forEach((change) => {
          if (change.type === "modified") {
            const docData = { id: change.doc.id, ...change.doc.data() };
            const comp = transactions.find((e) => e.id === docData.id);

            // Skip if we don't have the previous state to compare with
            if (!comp) return;

            // Init tracking for this document
            if (!notifiedChanges.has(docData.id)) {
              notifiedChanges.set(docData.id, new Set());
            }
            const docNotifications = notifiedChanges.get(docData.id);

            // Check for changes and show notifications

            // uploadStatus completed
            if (docData?.uploadStatus === "completed" && comp.uploadStatus !== "completed" && !docNotifications.has("uploadStatus")) {
              docNotifications.add("uploadStatus");
              // @ts-ignore
              othertoast(t("toasts.analysis_completed.title", { name: docData.name }), {
                // @ts-ignore
                description: t("toasts.analysis_completed.description", { name: docData.name }),
              });
            }

            // businessChecklistUploaded
            if (docData?.businessChecklistUploaded && !comp.businessChecklistUploaded && !docNotifications.has("businessChecklistUploaded")) {
              docNotifications.add("businessChecklistUploaded");
              // @ts-ignore
              othertoast(t("toasts.checklist_uploaded.title", { name: docData.name }), {
                // @ts-ignore
                description: t("toasts.checklist_uploaded.description", { name: docData.name }),
              });
            }

            // financialAuditUploaded
            if (docData?.financialAuditUploaded && !comp.financialAuditUploaded && !docNotifications.has("financialAuditUploaded")) {
              docNotifications.add("financialAuditUploaded");
              // @ts-ignore
              othertoast(t("toasts.audit_available.title", { name: docData.name }), {
                // @ts-ignore
                description: t("toasts.audit_available.description", { name: docData.name }),
              });
            }

            // regionalBusinessAnalysisCompleted
            if (docData?.regionalBusinessAnalysisCompleted && !comp.regionalBusinessAnalysisCompleted && !docNotifications.has("regionalBusinessAnalysisCompleted")) {
              docNotifications.add("regionalBusinessAnalysisCompleted");
              // @ts-ignore
              othertoast(t("toasts.market_analysis_completed.title", { name: docData.name }), {
                // @ts-ignore
                description: t("toasts.market_analysis_completed.description", { name: docData.name }),
              });
            }

            // offerMemorandumUploaded
            if (docData?.offerMemorandumUploaded && !comp.offerMemorandumUploaded && !docNotifications.has("offerMemorandumUploaded")) {
              docNotifications.add("offerMemorandumUploaded");
              // @ts-ignore
              othertoast(t("toasts.offer_memorandum_uploaded.title", { name: docData.name }), {
                // @ts-ignore
                description: t("toasts.offer_memorandum_uploaded.description", { name: docData.name }),
              });
            }

            // competitionSummary
            const hasCompetitionSummary = docData?.competitionSummary !== undefined && (Array.isArray(docData.competitionSummary) ? docData.competitionSummary.length > 0 : Object.keys(docData.competitionSummary || {}).length > 0);

            const hadCompetitionSummary = comp?.competitionSummary !== undefined && (Array.isArray(comp.competitionSummary) ? comp.competitionSummary.length > 0 : Object.keys(comp.competitionSummary || {}).length > 0);

            if (hasCompetitionSummary && !hadCompetitionSummary && !docNotifications.has("competitionSummary")) {
              docNotifications.add("competitionSummary");
              // @ts-ignore
              othertoast(t("toasts.market_analysis_completed.title", { name: docData.name }) || "Competition Summary Available", {
                // @ts-ignore
                description: t("toasts.market_analysis_completed.description", { name: docData.name }) || `Competition analysis for ${docData.name} is now available`,
              });
            }
          }
        });

        // Return null since we're using othertoast directly
        return null;
      },
    });

    // Cleanup on unmount or when transaction/org changes
    return () => {
      console.log(`Removing Firebase listener for deals_${organisationId}`);
      removeListener(key);
    };
  }, [transactionId, organisationId, addQueryListener, removeListener, t, dispatch, transactions]);

  return {
    deals: dealsData,
    uploadStatus,
    uploadMarket,
  };
}
