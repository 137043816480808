import React from "react";
import { RTVIEvent, RTVIMessage } from "@pipecat-ai/client-js";
import { useRTVIClient, useRTVIClientEvent, useRTVIClientMediaTrack, useRTVIClientTransportState, VoiceVisualizer } from "@pipecat-ai/client-react";
import { LoaderCircle, LoaderCircleIcon, MicIcon, MicOffIcon, ScreenShareIcon, Speech, TriangleAlertIcon, XIcon } from "lucide-react";
import { useCallback, useState } from "react";
import { useAppState } from "../../hooks/useAppState";
import { cn } from "../../lib/utils";
import { Button } from "../ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";
import BotReadyAudio from "./BotReadyAudio";
import ExpiryCountdown from "./ExpiryCountdown";
import { useDispatch, useSelector } from "react-redux";
import { clearPrompt, setOpenVoiceAI } from "../../redux/actions/promptAction"; // or wherever you keep it
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
interface Props {
  onChangeMode?: (isVoiceMode: boolean) => void;
  vision?: boolean;
}

const ChatControls: React.FC<Props> = ({ onChangeMode, vision = false }) => {
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const voiceAIActive = useSelector((state: any) => state.prompt.openVoiceAI);

  const { webrtcEnabled } = useAppState();

  const [isVoiceMode, setIsVoiceMode] = useState(false);
  const [isSreenShareOff, setScreenShareIsOn] = useState(true);
  const [isMicMuted, setIsMicMuted] = useState(false);

  const rtviClient = useRTVIClient();
  const transportState = useRTVIClientTransportState();

  const [endDate, setEndDate] = useState<Date | null>(null);
  const [error, setError] = useState("");
  const [processingAction, setProcessingAction] = useState(false);

  const handleConnect = useCallback(async () => {
    setIsVoiceMode(true);
    setIsMicMuted(false);
    rtviClient?.enableMic(true);
    onChangeMode?.(true);
    setEndDate(new Date(Number(rtviClient?.transportExpiry) * 1000));
  }, [onChangeMode, rtviClient]);

  const handleDisconnect = useCallback(() => {
    setIsVoiceMode(false);
    setIsMicMuted(false);
    rtviClient?.enableCam(false);
    rtviClient?.enableMic(false);
    onChangeMode?.(false);
    setEndDate(null);
    dispatch(setOpenVoiceAI(false));
    dispatch(clearPrompt());
  }, [onChangeMode, rtviClient]);

  useRTVIClientEvent(RTVIEvent.Connected, handleConnect);
  useRTVIClientEvent(RTVIEvent.Disconnected, handleDisconnect);

  const handleSwitchToTextMode = useCallback(() => {
    setIsVoiceMode(false);
    rtviClient?.disconnect();
  }, [rtviClient]);

  const handleSwitchToVoiceMode = useCallback(
    async (createIfNew = true) => {
      setIsVoiceMode(true);
      setError("");
      
      // Allow requestData to be updated before connecting
      await new Promise((resolve) => setTimeout(resolve, 100));
      
      try {
        await rtviClient?.connect();
      } catch (e) {
        console.error(e);
        setError("An error occurred while trying to start voice mode.");
        dispatch(setOpenVoiceAI(false));
        handleSwitchToTextMode();
      }
    },
    [handleSwitchToTextMode, rtviClient, dispatch],
  );

  useRTVIClientEvent(
    RTVIEvent.Error,
    useCallback(
      (message: RTVIMessage) => {
        console.error(message);
        setError("An error occurred during the voice session.");
        handleSwitchToTextMode();
      },
      [handleSwitchToTextMode],
    ),
  );

  // Toggle between cam mute and unmute in voice mode
  const handleScreenShareToggle = useCallback(() => {
    setScreenShareIsOn((muted) => {
      rtviClient?.enableScreenShare(muted);
      return !muted;
    });
  }, [rtviClient]);

  // Toggle between mic mute and unmute in voice mode
  const handleMicToggle = useCallback(() => {
    setIsMicMuted((muted) => {
      rtviClient?.enableMic(muted);
      return !muted;
    });
  }, [rtviClient]);

  const feedbackClassName = "bg-gradient-to-t from-background absolute w-full bottom-full pt-4 pb-2 flex gap-2 items-center justify-center z-10";

  const ToggledMicIcon = isMicMuted ? MicOffIcon : MicIcon;

  const camTrack = useRTVIClientMediaTrack("video", "local");

  const isConnecting = transportState === "authenticating" || transportState === "connecting" || transportState === "connected";
  React.useEffect(() => {
    if (voiceAIActive) {
      handleSwitchToVoiceMode();
    }
    // else if (!voiceAIActive && isVoiceMode) {
    //   handleSwitchToTextMode();
    // }
  }, [voiceAIActive]);
  return (
    <div className="relative w-full px-4">
      <BotReadyAudio active={isVoiceMode} />
      {!webrtcEnabled ? (
        <div className={cn(feedbackClassName, "text-destructive")}>
          <TriangleAlertIcon />
          <span>
            Missing <code>DAILY_API_KEY</code>
          </span>
        </div>
      ) : error ? (
        <div className={feedbackClassName}>
          <TriangleAlertIcon />
          <span>{error}</span>
        </div>
      ) : isConnecting ? (
        <div className={feedbackClassName}>
          <LoaderCircle className="animate-spin" />
          <span>Connecting…</span>
        </div>
      ) : transportState === "ready" ? (
        <div className={feedbackClassName}>
          <span>{isMicMuted ? "Tap to unmute" : processingAction ? "Thinking…" : "Listening"}</span>
          {endDate && (
            <div>
              <span className="select-none tabular-nums font-mono">
                <ExpiryCountdown endDate={endDate} />
              </span>
            </div>
          )}
        </div>
      ) : processingAction ? (
        <div className={feedbackClassName}>
          <LoaderCircle className="animate-spin" />
        </div>
      ) : null}
      <div className={`bg-secondary rounded-3xl flex flex-col gap-1 ${!isVoiceMode ? "" : "p-2 border border-black"}`}>
        {/* Chat Controls */}
        {!isVoiceMode ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  id="askAiButton"
                  onClick={
                    isVoiceMode
                      ? handleSwitchToTextMode
                      : () => {
                          handleSwitchToVoiceMode();
                          // if (!isSreenShareOff) {
                          //   handleScreenShareToggle();
                          // }
                        }
                  }
                  disabled={!webrtcEnabled || isConnecting}
                  size="icon"
                  variant={isVoiceMode ? "destructive" : "default"}
                  type="button"
                  className={cn("w-24")}
                >
                  {isConnecting ? (
                    <LoaderCircleIcon className="animate-spin rounded-full bg-muted text-background p-1" size={24} />
                  ) : isVoiceMode ? (
                    <XIcon size={24} />
                  ) : (
                    <>
                      <span className="ml-2">{isVoiceMode ? "End AI" : "ASK AI"}</span>
                      <Speech size={24} />
                    </>
                  )}
                </Button>
              </TooltipTrigger>
              <TooltipContent align="center" className="bg-background text-foreground shadow-sm">
                {isVoiceMode ? "End voice mode" : "Enable voice mode"}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : (
          <div className="flex gap-2 justify-between sm:grid sm:grid-cols-3">
            <div className="flex items-end gap-2">
              {/* Cam button for mute/unmute */}
              {vision && isVoiceMode && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        type="button"
                        size="icon"
                        variant="secondary"
                        disabled={!webrtcEnabled || isConnecting}
                        onClick={handleScreenShareToggle}
                        className={cn("rounded-full", {
                          "bg-primary hover:bg-primary text-primary-foreground": !isSreenShareOff,
                        })}
                      >
                        <ScreenShareIcon size={24} />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent className="bg-background text-foreground shadow-sm">{isSreenShareOff ? "Turn on screenshare" : "Turn off screenshare"}</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>

            <div className="mr-auto sm:mr-0 sm:justify-self-center">
              {/* Mic button for mute/unmute */}
              {isVoiceMode && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        type="button"
                        size="icon"
                        variant="secondary"
                        onClick={handleMicToggle}
                        className={cn("py-1 px-2 rounded-full focus:outline-none hover:bg-secondary flex justify-between gap-1 items-center w-24", {
                          "bg-destructive hover:bg-destructive text-destructive-foreground": isMicMuted,
                        })}
                      >
                        <ToggledMicIcon className="flex-none" size={24} />
                        {isMicMuted ? <span className="font-semibold uppercase">Muted</span> : <VoiceVisualizer backgroundColor="transparent" barColor={isMicMuted ? "gray" : "black"} barGap={3} barWidth={8} barMaxHeight={20} participantType="local" />}
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent className="bg-background text-foreground shadow-sm">{isMicMuted ? "Unmute microphone" : "Mute microphone"}</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>

            <div className=" flex items-end gap-3 relative">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      onClick={
                        isVoiceMode
                          ? handleSwitchToTextMode
                          : () => {
                              handleSwitchToVoiceMode();
                            }
                      }
                      disabled={!webrtcEnabled || isConnecting}
                      size="icon"
                      variant={isVoiceMode ? "destructive" : "secondary"}
                      type="button"
                      className={cn("flex bg-background rounded-full", {
                        "bg-secondary": isConnecting,
                        "bg-foreground": isVoiceMode,
                      })}
                    >
                      {isConnecting ? (
                        <LoaderCircleIcon className="animate-spin rounded-full bg-muted text-background p-1" size={24} />
                      ) : isVoiceMode ? (
                        <XIcon
                          onClick={() => {
                            console.log("clicked");
                          }}
                          size={24}
                        />
                      ) : (
                        <>
                          <span className="ml-2">{isVoiceMode ? "End AI" : "ASK AI"}</span>
                          <Speech size={24} />
                        </>
                      )}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent align="center" className="bg-background text-foreground shadow-sm">
                    {isVoiceMode ? "End voice mode" : "Enable voice mode"}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
        )}
      </div>

      {error && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className={feedbackClassName}>
                <TriangleAlertIcon />
                <span>{error}</span>
              </div>
            </TooltipTrigger>
            <TooltipContent className="bg-background text-foreground shadow-sm">{error}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </div>
  );
};

export default ChatControls;
