import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import { XMarkIcon } from "@heroicons/react/24/outline";
import { backendUrl } from "../../config";
import { AppStateProvider } from "../../context/AppStateProvider";
import { ClientPage } from "../ai-assistant/ClientPage";
import { XIcon } from "lucide-react";

const AskAIBox: React.FC = () => {
  const [input, setInput] = useState("");
  const [response, setResponse] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const location = useLocation();

  const handleDismiss = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    setIsVisible(true);
  }, [location]);

  return isVisible ? (
    <div className="fixed bottom-4 right-4 relative">
      <button className="absolute -top-2 -right-2 p-1 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors duration-200 z-[9999]" onClick={handleDismiss} aria-label="Close AI Assistant">
        <span className="pointer-events-none">
          <XIcon className="h-4 w-4 text-gray-600" />
        </span>
      </button>
      <AppStateProvider webrtcEnabled={true} websocketEnabled={false}>
        <ClientPage />
      </AppStateProvider>
    </div>
  ) : null;
};

export default AskAIBox;
