import React, { useEffect, useState } from "react";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../../components/ui/card";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { store } from "../../../redux/store";
import { fetchCashflowData } from "../../../redux/actions/cashflowAction";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../components/ui/tabs";
import DCF from "./dcf";

import FinancialTable from "./financialtable";
import PopulationChart from "./overview";
import ReusableFinancialTable from "./reactgrid";
import { FinancialRow } from "./reactgrid";
import { outerStep, buildWaterfallRowsFromJson, revertWaterfallToJson } from "./outersteps";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { MacroCard } from "./SetMacro";
interface Task {
  year: number;
  [key: string]: any; // if other properties exist
}

interface TableRowData {
  label: string;
  values: (string | number | any)[];
  isHighlighted?: boolean;
  rowType?: "default" | "mini-total" | "large-total" | string; // Add string type to allow flexibility
  isNegative?: boolean;
}

const processInvestmentCashFlow = (
  data: TableRowData[] | undefined,
  isCumulative: boolean = true,
): {
  year: string;
  cashOutflow: number;
  cashInflow: number;
  cashTotal: number;
}[] => {
  if (!data) return [];

  const cashFlowRow = data.find((row) => row.label === "Investment Cash Flow");
  if (!cashFlowRow) {
    throw new Error("Investment Cash Flow row not found!");
  }

  let cumulativeNegative = 0;
  let cumulativePositive = 0;
  let cumulativeTotal = 0;

  return cashFlowRow.values.map((value, index) => {
    const numericValue = typeof value === "string" ? Number(value.replace(/,/g, "")) || 0 : value || 0;

    if (isCumulative) {
      // Existing logic for cumulative cash flows
      if (numericValue < 0) {
        cumulativeNegative += numericValue;
      } else {
        cumulativePositive += numericValue;
      }
      cumulativeTotal += numericValue;

      return {
        year: `Year ${index}`,
        cashOutflow: cumulativeNegative,
        cashInflow: cumulativePositive,
        cashTotal: cumulativeTotal,
      };
    } else {
      // NEW logic: non-cumulative (raw yearly in/out)
      const cashOutflow = numericValue < 0 ? numericValue : 0;
      const cashInflow = numericValue > 0 ? numericValue : 0;
      const cashTotal = numericValue;

      return {
        year: `Year ${index}`,
        cashOutflow,
        cashInflow,
        cashTotal,
      };
    }
  });
};

const chartConfig = {
  cashOutflow: {
    label: "Cash Outflow",
    color: "#ef4444", // Blue
  },
  cashInflow: {
    label: "Cash Inflow",
    color: "#93c5fd", // Light Blue
  },
  cashTotal: {
    label: "Total Cash",
    color: "#1e40af", // Dark Blue
    isTotal: true,
  },
};
export const CashCard = ({ investmentCashFlowData, isCumulative = true, title = null }) => {
  const { t } = useTranslation();
  return (
    <Card className="col-span-2 w-full shadow-lg mt-2">
      <CardHeader>
        <CardTitle>
          {title
            ? title
            : // @ts-ignore
              t("overview.yearly_cash_flow")}
        </CardTitle>
      </CardHeader>
      <CardContent className={`p-6 grid grid-cols-${"1"} gap-4 col-span-2`}>
        <PopulationChart data={processInvestmentCashFlow(investmentCashFlowData, isCumulative)} chartConfig={chartConfig} title="" />
        {/* {combinedCharts.map((chart) => (
      <PopulationChart key={chart.id} title={`${chart.title}`} data={chart.data} chartConfig={chart.chartConfig} />
    ))} */}
      </CardContent>
    </Card>
  );
};

interface CashFlowRow {
  label: string;
  values: (string | number)[];
  isNegative?: boolean;
  rowType?: string; // e.g., "large-total"
}

interface ExtendedCashFlows {
  newInitialCashFlowData: CashFlowRow[];
  newInvestmentCashFlowData: CashFlowRow[];
}

export function extendCashFlowsWithTerminal(inputData: any, initialCashFlowData: CashFlowRow[], investmentCashFlowData: CashFlowRow[]): ExtendedCashFlows {
  if (!inputData || !Array.isArray(inputData) || inputData.length === 0 || !inputData[0].year) {
    return {
      newInitialCashFlowData: [],
      newInvestmentCashFlowData: [],
    };
  }

  if (!Array.isArray(investmentCashFlowData) || investmentCashFlowData.length === 0) {
    return {
      newInitialCashFlowData: [],
      newInvestmentCashFlowData: [],
    };
  }

  // Locate required items with safeguards
  const acquisitionRow = investmentCashFlowData.find((e) => e.label === "Acquisition Costs");
  if (!acquisitionRow || !Array.isArray(acquisitionRow.values) || acquisitionRow.values.length === 0) {
    console.warn("Acquisition Costs row missing or malformed");
    console.log(investmentCashFlowData);
    console.log(initialCashFlowData);
    return {
      newInitialCashFlowData: [],
      newInvestmentCashFlowData: [],
    };
  }
  // @ts-ignore
  const acquisitionCost = Math.abs(parseFloat(investmentCashFlowData.find((e) => e.label === "Acquisition Costs").values[0].replace(/,/g, "")));
  const nonForecastedYears = inputData.filter((e) => e.isForecasted === false).map((e) => e.year);
  console.log(nonForecastedYears);
  // Get the last year of non-forecasted data
  const lastNonForecastYear = Math.max(...nonForecastedYears);
  const ForecastedYears = inputData.filter((e) => e.isForecasted === true).map((e) => e.year);
  console.log(ForecastedYears);
  // Get the last year of -forecasted data
  const lastForecastYear = Math.max(...ForecastedYears);
  const ebitdaItem = [inputData.find((e) => e.year === lastNonForecastYear)["Adjusted EBITDA"].value, inputData.find((e) => e.year === lastForecastYear)["Adjusted EBITDA"].value];
  inputData.find((item) => item.id === "Adjusted EBITDA");
  const debtItem = [inputData.find((e) => e.year === lastNonForecastYear)["Debt Ending Balance"].value, inputData.find((e) => e.year === lastForecastYear)["Debt Ending Balance"].value];
  const fcfeItem = [inputData.find((e) => e.year === lastNonForecastYear)["Free Cash Flow to Equity"].value, inputData.find((e) => e.year === lastForecastYear)["Free Cash Flow to Equity"].value];

  // 3) Pull out the relevant values
  const ebitdaFirstNonForecast = ebitdaItem[0]; // avoid div by 0
  const ebitdaLastYear = ebitdaItem[1];

  // For Debt, we do the same to find the last year (largest year in Debt data):
  const debtLastYear = debtItem[1];

  // 4) Compute the Entry EBITDA Multiple
  let divider;
  if (ebitdaFirstNonForecast === 0) {
    console.warn("First non-forecasted EBITDA is 0");
    divider = fcfeItem[0];
  } else {
    divider = ebitdaFirstNonForecast;
  }
  const entryEbitdaMultiple = acquisitionCost / divider;
  console.log(entryEbitdaMultiple);
  console.log(debtLastYear);
  // 5) Compute the Terminal Value

  const terminalValue = ebitdaLastYear * entryEbitdaMultiple;
  console.log(terminalValue);

  // -------------------------------------------------------------------
  // Below is an example of how to:
  // (A) Add one extra "terminal" period (column) in your existing rows,
  // (B) Add a new row for Terminal Value, and
  // (C) Update the "Investment Cash Flow" row to include that terminal value.
  // The exact approach depends on how your table data is structured.
  // -------------------------------------------------------------------

  // 6) Create *copies* of the original arrays so we don't mutate them
  const extendedInvestment = JSON.parse(JSON.stringify(investmentCashFlowData)) as CashFlowRow[];
  const extendedInitial = JSON.parse(JSON.stringify(initialCashFlowData)) as CashFlowRow[];

  // 7) Extend each row’s values by 1 more column, with default = ""
  //    so that you visually see a new "Terminal" column in the last index.
  extendedInvestment.forEach((row) => {
    if (row.label === "Loan Balance") row.values = [...row.values, -debtLastYear];
    else {
      row.values = [...row.values, ""];
    }
  });
  extendedInitial.forEach((row) => {
    row.values = [...row.values, ""];
  });

  // 8) Optionally find the row that represents FCFE or "Annual Project Cash Flow".
  //    If you want to add zero or some value to it for the terminal period, do so.
  //    Alternatively, you may leave it as 0 or blank.
  //
  //    Let’s assume your `investmentCashFlowData` already has a row labeled "Annual Project Cash Flow"
  //    that you want to set to 0 in the final column.
  const fcfeRow = extendedInvestment.find((r) => r.label === "Annual Project Cash Flow");
  if (fcfeRow) {
    // The final column index will be fcfeRow.values.length - 1
    fcfeRow.values[fcfeRow.values.length - 1] = 0; // or "" or some logic
  }

  // 9) Insert a dedicated row for "Terminal Value"
  //    which is blank for all columns except the final one
  const closingCostIndex = extendedInvestment.findIndex((r) => r.label === "Closing Costs");

  const newTerminalValueRow: CashFlowRow = {
    label: "Terminal Value",
    // @ts-ignore
    values: extendedInvestment[0].values.map(() => ""), // same number of columns as others, fill with ""
  };
  // Now place the terminalValue in the final column
  newTerminalValueRow.values[newTerminalValueRow.values.length - 1] = terminalValue;

  // 10) Insert the Terminal Value row right before the "Investment Cash Flow" total row
  //     or at the bottom. Let’s place it at the bottom for clarity.
  // extendedInvestment.push(newTerminalValueRow);
  if (closingCostIndex !== -1) {
    extendedInvestment.splice(closingCostIndex + 1, 0, newTerminalValueRow);
  } else {
    console.warn("Closing Costs row not found, adding Terminal Value at the end.");
    extendedInvestment.push(newTerminalValueRow);
  }
  // 11) Update the "Investment Cash Flow" row so that in the new final column,
  //     we add the existing CF + terminalValue (if any).
  const investmentCfRow = extendedInvestment.find((r) => r.label === "Investment Cash Flow");
  if (investmentCfRow) {
    const lastIndex = investmentCfRow.values.length - 1;

    // If it’s numeric, parse it; if it’s a string, convert it.
    const existingFinalValue = Number(investmentCfRow.values[lastIndex]) || 0;
    investmentCfRow.values[lastIndex] = existingFinalValue + terminalValue - debtLastYear;
  }

  // 12) Do the same for initialCashFlowData if relevant (often a summarized version).
  //     E.g., we might just add the new final column to the row that shows "Investment Cash Flow".
  const initialCfRow = extendedInitial.find((r) => r.label === "Investment Cash Flow");
  if (initialCfRow) {
    const lastIndex = initialCfRow.values.length - 1;
    const existingFinalValue = Number(initialCfRow.values[lastIndex]) || 0;
    initialCfRow.values[lastIndex] = existingFinalValue + terminalValue;
  }

  // 13) Return your new extended data
  return {
    newInitialCashFlowData: extendedInitial,
    newInvestmentCashFlowData: extendedInvestment,
  };
}

export default function Cashflow() {
  const { t } = useTranslation();

  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const state = store.getState();
  const transaction = useSelector((state: any) => state.transactions.transaction);
  const cashflow = useSelector((state: any) => state.cashflow.tableData.cashFlow);
  const debt = useSelector((state: any) => state.cashflow.debt);
  console.log(debt);
  console.log(cashflow);
  const initialCashFlowData = useSelector((state: any) => state.cashflow.initialCashFlowData);
  const investmentCashFlowData = useSelector((state: any) => state.cashflow.investmentCashFlowData);

  const [loading, setLoading] = useState(true); // Manage loading state
  const [error, setError] = useState<string | null>(null); // Manage error state
  const [tasks, setTasks] = useState<any[]>([]); // Manage tasks state
  const [growth, setGrowth] = useState(null); // Manage tasks state
  const [inflation, setInflation] = useState(null); // Manage tasks state
  const year = "2024";

  // Build final rows in the desired waterfall sequence
  const years =
    cashflow && cashflow.length > 0
      ? cashflow.map((y: any) => {
          return { year: String(y.year ?? ""), isForecasted: y.isForecasted ? "Forecast" : "Actual" };
        })
      : [];
  const finalRows = React.useMemo(() => {
    console.log("final rows re-render");
    if (!cashflow) return [];
    if (cashflow.length === 0) return [];
    console.log("final rows re-render_2");
    // we call our new build function once, passing the entire array plus outerSteps

    return buildWaterfallRowsFromJson(cashflow, outerStep);
  }, [cashflow, debt]);

  const fetchCashflow = async () => {
    try {
      // @ts-ignore
      const { cashFlow, growthRate, inflationRate } = await dispatch(fetchCashflowData(transaction.id, debt));
      console.log(cashFlow);
      // console.log(growthRate);
      // const data = await getTasks();
      setTasks(cashFlow); // Set tasks to state
      setGrowth(growthRate);
      setInflation(inflationRate);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCashflow();
  }, [dispatch, transaction, debt]);
  // useEffect(() => {
  //   setTasks(cashflow);
  // }, [cashflow]);
  const dataModel = { model: finalRows };
  // console.log(/finalRows);
  const handleDataChange = async (data) => {
    // console.log("Final Data Submitted:", data);
  };
  // console.log(finalRows);
  const pricing = useSelector((state: any) => state.cashflow.purchasePrice) || {};
  console.log(pricing);
  const { newInitialCashFlowData, newInvestmentCashFlowData } = extendCashFlowsWithTerminal(cashflow, initialCashFlowData, investmentCashFlowData);
  // const newInitialCashFlowData = [];
  // const newInvestmentCashFlowData = [];
  console.log(initialCashFlowData, investmentCashFlowData);

  return (
    <>
      <div className="hidden h-full flex-1 flex-col space-y-8 p-2 md:flex ">
        <div className="flex flex-col items-center justify-between space-y-2 gap-2">
          {/* <AlertDestructive /> */}
          <div className="flex flex-col justify-start mr-auto">
            <h2 className="text-2xl font-bold tracking-tight">
              {
                // @ts-ignore
                t("cashflowPro.title")
              }
            </h2>
            <p className="text-muted-foreground">
              {
                // @ts-ignore
                t("cashflowPro.subtitle")
              }
            </p>
          </div>
          <Tabs defaultValue={"summary"} className="flex flex-col h-full justify-between w-full">
            <TabsList className={`grid w-full grid-cols-2`}>
              <TabsTrigger value="summary">
                {
                  // @ts-ignore
                  t("cashflowPro.tabs.macro")
                }
              </TabsTrigger>
              <TabsTrigger value="detail">
                {
                  // @ts-ignore
                  t("cashflowPro.tabs.detail")
                }
              </TabsTrigger>
            </TabsList>

            <TabsContent className="flex flex-col w-full gap-4 " value="summary">
              <MacroCard />
              <CashCard investmentCashFlowData={investmentCashFlowData} />
            </TabsContent>
            <TabsContent className="flex flex-col w-full gap-4" value="detail">
              <FinancialTable
                investmentCashFlowData={newInvestmentCashFlowData}
                dataModel={dataModel}
                title={
                  // @ts-ignore
                  t("cashflow.financialTableTitle")
                }
                years={years}
                onSubmit={handleDataChange}
                transaction={transaction}
                pricing={pricing}
                // setinitialCashFlowData={setinitialCashFlowData}
              />

              {newInitialCashFlowData.length > 0 && <DCF initialCashFlowData={newInitialCashFlowData} />}
            </TabsContent>
          </Tabs>
        </div>
      </div>
      {/* <DrawerUploaderDemo row={row} setRow={setRow} /> */}
    </>
  );
}
