"use client";
import { useState } from "react";

import { Row } from "@tanstack/react-table";
import { MoreHorizontal } from "lucide-react";

import { Button } from "../../../components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuRadioGroup, DropdownMenuRadioItem, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuTrigger } from "../../../components/ui/dropdown-menu";
import DrawerUploaderDemo from "../../../components/components/dialog-uploader";
import Spinner from "../../../components/ui/Spinner";

import { labels } from "../data/data";
import { taskSchema } from "../data/schema";
import { Textarea } from "../../../components/ui/textarea";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogClose, DialogTrigger, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../../../components/ui/dialog";
import { backendClient } from "../../../api/backend";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  onAction: (row: any, actionType: string) => void; // Add this property
}

export function DataTableRowActions<TData>({ row, onAction }: DataTableRowActionsProps<TData>) {
  const transaction = useSelector((state: any) => state.transactions.transaction);

  const task = taskSchema.parse(row.original);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [answer, setAnswer] = useState(task?.answer || "");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const handleAction = (action: string) => {
    // console.log(row);
    setDrawerOpen(true);
  };

  const handledeletebutton = (event) => {
    event.preventDefault();
    setIsDropdownOpen(false);
    setShowDeleteDialog(true);
  };
  const handleResponse = async (row) => {
    try {
      setLoading(true);

      await backendClient.answerChecklistQuestion(transaction.id, row?.original.id, answer);
      await onAction(row, "answer");

      // Now that all async operations are complete, we can close the drawer and show success message
      setDrawerOpen(false);

      // @ts-ignore
      toast(t("rowActions.answerUpdated"), {
        // @ts-ignore
        description: t("rowActions.answerUpdatedDescription"),
      });
    } catch (error) {
      // Handle any errors
      // @ts-ignore
      toast.error(t("rowActions.errorUpdating"), {
        // @ts-ignore
        description: t("rowActions.errorUpdatingDescription"),
      });
    } finally {
      setLoading(false);
    }
  };
  const handleNa = async (row) => {
    if (!transaction) return;
    await backendClient.setStatusChecklistQuestion(transaction.id, task.id, "not-applicable");
    await onAction(row, "Edit");
    // @ts-ignore
    toast(t("rowActions.statusUpdated"), {
      // @ts-ignore
      description: t("rowActions.notApplicableDescription"),
    });
  };
  const handleDelete = async (row) => {
    if (!transaction) return;
    setLoading(true);

    await backendClient.deleteBusQuestion(transaction.id, task.id);
    await onAction(row, "delete");
    setLoading(false);
    // @ts-ignore
    toast(t("rowActions.questionDeleted"), {
      // @ts-ignore
      description: t("rowActions.questionDeletedDescription"),
    });
  };
  const status = {
    respond: task.answer === "",
    edit: task.answer !== "",
    na: task.status !== "done",
    delete: true,
  };

  // @ts-ignore
  console.log(row);
  // const documentData = useDocumentStatus(`/deals/${transaction.id}/${row.original.type}s`, row.original.id, "status");

  const handleChange = (e) => {
    setAnswer(e.target.value);
  };
  return (
    <>
      {" "}
      <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="flex h-8 w-8 p-0 data-[state=open]:bg-muted">
            <MoreHorizontal />
            <span className="sr-only">
              {
                // @ts-ignore
                t("rowActions.openMenu")
              }
            </span>
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent align="end" className="w-[160px]">
          <DropdownMenuItem disabled={!status.respond} onClick={() => handleAction("upload")}>
            {
              // @ts-ignore
              t("rowActions.respond")
            }
          </DropdownMenuItem>
          <DropdownMenuItem disabled={!status.edit} onClick={() => handleAction("upload")}>
            {
              // @ts-ignore
              t("rowActions.editResponse")
            }
          </DropdownMenuItem>

          <DropdownMenuItem onClick={handleNa} disabled={!status.na}>
            {
              // @ts-ignore
              t("rowActions.notApplicable")
            }
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          {/* <DialogTrigger asChild> */}
          <DropdownMenuItem onClick={(event) => handledeletebutton(event)} disabled={!status.delete} className="text-red-600">
            {
              // @ts-ignore
              t("rowActions.deleteQuestion")
            }
            <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
          </DropdownMenuItem>
          {/* </DialogTrigger> */}
        </DropdownMenuContent>
      </DropdownMenu>
      <DrawerUploaderDemo
        row={row}
        isDrawerOpen={isDrawerOpen && !isDropdownOpen}
        setDrawerOpen={setDrawerOpen}
        title={
          // @ts-ignore
          t("rowActions.respondTo")
        }
        description={
          // @ts-ignore
          t("rowActions.enterResponse")
        }
        showFileUploader={false}
        handleAction={handleResponse}
        answer={answer}
        loading={loading}
        customContent={
          <Textarea
            className="resize-none"
            placeholder={
              // @ts-ignore
              t("rowActions.placeholder")
            }
            value={answer}
            onChange={handleChange}
          />
        }
      />
      <Dialog
        open={showDeleteDialog && !isDropdownOpen}
        // onOpenChange={(open) => {
        //   setShowDeleteDialog(open);
        //   setTimeout(() => {
        //     if (!open) {
        //       document.body.style.pointerEvents = "";
        //     }
        //   }, 100);
        // }}
        onOpenChange={setShowDeleteDialog}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {
                // @ts-ignore
                t("rowActions.confirmDelete")
              }
            </DialogTitle>
          </DialogHeader>

          <DialogDescription>
            {
              // @ts-ignore
              t("rowActions.confirmDeleteDescription")
            }
          </DialogDescription>

          <DialogFooter>
            <DialogClose asChild>
              <Button onClick={() => setShowDeleteDialog(false)} variant={"secondary"}>
                {
                  // @ts-ignore
                  t("rowActions.cancel")
                }
              </Button>
            </DialogClose>
            <Button
              variant="destructive"
              onClick={() => {
                handleDelete(row);
              }}
            >
              {loading ? (
                <>
                  {/* @ts-ignore */}
                  <Spinner size={16} /> {t("rowActions.deleting")}
                </>
              ) : (
                // @ts-ignore
                t("rowActions.delete")
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
