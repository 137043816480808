import { AppSidebar } from "../../components/components/app-sidebar";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "../../components/ui/breadcrumb";
import { Separator } from "../../components/ui/separator";
import { SidebarInset, SidebarProvider, SidebarTrigger } from "../../components/ui/sidebar";
import DashboardPage from "./DashboardPage";
import Cards from "./components/cards";
import ProgressPrice from "./ProgressPrice";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../redux/store";
import { extractFinancialItem } from "../../utils/utils";
import { fetchPurchasePrice } from "../../redux/actions/cashflowAction";
import React from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/ui/Spinner";
import { useTranslation } from "react-i18next";
import { SBAUses } from "./Uses";
const extractLast = (cashflow, itemname) => {
  if (cashflow.length === 0) return null;
  const item = extractFinancialItem(cashflow, itemname, null, false);
  const last = item[item.length - 1];
  return last;
};
const extractBeforeLast = (cashflow, itemname) => {
  if (cashflow.length < 1) return null;
  const item = extractFinancialItem(cashflow, itemname, null, false);
  const last = item[item.length - 2];
  return last;
};
export default function Valuation() {
  const dispatch = useDispatch();
  const { t } = useTranslation(); // ✅ Use translation hook

  const transaction = useSelector((state) => state.transactions?.transaction);
  const cashflow = useSelector((state) => state.cashflow.tableData.cashFlow);
  const pricing = useSelector((state) => state.cashflow.purchasePrice) || {};
  const debt = useSelector((state) => state.cashflow.debt);
  const navigate = useNavigate();
  console.log(cashflow);
  if (!cashflow) navigate("/pipeline");
  const fetch = async () => {
    const res = await dispatch(fetchPurchasePrice(transaction.id));
    console.log(res);
  };

  React.useEffect(() => {
    fetch();
  }, [transaction]);

  if (!cashflow) {
    return (
      <div className="flex flex-col mt-60 mx-auto h-full">
        <Spinner size={72} />
      </div>
    );
  }
  const fcf = extractFinancialItem(cashflow, "Free Cash Flow", null, true);
  const fcfe = extractFinancialItem(cashflow, "Free Cash Flow to Equity", null, true);
  const debtending = extractFinancialItem(cashflow, "Debt Ending Balance", null, true);
  const rev_pro = extractFinancialItem(cashflow, "Revenue", null, true);
  const new_debt = debt && debt.customDebt ? (debt.customDebt !== 0 ? debt.customDebt : debt.maximumDebt + extractLast(cashflow, "Debt Ending Balance")["Debt Ending Balance"]) : 0 + extractLast(cashflow, "Debt Ending Balance")["Debt Ending Balance"];

  const revenue = extractLast(cashflow, "Revenue")["Revenue"];
  const prevRevenue = extractBeforeLast(cashflow, "Revenue")["Revenue"];

  const costs = -extractLast(cashflow, "Costs")["Costs"];
  const prevCosts = -extractBeforeLast(cashflow, "Costs")["Costs"];

  const ebitda = extractLast(cashflow, "Adjusted EBITDA")["Adjusted EBITDA"];
  const prevEbitda = extractBeforeLast(cashflow, "Adjusted EBITDA")["Adjusted EBITDA"];

  const earnings = extractLast(cashflow, "Taxable Income")["Taxable Income"] + extractLast(cashflow, "Tax")["Tax"];

  const prevEarnings = extractBeforeLast(cashflow, "Taxable Income")["Taxable Income"] + extractBeforeLast(cashflow, "Tax")["Tax"];

  // Function to calculate growth rate
  const calculateGrowthRate = (current, previous) => {
    if (previous === 0 || previous === null || current === null) return null;
    return ((current - previous) / previous) * 100;
  };

  // Compute growth rates
  const revenueGrowth = calculateGrowthRate(revenue, prevRevenue) ?? "n.a.";
  const costsGrowth = calculateGrowthRate(costs, prevCosts) ?? "n.a.";
  const ebitdaGrowth = calculateGrowthRate(ebitda, prevEbitda) ?? "n.a.";
  const earningsGrowth = calculateGrowthRate(earnings, prevEarnings) ?? "n.a.";

  const earningsPrice = transaction.earningsMultiple * earnings || transaction.cashFlow;
  const revenuePrice = transaction.revenueMultiple * revenue || transaction.revenue;

  // Calculate min and max values
  const minPrice = Math.floor(Math.max(Math.min(earningsPrice, revenuePrice), 0)); // Round down and cap at 0
  const maxPrice = Math.floor(Math.max(earningsPrice, revenuePrice));

  const lowerBound = minPrice;
  const upperBound = maxPrice;

  // Calculate mean (mu) and standard deviation (sigma)
  const mu = (upperBound + lowerBound) / 2;
  const price = cashflow.purchasePrice || transaction.askPrice || mu;
  const sigma = (upperBound - lowerBound) / 4; // Approximation for 1 standard deviation

  // Calculate 3 standard deviations of the mean and cap at zero
  const lowerLimit = Number(Math.max(mu - 3 * sigma, 0).toFixed(0));
  const upperLimit = Math.max(Number(transaction.askPrice || 0) * 1.5, Number((mu + 3 * sigma).toFixed(0)));

  const actual_rev = extractFinancialItem(cashflow, "Revenue");
  const actual_ebitda = extractFinancialItem(cashflow, "Adjusted EBITDA");

  const actual_costs = extractFinancialItem(cashflow, "Costs");
  const act_adj = extractFinancialItem(cashflow, "Ebitda Adjustments");
  console.log(act_adj);

  return (
    <SidebarInset>
      <header className="flex h-16 shrink-0 items-center gap-2">
        <div className="flex items-center gap-2 px-4">
          <SidebarTrigger className="-ml-1" />
          <Separator orientation="vertical" className="mr-2 h-4" />
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage>
                  {
                    // @ts-ignore
                    t("valuation.businessPricing")
                  }
                </BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </header>
      <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
        <Cards revenue={revenue} costs={costs} ebitda={ebitda} earnings={earnings} revenueGrowth={revenueGrowth} costsGrowth={costsGrowth} ebitdaGrowth={ebitdaGrowth} earningsGrowth={earningsGrowth} />
        <div className="grid auto-rows-min gap-y-4 gap-x-4 md:grid-cols-2">
          <div className="col-span-2">
            <ProgressPrice lowerLimit={lowerLimit} upperLimit={upperLimit} price={price} ebitda={ebitda} earnings={revenue} transaction={transaction} newDebt={new_debt} fcf={fcf} fcfe={fcfe} rev_pro={rev_pro} pricing={pricing} debtending={debtending} />
          </div>

          {/* Two Dashboard Pages in a single row */}
          <div className="grid grid-cols-2 gap-4 col-span-2">
            <DashboardPage
              title={
                // @ts-ignore
                t("valuation.forecastRevenueEbitda")
              }
              overview={true}
              actual_rev={actual_rev}
              actual_ebitda={actual_ebitda}
            />

            <DashboardPage
              title={
                // @ts-ignore
                t("valuation.forecastCosts")
              }
              overview={false}
              actual_costs={actual_costs}
              act_adj={act_adj}
            />
          </div>
        </div>
      </div>
    </SidebarInset>
  );
}
