import React, { useEffect, useState } from "react";
import { z } from "zod";

import { getColumns } from "./columns";
import { DataTable } from "./DataTable";
import Spinner from "../../../components/ui/Spinner";
import { backendClient } from "../../../api/backend";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Info } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../../components/ui/tooltip";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../components/ui/table";
import { Task } from "../data/schema";
import { Badge } from "../../../components/ui/badge";

// Define a type for financial metric data structure
type RevenueData = {
  value: Record<string, number> | Record<string, any>;
  yoy: Record<string, number> | Record<string, any>;
  revenuePercentage: Record<string, number> | Record<string, any>;
};

// Rename to FinancialMetricTooltip to make it more generic
export function FinancialMetricTooltip({ data, label = "Financial Metric" }: { data: RevenueData | null; label?: string }) {
  if (!data) return null;

  // Extract years from the data (assuming all three objects have the same years)
  const years = Object.keys(data.value || {}).sort();

  // Format currency with locale string
  const formatCurrency = (value: number | any) => {
    if (typeof value !== "number") return "-";
    return value.toLocaleString("en-US", { maximumFractionDigits: 0 });
  };

  // Normalize and format percentage values
  const normalizePercentage = (value: any): number | null => {
    if (typeof value === "number") return value;
    if (typeof value === "string") {
      // Remove percentage sign and any commas, then parse
      const cleanValue = value.replace("%", "").replace(/,/g, "");
      const parsedValue = parseFloat(cleanValue);
      if (!isNaN(parsedValue)) return parsedValue;
    }
    return null;
  };

  // Format percentage for display
  const formatPercentage = (value: any) => {
    const normalizedValue = normalizePercentage(value);
    if (normalizedValue === null) return "-";
    return `${normalizedValue.toFixed(1)}%`;
  };

  // Check if a value is positive, negative, or zero
  const getValueColor = (value: number | any) => {
    if (typeof value !== "number") return "";
    if (value > 0) return "text-green-600";
    if (value < 0) return "text-red-600";
    return "";
  };

  // Determine color for percentage values
  const getPercentageColor = (value: any) => {
    const normalizedValue = normalizePercentage(value);
    if (normalizedValue === null) return "";
    if (normalizedValue > 0) return "text-green-600";
    if (normalizedValue < 0) return "text-red-600";
    return "";
  };

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="inline-flex items-center rounded-md border border-input px-1 py-0.5 text-xs font-semibold transition-colors hover:bg-muted/50 cursor-pointer">
            <span className="cursor-pointer ml-2 flex items-center gap-2">
              Source
              <Info className="h-4 w-4 text-gray-500 hover:text-black" />
            </span>
          </div>
        </TooltipTrigger>
        <TooltipContent className="bg-white p-0 w-auto max-w-[500px]" side="right">
          <div className="p-3">
            <p className="text-sm font-semibold mb-3">{label} Details</p>
            <div className="rounded-md border">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="whitespace-nowrap w-[120px]">Metric</TableHead>
                    {years.map((year) => (
                      <TableHead key={year} className="text-right whitespace-nowrap">
                        {year}
                      </TableHead>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableCell className="font-medium">{label}</TableCell>
                    {years.map((year) => (
                      <TableCell key={year} className="text-right">
                        {data.value?.[year] ? formatCurrency(data.value[year]) : "-"}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell className="font-medium">YoY Change</TableCell>
                    {years.map((year) => (
                      <TableCell key={year} className={`text-right ${getValueColor(data.yoy?.[year])}`}>
                        {data.yoy?.[year] ? formatCurrency(data.yoy[year]) : "-"}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell className="font-medium">Percentage</TableCell>
                    {years.map((year) => (
                      <TableCell key={year} className={`text-right}`}>
                        {data.revenuePercentage?.[year] ? formatPercentage(data.revenuePercentage[year]) : "-"}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default function TaskPage() {
  const transaction = useSelector((state: any) => state.transactions?.transaction);
  const { t } = useTranslation();

  const [tasks, setTasks] = useState<Task[]>([]); // Manage tasks state
  const [loading, setLoading] = useState(true); // Manage loading state
  const [error, setError] = useState<string | null>(null); // Manage error state
  const [sel, setSel] = useState(0);
  const [val, setVal] = useState(null);
  console.log("val", val);
  // Declare async function inside useEffect
  const fetchTasks = async () => {
    try {
      const audit = await backendClient.fetchAudit(transaction.id);
      console.log(audit);
      const list = audit.map((check) => {
        return {
          ...check,
          title: check.question,
          // .split(" ")
          // .map((e) => {
          //   // Try parsing the element as a number
          //   const num = parseFloat(e);

          //   // Check if it's a valid number and format it
          //   if (!isNaN(num) && isFinite(num) && e.length !== 4) {
          //     return num.toLocaleString();
          //   }

          // Return the original string if not a number
          //   return e;
          // })
          // .join(" "),
          label: check.item,
          valuation: String(check.valuation).replace("$", "").toLocaleString(),
          formattedval: check.valuation < 0 ? `(${Math.abs(Math.floor(check.valuation)).toLocaleString()})` : Math.floor(check.valuation).toLocaleString(),
          answer: check.answer ? check.answer : "",
          // preserve the hierarchy structure for use in tooltips
          hierarchy: check.hierarchy || null,
          // priority: check.type,
        };
      });
      // const data = await getTasks();
      setTasks(list); // Set tasks to state
    } catch (err) {
      // @ts-ignore
      setError(err instanceof Error ? err.message : t("taskPage.error", { message: "An unknown error occurred" }));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchTasks(); // Call async function
  }, [transaction]);
  const handleAction = async (rowData: Task, actionType: string) => {
    console.log(`Action "${actionType}" triggered for`, rowData);
    if (!transaction) return;

    await fetchTasks();
    // Add your logic for handling actions
  };
  const columns = getColumns(t, handleAction);

  if (loading) {
    return (
      <div className="flex flex-col mt-60 mx-auto h-full">
        <Spinner size={72} />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <div className="text-red-500 text-center">
          {
            // @ts-ignore
            t("taskPage.error", { message: error })
          }
        </div>
        ;
      </div>
    );
  }

  return (
    <>
      <div className="hidden h-full flex-1 flex-col space-y-8 p-2 md:flex">
        <div className="flex items-center justify-between space-y-2">
          <div>
            <h2 className="text-2xl font-bold tracking-tight">
              {" "}
              {
                // @ts-ignore
                sel > 0 ? t("taskPage.purchase_price_change", { value: Number(val).toLocaleString() }) : t("taskPage.financial_audit")
              }
            </h2>
            <p className="text-muted-foreground">
              {" "}
              {
                // @ts-ignore
                sel ? t("taskPage.negotiation_based", { count: sel, value: Number(val).toLocaleString() }) : t("taskPage.audit_description")
              }
            </p>
          </div>
          <div className="flex items-center space-x-2">{/* <UserNav /> */}</div>
        </div>
        <DataTable data={tasks} columns={columns} setVal={setVal} setSel={setSel} />
      </div>
    </>
  );
}
