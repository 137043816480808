// NAR BAR
export const SET_OPEN = "SET_OPEN";

// AUTH
export const GET_USER = "GET_USER";
export const ADD_TO_NEWSLETTER = "ADD_TO_NEWSLETTER";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";

// TRANSACTION
export const CREATE_TRANSACTION = "CREATE_TRANSACTION";
export const DELETE_TRANSACTIONS = "DELETE_TRANSACTIONS";
export const CLEAR_TRANSACTIONS = "CLEAR_TRANSACTIONS";
export const REMOVE_TRANSACTION_DOCUMENT_ID = "REMOVE_TRANSACTION_DOCUMENT_ID";
export const UPDATE_TRANSACTION_PROPERTY = "UPDATE_TRANSACTION_PROPERTY";
export const FETCH_TRANSACTIONS = "FETCH_TRANSACTIONS";
export const FETCH_TRANSACTION = "FETCH_TRANSACTION";
export const SET_TRANSACTION = "SET_TRANSACTION";
export const SET_TRANSACTIONS = "SET_TRANSACTIONS";
export const UPDATE_TRANSACTION_DOCUMENTS_IDS = "UPDATE_TRANSACTION_DOCUMENTS_IDS";
export const SET_TRANSACTION_ID = "SET_TRANSACTION_ID";

// EXPLORE
export const EXPLORE = "EXPLORE";
export const CLEAR_EXPLORED_DATA = "CLEAR_EXPLORED_DATA";
export const SET_FILTRED_EXPLORED_DATA = "SET_FILTRED_EXPLORED_DATA";

// KEYWORD
export const CREATE_KEYWORD = "CREATE_KEYWORD";
export const FETCH_KEYWORDS = "FETCH_KEYWORDS";
export const FETCH_KEYWORD = "FETCH_KEYWORD";
export const UPDATE_KEYWORD = "UPDATE_KEYWORD";
export const DELETE_KEYWORD = "DELETE_KEYWORD";
export const GET_SYNCHRONIZED = "GET_SYNCHRONIZED";
export const START_SYNCHRONIZE = "START_SYNCHRONIZE";
export const SET_KEYWORD_SYNCHRONIZED = "SET_KEYWORD_SYNCHRONIZED";

// DOCUMENTS
export const UPDATE_TRANSACTION_DOCUMENT_UPLOADED = "UPDATE_TRANSACTION_DOCUMENT_UPLOADED";
export const SET_PDF_LOADING_TASK = "SET_PDF_LOADING_TASK";
export const SET_CITATION_HIGHLIGHT = "SET_CITATION_HIGHLIGHT";
export const SET_ACTIVE_PDF = "SET_ACTIVE_PDF";
export const UPLOAD_DOCUMENTS = "UPLOAD_DOCUMENTS";
export const FETCH_DOCUMENTS = "FETCH_DOCUMENTS";
export const FETCH_DOCUMENT = "FETCH_DOCUMENT";
export const REMOVE_DOCUMENT_FROM_DOCUMENTS = "REMOVE_DOCUMENT_FROM_DOCUMENTS";
export const CLEAR_DOCUMENTS = "CLEAR_DOCUMENTS";
export const SET_DOCUMENTS_TRANSACTION_ID = "SET_DOCUMENTS_TRANSACTION_ID";
export const SET_DOCUMENTS = "SET_DOCUMENTS";
export const UPDATE_DOCUMENT_PROPERTY = "UPDATE_DOCUMENT_PROPERTY";
export const DELETE_DOCUMENT_FROM_TRANSACTION = "DELETE_DOCUMENT_FROM_TRANSACTION";
export const ADD_DOCUMENT_TO_DOCUMENTS = "ADD_DOCUMENT_TO_DOCUMENTS";
export const ADD_DOCUMENT_TO_DOCUMENTS_WITH_TRANSACTIONS = "ADD_DOCUMENT_TO_DOCUMENTS_WITH_TRANSACTIONS";

// CONVERSATIONS
export const UPDATE_MESSAGE_LIKE = "UPDATE_MESSAGE_LIKE";
export const CREATE_CONVERSATION = "CREATE_CONVERSATION";
export const DELETE_CONVERSATIONS = "DELETE_CONVERSATIONS";
export const SET_MESSAGES = "SET_MESSAGES";
export const FETCH_CONVERSATION = "FETCH_CONVERSATION";
export const FETCH_CONVERSATIONS = "FETCH_CONVERSATIONS";

// SNACKBARS
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";

// KG
export const PROCESS_DOC_FOR_KG = "PROCESS_DOC_FOR_KG";
export const QUERY_KG = "QUERY_KG";

// HYPOTHESIS
export const EXTRACT_ONE_HYPOTHESIS = "EXTRACT_ONE_HYPOTHESIS";
export const EXTRACT_HYPOTHESIS = "EXTRACT_HYPOTHESIS";
export const ADD_KEY_HYPOTHESIS = "ADD_KEY_HYPOTHESIS";
export const CLEAR_HYPOTHESIS = "CLEAR_HYPOTHESIS";
export const FETCH_HYPOTHESIS = "FETCH_HYPOTHESIS";
export const DELETE_HYPOTHESIS = "DELETE_HYPOTHESIS";

export const UPDATE_HYPOTHESIS = "UPDATE_HYPOTHESIS";

// TEMPLATEWORKSTREAMS
export const FETCH_TEMPLATE_WORKSTREAMS_REQUEST = "FETCH_TEMPLATE_WORKSTREAMS_REQUEST"; // New - Request initiation
export const FETCH_TEMPLATE_WORKSTREAMS_SUCCESS = "FETCH_TEMPLATE_WORKSTREAMS_SUCCESS"; // New - Request success
export const FETCH_TEMPLATE_WORKSTREAMS_FAILURE = "FETCH_TEMPLATE_WORKSTREAMS_FAILURE"; // New - Request failure

export const FETCH_TEMPLATE_WORKSTREAM = "FETCH_TEMPLATE_WORKSTREAM";
export const CREATE_TEMPLATE_WORKSTREAM = "CREATE_TEMPLATE_WORKSTREAM";
export const UPDATE_TEMPLATE_WORKSTREAM = "UPDATE_TEMPLATE_WORKSTREAM";
export const DELETE_TEMPLATE_WORKSTREAM = "DELETE_TEMPLATE_WORKSTREAM";
export const SET_TEMPLATE_WORKSTREAM = "SET_TEMPLATE_WORKSTREAM";
export const SET_TEMPLATE_WORKSTREAMS = "SET_TEMPLATE_WORKSTREAMS";
export const CLEAR_TEMPLATE_WORKSTREAMS = "CLEAR_TEMPLATE_WORKSTREAMS";
export const COUNT_TEMPLATE_WORKSTREAMS = "COUNT_TEMPLATE_WORKSTREAMS";
export const SET_TEMPLATE_WORKSTREAM_ID = "SET_TEMPLATE_WORKSTREAM_ID";
export const SET_SELECTED_INDUSTRY = "SET_SELECTED_INDUSTRY";

// src/actions/types.js

export const FETCH_TEMPLATE_QUESTIONNAIRES = "FETCH_TEMPLATE_QUESTIONNAIRES";
export const CREATE_TEMPLATE_QUESTIONNAIRE = "CREATE_TEMPLATE_QUESTIONNAIRE";
export const UPDATE_TEMPLATE_QUESTIONNAIRE = "UPDATE_TEMPLATE_QUESTIONNAIRE";
export const DELETE_TEMPLATE_QUESTIONNAIRE = "DELETE_TEMPLATE_QUESTIONNAIRE";
export const BULK_UPDATE_TEMPLATE_QUESTIONNAIRES = "BULK_UPDATE_TEMPLATE_QUESTIONNAIRES";
export const BULK_DELETE_TEMPLATE_QUESTIONNAIRES = "BULK_DELETE_TEMPLATE_QUESTIONNAIRES";
export const COUNT_QUESTIONS = "COUNT_QUESTIONS";

// New action types for loading and error handling
export const TEMPLATE_QUESTIONNAIRE_REQUEST = "TEMPLATE_QUESTIONNAIRE_REQUEST";
export const TEMPLATE_QUESTIONNAIRE_FAILURE = "TEMPLATE_QUESTIONNAIRE_FAILURE";

// TemplateQuestions
export const FETCH_TQUESTIONS_REQUEST = "FETCH_TQUESTIONS_REQUEST";
export const FETCH_TQUESTIONS_SUCCESS = "FETCH_TQUESTIONS_SUCCESS";
export const FETCH_TQUESTIONS_FAILURE = "FETCH_TQUESTIONS_FAILURE";

export const CREATE_TQUESTION_REQUEST = "CREATE_TQUESTION_REQUEST";
export const CREATE_TQUESTION_SUCCESS = "CREATE_TQUESTION_SUCCESS";
export const CREATE_TQUESTION_FAILURE = "CREATE_TQUESTION_FAILURE";

export const UPDATE_TQUESTION_REQUEST = "UPDATE_TQUESTION_REQUEST";
export const UPDATE_TQUESTION_SUCCESS = "UPDATE_TQUESTION_SUCCESS";
export const UPDATE_TQUESTION_FAILURE = "UPDATE_TQUESTION_FAILURE";

export const DELETE_TQUESTION_REQUEST = "DELETE_TQUESTION_REQUEST";
export const DELETE_TQUESTION_SUCCESS = "DELETE_TQUESTION_SUCCESS";
export const DELETE_TQUESTION_FAILURE = "DELETE_TQUESTION_FAILURE";

// Workstreams

export const FETCH_WORKSTREAMS_REQUEST = "FETCH_WORKSTREAMS_REQUEST";
export const FETCH_WORKSTREAMS_SUCCESS = "FETCH_WORKSTREAMS_SUCCESS";
export const FETCH_WORKSTREAMS_FAILURE = "FETCH_WORKSTREAMS_FAILURE";

export const CREATE_WORKSTREAM_REQUEST = "CREATE_WORKSTREAM_REQUEST";
export const CREATE_WORKSTREAM_SUCCESS = "CREATE_WORKSTREAM_SUCCESS";
export const CREATE_WORKSTREAM_FAILURE = "CREATE_WORKSTREAM_FAILURE";

export const UPDATE_WORKSTREAM_REQUEST = "UPDATE_WORKSTREAM_REQUEST";
export const UPDATE_WORKSTREAM_SUCCESS = "UPDATE_WORKSTREAM_SUCCESS";
export const UPDATE_WORKSTREAM_FAILURE = "UPDATE_WORKSTREAM_FAILURE";

export const DELETE_WORKSTREAM_REQUEST = "DELETE_WORKSTREAM_REQUEST";
export const DELETE_WORKSTREAM_SUCCESS = "DELETE_WORKSTREAM_SUCCESS";
export const DELETE_WORKSTREAM_FAILURE = "DELETE_WORKSTREAM_FAILURE";

export const FETCH_WORKSTREAM_BY_ID_REQUEST = "FETCH_WORKSTREAM_BY_ID_REQUEST";
export const FETCH_WORKSTREAM_BY_ID_SUCCESS = "FETCH_WORKSTREAM_BY_ID_SUCCESS";
export const FETCH_WORKSTREAM_BY_ID_FAILURE = "FETCH_WORKSTREAM_BY_ID_FAILURE";

// actions/types.js
export const FETCH_QUESTIONNAIRES_REQUEST = "FETCH_QUESTIONNAIRES_REQUEST";
export const FETCH_QUESTIONNAIRES_SUCCESS = "FETCH_QUESTIONNAIRES_SUCCESS";
export const FETCH_QUESTIONNAIRES_FAILURE = "FETCH_QUESTIONNAIRES_FAILURE";

export const CREATE_QUESTIONNAIRE_REQUEST = "CREATE_QUESTIONNAIRE_REQUEST";
export const CREATE_QUESTIONNAIRE_SUCCESS = "CREATE_QUESTIONNAIRE_SUCCESS";
export const CREATE_QUESTIONNAIRE_FAILURE = "CREATE_QUESTIONNAIRE_FAILURE";

export const UPDATE_QUESTIONNAIRE_REQUEST = "UPDATE_QUESTIONNAIRE_REQUEST";
export const UPDATE_QUESTIONNAIRE_SUCCESS = "UPDATE_QUESTIONNAIRE_SUCCESS";
export const UPDATE_QUESTIONNAIRE_FAILURE = "UPDATE_QUESTIONNAIRE_FAILURE";

export const DELETE_QUESTIONNAIRE_REQUEST = "DELETE_QUESTIONNAIRE_REQUEST";
export const DELETE_QUESTIONNAIRE_SUCCESS = "DELETE_QUESTIONNAIRE_SUCCESS";
export const DELETE_QUESTIONNAIRE_FAILURE = "DELETE_QUESTIONNAIRE_FAILURE";

export const BULK_DELETE_QUESTIONNAIRES_REQUEST = "BULK_DELETE_QUESTIONNAIRES_REQUEST";
export const BULK_DELETE_QUESTIONNAIRES_SUCCESS = "BULK_DELETE_QUESTIONNAIRES_SUCCESS";
export const BULK_DELETE_QUESTIONNAIRES_FAILURE = "BULK_DELETE_QUESTIONNAIRES_FAILURE";

// Fetch Questions
export const FETCH_QUESTIONS_REQUEST = "FETCH_QUESTIONS_REQUEST";
export const FETCH_QUESTIONS_SUCCESS = "FETCH_QUESTIONS_SUCCESS";
export const FETCH_QUESTIONS_FAILURE = "FETCH_QUESTIONS_FAILURE";

// Create Question
export const CREATE_QUESTION_REQUEST = "CREATE_QUESTION_REQUEST";
export const CREATE_QUESTION_SUCCESS = "CREATE_QUESTION_SUCCESS";
export const CREATE_QUESTION_FAILURE = "CREATE_QUESTION_FAILURE";

// Update Question
export const UPDATE_QUESTION_REQUEST = "UPDATE_QUESTION_REQUEST";
export const UPDATE_QUESTION_SUCCESS = "UPDATE_QUESTION_SUCCESS";
export const UPDATE_QUESTION_FAILURE = "UPDATE_QUESTION_FAILURE";

// Delete Question
export const DELETE_QUESTION_REQUEST = "DELETE_QUESTION_REQUEST";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";
export const DELETE_QUESTION_FAILURE = "DELETE_QUESTION_FAILURE";

// Validate Question
export const VALIDATE_QUESTION = "VALIDATE_QUESTION";
export const VALIDATE_QUESTION_REQUEST = "VALIDATE_QUESTION_REQUEST";
export const VALIDATE_QUESTION_SUCCESS = "VALIDATE_QUESTION_SUCCESS";
export const VALIDATE_QUESTION_FAILURE = "VALIDATE_QUESTION_FAILURE";

// FIREBASE ACTIONS
export const FIREBASE_AUTH_START = "FIREBASE_AUTH_START";
export const FIREBASE_AUTH_SUCCESS = "FIREBASE_AUTH_SUCCESS";
export const FIREBASE_AUTH_ERROR = "FIREBASE_AUTH_ERROR";
export const TOKEN_REFRESH_SUCCESS = "TOKEN_REFRESH_SUCCESS";

// Financial Statements
export const ADD_FINANCIAL_STATEMENT = "ADD_FINANCIAL_STATEMENT";
export const UPDATE_FINANCIAL_STATEMENT = "UPDATE_FINANCIAL_STATEMENT";
export const FETCH_FINANCIAL_STATEMENTS = "FETCH_FINANCIAL_STATEMENTS";
export const DELETE_FINANCIAL_STATEMENT = "DELETE_FINANCIAL_STATEMENT";
export const CLEAR_FINANCIAL_STATEMENTS = "CLEAR_FINANCIAL_STATEMENTS";
export const SET_FINANCIAL_STATEMENTS_LOADING = "SET_FINANCIAL_STATEMENTS_LOADING";

// Debt types
export const CREATE_DEBT = "CREATE_DEBT";
export const SET_DEBT = "SET_DEBT";
export const DELETE_DEBT = "DELETE_DEBT";
export const CLEAR_DEBTS = "CLEAR_DEBTS";

// Expansion Types
export const SET_EXPANSION_DATA = "SET_EXPANSION_DATA";
export const UPDATE_EXPANSION_DATA = "UPDATE_EXPANSION_DATA";
export const CLEAR_EXPANSION_DATA = "CLEAR_EXPANSION_DATA";

// Cashflow types
export const SET_CASHFLOW_DATA = "SET_CASHFLOW_DATA";
export const UPDATE_CASHFLOW_DATA = "UPDATE_CASHFLOW_DATA";
export const CLEAR_CASHFLOW_DATA = "CLEAR_CASHFLOW_DATA";

// src/store/actions/types.js

// Debt
export const FETCH_DEBT_DATA = "FETCH_DEBT_DATA";

// Expansion
export const FETCH_EXPANSION_DATA = "FETCH_EXPANSION_DATA";

// Cashflow
export const FETCH_CASHFLOW_DATA = "FETCH_CASHFLOW_DATA";
export const UPDATE_DEBT = "UPDATE_DEBT";
export const UPDATE_TRANCHES = "UPDATE_TRANCHES";
export const UPDATE_PURCHASE_PRICE = "UPDATE_PURCHASE_PRICE";
export const UPDATE_MINIDEAL = "UPDATE_MINIDEAL";
export const FETCH_DEBT = "FETCH_DEBT";
export const FETCH_TRANCHES = "FETCH_TRANCHES";
export const FETCH_PURCHASE_PRICE = "FETCH_PURCHASE_PRICE";
export const SET_LANGUAGE = "SET_LANGUAGE";

export const SET_PROMPT = "SET_PROMPT";
export const CLEAR_PROMPT = "CLEAR_PROMPT";
export const SET_OPEN_VOICE_AI = "SET_OPEN_VOICE_AI";
export const SET_ORGANIZATION = "SET_ORGANIZATION";
export const SET_LOGO = "SET_LOGO";
