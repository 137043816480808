import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "../../components/ui/breadcrumb";
import { Separator } from "../../components/ui/separator";
import { SidebarInset, SidebarProvider, SidebarTrigger } from "../../components/ui/sidebar";
import DashboardPage from "./DashboardPage";
import Cards from "./components/cards";
import DebtPricing from "./DebtPricing";
import { CombinedPage, SBAFinancingPage } from "./DebtBuilder";

import { useState, useEffect, useMemo } from "react";
import AlertDestructive from "./components/alert";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../redux/store";
import { extractFinancialItem } from "../../utils/utils";
import { fetchDebt } from "../../redux/actions/cashflowAction";
import { useTranslation } from "react-i18next";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import React from "react";

const extractLast = (cashflow, itemname) => {
  if (cashflow.length === 0) return null;
  const item = extractFinancialItem(cashflow, itemname, null, false);
  const last = item[item.length - 1];
  // console.log(last)
  return last;
};

export default function Debt() {
  const dispatch = useDispatch();
  const minideal = useSelector((state) => state.cashflow.miniDeal);
  const savedsu = useSelector((state) => state.cashflow.tranches);
  const organization = useSelector((state) => state.auth.organization);
  const useEBITDA = organization.type === "lender" ? "Adjusted EBITDA" : "Free Cash Flow";

  const debt = useSelector((state) => state.cashflow.debt);
  // console.log(minideal);
  const [purchaseCost, setPurchaseCost] = useState(2072000);
  const [amortization, setAmortization] = useState(debt?.amortization || minideal.amortization || 20);
  const [dscr, setDscr] = useState(debt?.dscr || minideal.dscr || 2.0);
  const [cashSweep, setCashSweep] = useState(debt?.cashSweep || 0);
  const [maximumDebt, setMaximumDebt] = useState(0);
  const [customDebt, setCustomDebt] = useState(debt?.customDebt || savedsu?.debtOutstanding || 0);
  const [cashAlert, setcashAlert] = useState(true);
  const transaction = useSelector((state) => state.transactions?.transaction);
  const cashflow = useSelector((state) => state.cashflow.tableData.cashFlow);
  const [currentSchedule, setCurrentSchedule] = useState(savedsu?.schedule || null);
  const [originalSchedule, setOriginalSchedule] = useState(null);
  const [isUsingBuilderSchedule, setIsUsingBuilderSchedule] = useState(savedsu?.schedule ? true : false);

  // Initialize sources from savedsu if available
  const [sources, setSources] = useState(
    savedsu?.sourceDebts ||
      debt?.sources || [
        {
          name: "SBA 7(a) Loan",
          principal: 0,
          principalInput: "0",
        },
      ],
  );

  // Initialize tranches from savedsu if available
  const [tranches, setTranches] = useState(savedsu?.tranches || debt?.tranches || []);

  // Initialize trancheTerms from savedsu if available
  const [trancheTerms, setTrancheTerms] = useState(
    debt?.trancheTerms ||
      (savedsu?.tranches
        ? savedsu.tranches.map((e) => ({
            ...e,
          }))
        : []),
  );

  // Initialize uses from savedsu if available - ensure it's in array format
  const defaultUses = [
    { key: "businessAcquisition", label: "Business Acquisition", value: 0, inputValue: "0", readOnly: false },
    { key: "realEstate", label: "Real Estate", value: 0, inputValue: "0", readOnly: false },
    { key: "legalcosts", label: "Legal Costs", value: 0, inputValue: "0", readOnly: false },
    { key: "equipmentAndMachinery", label: "M&E Purchase", value: 0, inputValue: "0", readOnly: false },
    { key: "transactionExpense", label: "Transaction Expense", value: 0, inputValue: "0", readOnly: false },
    { key: "sbaGtyFee", label: "SBA Gty Fee", value: 0, inputValue: "0", readOnly: true },
    { key: "workingCapital", label: "Working Capital", value: 0, inputValue: "0", readOnly: false },
  ];

  // Use savedsu.uses directly if it exists since it's already in the correct format
  const [uses, setUses] = useState(savedsu?.uses || (debt?.uses ? convertUsesObjectToArray(debt.uses) : defaultUses));

  // Helper function to convert uses object to array format
  function convertUsesObjectToArray(usesObj) {
    if (!usesObj) return defaultUses;

    return Object.entries(usesObj).map(([key, value]) => ({
      key,
      label: getUseLabelFromKey(key),
      value: value || 0,
      inputValue: (value || 0).toString(),
      readOnly: key === "sbaGtyFee",
    }));
  }

  // Helper function to get label from key
  const getUseLabelFromKey = (key) => {
    const labelMap = {
      businessAcquisition: "Business Acquisition",
      realEstate: "Real Estate",
      legalcosts: "Legal Costs",
      equipmentAndMachinery: "M&E Purchase",
      transactionExpense: "Transaction Expense",
      sbaGtyFee: "SBA Gty Fee",
      workingCapital: "Working Capital",
    };
    return labelMap[key] || key;
  };

  // Initialize SBA specific data from savedsu if available
  const defaultSbaData = {
    businessAcq: { pctOfTotal: 0, amount: 0, term: 10 },
    realEstate: { pctOfTotal: 0, amount: 0, term: 25 },
    equipment: { pctOfTotal: 0, amount: 0, term: 10 },
    transactionExpense: { pctOfTotal: 0, amount: 0, term: 10 },
  };

  // Add state for newEquity
  const [newEquity, setNewEquity] = useState(savedsu?.newEquity || 0);

  // Convert savedsu.justificationRows to sbaData format if it exists
  const savedsuSbaData = savedsu?.justificationRows
    ? {
        businessAcq: {
          amount: savedsu.justificationRows.find((row) => row.label === "Business Acq")?.amount || 0,
          pctOfTotal: savedsu.justificationRows.find((row) => row.label === "Business Acq")?.pctOfTotal || 0,
          term: savedsu.justificationRows.find((row) => row.label === "Business Acq")?.term || 10,
        },
        realEstate: {
          amount: savedsu.justificationRows.find((row) => row.label === "Real Estate")?.amount || 0,
          pctOfTotal: savedsu.justificationRows.find((row) => row.label === "Real Estate")?.pctOfTotal || 0,
          term: savedsu.justificationRows.find((row) => row.label === "Real Estate")?.term || 25,
        },
        legalcosts: {
          amount: savedsu.justificationRows.find((row) => row.label === "Legal Costs")?.amount || 0,
          pctOfTotal: savedsu.justificationRows.find((row) => row.label === "Legal Costs")?.pctOfTotal || 0,
          term: savedsu.justificationRows.find((row) => row.label === "Legal Costs")?.term || 10,
        },
        equipment: {
          amount: savedsu.justificationRows.find((row) => row.label === "M&E Purchase")?.amount || 0,
          pctOfTotal: savedsu.justificationRows.find((row) => row.label === "M&E Purchase")?.pctOfTotal || 0,
          term: savedsu.justificationRows.find((row) => row.label === "M&E Purchase")?.term || 10,
        },
        transactionExpense: {
          amount: savedsu.justificationRows.find((row) => row.label === "Transaction Expense")?.amount || 0,
          pctOfTotal: savedsu.justificationRows.find((row) => row.label === "Transaction Expense")?.pctOfTotal || 0,
          term: savedsu.justificationRows.find((row) => row.label === "Transaction Expense")?.term || 10,
        },
        // Add additional fields for SBA Fees and Working Capital
        sbaFees: {
          amount: savedsu.justificationRows.find((row) => row.label === "SBA Fees")?.amount || 0,
          pctOfTotal: savedsu.justificationRows.find((row) => row.label === "SBA Fees")?.pctOfTotal || 0,
          term: savedsu.justificationRows.find((row) => row.label === "SBA Fees")?.term || 10,
        },
        workingCapital: {
          amount: savedsu.justificationRows.find((row) => row.label === "Working Capital")?.amount || 0,
          pctOfTotal: savedsu.justificationRows.find((row) => row.label === "Working Capital")?.pctOfTotal || 0,
          term: savedsu.justificationRows.find((row) => row.label === "Working Capital")?.term || 10,
        },
      }
    : null;

  const [sbaData, setSbaData] = useState(savedsuSbaData || debt?.sbaData || defaultSbaData);

  const LTM_CFADS = cashflow ? extractLast(cashflow, useEBITDA)[useEBITDA] : null;
  const interest = cashflow ? extractLast(cashflow, "Payable Interest Percentage")["Payable Interest Percentage"] : null;
  // console.log(interest);
  const [noi, setNoi] = useState(savedsu?.ltmEbitda || debt?.noi || LTM_CFADS || (minideal.projections && minideal.projections.length > 0 && Number(minideal?.projections[0]?.cashFlow)) || transaction.cashFlow);
  // console.log(noi , debt.noi , LTM)
  const [interestRate, setInterestRate] = useState(debt?.interestRate || minideal?.interestRate || interest * 100 || 16);
  // console.log(LTM_CFADS);
  // console.log(cashflow);
  // console.log(data);
  const fetch = async () => {
    const debt = await dispatch(fetchDebt(transaction.id));
    console.log(debt);
  };
  useEffect(() => {
    fetch();
  }, [transaction]);

  const calculateMaximumDebt = (fcfArray, amortizationValue, dscrValue, interestRateValue) => {
    let totalDebt = 0;
    const cfads_arr = [];
    for (let year = 0; year < amortizationValue; year++) {
      const currentNOI = Math.max(Number(fcfArray[year]), 0);
      // CFADS for the period is the current period's cash flow divided by the DSCR
      const cfads = currentNOI / dscrValue;
      // Discount the CFADS back to present value. (year + 1) because we start at year 0.
      const discountFactor = Math.pow(1 + interestRateValue / 100, year + 1);
      totalDebt += cfads / discountFactor;
      cfads_arr.push(cfads);
    }
    return Math.round(totalDebt);
  };

  // Use useMemo for fcf calculation
  const fcf = useMemo(() => {
    let result = [];
    if (cashflow) {
      result = extractFinancialItem(cashflow, useEBITDA, null, organization.type === "lender" ? false : true).map((e) => {
        const n = Number(e[useEBITDA]);
        return n;
      });
    } else {
      result = [noi];
    }

    // Adjust array length to match amortization
    const targetLength = amortization;

    // If result is shorter than amortization, extend it with its last element
    if (result.length < targetLength) {
      const lastValue = result.length > 0 ? result[result.length - 1] : 0;
      while (result.length < targetLength) {
        result.push(lastValue);
      }
    }
    // If result is longer than amortization, slice it
    else if (result.length > targetLength) {
      result = result.slice(0, targetLength);
    }

    return result;
  }, [cashflow, noi, amortization]);

  // Use useMemo for maximumDebt calculation
  const maximumDebtCalculated = useMemo(() => {
    if (fcf && fcf.length > 0) {
      return calculateMaximumDebt(fcf, amortization, dscr, interestRate);
    }
    return 0;
  }, [fcf, amortization, dscr, interestRate, calculateMaximumDebt]);

  // Update maximumDebt state when calculation changes
  useEffect(() => {
    setMaximumDebt(maximumDebtCalculated);
  }, [maximumDebtCalculated]);

  /**
   * Calculate the debt repayment schedule using the FCF for each period.
   * For each period, we use the corresponding fcf value instead of a fixed NOI.
   */
  const calculateDebtRepayment = React.useCallback(() => {
    // Use custom debt if provided; otherwise, start with the maximum debt calculated above.
    let debtBalance = customDebt === 0 ? maximumDebt : customDebt;
    const schedule = [];

    // If there is no free cash flow data, return an empty schedule.
    if (!fcf || fcf.length === 0) return [];

    // Loop through each period. We assume that amortization equals fcf.length.
    for (let year = 0; year < amortization; year++) {
      const currentNOI = Math.max(fcf[year]);
      const debtService = currentNOI / dscr;
      const interestPayment = debtBalance * (interestRate / 100);
      const principalPayment = Math.max(Math.min(debtService - interestPayment, debtBalance), 0);
      debtBalance -= principalPayment;
      const cashAvailable = currentNOI - interestPayment - principalPayment;
      const cashSweepPayment = Math.min(cashAvailable * cashSweep, debtBalance);
      const dividends = cashAvailable - cashSweepPayment;

      // Subtract the cash sweep payment from the remaining debt balance
      debtBalance -= cashSweepPayment;

      // Push the schedule for the current period (displaying year as 1-indexed)
      schedule.push({
        year: year + 1,
        noi: currentNOI.toFixed(2),
        debtService: debtService.toFixed(2),
        interestPayment: interestPayment.toFixed(2),
        principalPayment: principalPayment.toFixed(2),
        cashSweepPayment: cashSweepPayment.toFixed(2),
        dividends: dividends.toFixed(2),
        debtBalance: debtBalance.toFixed(2),
      });

      // If the debt is fully repaid, exit the loop early.
      if (debtBalance <= 0) break;
    }

    return schedule;
  }, [fcf, amortization, dscr, interestRate, cashSweep, maximumDebt, customDebt]);

  // Function to update sources and uses
  const updateSourcesAndUses = React.useCallback(
    (newSources, newUses) => {
      // 1) Convert newSources to final "array" form
      let finalSources = [];
      if (Array.isArray(newSources)) {
        finalSources = newSources.map((source) => ({
          name: source.name,
          principal: source.principal || 0,
          principalInput: (source.principal || 0).toString(),
        }));
      } else if (typeof newSources === "object" && newSources !== null) {
        finalSources = Object.entries(newSources).map(([name, principal]) => ({
          name,
          principal: principal || 0,
          principalInput: (principal || 0).toString(),
        }));
      }

      // 2) Compare old vs new for "sources"
      const oldSourcesStr = JSON.stringify(sources);
      const newSourcesStr = JSON.stringify(finalSources);
      if (oldSourcesStr !== newSourcesStr) {
        setSources(finalSources);
      }

      // 3) Handle uses - now directly accepting array format from DebtBuilder
      if (Array.isArray(newUses)) {
        // If newUses is already in array format, use it directly
        const oldUsesStr = JSON.stringify(uses);
        const newUsesStr = JSON.stringify(newUses);
        if (oldUsesStr !== newUsesStr) {
          setUses(newUses);
        }
      } else if (typeof newUses === "object" && newUses !== null) {
        // If newUses is in object format, convert to array format
        const finalUses = Object.entries(newUses).map(([key, value]) => {
          // Find existing use item to preserve other properties
          const existingUse = uses.find((u) => u.key === key);
          return {
            key,
            label: existingUse?.label || getUseLabelFromKey(key),
            value: value || 0,
            inputValue: (value || 0).toString(),
            readOnly: key === "sbaGtyFee" || existingUse?.readOnly,
          };
        });

        // Compare old vs new for "uses"
        const oldUsesStr = JSON.stringify(uses);
        const newUsesStr = JSON.stringify(finalUses);
        if (oldUsesStr !== newUsesStr) {
          setUses(finalUses);
        }
      }
    },
    [sources, uses, getUseLabelFromKey],
  );

  // Function to add a new tranche
  const addTranche = (tranche) => {
    setTranches([...tranches, tranche]);
    setTrancheTerms([
      ...trancheTerms,
      {
        amortization: amortization,
        dscr: dscr,
        interestRate: interestRate,
        cashSweep: cashSweep,
      },
    ]);
  };

  // Function to update tranche terms
  const updateTrancheTerm = (index, terms) => {
    const newTerms = [...trancheTerms];
    newTerms[index] = { ...newTerms[index], ...terms };
    setTrancheTerms(newTerms);
  };

  // Function to remove a tranche
  const removeTranche = (index) => {
    setTranches(tranches.filter((_, i) => i !== index));
    setTrancheTerms(trancheTerms.filter((_, i) => i !== index));
  };

  // Remove the message event listener and replace with a handler function
  const handleScheduleUpdate = React.useCallback(
    (data) => {
      const { schedule, totalDebt, sources: newSources, uses: newUses, tranches: newTranches, sbaData: newSbaData } = data;
      const allZeros = schedule.every((item) => Number(item.debtService) === 0 && Number(item.interestPayment) === 0 && Number(item.principalPayment) === 0 && Number(item.debtBalance) === 0);

      if (allZeros || totalDebt === 0) {
        setIsUsingBuilderSchedule(false);
        setCurrentSchedule(null);
        setCustomDebt(0);
      } else {
        setIsUsingBuilderSchedule(true);
        setCurrentSchedule(schedule);
        setCustomDebt(totalDebt);
      }

      // 1) Update Sources if changed
      if (newSources) {
        let finalSources = [];
        if (Array.isArray(newSources)) {
          finalSources = newSources.map((source) => ({
            name: source.name,
            principal: source.principal || 0,
            principalInput: (source.principal || 0).toString(),
          }));
        } else if (typeof newSources === "object") {
          finalSources = Object.entries(newSources).map(([name, principal]) => ({
            name,
            principal: principal || 0,
            principalInput: (principal || 0).toString(),
          }));
        }
        const newSourcesStr = JSON.stringify(finalSources);
        if (prevSourcesRef.current !== newSourcesStr) {
          setSources(finalSources);
        }
      }

      // 2) Update Uses if changed
      if (newUses) {
        // Handle uses directly if they're in array format
        if (Array.isArray(newUses)) {
          const newUsesStr = JSON.stringify(newUses);
          if (prevUsesRef.current !== newUsesStr) {
            setUses(newUses);
          }
        } else if (typeof newUses === "object") {
          // If newUses is in object format, convert to array format
          const finalUses = Object.entries(newUses).map(([key, value]) => ({
            key,
            label: getUseLabelFromKey(key),
            value: value || 0,
            inputValue: (value || 0).toString(),
            readOnly: key === "sbaGtyFee", // SBA Gty Fee is read-only
          }));

          const newUsesStr = JSON.stringify(finalUses);
          if (prevUsesRef.current !== newUsesStr) {
            setUses(finalUses);
          }
        }
      }

      // 3) Update Tranches if changed
      if (newTranches) {
        const newTranchesStr = JSON.stringify(newTranches);
        if (prevTranchesRef.current !== newTranchesStr) {
          setTranches(newTranches);

          // Current values can be accessed via closure without dependencies
          const newTterms = newTranches.map((_, idx) => {
            const currentTrancheTerms = JSON.parse(prevTrancheTermsRef.current || "[]");
            return {
              ...(currentTrancheTerms[idx] || {
                amortization,
                dscr,
                interestRate,
                cashSweep,
              }),
            };
          });

          setTrancheTerms(newTterms);
        }
      }

      // 4) Update sbaData if changed
      if (newSbaData) {
        const newSbaStr = JSON.stringify(newSbaData);
        if (prevSbaDataRef.current !== newSbaStr) {
          setSbaData(newSbaData);
        }
      }
    },
    // Only include amortization, dscr, interestRate, and cashSweep as dependencies
    // since they are accessed directly and unlikely to change frequently
    [amortization, dscr, interestRate, cashSweep, getUseLabelFromKey],
  );

  // Add refs to track previous values and avoid unnecessary re-renders
  const prevSourcesRef = React.useRef(JSON.stringify(sources));
  const prevUsesRef = React.useRef(JSON.stringify(uses));
  const prevTranchesRef = React.useRef(JSON.stringify(tranches));
  const prevTrancheTermsRef = React.useRef(JSON.stringify(trancheTerms));
  const prevSbaDataRef = React.useRef(JSON.stringify(sbaData));

  // Update refs when values change
  React.useEffect(() => {
    prevSourcesRef.current = JSON.stringify(sources);
  }, [sources]);

  React.useEffect(() => {
    prevUsesRef.current = JSON.stringify(uses);
  }, [uses]);

  React.useEffect(() => {
    prevTranchesRef.current = JSON.stringify(tranches);
  }, [tranches]);

  React.useEffect(() => {
    prevTrancheTermsRef.current = JSON.stringify(trancheTerms);
  }, [trancheTerms]);

  React.useEffect(() => {
    prevSbaDataRef.current = JSON.stringify(sbaData);
  }, [sbaData]);

  // Calculate the default schedule
  const calculatedSchedule = useMemo(() => {
    return calculateDebtRepayment();
  }, [fcf, amortization, dscr, cashSweep, interestRate, maximumDebt, customDebt]);

  // Use currentSchedule from DebtBuilder if available and form is locked, otherwise use calculated schedule
  const schedule = useMemo(() => {
    if (currentSchedule && isUsingBuilderSchedule) {
      return currentSchedule;
    }
    return calculatedSchedule;
  }, [currentSchedule, isUsingBuilderSchedule, calculatedSchedule]);

  const negdiv = useMemo(() => {
    return Math.min(...schedule.map((e) => Number(e.dividends)));
  }, [schedule]);

  const { t } = useTranslation();
  return (
    <SidebarInset>
      <header className="flex h-16 shrink-0 items-center gap-2">
        <div className="flex items-center gap-2 px-4">
          <SidebarTrigger className="-ml-1" />
          <Separator orientation="vertical" className="mr-2 h-4" />
          <Breadcrumb>
            <BreadcrumbList>
              {/* <BreadcrumbItem className="hidden md:block">
                <BreadcrumbLink href="#">Building Your Application</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator className="hidden md:block" /> */}
              <BreadcrumbItem>
                <BreadcrumbPage>
                  {
                    // @ts-ignore
                    t("debt.breadcrumb")
                  }
                </BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </header>
      <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
        {/* <Cards /> */}
        {maximumDebt < customDebt && (
          <AlertDestructive
            setCustomDebt={(value) => {
              setCustomDebt(value);
              // Reset all schedule states
              setIsUsingBuilderSchedule(false);
              setCurrentSchedule(null);
              setSources([
                {
                  name: "SBA 7(a) Loan",
                  principal: 0,
                  principalInput: "0",
                },
              ]);
              setUses(defaultUses);
              setTranches([]);
              setTrancheTerms([]);
              setSbaData({
                businessAcq: { pctOfTotal: 0, amount: 0, term: 10 },
                realEstate: { pctOfTotal: 0, amount: 0, term: 25 },
                equipment: { pctOfTotal: 0, amount: 0, term: 10 },
                transactionExpense: { pctOfTotal: 0, amount: 0, term: 10 },
              });
              setIsUsingBuilderSchedule(false);
            }}
          />
        )}
        {customDebt > 0 && customDebt < maximumDebt && (
          <AlertDestructive
            setCustomDebt={(value) => {
              setCustomDebt(value);
              // Reset all schedule states
              setIsUsingBuilderSchedule(false);
              setCurrentSchedule(null);
              setSources([
                {
                  name: "SBA 7(a) Loan",
                  principal: 0,
                  principalInput: "0",
                },
              ]);
              setUses(defaultUses);
              setTranches([]);
              setTrancheTerms([]);
              setSbaData({
                businessAcq: { pctOfTotal: 0, amount: 0, term: 10 },
                realEstate: { pctOfTotal: 0, amount: 0, term: 25 },
                equipment: { pctOfTotal: 0, amount: 0, term: 10 },
                transactionExpense: { pctOfTotal: 0, amount: 0, term: 10 },
              });
              setIsUsingBuilderSchedule(false);
            }}
            buttonText={t("alert.set_maximum_debt")}
            title={t("alert.custom_debt_title")}
            description={t("alert.custom_debt_description")}
            variant={0}
          />
        )}
        {negdiv < 0 && customDebt == 0 && <AlertDestructive setCustomDebt={setCustomDebt} buttonText={t("alert.go_to_cashflow")} title={t("alert.custom_debt_title_2")} description={t("alert.custom_debt_description_2")} gotocash={1} />}
        <div className="grid auto-rows-min gap-y-4 gap-x-4 md:grid-cols-2">
          <div className="col-span-2">
            <Tabs defaultValue="bottom" className="w-full">
              <TabsList className="grid w-full grid-cols-2 mb-4">
                <TabsTrigger value="bottom">Bottom Up</TabsTrigger>
                <TabsTrigger value="top">Top Down</TabsTrigger>
              </TabsList>
              <TabsContent value="bottom" className="flex flex-col gap-4">
                <DebtPricing
                  noi={noi}
                  setNoi={setNoi}
                  purchaseCost={purchaseCost}
                  setPurchaseCost={setPurchaseCost}
                  amortization={amortization}
                  setAmortization={setAmortization}
                  dscr={dscr}
                  setDscr={setDscr}
                  cashSweep={cashSweep}
                  setCashSweep={setCashSweep}
                  interestRate={interestRate}
                  setInterestRate={setInterestRate}
                  maximumDebt={maximumDebt}
                  setMaximumDebt={setMaximumDebt}
                  calculateMaximumDebt={calculateMaximumDebt}
                  schedule={schedule}
                  customDebt={customDebt}
                  setCustomDebt={(value) => {
                    setCustomDebt(value);
                    if (value === 0) {
                      setIsUsingBuilderSchedule(false);
                      setCurrentSchedule(null);
                    }
                  }}
                  transaction={transaction}
                  minideal={minideal}
                  debt={debt}
                  LTM_CFADS={LTM_CFADS}
                  cashflow={cashflow}
                  fcf={fcf}
                  isCustomDebtFromBuilder={isUsingBuilderSchedule}
                />
              </TabsContent>
              <TabsContent value="top" className="flex flex-col gap-4">
                <SBAFinancingPage
                  initialLtmEbitda={LTM_CFADS ?? noi}
                  initialDebtAvailable={maximumDebt}
                  sources={sources}
                  uses={uses}
                  tranches={tranches}
                  trancheTerms={trancheTerms}
                  updateSourcesAndUses={updateSourcesAndUses}
                  addTranche={addTranche}
                  removeTranche={removeTranche}
                  updateTrancheTerm={updateTrancheTerm}
                  sbaData={sbaData}
                  setSbaData={setSbaData}
                  defaultTrancheTerms={{
                    amortization: amortization,
                    dscr: dscr,
                    interestRate: interestRate,
                    cashSweep: cashSweep,
                  }}
                  updateSchedule={handleScheduleUpdate}
                  fcfArray={fcf}
                  transaction={transaction}
                  initialNewEquity={newEquity}
                />
              </TabsContent>
            </Tabs>
          </div>
          {/* Two Dashboard Pages in a single row */}
          <div className="grid grid-cols-2 gap-4 col-span-2">
            <DashboardPage
              title={
                // @ts-ignore
                t("debt.forecasted_outstanding")
              }
              overview={true}
              schedule={schedule}
            />
            <DashboardPage
              title={
                // @ts-ignore
                t("debt.forecasted_repayment")
              }
              overview={false}
              schedule={schedule}
            />
          </div>
        </div>
      </div>
    </SidebarInset>
  );
}
