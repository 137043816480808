"use client";
import React, { useState } from "react";

import { Row } from "@tanstack/react-table";
import { MoreHorizontal } from "lucide-react";

import { toast } from "sonner";
import { Button } from "../../../components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuRadioGroup, DropdownMenuRadioItem, DropdownMenuSeparator, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuTrigger } from "../../../components/ui/dropdown-menu";
import DrawerUploaderDemo from "../../../components/components/dialog-uploader";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { updateTransactionProperty } from "../../../redux/actions/transactionAction";
import { statuses as labels } from "../data/data";
import { taskSchema } from "../data/schema";
import { DialogComponent } from "./buttonaction";
import { backendClient } from "../../../api/backend";
import Spinner from "../../../components/ui/Spinner";

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
}

export function DataTableRowActions<TData>({ row }: DataTableRowActionsProps<TData>) {
  const task = taskSchema.parse(row.original);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const transaction = useSelector((state: any) => state.transactions.transaction);
  const transactions = useSelector((state: any) => state.transactions?.transactions);
  const organization = useSelector((state: any) => state.auth.organization);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [isdialogOpen, setIsdialogOpen] = useState(false);
  const { t } = useTranslation(); // ✅ Use translation hook

  const dialogContent = {
    // @ts-ignore
    title: t("rowActions.addDealTitle"),
    // @ts-ignore
    description: t("rowActions.addDealDescription"),
  };

  const [curtr, setcurTr] = useState(null);

  const handleAction = async (action: any, label) => {
    console.log(row);
    const append = false;
    const remove = false;
    setIsDropdownOpen(false);
    if (action === "status") {
      const tr = await dispatch(updateTransactionProperty(task.id, { pipelineStatus: label }));
      console.log(tr);
      // dispatch(updateTransaction);
      // @ts-ignore
      toast(t("rowActions.statusUpdated"), {
        // @ts-ignore
        description: t("rowActions.statusChanged", { status: label }),
      });
    } else if (action === "upload") {
      setDrawerOpen(true);
    } else if (action === "compare") {
      await dispatch(updateTransactionProperty(task.id, { addToCompare: true }));
      toast.success("Transaction added to the benchmarking table");
      console.log("compare");
    }
  };

  const handleDialogClose = () => {
    setIsdialogOpen(false);
  };

  const handleEdit = (event) => {
    event.preventDefault();
    setIsDropdownOpen(false);
    const t = transactions.find((e) => e.id === task.id);
    console.log(t);
    setcurTr(t);
    setIsdialogOpen(true);
  };

  const handleExportToPowerPoint = async () => {
    try {
      setIsExporting(true);
      setIsDropdownOpen(false);
      const transactionId = task.id;
      const blob = await backendClient.exportToPowerPoint(transactionId);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `presentation-${transactionId}.pptx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      toast.success("PowerPoint downloaded successfully!");
    } catch (error) {
      console.error("Error downloading PowerPoint:", error);
      toast.error("Failed to download PowerPoint");
    } finally {
      setIsExporting(false);
    }
  };

  const handleExportToExcel = async () => {
    try {
      setIsExporting(true);
      setIsDropdownOpen(false);
      const transactionId = task.id;
      const blob = await backendClient.exportToExcel(transactionId);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `deal-data-${transactionId}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      toast.success("Excel file downloaded successfully!");
    } catch (error) {
      console.error("Error downloading Excel file:", error);
      toast.error("Failed to download Excel file");
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <>
      {isExporting ? (
        <div className="flex h-8 w-8 items-center justify-center">
          <Spinner size={20} />
        </div>
      ) : (
        <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="flex h-8 w-8 p-0 data-[state=open]:bg-muted">
              <MoreHorizontal />
              <span className="sr-only">
                {
                  // @ts-ignore
                  t("rowActions.openMenu")
                }
              </span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-[160px]">
            <DropdownMenuItem onClick={handleEdit}>
              {
                // @ts-ignore
                t("rowActions.edit")
              }
            </DropdownMenuItem>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                {
                  // @ts-ignore
                  t("rowActions.statusUpdate")
                }
              </DropdownMenuSubTrigger>
              <DropdownMenuSubContent>
                <DropdownMenuRadioGroup value={task.pipelineStatus}>
                  {labels.map((label) => (
                    <DropdownMenuRadioItem key={label.value} value={label.value} onClick={() => handleAction("status", label.value)}>
                      {label.label}
                    </DropdownMenuRadioItem>
                  ))}
                </DropdownMenuRadioGroup>
              </DropdownMenuSubContent>
            </DropdownMenuSub>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>Export</DropdownMenuSubTrigger>
              <DropdownMenuSubContent>
                <DropdownMenuItem disabled={organization?.type === "lender"} onSelect={handleExportToPowerPoint}>
                  PowerPoint
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={handleExportToExcel}>Excel</DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuSub>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => handleAction("compare", "")}>
              {
                // @ts-ignore
                // t("rowActions.delete")
                "Compare"
              }
            </DropdownMenuItem>
            <DropdownMenuItem
              disabled={
                // @ts-ignore
                row?.original?.offerMemorandumUploaded
              }
              onClick={() => handleAction("upload", "")}
            >
              {
                // @ts-ignore
                // t("rowActions.delete")
                "Upload CIM"
              }
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
      <DialogComponent isOpen={isdialogOpen && !isDropdownOpen} onClose={handleDialogClose} dialogContent={dialogContent} selectedDealType={"sell"} transaction={curtr} />
      <DrawerUploaderDemo row={row} isDrawerOpen={isDrawerOpen && !isDropdownOpen} setDrawerOpen={setDrawerOpen} description="Drag and Drop your Memorandum here" />
    </>
  );
}
