import { AppStateContext, InteractionMode } from "./AppStateContext";
import { useDeferredValue, useEffect, useState } from "react";

const Q = "q";

interface Props {
  websocketEnabled: boolean;
  webrtcEnabled: boolean;
}

export const AppStateProvider: React.FC<React.PropsWithChildren<Props>> = ({ children, webrtcEnabled, websocketEnabled }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const q = searchParams.get(Q);

  const [interactionMode, setInteractionMode] = useState<InteractionMode>("informational");

  const [searchQuery, setSearchQuery] = useState(q ?? "");
  const deferredSearchQuery = useDeferredValue(searchQuery);

  useEffect(() => {
    const searchParams = new URLSearchParams();
    if (q) searchParams.append(Q, q);
    // window.history.replaceState(null, "", `/?${searchParams.toString()}`);
  }, [q]);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Logic to end the call
    }, 900000); // 15 minutes in milliseconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <AppStateContext.Provider
      value={{
        interactionMode,
        setInteractionMode,
        searchQuery: deferredSearchQuery,
        setSearchQuery,
        webrtcEnabled,
        websocketEnabled,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};
