"use client";
import React, { useEffect, useState } from "react";
import { Row } from "@tanstack/react-table";
import { MoreHorizontal } from "lucide-react";

import { Button } from "../../../components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuRadioGroup, DropdownMenuRadioItem, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuTrigger } from "../../../components/ui/dropdown-menu";
import DrawerUploaderDemo from "../../../components/components/dialog-uploader";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions, setTransaction } from "../../../redux/actions/transactionAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { labels } from "../data/data";
import { taskSchema } from "../data/schema";
import { clearDocuments, delDocument, setDocumentNa } from "../../../redux/actions/documentsAction";
import { toast } from "sonner";
import { useDocumentStatus } from "../../../hooks/useDocuments";
import { useNotifications } from "../../../router/NotificationsProvider";
import { and, collection, or, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import { useTranslation } from "react-i18next";

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  onAction: (row: any, actionType: string) => void; // Add this property

  // onAction: (action: string, rowData: TData) => void; // Callback function to handle actions
}

export function DataTableRowActions<TData>({ row, onAction }: DataTableRowActionsProps<TData>) {
  // console.log(onAction(row, "edit"));
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const transaction = useSelector((state: any) => state.transactions.transaction);
  const task = taskSchema.parse(row.original);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleAction = (action: string) => {
    // console.log(row);
    setDrawerOpen(true);
  };
  const navigate = useNavigate(); // <== useNavigate for routing
  const location = useLocation();
  console.log(row);
  const transactionId = transaction?.id;
  // @ts-ignore
  const taskKey = `/deals/${transactionId}/${row.original.type}s/${row.original.id}/status`;

  const actions = {
    upload: task.status === "pending",
    preview: task.status === "uploaded" || task.status === "validated",
    edit: task.status === "uploaded" || task.status === "validated",
    na: task.status === "pending",
    delete: task.status !== "pending",
  };

  const handleValidate = (task, preview = false) => {
    navigate(`/validation/${task.id}`, {
      state: {
        // file: files,
        previous_screen: location.pathname,
        // You can pass additional data here, e.g.:
        row: row.original,
        upload: true,
        preview: preview,
      },
    });
  };
  const { t } = useTranslation();
  const handleNa = async (task) => {
    if (!transaction) return;
    const type = `${(row.original as any).type}s`;
    // await dispatch(clearDocuments());
    const doc = await dispatch(setDocumentNa(transaction.id, type, task.id));
    // @ts-ignore
    toast(t("rowActions.statusUpdated"), {
      // @ts-ignore
      description: t("rowActions.noLongerRequired", { title: task.title }),
    });
    console.log(doc);
  };

  const handleDelete = async (task) => {
    if (!transaction) return;
    const type = `${(row.original as any).type}s`;
    // await dispatch(clearDocuments());
    const doc = await dispatch(delDocument(transaction.id, type, task.id));
    console.log(doc);
    await onAction(row, "edit");
    // @ts-ignore
    toast(t("rowActions.statusUpdated"), {
      // @ts-ignore
      description: t("rowActions.modify", { title: task.title }),
    });
  };

  return (
    <>
      <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="flex h-8 w-8 p-0 data-[state=open]:bg-muted">
            <MoreHorizontal />
            <span className="sr-only">
              {
                // @ts-ignore
                t("rowActions.openMenu")
              }
            </span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          <DropdownMenuItem disabled={!actions.upload} onClick={() => handleAction("upload")}>
            {
              // @ts-ignore
              t("rowActions.upload")
            }
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleValidate(task, task.status === "validated")} disabled={!actions.preview}>
            {
              // @ts-ignore
              t("rowActions.preview")
            }
          </DropdownMenuItem>
          {/* <DropdownMenuItem disabled={!actions.edit}>Edit</DropdownMenuItem> */}
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => handleNa(task)} disabled={!actions.na}>
            {
              // @ts-ignore
              t("rowActions.notApplicable")
            }
            {/* <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut> */}
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              handleDelete(task);
            }}
            disabled={!actions.delete}
          >
            {
              // @ts-ignore
              t("rowActions.delete")
            }

            <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <DrawerUploaderDemo row={row} isDrawerOpen={isDrawerOpen && !isDropdownOpen} setDrawerOpen={setDrawerOpen} />
    </>
  );
}
