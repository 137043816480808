import React, { useState } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip as RechartTooltip, CartesianGrid, Legend, ResponsiveContainer } from "recharts";
import { RadioGroup, RadioGroupItem } from "../../../components/ui/radio-group";
import { Slider } from "../../../components/ui/slider";
import { FinancialRow } from "./reactgrid";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogDescription } from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import { Label } from "../../../components/ui/label";
import { Separator } from "../../../components/ui/separator";

interface ForecastDialogProps {
  row: FinancialRow;
  years: string[];
  isOpen: boolean;
  onClose: () => void;
  onApply: (type: string, sliders: number[]) => void;
}

const ForecastDialog: React.FC<ForecastDialogProps> = ({ row, years, isOpen, onClose, onApply }) => {
  const [forecastType, setForecastType] = useState<string>("percentRevenue");
  const [customSliders, setCustomSliders] = useState<number[]>([0, 0, 0]);

  const dataForChart = years.map((y) => ({
    year: y,
    value: row.valuesByYear[y] ?? 0,
  }));

  const handleApply = () => {
    onApply(forecastType, customSliders);
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-[80%]">
        <DialogHeader>
          <DialogTitle>Forecast Settings</DialogTitle>
          <DialogDescription>
            Configure how <b>{row.displayName}</b> values will be forecasted.
          </DialogDescription>
        </DialogHeader>
        <div className="grid justify-evenly grid-cols-3 gap-4">
          <div className="space-y-4">
            <RadioGroup value={forecastType} onValueChange={(val) => setForecastType(val)}>
              <div className="flex items-center space-x-2 mb-2">
                <RadioGroupItem id="percentRevenue" value="percentRevenue" />
                <Label htmlFor="percentRevenue">As % of Revenue</Label>
              </div>
              <div className="flex items-center space-x-2 mb-2">
                <RadioGroupItem id="globalGrowth" value="globalGrowth" />
                <Label htmlFor="globalGrowth">Global Growth Rate</Label>
              </div>
              <div className="flex items-center space-x-2 mb-2">
                <RadioGroupItem id="inflation" value="inflation" />
                <Label htmlFor="inflation">Inflation-based</Label>
              </div>
              <div className="flex items-center space-x-2 mb-2">
                <RadioGroupItem id="custom" value="custom" />
                <Label htmlFor="custom">Custom Growth</Label>
              </div>
            </RadioGroup>
          </div>

          {forecastType === "custom" && (
            <>
              <Separator className="justify-self-center" orientation="vertical" />
              <div className="space-y-4">
                {/* <p>Adjust 1st, 3rd, 5th year multiples (example):</p> */}
                <div className="flex flex-col gap-2">
                  <Label>Year 1 factor</Label>
                  {/* @ts-ignore */}
                  <Slider defaultValue={[customSliders[0]]} max={2} step={0.05} onValueChange={([val = 0]) => setCustomSliders((prev) => [val, prev[1], prev[2]])} />
                  <Label>Year 3 factor</Label>
                  {/* @ts-ignore */}
                  <Slider defaultValue={[customSliders[1]]} max={2} step={0.05} onValueChange={([val = 0]) => setCustomSliders((prev) => [prev[0], val, prev[2]])} />
                  <Label>Year 5 factor</Label>
                  {/* @ts-ignore */}
                  <Slider defaultValue={[customSliders[2]]} max={2} step={0.05} onValueChange={([val = 0]) => setCustomSliders((prev) => [prev[0], prev[1], val])} />
                </div>
              </div>
            </>
          )}
          <div className="col-span-3 w-full h-[300px] mt-4 bg-white">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={dataForChart}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" />
                <YAxis />
                <RechartTooltip />
                <Legend />
                <Line type="monotone" dataKey="value" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <DialogFooter>
          <Button variant={"ghost"} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleApply}>Apply</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ForecastDialog;
