"use client";
import React, { useRef, useState, useEffect, useMemo } from "react";

import { ColumnDef } from "@tanstack/react-table";
import { Badge } from "../../../components/ui/badge";
import { Checkbox } from "../../../components/ui/checkbox";
import { Slider } from "../../../components/ui/slider";
import { Input } from "../../../components/ui/input";
import { labels, priorities, statuses } from "../data/data";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";
import { DataTableRowActions } from "./data-table-row-actions";
import { Row } from "@tanstack/react-table";

interface PurchasePriceCellProps {
  row: Row<Task>;
  onPurchasePriceChange: (taskId: string, newPrice: number) => void;
}

export function PurchasePriceCell({ row, onPurchasePriceChange }: PurchasePriceCellProps) {
  // The row’s data
  const task = row.original;
  const taskId = task.id;

  // Store the initial price in a ref, so it doesn’t change
  const initPriceRef = useRef<number>(task.purchasePrice ?? 0);

  // Local state for the current slider value
  const [sliderValue, setSliderValue] = useState<number>(task.purchasePrice ?? 0);

  // If the parent changes the purchasePrice for some reason,
  // sync that into our local slider state:
  useEffect(() => {
    if (typeof task.purchasePrice === "number") {
      setSliderValue(task.purchasePrice);
    }
  }, [task.purchasePrice]);

  // A stable max range for the slider (3x the initial price, for example)
  const sliderMax = initPriceRef.current * 3 || 300000;

  return (
    <div className="flex flex-col gap-2 w-[200px]">
      <span className="text-xs text-muted-foreground">{sliderValue.toLocaleString()}</span>

      <Slider
        value={[sliderValue]}
        min={0}
        max={sliderMax}
        step={10_000}
        // Update local state and call parent callback on drag
        onValueChange={(val) => {
          if (!val || typeof val[0] !== "number") return;
          setSliderValue(val[0]);
          onPurchasePriceChange(taskId, val[0]); // Move this here to update on slide
        }}
      />
    </div>
  );
}

export const getColumns = (t: Function, onPurchasePriceChange: (taskId: string, newPrice: number) => void, onScoreChange: (taskId: string, newScore: number) => void): ColumnDef<Task>[] => [
  {
    accessorKey: "name",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.opportunity")} />,
    cell: ({ row }) => {
      const label = row.original.country && row.original.country === "" ? false : row.original.country;
      return (
        <div className="flex space-x-2">
          {label && <Badge variant="outline">{label}</Badge>}
          <span className="max-w-[500px] truncate font-medium">{row.getValue("name")}</span>
        </div>
      );
    },
  },
  {
    accessorKey: "pipelineStatus",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.status")} />,
    cell: ({ row }) => {
      const status = statuses.find((s) => s.value === row.getValue("pipelineStatus"));
      if (!status) {
        return null;
      }
      return (
        <div className="flex w-[100px] items-center">
          {status.icon && <status.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
          <span>{status.label}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "city",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.location")} />,
    cell: ({ row }) => {
      return (
        <div className="flex w-[100px] items-center">
          <span>{row.getValue("city")}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
  },
  {
    accessorKey: "industry",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.industry")} />,
    cell: ({ row }) => {
      const label = labels.find((l) => l.value === row.original.industry);
      return (
        <div className="flex w-[100px] items-center">
          {label?.icon && <label.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
          <span>{row.getValue("industry")}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
  },
  {
    accessorKey: "estPrice",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.estPrice")} />,
    cell: ({ row }) => {
      return (
        <div className="flex w-[100px] items-center">
          <span>{row.getValue("estPrice")}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
  },
  {
    accessorKey: "askPrice",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.askPrice")} />,
    cell: ({ row }) => {
      const ask = Number(row.getValue("askPrice"));
      return (
        <div className="flex w-[100px] items-center">
          <span>{ask.toLocaleString()}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
  },
  // PURCHASE PRICE + SLIDER
  {
    accessorKey: "purchasePrice",
    header: ({ column }) => <DataTableColumnHeader column={column} title="Purchase Price" />,
    cell: ({ row }) => {
      return <PurchasePriceCell row={row} onPurchasePriceChange={onPurchasePriceChange} />;
    },
  },
  {
    accessorKey: "irr",
    header: () => <div className="text-right">IRR (%)</div>,
    cell: ({ row }) => {
      const irr = row.getValue("irr") as number;
      return <div className="text-right w-[80px]">{Number.isFinite(irr) ? irr.toFixed(2) + "%" : "N/A"}</div>;
    },
  },
  {
    accessorKey: "payback",
    header: () => <div className="text-right">Payback (yrs)</div>,
    cell: ({ row }) => {
      const payback = row.getValue("payback") as number;
      return <div className="text-right w-[80px]">{Number.isFinite(payback) ? payback.toFixed(2) : "N/A"}</div>;
    },
  },
  {
    accessorKey: "cashOnCashReturn",
    header: () => <div className="text-right">CoC Return</div>,
    cell: ({ row }) => {
      const coc = row.getValue("cashOnCashReturn") as number;
      return <div className="text-right w-[80px]">{Number.isFinite(coc) ? coc.toFixed(2) + "x" : "N/A"}</div>;
    },
  },
  {
    accessorKey: "EBITDAMultiple",
    header: () => <div className="text-right">EBITDA Multiple</div>,
    cell: ({ row }) => {
      const profit = row.getValue("EBITDAMultiple") as number;
      return <div className="text-right w-[80px]">{Number.isFinite(profit) ? profit.toFixed(2) + "x" : "N/A"}</div>;
    },
  },
  {
    accessorKey: "equityMultiple",
    header: () => <div className="text-right">Equity Multiple</div>,
    cell: ({ row }) => {
      const multiple = row.getValue("equityMultiple") as number;
      return <div className="text-right w-[80px]">{Number.isFinite(multiple) ? multiple.toFixed(2) + "x" : "N/A"}</div>;
    },
  },
  // NEW SCORE COLUMN
  {
    accessorKey: "score",
    header: ({ column }) => <DataTableColumnHeader column={column} title="Score" />,
    // Let the table sort by numeric value
    enableSorting: true,
    sortingFn: (a, b) => {
      const scoreA = a.getValue<number>("score") ?? 0;
      const scoreB = b.getValue<number>("score") ?? 0;
      return scoreA - scoreB;
    },
    cell: ({ row }) => {
      const taskId = row.original.id;
      // We read the "score" from the row
      const scoreVal = row.getValue<number>("score") ?? 0;

      return (
        <Input
          type="number"
          className="w-[80px] border border-gray-300 p-1 rounded-sm"
          value={scoreVal}
          onChange={(e) => {
            const value = Number(e.target.value);
            onScoreChange(taskId, isNaN(value) ? 0 : value);
          }}
        />
      );
    },
  },
  {
    id: "actions",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.actions")} />,
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
];
