import React, { createContext, useContext, useRef, useEffect, ReactNode, useState, useCallback, useMemo } from "react";
import { onSnapshot, Query, DocumentData, FirestoreError } from "firebase/firestore";
import { toast } from "sonner";

interface ListenerInfo {
  unsubscribe: () => void;
}

interface AddQueryListenerOptions {
  /**
   * A unique key representing this listener.
   * This key will be used to avoid duplicate listeners and to remove them later.
   */
  key: string;
  /**
   * The Firestore query object (can be a doc or collection query).
   */
  query: Query<DocumentData>;
  /**
   * Optional callback that runs for each snapshot.
   * If omitted, we just store data in `observedData`.
   */
  onSnapshotCallback?: (docs: DocumentData[], snapshot: any) => void;
  /**
   * Optional toast configuration function. Receives the docs from the snapshot.
   * Return { title, description } for a dynamic toast message (or null if no toast).
   */
  toastConfig?: (
    docs: DocumentData[],
    snapshot: any,
  ) =>
    | {
        title: string;
        description: string;
      }
    | null
    | Array<{ title: string; description: string }>;
}

interface NotificationsContextType {
  /**
   * Listen to any Firestore query (doc or collection) with a unique key.
   */
  addQueryListener: (options: AddQueryListenerOptions) => void;
  /**
   * Remove the listener associated with a given key.
   */
  removeListener: (key: string) => void;
  /**
   * Get the list of active listener keys.
   */
  getListeners: () => string[];
  /**
   * Observed data from all listeners, stored by key.
   */
  observedData: Record<string, DocumentData[]>;
  getObservedDocs: (key: string) => DocumentData[]; // Helper to retrieve docs
}

const NotificationsContext = createContext<NotificationsContextType | undefined>(undefined);

export const NotificationsProvider = ({ children }: { children: ReactNode }) => {
  const listenersRef = useRef<Map<string, ListenerInfo>>(new Map());
  /**
   * Observed data structure:
   *   {
   *     [key]: DocumentData[]
   *   }
   */
  const [observedData, setObservedData] = useState<Record<string, DocumentData[]>>({});

  /**
   * Add a query-based listener
   */
  const addQueryListener = useCallback((options: AddQueryListenerOptions) => {
    const { key, query, onSnapshotCallback, toastConfig } = options;

    // Avoid duplicate listener for the same key
    if (listenersRef.current.has(key)) {
      console.log(`🔎 [NotificationsProvider] Listener with key="${key}" already exists.`);
      return;
    }

    console.log(`✅ Adding listener: ${key}`);

    const unsubscribe = onSnapshot(
      query,
      (snapshot) => {
        const docs = snapshot.docs.map((doc) => ({
          id: doc.id, // explicitly include the doc's ID
          ...doc.data(),
        }));

        // 1. Store the docs in state if needed
        setObservedData((prev) => ({
          ...prev,
          [key]: docs,
        }));

        // 2. Run a custom callback if provided
        if (onSnapshotCallback) {
          onSnapshotCallback(docs, snapshot);
        }

        // 3. Run a toast if configured
        if (toastConfig) {
          const config = toastConfig(docs, snapshot);
          // config can be:
          // - null (no toast)
          // - single { title, description }
          // - array of { title, description } for multiple toasts
          if (Array.isArray(config)) {
            config.forEach((toastObj) => {
              toast(toastObj.title, { description: toastObj.description });
            });
          } else if (config) {
            toast(config.title, {
              description: config.description,
            });
          }
        }
      },
      (error: FirestoreError) => {
        console.error(`[NotificationsProvider] Error in listener "${key}":`, error);
      },
    );

    listenersRef.current.set(key, { unsubscribe });
  }, []);

  /**
   * Remove a listener by key
   */
  const removeListener = useCallback((key: string) => {
    const listener = listenersRef.current.get(key);
    if (listener) {
      console.log(`🛑 Removing listener: ${key}`);
      listener.unsubscribe();
      listenersRef.current.delete(key);

      // Optionally clear data from observedData
      setObservedData((prev) => {
        const copy = { ...prev };
        delete copy[key];
        return copy;
      });
    }
  }, []);

  /**
   * Return all active listener keys
   */
  const getListeners = useCallback(() => {
    return Array.from(listenersRef.current.keys());
  }, []);

  /**
   * Get observed docs by key with error handling
   */
  const getObservedDocs = useCallback(
    (key: string): DocumentData[] => {
      return observedData[key] ?? [];
    },
    [observedData],
  );

  /**
   * Cleanup on unmount
   */
  useEffect(() => {
    console.log("👀 NotificationsProvider Mounted");
    return () => {
      console.log("♻️ Cleaning up all listeners on provider unmount...");
      listenersRef.current.forEach(({ unsubscribe }) => unsubscribe());
      listenersRef.current.clear();
    };
  }, []);

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      addQueryListener,
      removeListener,
      getListeners,
      observedData,
      getObservedDocs,
    }),
    [addQueryListener, removeListener, getListeners, observedData, getObservedDocs],
  );

  return <NotificationsContext.Provider value={contextValue}>{children}</NotificationsContext.Provider>;
};

export const useNotifications = (): NotificationsContextType => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error("useNotifications must be used within a NotificationsProvider");
  }
  return context;
};
