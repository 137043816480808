"use client";
import React, { useState } from "react";

import { Row } from "@tanstack/react-table";
import { MoreHorizontal } from "lucide-react";

import { Button } from "../../../components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuRadioGroup, DropdownMenuRadioItem, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuTrigger } from "../../../components/ui/dropdown-menu";
import DrawerUploaderDemo from "../../../components/components/dialog-uploader";
import { toast } from "sonner";

import { statuses as labels } from "../data/data";
import { taskSchema } from "../data/schema";
import { Textarea } from "../../../components/ui/textarea";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions, updateTransactionProperty } from "../../../redux/actions/transactionAction";
import { setOpenVoiceAI, setPrompt } from "../../../redux/actions/promptAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { DialogComponent } from "./buttonaction";
import { useDocumentStatus } from "../../../hooks/useDocuments";
// import { addListener, removeListener } from "@reduxjs/toolkit";
// import { useNotifications } from "../../../router/NotificationsProvider";
import { useNotifications } from "../../../router/NotificationsProvider";
import { useTranslation } from "react-i18next";
import { backendClient } from "../../../api/backend";

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
}

export function DataTableRowActions<TData>({ row }: DataTableRowActionsProps<TData>) {
  const task = taskSchema.parse(row.original);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const transaction = useSelector((state: any) => state.transactions.transaction);
  const transactions = useSelector((state: any) => state.transactions?.transactions);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isdialogOpen, setIsdialogOpen] = useState(false);
  const { t } = useTranslation(); // ✅ Use translation hook

  const dialogContent = {
    // @ts-ignore
    title: t("rowActions.addDealTitle"),
    // @ts-ignore
    description: t("rowActions.addDealDescription"),
  };

  const [curtr, setcurTr] = useState(null);

  const handleAction = async (action: any, label) => {
    console.log(row);
    const append = false;
    const remove = false;
    if (action === "remove") {
      await dispatch(updateTransactionProperty(task.id, { addToCompare: false }));
      toast.success("Transaction removed from the benchmarking table");
      console.log("compare");
    }
    setIsDropdownOpen(false);
  };

  const handleDialogClose = () => {
    setIsdialogOpen(false);
  };
  console.log(transactions.filter((t) => t.addToCompare === true));
  const handleEdit = async (event) => {
    event.preventDefault();
    setIsDropdownOpen(false);
    const memo = await dispatch(setPrompt(task.id));
    console.log("memo", memo);
    dispatch(setOpenVoiceAI(true));
    // const aiButton = document.querySelector<HTMLButtonElement>("#askAiButton");
    // if (aiButton) {
    //   aiButton.click();
    // } else {
    //   console.warn("Could not find #askAiButton on the page!");
    // }
  };
  // console.log("row", row.original);
  // @ts-ignore
  const voiceAvail = row?.original?.offerMemorandumUploaded;
  return (
    <>
      <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="flex h-8 w-8 p-0 data-[state=open]:bg-muted">
            <MoreHorizontal />
            <span className="sr-only">
              {
                // @ts-ignore
                t("rowActions.openMenu")
              }
            </span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          {/* <DropdownMenuItem onClick={() => handleAction("upload")}>Status Update</DropdownMenuItem> */}
          {/* <DropdownMenuItem>Make a copy</DropdownMenuItem> */}
          {/* <DropdownMenuItem onClick={handleEdit}>
            {
              // @ts-ignore
              // t("rowActions.edit")
              "Upload Memo"
            }
          </DropdownMenuItem> */}
          <DropdownMenuItem disabled={!voiceAvail} onClick={handleEdit}>
            {
              // @ts-ignore
              // t("rowActions.edit")
              "Voice Chat"
            }
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleAction("remove", "")} className="text-red-600">
            {
              // @ts-ignore
              // t("rowActions.edit")
              "Remove"
            }
          </DropdownMenuItem>
          {/* <DropdownMenuSeparator /> */}
          {/* <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              {
                // @ts-ignore
                t("rowActions.statusUpdate")
              }
            </DropdownMenuSubTrigger>
            <DropdownMenuSubContent>
              <DropdownMenuRadioGroup value={task.pipelineStatus}>
                {labels.map((label) => (
                  <DropdownMenuRadioItem key={label.value} value={label.value} onClick={() => handleAction(row, label.value)}>
                    {label.label}
                  </DropdownMenuRadioItem>
                ))}
              </DropdownMenuRadioGroup>
            </DropdownMenuSubContent>
          </DropdownMenuSub> */}
        </DropdownMenuContent>
      </DropdownMenu>
      <DialogComponent isOpen={isdialogOpen && !isDropdownOpen} onClose={handleDialogClose} dialogContent={dialogContent} selectedDealType={"sell"} transaction={curtr} />
    </>
  );
}
