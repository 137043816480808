"use client";

import { ColumnDef } from "@tanstack/react-table";

import { Badge } from "../../../components/ui/badge";
import { Checkbox } from "../../../components/ui/checkbox";

import { labels, priorities, statuses } from "../data/data";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";
import { DataTableRowActions } from "./data-table-row-actions";

export const getColumns = (t: Function, onAction: (rowData: Task, actionType: string) => void): ColumnDef<Task>[] => [
  {
    accessorKey: "title",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.query")} />,
    cell: ({ row }) => {
      const label = labels.find((label) => label.value === row.original.label);

      return (
        <div className="flex space-x-2">
          {label && <Badge variant="outline">{label.label}</Badge>}
          <span className="max-w-[500px] font-medium">{row.getValue("title")}</span>
        </div>
      );
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.status")} />,
    cell: ({ row }) => {
      const status = statuses.find((status) => status.value === row.getValue("status"));

      if (!status) {
        return null;
      }

      return (
        <div className="flex w-[100px] items-center">
          {status.icon && <status.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
          <span>{status.label}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },

  {
    accessorKey: "label",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.type")} />,
    cell: ({ row }) => {
      // console.log("row", row);
      const priority = labels.find((priority) => priority.value === row.getValue("label"));

      if (!priority) {
        return null;
      }

      return (
        <div className="flex items-center">
          {priority.icon && <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
          <span>{priority.label}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "actions",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.actions")} />,
    cell: ({ row }) => <DataTableRowActions row={row} onAction={(row: any, actionType: string) => onAction(row.original, actionType)} />,
  },
];
