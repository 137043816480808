import { FinancialRow } from "./reactgrid";

export interface OuterStep {
  id: string;
  role: "revenue" | "expense" | "total" | "asset" | "liability" | undefined;
}
export const outerStep: OuterStep[] = [
  { id: "Revenue", role: "revenue" },
  // { id: "Total income (loss). Add lines 3 through 5", role: "total" },

  { id: "Costs", role: "expense" },
  // { id: "Depreciation", role: "expense" },
  // { id: "Depreciation", role: "expense" },
  // { id: "Ebit Adjustments", role: "expense" },
  { id: "Net Income", role: "total" },
  { id: "Depreciation", role: "expense" },
  { id: "Interest Payment", role: "expense" },
  { id: "Other Applicable Taxes", role: "expense" },
  // { id: "EBIT", role: "total" },
  // { id: "Depreciation", role: "expense" },
  { id: "EBITDA", role: "total" },
  // { id: "Ebitda Adjustments", role: "expense" },
  { id: "Adjusted EBITDA", role: "total" },
  { id: "Adjustments to EBITDA", role: "expense" },
  // { id: "Tax", role: "expense" },
  // { id: "Change in Current Assets", role: "asset" },
  // { id: "Change in Current Liabilities", role: "expense" },
  // { id: "Change in Working Capital", role: "expense" },
  // { id: "Change in Long Term Assets", role: "asset" },

  // { id: "Capital Expenditures", role: "expense" },
  // { id: "Free Cash Flow", role: "total" },
  // { id: "Interest Paid", role: "expense" },
  // { id: "Payable Interest", role: "expense" },
  // { id: "Debt Starting Balance", role: "liability" },
  // { id: "Debt Repayment", role: "liability" },
  // // { id: "New Debt", role: "liability" },
  // // { id: "New Debt", role: "liability" },
  // { id: "Debt Ending Balance", role: "liability" },
  // { id: "Free Cash Flow to Equity", role: "total" },
];

export function buildWaterfallRowsFromJson(yearlyData: any[], outerSteps: OuterStep[]): FinancialRow[] {
  const rowMap: Record<string, FinancialRow> = {};

  // Simple helper: does a child's name basically match an outer step ignoring trailing "s"?
  // e.g. "Revenue" ~ "Revenues", "Cost" ~ "Costs"
  function isSimilarToOuterStep(name: string): boolean {
    const norm = name.toLowerCase().replace(/s$/, ""); // remove trailing 's'
    return outerSteps.some((s) => {
      const outerNorm = s.id.toLowerCase().replace(/s$/, "");
      return outerNorm === norm;
    });
  }

  // 1) Create one row for each outer step
  // console.log(rowMap, "Let's start");
  for (const step of outerSteps) {
    if (!rowMap[step.id]) {
      rowMap[step.id] = {
        id: step.id,
        displayName: step.id,
        role: step.role ?? "total",
        parentName: undefined,
        category: undefined,
        terms: [],
        valuesByYear: {},
        editableByYear: {},
        children: [],
      };
      // console.log(step.id, "does not exist");
    }
  }
  // console.log(step);

  // 2) Fill each outer step row’s year values, store .terms if any

  for (const step of outerSteps) {
    for (const yObj of yearlyData) {
      // console.log(step);
      const yearKey = String(yObj.year);
      const lineObj = yObj[step.id] || null;

      let val = 0;
      let hasTerms = false;
      let nested = false;
      if (lineObj) {
        if (typeof lineObj.value === "number") val = lineObj.value;
        else if (lineObj.value != null) val = Number(lineObj.value) || 0;

        hasTerms = Array.isArray(lineObj.terms) && lineObj.terms.length > 0;
        // if (hasTerms && rowMap[step.id]!.terms.length === 0) {
        if (hasTerms && rowMap[step.id]!.terms.length === 0) {
          rowMap[step.id]!.terms = lineObj.terms;
        }
      }
      rowMap[step.id]!.valuesByYear[yearKey] = val;
      rowMap[step.id]!.editableByYear[yearKey] = !hasTerms && !nested;
    }
  }
  // console.log;
  // if (rowMap["Revenue"]) return [];
  // console.log("checkpoit", rowMap);

  // for (const row of Object.keys(rowMap)) {
  //   console.log(row);
  //   if (row.split("-").length > 1) {
  //     const entry = row.split("-")[1];
  //     console.log(entry);
  //     for (const yObj of yearlyData) {
  //       if (entry && yObj[entry]) {
  //         // @ts-ignore
  //         for (const child of rowMap[row]) {
  //           console.log(child);
  //         }
  //       }
  //     }
  //   }
  // }
  // 3) Collect child references from each outer step’s .terms
  for (const step of outerSteps) {
    const topId = step.id;

    // gather all unique child names from all years’ .terms
    const childNames = new Set<string>();

    for (const yObj of yearlyData) {
      const lineObj = yObj[topId];
      if (!lineObj?.terms) continue;
      for (const t of lineObj.terms) {
        const childName = t.name;
        // skip if referencing itself, or if child is also an outer step,
        // or if child is "similar" to an outer step ignoring trailing 's'
        // console.log(t);
        if (childName === topId) {
          // console.log(true);
          continue;
        }
        if (isSimilarToOuterStep(childName)) {
          // console.log(true);
          continue;
        }

        childNames.add(childName);
      }
    }
    // console.log(childNames);
    // console.log(childNames);
    // 4) Create child rows
    for (const childName of childNames) {
      const childId = `${topId}--${childName}`;
      // console.log(childId);
      if (!rowMap[childId]) {
        rowMap[childId] = {
          id: childId,
          displayName: childName,
          role: "expense", // default; adjust if needed
          parentName: topId,
          category: undefined,
          terms: [],
          valuesByYear: {},
          editableByYear: {},
          children: [],
        };
        // console.log(rowMap[childId]);
      }

      // console.log(rowMap[childId], childId);
      // fill year values for the child
      for (const yObj of yearlyData) {
        const yearKey = String(yObj.year);
        let cVal = 0;
        let hasChildTerms = false;

        const parentLineObj = yObj[topId];
        const refInTerms = parentLineObj?.terms?.find((x: any) => x.name === childName);
        // Here we should add a second degree reference for items that are nested so we can update their update status and their value (the same way parent is defined)
        const directChildLine = yObj[childName];
        if (directChildLine && typeof directChildLine.value === "number") {
          // console.log(childName, directChildLine);
          cVal = directChildLine.value;
          // console.log(childName, directChildLine);
          if (Array.isArray(directChildLine.terms) && directChildLine.terms.length > 0) {
            // console.log("got here");
            // console.log(rowMap[childId], childId);
            hasChildTerms = true;
            if (rowMap[childId]!.terms.length === 0) {
              rowMap[childId]!.terms = directChildLine.terms;
              if ((rowMap[childId]?.terms?.length ?? 0) > 0) {
                // console.log("Hello", refInTerms);
              }
            }
          }
        } else if (refInTerms) {
          // fallback to the term’s .value
          // console.log(refInTerms);

          if (typeof refInTerms.value === "number") {
            // console.log("on est la", rowMap[childId]);
            cVal = refInTerms.value;
          } else if (refInTerms.value != null) {
            cVal = Number(refInTerms.value) || 0;
          }
        }
        // Here we should add a condition to capture the nested items wheteher they are editable and their value. We expect to not have more than 1 layer of grandchildren
        // console.log(rowMap[childId], childId);
        rowMap[childId]!.valuesByYear[yearKey] = cVal;
        rowMap[childId]!.editableByYear[yearKey] = !hasChildTerms;
        // console.log(childId);
        if (hasChildTerms) {
          // @ts-ignore
          for (const item of rowMap[childId]!.children) {
            item!.editableByYear[yearKey] = true;
          }
        }
      }
    }
  }

  // 5) Build final output in the same order as outerSteps, pushing each outer row and then its children
  const output: FinancialRow[] = [];
  // console.log(rowMap);
  for (const step of outerSteps) {
    const r = rowMap[step.id];
    if (!r) continue;
    output.push(r);

    // then all child rows
    const childRows = Object.values(rowMap).filter((x) => x.parentName === step.id);
    // console.log(childRows);
    output.push(...childRows);
  }

  return output;
}

export function revertWaterfallToJson(financialRows: FinancialRow[], outerSteps: OuterStep[]): any[] {
  const revertedData: Record<string, any>[] = [];

  // Extract unique years from financialRows
  const yearKeys = new Set<string>();

  financialRows.forEach((row) => {
    Object.keys(row.valuesByYear).forEach((year) => yearKeys.add(year));
  });
  // console.log(yearKeys);
  // console.log(financialRows);

  // Process each year separately
  for (const year of yearKeys) {
    const yearObj: Record<string, any> = { year: Number(year) };

    // Iterate through all financial rows
    for (const row of financialRows) {
      // Perform deep cloning of terms and extract year-specific values
      const termsForYear = row.terms
        ? row.terms.map((term) => {
            const clonedTerm = JSON.parse(JSON.stringify(term)); // Deep clone term object
            // console.log(term);
            return {
              ...clonedTerm,
              value:
                (
                  financialRows.find((item) => {
                    return item.displayName === term.name;
                  }) || {}
                ).valuesByYear?.[year] ?? 0, // Extract value for the current year
              year: year,
            };
          })
        : undefined;
      // if (row.terms && String(year) === "2023") {
      //   console.log(row);
      //   console.log(termsForYear);
      //   // console.log(term)
      //   return [];
      // }
      if (row.parentName === undefined) {
        // Parent step
        yearObj[row.id] = {
          value: row.valuesByYear[year] ?? 0,
          terms: termsForYear && termsForYear.length > 0 ? termsForYear : undefined, // Assign terms correctly
        };
      } else {
        // Child step
        yearObj[row.displayName] = {
          value: row.valuesByYear[year] ?? 0,
          terms: termsForYear && termsForYear.length > 0 ? termsForYear : undefined,
        };
      }
    }

    revertedData.push(yearObj);
  }

  return revertedData;
}
