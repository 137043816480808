import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../../components/ui/card";
import { Separator } from "../../../components/ui/separator";
import { ScrollArea } from "../../../components/ui/scroll";
import React from "react";
import { GoogleMap, LoadScript, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { Phone } from "lucide-react";
import { FcGoogle } from "react-icons/fc";
import { backendClient } from "../../../api/backend";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Sheet, SheetClose, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle, SheetTrigger } from "../../../components/ui/sheet";
import AlertDestructive from "./AlertDestructive";
import { useSelector } from "react-redux";
const maps_api = process.env.REACT_APP_GOOGLE_MAPS || "";
const geocode_api = process.env.REACT_APP_GEOCODE_API || "";

const BusinessCard = ({ transaction }) => {
  // console.log(transaction);
  const { t } = useTranslation();
  return (
    <Card className="w-[40%] max-h-[100%] max-w-md shadow-none border absolute top-[0%] right-[0%]">
      <ScrollArea className="h-[24rem] w-full rounded-md border z-12">
        <CardHeader className="p-4">
          <div className="flex items-start">
            <div className="ml-4">
              <h3 className="text-xl font-bold">{transaction.name}</h3>
              <p className="text-sm text-gray-500">{transaction.address}</p>
              {/* <p className="text-sm text-gray-500">(941) 727-8000</p> */}
              <div className="flex items-center text-sm">
                <div className="flex items-center  mr-2">
                  {/* <span className="font-bold ">4.8</span> */}
                  {/* <span className="ml-1 text-yellow-500">⭐</span> */}
                </div>
                {/* <p className="text-gray-500">(203 Google reviews)</p> */}
              </div>
              {/* <p className="text-black font-bold mt-1">Distance: 1.6 mi</p> */}
            </div>
          </div>
        </CardHeader>
        <Separator />
        <CardContent className="p-4 ">
          {/* <Separator /> */}
          <div className="flex flex-col max-h-full overflow-auto px-2">
            <h4 className="font-bold text-lg mb-2">
              {
                // @ts-ignore
                t("overview_.businessCard.ai_summary")
              }
            </h4>
            <p className="text-green-600 font-bold mb-2">
              {
                // @ts-ignore
                t("overview_.businessCard.pros")
              }
            </p>
            <ul className="list-disc pl-4 text-sm text-gray-700">
              {transaction.competitionSummary?.pros &&
                transaction.competitionSummary.pros.map((e, i) => {
                  return <li key={i}>{e}</li>;
                })}
            </ul>
            <p className="text-red-600 font-bold mt-2 mb-2">
              {
                // @ts-ignore
                t("overview_.businessCard.cons")
              }
            </p>
            <ul className="list-disc pl-4 text-sm text-gray-700">
              {transaction.competitionSummary?.cons &&
                transaction.competitionSummary.cons.map((e, i) => {
                  return <li key={i}>{e}</li>;
                })}
            </ul>
          </div>
        </CardContent>
      </ScrollArea>
    </Card>
  );
};
const StorageCard = ({ transaction, data, call = false }) => {
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = React.useState<any>(null);
  const [pcall, setCall] = React.useState(false);
  // console.log(imageUrl);
  // Extract IDs from `data`
  const dealId = data?.dealId;
  const placeId = data?.id;
  // console.log(data);
  const photoReference = data.photos && data.photos.length > 0 ? data.photos[0]?.photoReference : null; // API reference
  const geti = async () => {
    try {
      const img = await backendClient.getImg(transaction.id, placeId, photoReference);
      setImageUrl(img);
    } catch (error) {
      console.error("Error fetching image:", error);
      setImageUrl(null);
    }
  };
  // ✅ Construct API URL dynamically
  React.useEffect(() => {
    if (!photoReference) return;
    geti();
  }, [dealId, placeId, photoReference]); // ✅ Dependencies: only fetch when these change

  return (
    <Card className="w-full flex flex-col flex-grow lg:flex-row gap-4 p-4 shadow-none border-none rounded-none hover:bg-muted hover:cursor-pointer">
      {/* Image Section */}
      <img src={imageUrl} alt={data.title} className="w-32 h-32 object-cover rounded-lg" />

      {/* Details Section */}
      <div className="flex flex-col w-[70%]">
        <CardHeader className="p-0">
          <CardTitle className="text-md font-bold">{data.title}</CardTitle>
          <p className="text-sm text-gray-500">{data.address}</p>
          <p className="text-sm text-gray-500">{data.phone}</p>
        </CardHeader>
        <CardContent className="p-0 mt-2">
          <p className="text-black font-bold">
            {" "}
            {
              // @ts-ignore
              t("overview_.storageCard.distance", { distance: (data.distance / 1000).toFixed(1) })
            }
          </p>
        </CardContent>
        {/* Rating and Website Section */}
        <div className="flex flex-1 items-center gap-4">
          <div className="flex flex-col items-center gap-1 justify-start">
            <div className="w-full flex items-center justify-start">
              <FcGoogle className="mr-2 size-5" />
              <span className="font-bold mr-1">{Number(data.rating).toFixed(1)}</span>
              <span className="text-yellow-500 text-md">⭐</span>
            </div>

            <span className="text-sm text-gray-500">
              {
                // @ts-ignore
                t("reviews.google_reviews", { count: data.reviews })
              }
            </span>
          </div>
          <a href={data.website} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-sm flex items-center gap-1">
            {
              // @ts-ignore
              t("reviews.visit_website")
            }
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6.75H19.5M19.5 6.75V12.75M19.5 6.75L7.5 18.75" />
            </svg>
          </a>
        </div>
      </div>
      {call && (
        <CardContent className="w-full flex items-start justify-end">
          <Button disabled={!pcall} className="ml-auto" onClick={() => {}}>
            {
              // @ts-ignore
              "Schedule Call"
            }
            <Phone className="" />
          </Button>
        </CardContent>
      )}
    </Card>
  );
};

// Main Component
const StorageList = ({ transaction, place }) => {
  const { t } = useTranslation();
  const scrollAreaRef = React.useRef<HTMLDivElement>(null);
  const sheetHeaderRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);
  const setScrollAreaHeight = () => {
    if (scrollAreaRef.current && sheetHeaderRef.current) {
      const parentHeight = scrollAreaRef.current.parentElement?.offsetHeight;
      const headerHeight = sheetHeaderRef.current.offsetHeight;
      // console.log("parentHeight", parentHeight);
      // console.log("headerHeight", headerHeight);
      if (parentHeight && headerHeight) {
        scrollAreaRef.current.style.height = `calc(${parentHeight}px - ${headerHeight}px - 4rem)`;
      }
    }
  };

  React.useEffect(() => {
    setScrollAreaHeight();
    window.addEventListener("resize", setScrollAreaHeight);
    return () => {
      window.removeEventListener("resize", setScrollAreaHeight);
    };
  }, [open]);

  React.useEffect(() => {
    if (open) {
      setTimeout(() => {
        setScrollAreaHeight();
      }, 300); // Adjust the timeout duration as needed
    }
  }, [open]);

  return (
    <div className="space-y-4">
      <p className="font-bold ml-2 mt-3 text-sm">
        {
          // @ts-ignore
          t("overview_.similar_opportunities")
        }
      </p>
      <div className="flex">
        <div className="min-w-[60%]">
          {[place[0]].map((location, index) => {
            const imageUrl = location?.photos;

            return (
              <div key={index} className="w-full">
                <StorageCard
                  transaction={transaction}
                  data={{
                    photos: imageUrl,
                    id: location?.id,
                    title: location?.displayName?.text || "No Name Available",
                    address: location?.formattedAddress || "No Address Available",
                    phone: location?.phone || "No Phone Available",
                    website: location?.websiteUri || "#",
                    rating: location?.rating || "N/A",
                    reviews: location?.reviews?.length || 0,
                    distance: location?.distance || "Not provided",
                  }}
                />
                {index !== place.length - 1 && <Separator />}
              </div>
            );
          })}
        </div>
        <div className="w-full">
          <Card className="p-6 rounded-none border-none shadow-none hover:bg-muted hover:cursor-pointer flex flex-col w-full h-full flex-grow">
            <CardTitle>
              {
                // @ts-ignore
                t("overview_.off_market.best_deals")
              }
            </CardTitle>
            <CardDescription>
              {
                // @ts-ignore
                t("overview_.off_market.find_prospects")
              }
            </CardDescription>
            <CardContent className="flex mt-4 justify-end w-full  h-full">
              <Sheet open={open} onOpenChange={setOpen}>
                <SheetTrigger asChild>
                  <Button>
                    <span className="mr-2">
                      {
                        // @ts-ignore
                        t("overview_.off_market.get_off_market_deals")
                      }
                    </span>{" "}
                    &rarr;
                  </Button>
                </SheetTrigger>
                <SheetContent side={"right"} className="h-full min-w-[75%]">
                  <SheetHeader ref={sheetHeaderRef}>
                    {" "}
                    <AlertDestructive
                      setCustomDebt={0}
                      buttonText={`t("alert.set_maximum_debt")`}
                      title={
                        // @ts-ignore
                        t("overview_.off_market.feature_under_development")
                      }
                      description={
                        // @ts-ignore
                        t("overview_.off_market.waitlist_signup")
                      }
                      variant={0}
                    />
                    {/* <SheetTitle className="mt-4"> */} {/* </SheetTitle> */}
                    <SheetTitle>
                      {
                        // @ts-ignore
                        t("overview_.off_market.off_market_deals")
                      }
                    </SheetTitle>
                    <SheetDescription>
                      {
                        // @ts-ignore
                        t("overview_.off_market.ai_agent_tasks")
                      }
                    </SheetDescription>
                  </SheetHeader>

                  <ScrollArea ref={scrollAreaRef} className="mt-2 w-full rounded-md z-12">
                    {" "}
                    {place.map((location, index) => {
                      const photoReference = location.photos?.[0]?.name ? location.photos?.[0]?.name.split("/").pop() : undefined; // Extract reference from 'name'

                      const imageUrl = location?.photos;

                      return (
                        <div key={index} className="w-full">
                          <StorageCard
                            transaction={transaction}
                            data={{
                              photos: imageUrl,
                              id: location.id,
                              title: location.displayName?.text || "No Name Available",
                              address: location.formattedAddress || "No Address Available",
                              phone: location.phone || "No Phone Available",
                              website: location.websiteUri || "#",
                              rating: location.rating || "N/A",
                              reviews: location.reviews?.length || 0,
                              distance: location.distance || "Not provided",
                            }}
                            call={true}
                          />
                          {index !== place.length - 1 && <Separator />}
                        </div>
                      );
                    })}
                  </ScrollArea>
                </SheetContent>
              </Sheet>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

// export default StorageList;
const MapCard = ({ transaction, place }) => {
  // console.log(place);
  const [markerPosition, setMarkerPosition] = React.useState<{ id: any; position: { lat: number; lng: number }; title: string }[]>([]);
  const organization = useSelector((state: any) => state.auth.organization);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: maps_api,
  });

  const getLocation = async (api_key, address) => {
    try {
      const data = await backendClient.fetchLocation(api_key, address);
      // console.log("Fetched location data:", data, address);

      if (data && data.length > 0) {
        const location = data[0]; // Access the first result

        // if (location.boundingbox) {
        //   setPolygonContains(transformToLonLat(location.boundingbox));
        // }

        if (location.lat && location.lon) {
          setMarkerPosition([
            {
              id: "1",
              title: `Your ${transaction?.industry || "Business"} Opportunities`,
              position: {
                lat: parseFloat(location.lat),
                lng: parseFloat(location.lon),
              },
            },
          ]);
        }
      } else {
        console.warn("No location data found");
      }
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  React.useEffect(() => {
    getLocation(geocode_api, transaction.municipality ?? transaction.city ?? transaction.state);
  }, [transaction, geocode_api]);

  const { t } = useTranslation();
  if (loadError) return <div>Error loading Google Maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Card className="col-span-2 max-w-full">
      <CardHeader>
        <CardTitle>
          {
            // @ts-ignore
            t("overview.similar_opportunities")
          }
        </CardTitle>
      </CardHeader>
      <CardContent className="p-0">
        {/* Google Map Integration */}
        <div className="h-96 w-full relative">
          {markerPosition.length > 0 && markerPosition[0]?.position && (
            <>
              <GoogleMap mapContainerStyle={{ height: "100%", width: "100%" }} center={markerPosition[0].position} zoom={10} mapContainerClassName="">
                {markerPosition.map((marker) => (
                  <MarkerF key={marker.id} position={marker.position} title={marker.title} />
                ))}
              </GoogleMap>
            </>
          )}
          <BusinessCard transaction={transaction} />
        </div>

        {organization.type !== "lender" && <StorageList transaction={transaction} place={place} />}
      </CardContent>
    </Card>
  );
};

export default MapCard;
