import React, { useEffect, useState, useCallback } from "react";
import { z } from "zod";

import { getColumns } from "./columns";
import { DataTable } from "./DataTable";
import { toast } from "sonner";

import { taskSchema } from "../data/schema";
import Spinner from "../../../components/ui/Spinner";
import EmptyPipeline from "./nodeal";
import { DropdownMenuaction } from "./buttonaction";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions } from "../../../redux/actions/transactionAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { store } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import { fetchCashflowData, updateMinideal } from "../../../redux/actions/cashflowAction";
import { extractFinancialItem } from "../../../utils/utils";
import IRR from "../../CapexPlanScreen/_irrResult";
import Payback from "../../CapexPlanScreen/_payback";
import { calculatePaperValuation } from "../../../redux/actions/util";
import { backendClient } from "../../../api/backend";

function mapCashFlow(data, investment: number, exitValue: number) {
  // Sort by year
  const years = data.map((item) => item.year).sort((a, b) => a - b);
  const firstYear = years[0];
  const lastYear = years[years.length - 1];

  const mappedCashFlows: any[] = [];

  // Add the investment (negative) 1 year before firstYear
  mappedCashFlows.push({
    year: firstYear - 1,
    freeCashFlow: investment,
    isForecasted: false,
  });

  // Map each FCF
  data.forEach((item) => {
    if (!item.isForecasted) return;
    mappedCashFlows.push({
      year: item.year,
      freeCashFlow: item["Free Cash Flow"]?.value ?? Number(item.cashFlow),
      isForecasted: item.isForecasted,
    });
  });

  // Add the exitValue (positive) 1 year after lastYear
  mappedCashFlows.push({
    year: lastYear + 1,
    freeCashFlow: exitValue,
    isForecasted: false,
  });

  return mappedCashFlows;
}

function calculateUnleveredIRR(cashFlows) {
  cashFlows.sort((a, b) => a.year - b.year);
  const values = cashFlows.map((cf) => Number(cf.freeCashFlow));
  // console.log("values", values);
  return IRR(values, 0.1) * 100;
}

function calculatePaybackPeriod(cashFlows) {
  cashFlows.sort((a, b) => a.year - b.year);
  const cashFlowValues = cashFlows.map((cf) => Number(cf.freeCashFlow));
  return Payback(cashFlowValues, 0);
}

function calculateCashOnCashReturn(cashFlows) {
  cashFlows.sort((a, b) => a.year - b.year);
  const cashFlowValues = cashFlows.map((cf) => Number(cf.freeCashFlow));
  console.log("cashFlowValues", cashFlowValues);
  const totalPositiveCF = cashFlowValues.reduce((acc, v) => (v > 0 ? acc + v : acc), 0);
  const totalNegativeCF = cashFlowValues.reduce((acc, v) => (v < 0 ? acc + v : acc), 0);
  console.log("totalPositiveCF", totalPositiveCF);
  console.log("totalNegativeCF", totalNegativeCF);
  return totalPositiveCF / Math.abs(totalNegativeCF || 1);
}

function extractLast(cashflow, itemName: string) {
  if (!cashflow || !cashflow.length) return null;
  // @ts-ignore
  const item = extractFinancialItem(cashflow, itemName, null, false);
  return item && item.length > 0 ? item[item.length - 1] : null;
}

// -------------------

type Task = {
  id: string;
  name: string;
  askPrice: number;
  estPrice: string;
  industry: string;
  country: string;
  pipelineStatus: string;
  city: string;
  seller: string;
  addtocompare: boolean;
  // These are dynamic metrics:
  purchasePrice: number; // <-- We'll store local state here
  irr?: number;
  payback?: number;
  cashOnCashReturn?: number;
  EBITDAMultiple?: number;
  equityMultiple?: number;
  cachedCashFlow?: any[]; // For completed deals
  cachedProjections?: any[]; // For non-completed deals (paper valuation)
  revenueMultiple?: number; // might need these for recalc
  earningsMultiple?: number;
  revenue?: number;
  ebitda?: number;
  cashFlow?: number;
  uploadStatus?: string;
  // NEW: user-defined Score
  score?: number;
};

// If you want to keep your old logic for estimated price:
const addEstimatedPrice = (deals) => {
  return deals.map((deal) => {
    const earningsPrice = deal.earningsMultiple * deal.cashFlow;
    const revenuePrice = deal.revenueMultiple * deal.revenue;

    const minPrice = Math.floor(Math.max(Math.min(earningsPrice, revenuePrice), 0));
    const maxPrice = Math.floor(Math.max(earningsPrice, revenuePrice));
    const c = deal.city ? deal.city : "new york";

    return {
      ...deal,
      estPrice: `${minPrice.toLocaleString()} - ${maxPrice.toLocaleString()}`,
      city: c,
    };
  });
};
function getLastYearValue(data) {
  if (data.length === 0) {
    throw new Error("The data array is empty.");
  }

  // Sort the data by year in ascending order
  const sortedData = data.sort((a, b) => a.year - b.year);

  // Get the last year object (largest year)
  const lastYearObject = sortedData[sortedData.length - 1];

  return lastYearObject["Revenue"];
}

function recalcMetricsForDeal(deal: Task, newPurchasePrice: number): Partial<Task> {
  let purchasePrice = newPurchasePrice;
  let irr = 0;
  let payback = 0;
  let cashOnCashReturn = 0;
  let EBITDAMultiple = 0;
  let equityMultiple = 0;

  try {
    if (deal.uploadStatus === "completed" && deal.cachedCashFlow) {
      // We have real data in deal.cachedCashFlow
      // Example exit assumption:
      const exitValue =
        getLastYearValue(
          // @ts-ignore
          extractFinancialItem(deal.cachedCashFlow, "Revenue", null, true),
        ) * (deal.revenueMultiple || 1);

      const mappedCashFlows = mapCashFlow(deal.cachedCashFlow, -purchasePrice, exitValue);
      irr = calculateUnleveredIRR(mappedCashFlows);
      payback = calculatePaybackPeriod(mappedCashFlows);
      cashOnCashReturn = calculateCashOnCashReturn(mappedCashFlows);

      // PotentialProfit / multiples:
      // If you want final-year EBITDA
      const lastEBITDA = extractLast(deal.cachedCashFlow, "Adjusted EBITDA");
      const lastRevenue = extractLast(deal.cachedCashFlow, "Revenue");

      const ebitdaValue = lastEBITDA ? lastEBITDA["Adjusted EBITDA"] : deal.ebitda;
      const revenueValue = lastRevenue ? lastRevenue["Revenue"] : deal.revenue;

      EBITDAMultiple = purchasePrice / (ebitdaValue || 1);
      equityMultiple = purchasePrice / (revenueValue || 1);
    } else if (deal.cachedProjections) {
      // For non-completed deals, we have "paper" forecasts in cachedProjections
      const comp = deal.cachedProjections.map((item) => {
        return { ...item, isForecasted: true };
      });
      console.log("This is happening", deal.cachedProjections);
      const mappedCashFlows = mapCashFlow(
        comp,
        -purchasePrice,
        0, // or some exit assumption if you like
      );
      console.log("mappedcash", mappedCashFlows);
      irr = calculateUnleveredIRR(mappedCashFlows);
      payback = calculatePaybackPeriod(mappedCashFlows);
      cashOnCashReturn = calculateCashOnCashReturn(mappedCashFlows);

      EBITDAMultiple = purchasePrice / (deal.ebitda || 1);
      equityMultiple = purchasePrice / (deal.revenue || 1);
    }
  } catch (err) {
    console.error("Failed to recalc metrics", err);
  }

  return {
    purchasePrice,
    irr,
    payback,
    cashOnCashReturn,
    EBITDAMultiple,
    equityMultiple,
  };
}

export default function TaskPage() {
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [sel, setSel] = useState(0);
  const [val, setVal] = useState(null);
  // Grab your list of transactions from Redux
  const transactions = useSelector((state: any) => state.transactions?.transactions);

  const { t } = useTranslation();

  // ---------------------------------------
  // (A) Fetch data ONCE and store locally
  // ---------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        // 1) Ensure transactions are loaded
        if (!transactions || transactions.length === 0) {
          await dispatch(fetchTransactions());
        }

        // 2) Filter only deals we want to compare
        const deals = (transactions || []).filter((t) => t.addToCompare);

        // 3) For each deal, fetch any needed data
        const tasksWithMetrics: Task[] = [];
        for (const deal of deals) {
          let cachedCashFlow: any[] | undefined = undefined;
          let cachedProjections: any[] | undefined = undefined;

          // If "completed", fetch actual CF once
          if (deal.uploadStatus === "completed") {
            const { cashFlow } = await backendClient.fetchCashflow(deal.id);
            cachedCashFlow = cashFlow;
          } else {
            // If not completed, build the "paper" forecast once
            const miniDeal = calculatePaperValuation(deal.revenue, deal.ebitda, deal.cashFlow, deal.earningsMultiple, deal.revenueMultiple);
            cachedProjections = miniDeal.projections;
            console.log(cachedProjections);
          }

          // 4) Decide default purchase price
          const { PP } = await backendClient.fetchPurchasePrice(deal.id);

          // Ensure `extractLast` calls are safe
          const revenue = extractLast(cachedCashFlow, "Revenue")?.["Revenue"] || deal.revenue;
          const earnings = (extractLast(cachedCashFlow, "Taxable Income")?.["Taxable Income"] || deal.cashFlow) + (extractLast(cachedCashFlow, "Tax")?.["Tax"] || 0);

          // Compute price estimates
          const earningsPrice = deal.earningsMultiple * earnings;
          const revenuePrice = deal.revenueMultiple * revenue;
          const minPrice = Math.floor(Math.max(Math.min(earningsPrice, revenuePrice), 0));
          const maxPrice = Math.floor(Math.max(earningsPrice, revenuePrice));
          const defaultPurchasePrice = PP?.purchasePrice ?? maxPrice;

          // 5) Recalculate metrics using the data we’ve just fetched
          //    This is synchronous now
          const metrics = recalcMetricsForDeal(
            {
              ...deal,
              cachedCashFlow,
              cachedProjections,
            },
            defaultPurchasePrice,
          );

          // 6) Insert everything into a new Task object
          tasksWithMetrics.push({
            ...deal,
            cachedCashFlow,
            cachedProjections,
            // old estimated price logic:
            estPrice: `${minPrice.toLocaleString()} - ${maxPrice.toLocaleString()}`,
            ...metrics,
          });
        }

        setTasks(tasksWithMetrics);
      } catch (err) {
        console.error(err);
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, transactions]);

  // ---------------------------------------
  // (B) Handle user changing the slider
  // ---------------------------------------
  const handlePurchasePriceChange = useCallback(
    (taskId: string, newPrice: number) => {
      // 1) Find the existing task
      const existingTask = tasks.find((t) => t.id === taskId);
      if (!existingTask) return;

      // 2) Recalc metrics with the local data
      const updatedMetrics = recalcMetricsForDeal(existingTask, newPrice);

      // 3) Merge them back into tasks
      setTasks((prev) =>
        prev.map((t) =>
          t.id === taskId
            ? {
                ...t,
                ...updatedMetrics,
              }
            : t,
        ),
      );
    },
    [tasks.length],
  ); // Only update when tasks length changes
  // Add a new callback to update the score:
  const handleScoreChange = useCallback((taskId: string, newScore: number) => {
    setTasks((prev) => prev.map((task) => (task.id === taskId ? { ...task, score: newScore } : task)));
  }, []);
  // Then, pass that callback into your columns:
  const columns = React.useMemo(() => getColumns(t, handlePurchasePriceChange, handleScoreChange), [t, handlePurchasePriceChange, handleScoreChange]);
  console.log(tasks);
  if (loading) {
    return (
      <div className="flex flex-col mt-60 mx-auto h-full">
        <Spinner size={72} />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <div className="hidden h-full flex-1 flex-col space-y-8 p-2 md:flex">
        <div className="flex items-center justify-between space-y-2">
          <div className="w-[70%]">
            <h2 className="text-2xl font-bold tracking-tight">
              {
                // @ts-ignore
                "Benchmarking"
              }
            </h2>
            <p className="text-muted-foreground">
              {
                // @ts-ignore
                "Here you will find a list of opportunities that are being compared"
              }
            </p>
          </div>
        </div>
        {tasks.length === 0 ? (
          <EmptyPipeline />
        ) : (
          <>
            <DataTable data={tasks} columns={columns} setVal={setVal} setSel={setSel} />
          </>
        )}
      </div>
    </>
  );
}
