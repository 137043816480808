import { RTVIClient } from "@pipecat-ai/client-js";
import { RTVIClientAudio, RTVIClientProvider } from "@pipecat-ai/client-react";
import { DailyTransport } from "@pipecat-ai/daily-transport";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { backendUrl } from "../../config";
import { useAppState } from "../../hooks/useAppState";
import { getPrompt } from "../components/prompt";
import ChatControls from "./ChatControls";
import { backendClient } from "../../api/backend";
const defaultRequestData = {
  bot_profile: "vision",
};

export function ClientPage() {
  const voiceAIActive = useSelector((state: any) => state.prompt.openVoiceAI);

  const transaction = useSelector((state: any) => state.transactions.transaction);
  const transactions = useSelector((state: any) => state.transactions?.transactions);
  const minideal = useSelector((state: any) => state.cashflow.miniDeal);
  const tableData = useSelector((state: any) => state.cashflow.tableData);
  const cimprompt = useSelector((state: any) => state?.prompt?.prompt ?? "");
  const organization = useSelector((state: any) => state?.auth?.organization ?? "");
  const savedsu = useSelector((state: any) => state.cashflow.tranches);

  // console.log(prompt);
  const [client, setClient] = useState<RTVIClient>();
  //SIMPLIFIED CLIENT
  // 1. Memoize the prompt itself
  const memoPrompt = React.useMemo(() => getPrompt(transaction, transactions, tableData, minideal, organization, savedsu), [transaction, transactions, tableData, minideal, organization, savedsu]);
  // console.log(memoPrompt);

  // 2. Memoize the request data
  const requestData = React.useMemo(
    () => ({
      bot_profile: "vision",
      conversation_id: "",
      deal_id: transaction?.id,
      ...(voiceAIActive ? { offer_memorandum_context_prompt: cimprompt } : { deal_context_prompt: memoPrompt }),
    }),
    [transaction?.id, voiceAIActive, cimprompt, memoPrompt],
  );

  const voice = async () => {
    const voice = await backendClient.fetchvoicePrompt("system_lender");
    console.log(voice);
  };
  // voice();
  useEffect(() => {
    voice();
  }, []);

  useEffect(() => {
    const newClient = new RTVIClient({
      enableCam: false,
      enableMic: true,
      transport: new DailyTransport(),
      params: {
        baseUrl: `${backendUrl}/v1/api`,
        endpoints: {
          connect: "/bot/connect",
          action: "/bot/action",
        },
        requestData,
      },
    });

    setClient(newClient);
  }, [requestData]);

  useEffect(() => {
    if (!client) return;
    const isConnected = client.connected;
    const isConnecting = client.state === "authenticating" || client.state === "connecting";
    if (isConnecting || isConnected) {
      client.disconnect();
    }
  }, [client, requestData]);

  return (
    <>
      {client && (
        <RTVIClientProvider client={client}>
          <ChatControls vision />

          <RTVIClientAudio />
        </RTVIClientProvider>
      )}
    </>
  );
}
