"use client";

import { ColumnDef } from "@tanstack/react-table";

import { Badge } from "../../../components/ui/badge";
import { Checkbox } from "../../../components/ui/checkbox";

import { labels, priorities, statuses } from "../data/data";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";
import { DataTableRowActions } from "./data-table-row-actions";
import { FinancialMetricTooltip } from "./Audit";

// Create type for the financialData
type FinancialData = {
  value: Record<string, any>;
  revenuePercentage: Record<string, any>;
  yoy: Record<string, any>;
};

export const getColumns = (t: Function, onAction: (rowData: Task, actionType: string) => void): ColumnDef<Task>[] => [
  {
    id: "select",
    header: ({ table }) => <Checkbox checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")} onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)} aria-label="Select all" className="translate-y-[2px]" />,
    cell: ({ row }) => <Checkbox checked={row.getIsSelected()} onCheckedChange={(value) => row.toggleSelected(!!value)} aria-label="Select row" className="translate-y-[2px]" />,
    enableSorting: false,
    enableHiding: false,
  },

  {
    accessorKey: "title",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.query")} />,
    cell: ({ row }) => {
      const label = labels.find((label) => label.value === row.original.label);

      // Check if the item has hierarchy data
      const hasHierarchy = !!row.original.hierarchy && typeof row.original.hierarchy === "object";

      // Extract financial data from the hierarchy
      let financialData: FinancialData | null = null;

      if (hasHierarchy && row.original.hierarchy) {
        const hierarchy = row.original.hierarchy as Record<string, any>;

        try {
          // Get the first key in the hierarchy object
          const hierarchyKeys = Object.keys(hierarchy);

          if (hierarchyKeys.length > 0) {
            // Try to get the first key
            const firstKey = hierarchyKeys[0];
            // @ts-ignore
            const hierarchyData = hierarchy[firstKey];

            if (hierarchyData) {
              // Check if the data is directly under this key
              if (hierarchyData.value || hierarchyData.yoy || hierarchyData.revenuePercentage) {
                financialData = {
                  value: hierarchyData.value || {},
                  revenuePercentage: hierarchyData.revenuePercentage || {},
                  yoy: hierarchyData.yoy || {},
                };
              }
              // Check if there's a categories property with data inside
              else if (hierarchyData.categories) {
                const categories = hierarchyData.categories as Record<string, any>;
                // Get the first key in the categories object
                const categoryKeys = Object.keys(categories);

                if (categoryKeys.length > 0) {
                  const firstCategoryKey = categoryKeys[0];
                  // @ts-ignore
                  const categoryData = categories[firstCategoryKey];

                  if (categoryData) {
                    financialData = {
                      value: categoryData.value || {},
                      revenuePercentage: categoryData.revenuePercentage || {},
                      yoy: categoryData.yoy || {},
                    };
                  }
                }
              }
            }
          }
        } catch (error) {
          console.error("Error extracting financial data:", error);
        }
      }

      return (
        <div className="flex space-x-2 items-center">
          {label && <Badge variant="outline">{label.label}</Badge>}
          <span className="max-w-[500px] font-medium">{row.getValue("title")}</span>
          {hasHierarchy && financialData && (
            <>
              <FinancialMetricTooltip data={financialData} label={row.original.label} />
            </>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.status")} />,
    cell: ({ row }) => {
      const status = statuses.find((status) => status.value === row.getValue("status"));

      if (!status) {
        return null;
      }

      return (
        <div className="flex w-[100px] items-center">
          {status.icon && <status.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
          <span>{status.label}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "formattedval",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.estimatedValue")} />,
    cell: ({ row }) => {
      return (
        <div className="flex w-[100px] items-center">
          {/* {status.icon && <status.icon className="mr-2 h-4 w-4 text-muted-foreground" />} */}
          <span>{row.getValue("formattedval")}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "priority",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.priority")} />,
    cell: ({ row }) => {
      const priority = priorities.find((priority) => priority.value === row.getValue("priority"));

      if (!priority) {
        return null;
      }

      return (
        <div className="flex items-center">
          {priority.icon && <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
          <span>{priority.label}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "actions",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.actions")} />,
    cell: ({ row }) => <DataTableRowActions row={row} onAction={(row: any, actionType: string) => onAction(row.original, actionType)} />,
  },
];
